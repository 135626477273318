import axios from "axios";
import { CONFIG } from "../../shared/config/config";
import { BACKOFFICE_API } from "../apis/backoffice-apis";
import { GET_BASIC_HEADER } from "./api-header";

const Axios = axios.create({
  baseURL: CONFIG.DCIR_HOST,
});

// async function createAPIRequest(requestType, payload ,url) {
//   const header = GET_BASIC_HEADER();
//   return new Promise((resolve, reject) => {
//     try {
//     const response = requestType === "get" ? await Axios.get(url): await Axios[requestType](url, payload, {headers: header})
//     resolve(response)
//   } catch(error) {
//     reject(error)
//   }
//   })
// }

function getAllUsers(params) {
  const url = `${BACKOFFICE_API.GET_ALL_USERS}?${params}`;
  const header = GET_BASIC_HEADER();
  return new Promise((resolve, reject) => {
    Axios.get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getUserRoles() {
  const url = BACKOFFICE_API.GET_USER_ROLES;
  const header = GET_BASIC_HEADER();
  return new Promise((resolve, reject) => {
    Axios.get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function createAdUser(payload) {
  const url = BACKOFFICE_API.CREATE_AD_USER;
  const header = GET_BASIC_HEADER();
  return new Promise((resolve, reject) => {
    Axios.post(url, payload, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function createLocalUser(payload) {
  const url = BACKOFFICE_API.CREATE_LOCAL_USER;
  const header = GET_BASIC_HEADER();
  return new Promise((resolve, reject) => {
    Axios.post(url, payload, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function uploadBulkUsers(params, payload) {
  const url = `${BACKOFFICE_API.UPLOAD_BULK_USERS}?${params}`;
  const header = GET_BASIC_HEADER();
  return new Promise((resolve, reject) => {
    Axios.post(url, payload, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getUsersByRole(params, role) {
  const url = `${BACKOFFICE_API.GET_USERS_BY_ROLE}/${role}?${params}`;
  const header = GET_BASIC_HEADER();
  return new Promise((resolve, reject) => {
    Axios.get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getUserByUsername(params) {
  const url = `${BACKOFFICE_API.GET_USER_BY_USERNAME}?${params}`;
  const header = GET_BASIC_HEADER();
  return new Promise((resolve, reject) => {
    Axios.get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getSingleUser(userId) {
  const url = `${BACKOFFICE_API.GET_SINGLE_USER}/${userId}`;
  const header = GET_BASIC_HEADER();
  return new Promise((resolve, reject) => {
    Axios.get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getAdUserByUsername(params) {
  const url = `${BACKOFFICE_API.GET_AD_USER_BY_USERNAME}?${params}`;
  const header = GET_BASIC_HEADER();
  return new Promise((resolve, reject) => {
    Axios.get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function unblockUser(payload) {
  const url = BACKOFFICE_API.UNBLOCK_USER;
  const header = GET_BASIC_HEADER();
  return new Promise((resolve, reject) => {
    Axios.put(url, payload, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getTransactionsOnRequery(params, transactionType) {
  const url = `${
    transactionType === "disputes"
      ? BACKOFFICE_API.GET_DISPUTES_ON_REQUERY
      : BACKOFFICE_API.GET_SETTLEMENTS_ON_REQUERY
  }?${params}`;
  const header = GET_BASIC_HEADER();
  return new Promise((resolve, reject) => {
    Axios.get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function uploadReconciliationFile(payload) {
  const url = BACKOFFICE_API.UPLOAD_RECONCILIATION_FILE;
  const header = GET_BASIC_HEADER();
  return new Promise((resolve, reject) => {
    Axios.post(url, payload, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getUploadReconciliationFiles(params) {
  const url = `${BACKOFFICE_API.GET_UPLOADED_RECONCILIATION_FILES}?${params}`;
  const header = GET_BASIC_HEADER();
  return new Promise((resolve, reject) => {
    Axios.get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getUploadReconciliationFilesByDays(params) {
  const url = `${BACKOFFICE_API.GET_UPLOADED_RECONCILIATION_FILES_BY_DAYS}?${params}`;
  const header = GET_BASIC_HEADER();
  return new Promise((resolve, reject) => {
    Axios.get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function updateTransactionOnRequery(id, params, transactionType) {
  const url = `${
    transactionType === "disputes"
      ? BACKOFFICE_API.UPDATE_DSIPUTE_ON_REQUERY
      : BACKOFFICE_API.UPDATE_SETTLEMENT_ON_REQUERY
  }/${id}?${params}`;
  const header = GET_BASIC_HEADER();
  return new Promise((resolve, reject) => {
    Axios.put(url, {}, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function downloadTransactionsOnRequery(payload, transactionType) {
  const url = `${
    transactionType === "settlements"
      ? BACKOFFICE_API.DOWNLOAD_SETTLEMENTS_ON_REQUERY
      : BACKOFFICE_API.DOWNLOAD_DISPUTES_ON_REQUERY
  }`;
  const header = GET_BASIC_HEADER();
  return new Promise((resolve, reject) => {
    Axios.put(url, payload, { headers: header, responseType: "blob" })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function downloadSuperAgents(payload) {
  const url = BACKOFFICE_API.DOWNLOAD_FILTERED_MERCHANTS;
  const header = GET_BASIC_HEADER();
  return new Promise((resolve, reject) => {
    Axios.put(url, payload, { headers: header, responseType: "blob" })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getReconFileUploadErrors(fileKey, params) {
  const url = `${BACKOFFICE_API.GET_RECON_FILE_UPLOAD_ERRORS}/${fileKey}?${params}`;
  const header = GET_BASIC_HEADER();
  return new Promise((resolve, reject) => {
    Axios.get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getWorkflowRequests(params, requestType) {
  const url = `${
    requestType === "self"
      ? BACKOFFICE_API.GET_USER_WORKFLOW_REQUESTS
      : BACKOFFICE_API.GET_ALL_WORKFLOW_REQUESTS
  }?${params}`;
  const header = GET_BASIC_HEADER();

  return new Promise((resolve, reject) => {
    Axios.get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getReconUploadsByDay(params) {
  const url = `${BACKOFFICE_API.GET_RECON_UPLOADS_BY_DAY}?${params}`;
  const header = GET_BASIC_HEADER();
  return new Promise((resolve, reject) => {
    Axios.get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function processWorkflowRequest(referenceId, payload = {}) {
  const url = `${BACKOFFICE_API.PROCESS_WORKFLOW_REQUEST}/${referenceId}`;
  const header = GET_BASIC_HEADER();
  return new Promise((resolve, reject) => {
    Axios.put(url, payload, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function checkOrGenerateReconReport(payload) {
  const url = `${BACKOFFICE_API.CHECK_OR_GENERATE_RECON_REPORT}`;
  const header = GET_BASIC_HEADER();
  return new Promise((resolve, reject) => {
    Axios.put(url, payload, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function downloadReconStatement(downloadKey) {
  const url = `${BACKOFFICE_API.DOWNLOAD_RECON_STATEMENT}/${downloadKey}`;
  const header = GET_BASIC_HEADER();
  return new Promise((resolve, reject) => {
    Axios.get(url, { headers: header, responseType: "blob" })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getReconFileAccountEntries(payload) {
  const url = `${BACKOFFICE_API.GET_RECON_FILE_ACCOUNT_ENTRIES}`;
  const header = GET_BASIC_HEADER();
  return new Promise((resolve, reject) => {
    Axios.put(url, payload, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}
function getReconFileTransactionMetrics(fileKey, params) {
  const url = `${BACKOFFICE_API.GET_UPLOADED_RECONCILIATION_FILES_BY_DAYS}/${fileKey}?${params}`;
  const header = GET_BASIC_HEADER();
  return new Promise((resolve, reject) => {
    Axios.get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getReconConfigurations() {
  const url = `${BACKOFFICE_API.RECON_CONFIGURATIONS}`;
  const header = GET_BASIC_HEADER();
  return new Promise((resolve, reject) => {
    Axios.get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function updateReconConfiguration(payload) {
  const url = `${BACKOFFICE_API.RECON_CONFIGURATIONS}`;
  const header = GET_BASIC_HEADER();
  return new Promise((resolve, reject) => {
    Axios.put(url, payload, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getDailyCreditDebitSummaries(params) {
  const url = `${BACKOFFICE_API.GET_DAILY_CREDIT_DEBIT_SUMMARIES}?${params}`;
  const header = GET_BASIC_HEADER();
  return new Promise((resolve, reject) => {
    Axios.get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getSingleRole(roleName) {
  const url = `${BACKOFFICE_API.GET_SINGLE_ROLE}/${roleName}`;
  const header = GET_BASIC_HEADER();
  return new Promise((resolve, reject) => {
    Axios.get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function updateUserRole(payload) {
  const url = `${BACKOFFICE_API.UPDATE_USER_ROLE}`;
  const header = GET_BASIC_HEADER();
  return new Promise((resolve, reject) => {
    Axios.put(url, payload, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function makeGETRequest(url, params) {
  const header = GET_BASIC_HEADER();
  url = params ? `${url}?${params}` : url;
  return new Promise((resolve, reject) => {
    Axios.get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function makePUTRequest(url, payload, params, path) {
  // const url = `${BACKOFFICE_API.RECON_CONFIGURATIONS}`;
  url = path ? `${url}/${path}` : url;
  url = params ? `${url}?${params}` : url;
  const header = GET_BASIC_HEADER();
  return new Promise((resolve, reject) => {
    Axios.put(url, payload, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function makePOSTRequest(url, payload, params, path) {
  url = path ? `${url}/${path}` : url;
  url = params ? `${url}?${params}` : url;
  const header = GET_BASIC_HEADER();
  return new Promise((resolve, reject) => {
    Axios.post(url, payload, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

export const BACKOFFICE_SERVICE = {
  GET_ALL_USERS: getAllUsers,
  GET_USER_ROLES: getUserRoles,
  CREATE_AD_USER: createAdUser,
  CREATE_LOCAL_USER: createLocalUser,
  GET_USERS_BY_ROLE: getUsersByRole,
  GET_USER_BY_USERNAME: getUserByUsername,
  GET_SINGLE_USER: getSingleUser,
  GET_AD_USER_BY_USERNAME: getAdUserByUsername,
  UNBLOCK_USER: unblockUser,
  UPLOAD_RECONCILIATION_FILE: uploadReconciliationFile,
  GET_UPLOADED_RECONCILIATION_FILES: getUploadReconciliationFiles,
  GET_UPLOADED_RECONCILIATION_FILES_BY_DAYS: getUploadReconciliationFilesByDays,
  GET_RECON_FILE_UPLOAD_ERRORS: getReconFileUploadErrors,
  GET_RECON_UPLOADS_BY_DAY: getReconUploadsByDay,
  GET_RECON_CONFIGURATIONS: getReconConfigurations,
  UPDATE_RECON_CONFIGURATION: updateReconConfiguration,
  GET_RECON_FILE_ACCOUNT_ENTRIES: getReconFileAccountEntries,
  GET_RECON_FILE_TRANSACTION_METRICS: getReconFileTransactionMetrics,
  DOWNLOAD_RECON_STATEMENT: downloadReconStatement,
  UPLOAD_BULK_USERS: uploadBulkUsers,
  GET_TRANSACTIONS_ON_REQUERY: getTransactionsOnRequery,
  UPDATE_TRANSACTION_ON_REQUERY: updateTransactionOnRequery,
  GET_WORKFLOW_REQUESTS: getWorkflowRequests,
  PROCESS_WORKFLOW_REQUEST: processWorkflowRequest,
  CHECK_OR_GENERATE_RECON_REPORT: checkOrGenerateReconReport,
  GET_DAILY_CREDIT_DEBIT_SUMMARIES: getDailyCreditDebitSummaries,
  DOWNLOAD_TRANSACTIONS_ON_REQUERY: downloadTransactionsOnRequery,
  UPDATE_USER_ROLE: updateUserRole,
  GET_SINGLE_ROLE: getSingleRole,
  MAKE_GET_REQUEST: makeGETRequest,
  MAKE_PUT_REQUEST: makePUTRequest,
  MAKE_POST_REQUEST: makePOSTRequest,
  DOWNLOAD_SUPER_AGENTS: downloadSuperAgents,
};
