import { useEffect, useState } from "react";
import { BACKOFFICE_CONFIGS } from "../../shared/constants";
import { SERVICES } from "../services/services";

export function useGlAccountSize() {
  const [glAccountSize, setGlAccountSize] = useState(null);
  useEffect(() => {
    function getGlSize() {
      SERVICES.GET_SINGLE_CONFIGURATION(
        BACKOFFICE_CONFIGS.GL_ACCOUNT_NUMBER_SIZE
      )
        .then((response) => {
          const glSize = response?.result?.configValue;
          setGlAccountSize(glSize);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    getGlSize();
  }, []);
  return glAccountSize;
}
