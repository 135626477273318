import React, { useContext, useState } from "react";
import { SERVICES } from "../../../core/services/services";
import { saveAs } from "file-saver";
import { CustomToast } from "../alert/custom-toast";
// import { CustomLoader } from "../../../shared/components/custom-loader/custom-loader";
import { HELPER } from "../../helper/helper";
import moment from "moment";
import { Optional } from "../optional/optional";
import { DownloadProgress } from "../download-progress/download-progress";
import { MainContext } from "../../../../App";

export function DownloadConfirmation(props) {
  const mainContext = useContext(MainContext);
  const [loading, setLoading] = useState(false);
  const [downloadError, setDownloadError] = useState(null);

  function downloadTransactions() {
    const currentDate = Date.now();
    setLoading(true);
    SERVICES.DOWNLOAD_BULK_DISPUTE_TRANSACTIONS(props.batchId, mainContext)
      .then((data) => {
        saveAs(
          data,
          `${props.batchId}-${moment(currentDate).format("YYYY-MM-DD")}.xlsx`
        );
        props.closeModal();
      })
      .catch((error) => {
        handleError(error);
      });
  }

  async function handleError(error) {
    const errMessage = await HELPER.PARSE_BLOB_ERROR(error);
    setDownloadError(errMessage);
    resetError();
    setLoading(false);
  }

  function resetError() {
    setTimeout(() => {
      setDownloadError(null);
    }, 5000);
  }

  return (
    <div>
      <div className="p-pb-1">
        {downloadError && (
          <CustomToast title="Error" description={downloadError} type="error" />
        )}
      </div>
      <h4 className="p-text-left p-mt-0">Confirm Download!</h4>
      <p className="p-text-left">
        Confirm that you want to download these transactions.
      </p>
      <Optional show={loading}>
        <DownloadProgress />
      </Optional>
      {/* {loading ? (
        <CustomLoader loadingText="Loading..." />
      ) : ( */}
      <Optional show={!loading}>
        <div className="p-mt-4 p-pb-2">
          <div className="p-grid">
            <div className="p-col-6">
              <button onClick={props.closeModal} className="secondary-button">
                Cancel
              </button>
            </div>
            <div className="p-col-6">
              <button onClick={downloadTransactions} className="primary-button">
                Download
              </button>
            </div>
          </div>
        </div>
      </Optional>
      {/* )} */}
    </div>
  );
}
