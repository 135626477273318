import React, { useCallback, useEffect, useRef, useState } from "react";
import { CustomTable } from "../../../shared/components/custom-table/custom-table";
import { SERVICES } from "../../../core/services/services";
import { CustomLoader } from "../../../shared/components/custom-loader/custom-loader";
import { CustomModal } from "../../../shared/components/custom-modal/custom-modal";
import { DetailsBreakDown } from "../../../shared/components/details-break-down/details-break-down";
import { Toast } from "primereact/toast";
import { HELPER } from "../../../shared/helper/helper";
import { PaymentRequestBreakdown } from "./payment-request-break-down";
import "./bulk-settlement.css";
import { DOWNLOADED_FOR_PROCESSING } from "../../../shared/constants";

export function PaymentRequests(props) {
  const toast = useRef(null);
  const [visible, setVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [error, setError] = useState("");
  const [search, setSearch] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [range] = useState(5);
  const [breakDownTitle] = useState("");
  const [emptyText, setEmptyText] = useState("");
  const [currentModalIndex] = useState(0);
  const [transactionSearchKey, setTransactionSearchKey] = useState("");
  const [pageNo, setPageNo] = useState(0);

  const tableHeaders = [
    { label: "Name", value: "participant" },
    { label: "Recipient Type", value: "recipientType" },
    { label: "Amount", value: "amount" },
    { label: "Total Attempts", value: "attempts" },
    { label: "Status", value: "formattedStatus" },
    { label: "Created On", value: "createdAt" },
    { label: "Actions", value: "actions" },
  ];

  const [details, setDetails] = useState([]);

  const [transactions, setTransactions] = useState([]);
  const [requestId, setRequestId] = useState(null);

  const getTransactionDetails = useCallback((e, isMobile) => {
    let arr = [];

    setRequestId(e?.requestId);
    setDetails([]);
    setTransactionSearchKey(e?.transactionSearchKey);
    arr.push({
      label: "Card acceptor id",
      value: e?.bulkSettlementRecord?.merchant?.cardAcceptorId,
    });
    arr.push({ label: "Total Attempts", value: e?.attempts });
    arr.push({ label: "Credit Account", value: e?.creditAccount });
    arr.push({ label: "Debit Account:", value: e?.debitAccount });
    arr.push({ label: "Recipient Type", value: e?.recipientType });
    arr.push({ label: "Narration", value: e?.narration });
    arr.push({
      label: "Status",
      value: e?.formattedStatus,
      itemCase: "status",
    });
    arr.push({
      label: "Processed By",
      value: e?.processedBy,
    });
    arr.push({
      label: "Transaction amount",
      value: e?.amount,
      itemCase: "transactionAmount",
    });
    arr.push({
      label: "Created On",
      value: e?.createdAt,
      itemCase: "transactionTime",
    });
    arr.push({
      label: "Completed On",
      value: e?.completedAt,
      itemCase: "transactionTime",
    });
    arr.push({
      label: "Last Updated On",
      value: e?.lastUpdateTime,
      itemCase: "transactionTime",
    });

    setDetails(arr);
    setCurrentIndex(3);
  }, []);

  const openAction = useCallback(
    (e, action, isMobile) => {
      // eslint-disable-next-line default-case
      switch (action) {
        case "VIEW": {
          getTransactionDetails(e, isMobile);
          break;
        }
      }
    },
    [getTransactionDetails]
  );

  const getTransactions = useCallback(() => {
    setCurrentIndex(0);
    setTransactions([]);
    let params = {
      page: pageNo,
      size: 10,
    };
    params = HELPER.TO_URL_STRING(params);
    SERVICES.GET_PAYMENT_REQUEST(params, props.bulkSettlementKey)
      .then((data) => {
        const result = data?.result?.content;
        if (!result.length) {
          setEmptyText("No payment requests found ...");
        } else {
          let arr = [];
          setTotalItems(data?.result.totalItems); //need adjustment
          setTotalPages(data?.result.totalPages); //need adjustment
          result.forEach((e) => {
            arr.push({
              ...e,
              participant: e?.participant
                ? e?.participant?.name
                : e?.bulkSettlementRecord?.merchant?.merchantName,
              formattedStatus:
                e?.status === DOWNLOADED_FOR_PROCESSING
                  ? "DOWNLOADED"
                  : e?.status,
              actions: "CR",
              detailsFunction: openAction,
            });
          });
          setTransactions(arr);
        }
        setError(null);
        setCurrentIndex(1);
        // setLoading(false);
      })
      .catch((error) => {
        setError(HELPER.PROCESS_ERROR(error));
        setCurrentIndex(1);
        // setLoading(false);
      });
  }, [pageNo, props.bulkSettlementKey, openAction]);

  function goToPage(pageNo) {
    setPageNo(pageNo);
  }
  useEffect(() => {
    getTransactions();
  }, [pageNo, getTransactions]);

  function reload() {
    // setLoading(true);
    setSearch(false);
    setCurrentIndex(0);
    getTransactions();
  }

  function closeModal(isReload) {
    if (visible) {
      setVisible(false);
    }
    if (currentIndex === 2) {
      setCurrentIndex(1);
    }

    if (isReload) {
      reload();
    }
  }

  function goBack() {
    setCurrentIndex(1);
  }

  const mobileModal = () => {
    // eslint-disable-next-line default-case
    switch (currentModalIndex) {
      case 1:
        return "";
      case 2:
        return (
          <DetailsBreakDown
            mobile={true}
            transactionSearchKey={transactionSearchKey}
            title={breakDownTitle}
            breakDown={details}
            closeModal={closeModal}
          />
        );
    }
  };

  const PaymentRequestsView = () => {
    // eslint-disable-next-line default-case
    switch (currentIndex) {
      case 0:
        return (
          <div className="loading-container tab">
            <CustomLoader loadingText="Loading payment requests..." />
          </div>
        );
      case 1:
        return (
          <CustomTable
            totalPages={totalPages}
            totalItems={totalItems}
            currentPage={pageNo + 1}
            range={range}
            emptyText={emptyText}
            search={search}
            reload={reload}
            error={error}
            items={transactions}
            headers={tableHeaders}
            nextPage={() => setPageNo(pageNo + 1)}
            prevPage={() => setPageNo(pageNo - 1)}
            goToFirstPage={() => setPageNo(0)}
            goToLastPage={() => setPageNo(totalPages - 1)}
            goToPage={goToPage}
          />
        );
      case 2:
        return (
          <div className="mobile-modal-container">
            <div className="custom-card">
              <div className="mobile-card-position">{mobileModal()}</div>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="p-mt-2">
            <PaymentRequestBreakdown
              goBack={goBack}
              isMobile={props.isMobile}
              details={details}
              requestId={requestId}
            />
          </div>
        );
    }
  };

  function modalFooter(footer) {}

  const modalContent = () => {
    // eslint-disable-next-line default-case
    switch (currentModalIndex) {
      case 1:
        return "";
      case 2:
        return (
          <DetailsBreakDown
            footer={modalFooter}
            transactionSearchKey={transactionSearchKey}
            title={breakDownTitle}
            breakDown={details}
            closeModal={closeModal}
          />
        );
    }
  };

  function onHide() {}

  return (
    <div>
      <Toast ref={toast} />
      <div>
        <CustomModal
          onHide={onHide}
          visible={visible}
          modalContent={modalContent}
        />
      </div>
      <div className="p-mt-6"></div>
      <div>{PaymentRequestsView()}</div>
    </div>
  );
}
