// const login = "/oauth/token";
const login = "/common/authentication/login";
const logout = "/common/authentication/logout";
const authenticate_2fa_code = "/common/authentication/authenticate-2fa";
const get_user_details = "/common/authentication/user-detail";
const change_password = "/common/authentication/change-password";
const reset_super_agent_user_password =
  "/backoffice/merchant-user/reset-password";
const reset_super_agent_user_password_fo = "/frontoffice/user/reset-password";
const create_merchant = "/backoffice/merchant";
const upload_bulk_merchant = "/backoffice/merchant/bulk-upload";
const update_merchant_settlement = "/backoffice/merchant/change-settlement/";
const create_merchant_user = "/backoffice/merchant-user";
const get_merchant_user_roles = "/backoffice/merchant-user/roles";
const filter_merchant_users = "/backoffice/merchant-user/filter";
const filter_merchant_users_fo = "/frontoffice/user/filter";
const change_super_agent_user_status =
  "/backoffice/merchant-user/status/change";
const change_super_agent_user_status_fo = "/frontoffice/user/status/change";
const create_front_office_merchant_user = "/frontoffice/user";
const get_front_office_merchant_roles = "/frontoffice/user/roles";
const create_charge_type_model = "/backoffice/charge-model";
const verify_account_number = "/common/account/name-enquiry/";
const get_dispute_by_transaction_key = "/backoffice/dispute/transaction/";
const get_filtered_disputes = "/backoffice/dispute/filter";
const get_dispute_by_transaction_key_fo = "/frontoffice/dispute/transaction/";
const get_super_agent_disputes = "/frontoffice/dispute/by-superagent";
const get_super_agent_bank_disputes = "/frontoffice/dispute/by-backoffice";
const get_filtered_agent_disputes = "/frontoffice/dispute/filter/superagent";
const get_filtered_bank_disputes = "/frontoffice/dispute/filter/backoffice";
const get_charge_model = "/backoffice/charge-model";
const delete_charge_model = "/backoffice/charge-model/remove";
const get_charge_types = "/backoffice/charge-model/types"; //remember to remove the s from backoffice
const get_settlement_type = "/backoffice/merchant/settlement/types";
const create_settlement = "/backoffice/settlement-participant";
const delete_settlement = "/backoffice/settlement-participant/remove";
const deactivate_merchant = "/backoffice/merchant/status/change";
const create_dispute = "/backoffice/dispute";
const download_disputes = "/backoffice/dispute/download/filter";
const download_superagent_disputes =
  "/frontoffice/dispute/download/filter/superagent";
const download_backoffice_disputes =
  "/frontoffice/dispute/download/filter/backoffice";
const reopen_dispute = "/backoffice/dispute/reopen";
const reopen_system_accepted_dispute =
  "/backoffice/dispute/reopen-system-accepted";
const reopen_dispute_fo = "/frontoffice/dispute/reopen";
const create_dispute_fo = "/frontoffice/dispute";
const update_dispute_fo = "/frontoffice/dispute";
const get_transactions = "/backoffice/transaction";
const get_front_office_transactions = "/frontoffice/transaction";
const get_bulk_settlement = "/backoffice/settlement/bulk";
const get_front_office_bulk_settlement = "/frontoffice/settlement/bulk";
const get_bulk_settlement_transactions = "/backoffice/settlement/";
const get_front_office_bulk_settlement_transactions =
  "/frontoffice/settlement/";
const get_payment_request = "/backoffice/paymentrequest/";
const getSettlementPaymentAttempts = "/backoffice/payment-attempt/";
const get_front_office_payment_request = "/frontoffice/paymentrequest/";
const search_transactions = "/backoffice/transaction/filter";
const get_dispute_codes = "/backoffice/dispute/disputable-codes";
const get_dispute_codes_fo = "/frontoffice/dispute/disputable-codes";
const filter_transactions = "/backoffice/transaction/filter";
const get_settlement_participants = "/backoffice/settlement-participant";
const get_settlement_participant_non_global =
  "/backoffice/settlement-participant/all/non-global";
const get_all_merchants = "/backoffice/merchant/merchants";
const get_merchants_tied_to_user = "/backoffice/merchant/user-merchants";
const download_bulk_settlement = "/backoffice/settlement/download/";
const download_bulk_settlement_fo = "/frontoffice/settlement/download/";
const create_public_holiday = "/backoffice/public-holiday";
const get_all_public_holidays = "/backoffice/public-holiday/all";
const filter_public_holidays = "/backoffice/public-holiday/filter";
const delete_public_holiday = "/backoffice/public-holiday/";
const update_public_holiday = "/backoffice/public-holiday/";
const download_transactions = "/backoffice/transaction/download/filter";
const download_transactions_fo = "/frontoffice/transaction/download/filter";
const get_configurations = "/backoffice/dcir-config";
const update_configuration = "/backoffice/dcir-config/";
const get_single_configuration = "/backoffice/dcir-config/";
const get_customer_information = "/common/account/card-account/";
const get_participant_users = "/backoffice/settlement-participant-user";
const create_participant_user = "/backoffice/settlement-participant-user";
const update_participant_user = "/backoffice/settlement-participant-user";
const get_participants_for_user =
  "/backoffice/settlement-participant-user/user";
const get_user_participants =
  "/backoffice/settlement-participant-user/participants";
const validate_participant_username =
  "/backoffice/settlement-participant-user/validate";
const get_participant_reports = "/backoffice/settlement-participant-report";
const download_participant_report =
  "/backoffice/settlement-participant-report/download/";
const filter_participant_reports = "/backoffice/settlement-participant-report";
const upload_bulk_dispute = "/backoffice/bulk-dispute/upload";
const get_bulk_disputes = "/backoffice/bulk-dispute";
const get_bulk_dispute_transactions = "/backoffice/bulk-dispute/transactions/";
const download_bulk_dispute_transactions =
  "/backoffice/bulk-dispute/transactions/download";
const get_backoffice_user_details = "/common/authentication/get-user";
const change_backoffice_user_status = "/common/authentication/change-status";
const reset_backoffice_user_password = "/common/authentication/reset-password";
const get_bank_features = '/common/features'

export const API = {
  LOGIN_API: login,
  LOGOUT: logout,
  AUTHENTICATE_2FA_CODE: authenticate_2fa_code,
  GET_USER_DETAILS: get_user_details,
  CHANGE_PASSWORD: change_password,
  RESET_SUPER_AGENT_USER_PASSWORD: reset_super_agent_user_password,
  RESET_SUPER_AGENT_USER_PASSWORD_FO: reset_super_agent_user_password_fo,
  CREATE_MERCHANT: create_merchant,
  UPLOAD_BULK_MERCHANT: upload_bulk_merchant,
  UPDATE_MERCHANT_SETTLEMENT: update_merchant_settlement,
  CREATE_PARTICIPANT: create_settlement,
  CREATE_CHARGE_TYPE_MODEL: create_charge_type_model,
  GET_CHARGE_TYPES: get_charge_types,
  GET_CHARGE_MODEL: get_charge_model,
  DELETE_CHARGE_MODEL: delete_charge_model,
  GET_SETTLEMENT_PARTICIPANTS: get_settlement_participants,
  GET_SETTLEMENT_PARTICIPANT_NON_GLOBAL: get_settlement_participant_non_global,
  GET_SETTLEMENT_TYPE: get_settlement_type,
  GET_TRANSACTIONS: get_transactions,
  FILTER_TRANSACTIONS: filter_transactions,
  GET_DISPUTE_CODES: get_dispute_codes,
  GET_DISPUTE_CODES_FO: get_dispute_codes_fo,
  CREATE_DISPUTE: create_dispute,
  REOPEN_DISPUTE: reopen_dispute,
  REOPEN_SYSTEM_ACCEPTED_DISPUTE: reopen_system_accepted_dispute,
  REOPEN_DISPUTE_FO: reopen_dispute_fo,
  CREATE_DISPUTE_FO: create_dispute_fo,
  UPDATE_DISPUTE_FO: update_dispute_fo,
  GET_MERCHANT_USER: create_merchant_user,
  GET_MERCHANT_USER_ROLES: get_merchant_user_roles,
  FILTER_MERCHANT_USERS: filter_merchant_users,
  FILTER_MERCHANT_USERS_FO: filter_merchant_users_fo,
  CHANGE_SUPER_AGENT_USER_STATUS: change_super_agent_user_status,
  CHANGE_SUPER_AGENT_USER_STATUS_FO: change_super_agent_user_status_fo,
  SEARCH_TRANSACTION: search_transactions,
  DELETE_PARTICIPANT: delete_settlement,
  GET_BULK_SETTLEMENT: get_bulk_settlement,
  GET_FRONT_OFFICE_BULK_SETTLEMENT: get_front_office_bulk_settlement,
  GET_BULK_SETTLEMENT_TRANSACTION: get_bulk_settlement_transactions,
  GET_FRONT_OFFICE_BULK_SETTLEMENT_TRANSACTION:
    get_front_office_bulk_settlement_transactions,
  GET_PAYMENT_REQUEST: get_payment_request,
  GET_SETTLEMENT_PAYMENT_ATTEMPTS: getSettlementPaymentAttempts,
  GET_FRONT_OFFICE_PAYMENT_REQUEST: get_front_office_payment_request,
  CREATE_FRONT_OFFICE_MERCHANT_USER: create_front_office_merchant_user,
  GET_FRONT_OFFICE_MERCHANT_ROLES: get_front_office_merchant_roles,
  GET_FRONT_OFFICE_TRANSACTIONS: get_front_office_transactions,
  DEACTIVATE_MERCHANT: deactivate_merchant,
  GET_ALL_MERCHANTS: get_all_merchants,
  GET_MERCHANTS_TIED_TO_USER: get_merchants_tied_to_user,
  VERIFY_ACCOUNT_NUMBER: verify_account_number,
  GET_DISPUTE_BY_TRANSACTION_KEY: get_dispute_by_transaction_key,
  GET_FILTERED_DISPUTES: get_filtered_disputes,
  GET_DISPUTE_BY_TRANSACTION_KEY_FO: get_dispute_by_transaction_key_fo,
  GET_SUPER_AGENT_DISPUTES: get_super_agent_disputes,
  GET_SUPER_AGENT_BANK_DISPUTES: get_super_agent_bank_disputes,
  GET_FILTERED_BANK_DISPUTES: get_filtered_bank_disputes,
  GET_FILTERED_AGENT_DISPUTES: get_filtered_agent_disputes,
  DOWNLOAD_BULK_SETTLEMENT: download_bulk_settlement,
  DOWNLOAD_BULK_SETTLEMENT_FO: download_bulk_settlement_fo,
  CREATE_PUBLIC_HOLIDAY: create_public_holiday,
  GET_PUBLIC_HOLIDAYS: get_all_public_holidays,
  FILTER_PUBLIC_HOLIDAYS: filter_public_holidays,
  DELETE_PUBLIC_HOLIDAY: delete_public_holiday,
  UPDATE_PUBLIC_HOLIDAY: update_public_holiday,
  DOWNLOAD_TRANSACTIONS: download_transactions,
  DOWNLOAD_TRANSACTIONS_FO: download_transactions_fo,
  GET_CONFIGURATIONS: get_configurations,
  UPDATE_CONFIGURATION: update_configuration,
  GET_SINGLE_CONFIGURATION: get_single_configuration,
  GET_CUSTOMER_INFORMATION: get_customer_information,
  GET_PARTICIPANT_USERS: get_participant_users,
  CREATE_PARTICIPANT_USER: create_participant_user,
  UPDATE_PARTICIPANT_USER: update_participant_user,
  GET_PARTICIPANTS_FOR_USER: get_participants_for_user,
  VALIDATE_PARTICIPANT_USERNAME: validate_participant_username,
  GET_PARTICIPANT_REPORTS: get_participant_reports,
  DOWNLOAD_PARTICIPANT_REPORT: download_participant_report,
  FILTER_PARTICIPANT_REPORTS: filter_participant_reports,
  UPLOAD_BULK_DISPUTE: upload_bulk_dispute,
  GET_BULK_DISPUTES: get_bulk_disputes,
  GET_BULK_DISPUTE_TRANSACTIONS: get_bulk_dispute_transactions,
  GET_USER_PARTICIPANTS: get_user_participants,
  DOWNLOAD_DISPUTES: download_disputes,
  DOWNLOAD_SUPERAGENT_DISPUTES: download_superagent_disputes,
  DOWNLOAD_BACKOFFICE_DISPUTES: download_backoffice_disputes,
  DOWNLOAD_BULK_DISPUTE_TRANSACTIONS: download_bulk_dispute_transactions,
  GET_BACKOFFICE_USER_DETAILS: get_backoffice_user_details,
  CHANGE_BACKOFFICE_USER_STATUS: change_backoffice_user_status,
  RESET_BACKOFFICE_USER_PASSWORD: reset_backoffice_user_password,
  GET_BANK_FEATURES:get_bank_features
};
