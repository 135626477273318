import { Optional } from "../../../shared/components/optional/optional";
import { SpecialLabelCases } from "../../../shared/models/utilities";
// import {CustomAccordion}   from "../../../shared/components/custom-accordion/custom-accordion";

export function BulkTransactionBreakDown(props) {
  const transformView = (itemCase, value) => {
    let result = value;
    if (itemCase) {
      SpecialLabelCases.forEach((e) => {
        if (e.case === itemCase) {
          result = e.action(value);
        }
      });
    }
    return result;
  };

  const detailsView = () => {
    return props.details.map((details, index) => (
      <div key={index.toString()} className="p-grid dcir-row">
        <div className="p-col-6">
          <p className="details-label">{details.label}:</p>
        </div>
        <div className="p-col-6">
          <Optional show={details.itemCase !== "disputeLogCode"}>
            <p className="details-value">
              {transformView(details.itemCase, details.value)}
            </p>
          </Optional>
          <Optional show={details.itemCase === "disputeLogCode"}>
            <button
              className="p-button-success view-details p-pt-3"
              onClick={props.showDisputeDetails}
            >
              {details.value}
            </button>
          </Optional>
        </div>
      </div>
    ));
  };

  return (
    <div>
      <Optional show={props.showHeader !== false}>
        <div className="p-text-left">
          <p
            onClick={() => props.goBack()}
            className="close-modal add-cursor p-ml-4"
          >
            <i className="pi pi-arrow-left"></i>
          </p>
          <p className="custom-title p-ml-4">Transaction Details</p>
        </div>
      </Optional>

      <div className="p-text-left" style={{ maxWidth: "1000px" }}>
        {detailsView()}
      </div>
    </div>
  );
}
