import "./bulk-settlement.css";
import React, {
  useCallback,
  // useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Toast } from "primereact/toast";
import { OverlayPanel } from "primereact/overlaypanel";
import { BulkSettlementSearch } from "./bulk-settlement-search";
import { BulkSettlementBreakDown } from "./bulk-settlement-break-down";
import { HELPER } from "../../../shared/helper/helper";
import { SERVICES } from "../../../core/services/services";
import { DetailsBreakDown } from "../../../shared/components/details-break-down/details-break-down";
import { CustomTable } from "../../../shared/components/custom-table/custom-table";
import { CustomModal } from "../../../shared/components/custom-modal/custom-modal";
import { CustomBreadcrumb } from "../../../shared/components/custom-breadcrumb/custom-breadcrumb";
import { AccessDenied } from "../../access-denied/access-denied";
import { CustomLoader } from "../../../shared/components/custom-loader/custom-loader";
import ErrorBoundary from "../../../shared/components/error-boundary/error-boundary";
// import { MainContext } from "../../../../App";

export function FrontOfficeBulkSettlement() {
  const toast = useRef(null);
  const op = useRef(null);
  // const mainContext = useContext(MainContext);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [error, setError] = useState("");
  const [search, setSearch] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [isMobileTransaction, setIsMobileTransaction] = useState(false);
  // const [currentPage] = useState(mainContext?.mainState?.currentPage);
  const [range] = useState(5);
  const [breakDownTitle] = useState("");
  const [emptyText, setEmptyText] = useState("");
  const [currentModalIndex, setCurrentModalIndex] = useState(0);
  const [bulkSettlementKey, setBulkSettlementKey] = useState("");
  const [merchantDetails, setMerchantDetails] = useState([]);
  const [details, setDetails] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [searchParams, setSearchParams] = useState(null);

  const tableHeaders = [
    { label: "Super agent", value: "merchantName" },
    { label: "Total Amount", value: "transactionMerchantAmount" },
    { label: "Total Transactions", value: "transactionCount" },
    { label: "Settlement Type", value: "settlementType" },
    { label: "Status", value: "status" },
    { label: "Created On", value: "createdAt" },
    // {label:'Completed On',value:'completedAt'},
    { label: "Actions", value: "actions" },
  ];

  const getSettlementDetails = useCallback((e, isMobile) => {
    let arr = [];
    setMerchantDetails([]);
    setDetails([]);
    const dateRange = `${HELPER.FORMAT_DATE(
      e?.transactionTimeBegin
    )} - ${HELPER.FORMAT_DATE(e?.transactionTimeEnd)}`;
    setBulkSettlementKey(e?.bulkSettlementKey);
    // arr.push({ label: "Super Agent", value: e?.merchant?.merchantName });
    arr.push({
      label: "Transaction Count",
      value: e?.transactionCount,
    });
    arr.push({
      label: "Total Amount",
      value: e?.transactionTotalAmount,
      itemCase: "transactionAmount",
    });
    arr.push({
      label: "Total Transaction Charge",
      value: e?.transactionChargeAmount,
      itemCase: "transactionAmount",
    });
    arr.push({
      label: "Total VAT Amount",
      value: e?.transactionVatAmount,
      itemCase: "transactionAmount",
    });
    arr.push({
      label: "Super Agent Net Settlement",
      value: e?.transactionMerchantAmount,
      itemCase: "transactionAmount",
    });

    arr.push({
      label: "Settlement Status",
      value: e?.status,
      itemCase: "status",
    });
    arr.push({ label: "Settlement Type", value: e?.settlementType });
    arr.push({
      label: "Report Status",
      value: e?.reportStatus,
      itemCase: "status",
    });
    arr.push({ label: "Bulk Settlement Key", value: e?.bulkSettlementKey });
    arr.push({
      label: "Payment Request Attempt",
      value: e?.paymentRequestAttempt,
    });
    arr.push({
      label: "Report Generation Attempt",
      value: e?.reportGenerationAttempt,
    });
    arr.push({ label: "Transaction Date Range", value: dateRange });
    arr.push({
      label: "Created On",
      value: e?.createdAt,
      itemCase: "transactionTime",
    });
    arr.push({
      label: "Completed On",
      value: e?.completedAt,
      itemCase: "transactionTime",
    });
    setDetails(arr);
    setMerchantDetails(fillMerchantDetails(e?.merchant));
    setIsMobileTransaction(isMobile);
    setCurrentIndex(3);
  }, []);

  const openAction = useCallback(
    (e, action, isMobile) => {
      // eslint-disable-next-line default-case
      switch (action) {
        case "VIEW": {
          getSettlementDetails(e, isMobile);
          break;
        }
      }
    },
    [getSettlementDetails]
  );

  const getBulkSettlement = useCallback(
    (queryParams = null, isReload = false) => {
      if (queryParams) {
        setPageNo(0);
      }
      setCurrentIndex(0);
      setTransactions([]);
      let params = {
        page: pageNo,
        size: 10,
      };
      if ((queryParams || searchParams) && !isReload) {
        // close filter modal
        setVisible(false);
        setSearch(true);
        if (queryParams) {
          params = {
            ...params,
            startDate: queryParams?.startDate,
            endDate: queryParams?.endDate,
            bulkSettlementKey: queryParams?.bulkSettlementKey,
            settlementStatus: queryParams?.settlementStatus?.code,
            reportStatus: queryParams?.reportStatus?.code,
          };
          setSearchParams(params);
        } else {
          params = {
            ...params,
            ...searchParams,
            page: pageNo,
          };
        }

        for (const prop in params) {
          if (!params[prop] && params[prop] !== 0) {
            delete params[prop];
          }
        }
      }
      params = HELPER.TO_URL_STRING(params);
      SERVICES.GET_FRONT_OFFICE_BULK_SETTLEMENT(params)
        .then((data) => {
          const result = data?.result?.content;
          if (!result.length) {
            setEmptyText("No bulk settlements found...");
          } else {
            let arr = [];
            setTotalItems(data?.result.totalElements); //need adjustment
            setTotalPages(data?.result.totalPages); //need adjustment
            result.forEach((e) => {
              arr.push({
                ...e,
                merchantName: e.merchant?.merchantName,
                actions: "CR",
                detailsFunction: openAction,
              });
            });
            setTransactions(arr);
          }
          setError(null);
          setCurrentIndex(1);
          setLoading(false);
        })
        .catch((error) => {
          setError(HELPER.PROCESS_ERROR(error));
          setCurrentIndex(1);
          setLoading(false);
        });
    },
    [pageNo, searchParams, openAction]
  );

  // useEffect(() => {
  //   getBulkSettlement();
  // }, [pageNo]);

  useEffect(() => {
    // let mounted = true;
    // if (mounted) {
    getBulkSettlement();
    // }
    // return () => {
    //   mounted = false;
    // };
  }, [getBulkSettlement]);

  function fillMerchantDetails(e) {
    let arr = [];
    arr.push({ label: "Super Agent Name", value: e?.merchantName });
    arr.push({ label: "Card Acceptor Id", value: e?.cardAcceptorId });
    arr.push({ label: "Phone Number", value: e?.phoneNumber });
    arr.push({ label: "mainEmail", value: e?.mainEmail });
    arr.push({ label: "disputeEmail", value: e?.disputeEmail });
    arr.push({ label: "merchantId", value: e?.merchantId });
    arr.push({ label: "Settlement Email", value: e?.settlementEmail });
    arr.push({
      label: "Date Created",
      value: e?.createdTime,
      itemCase: "transactionTime",
    });
    return arr;
  }

  function goToPage(pageNo) {
    setPageNo(pageNo);
  }

  function onHide() {}

  function openModal(index, isMobile) {
    setCurrentModalIndex(index);
    if (isMobile) {
      setCurrentIndex(2);
    } else {
      setVisible(true);
    }
  }

  function reload() {
    setPageNo(0);
    setSearchParams(null);
    setLoading(true);
    setSearch(false);
    setCurrentIndex(0);
    getBulkSettlement(null, true);
  }

  function closeModal(isReload) {
    if (visible) {
      setVisible(false);
    }
    if (currentIndex === 2) {
      setCurrentIndex(1);
    }

    if (isReload) {
      reload();
    }
  }

  const mobileModal = () => {
    // eslint-disable-next-line default-case
    switch (currentModalIndex) {
      case 1:
        return (
          <BulkSettlementSearch
            searchFunction={getBulkSettlement}
            closeModal={closeModal}
          />
        );
      case 2:
        return (
          <DetailsBreakDown
            merchantDetails={merchantDetails}
            mobile={true}
            bulkSettlementKey={bulkSettlementKey}
            title={breakDownTitle}
            breakDown={details}
            closeModal={closeModal}
          />
        );
    }
  };

  function goBack() {
    setCurrentIndex(1);
  }

  const bulkSettlementView = () => {
    // eslint-disable-next-line default-case
    switch (currentIndex) {
      case 0:
        return (
          <div className="loading-container">
            <CustomLoader loadingText="Loading bulk settlements..." />
          </div>
        );
      case 1:
        return (
          <CustomTable
            isReload={true}
            totalPages={totalPages}
            totalItems={totalItems}
            currentPage={pageNo + 1}
            range={range}
            emptyText={emptyText}
            search={search}
            reload={reload}
            error={error}
            items={transactions}
            headers={tableHeaders}
            nextPage={() => setPageNo(pageNo + 1)}
            prevPage={() => setPageNo(pageNo - 1)}
            goToFirstPage={() => setPageNo(0)}
            goToLastPage={() => setPageNo(totalPages - 1)}
            goToPage={goToPage}
          />
        );
      case 2:
        return (
          <div className="mobile-modal-container">
            <div className="custom-card">
              <div className="mobile-card-position">{mobileModal()}</div>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="p-mt-2">
            <BulkSettlementBreakDown
              merchantDetails={merchantDetails}
              bulkSettlementKey={bulkSettlementKey}
              goBack={goBack}
              detials={details}
              mobile={isMobileTransaction}
            />
          </div>
        );
    }
  };

  function modalFooter(footer) {}

  const modalContent = () => {
    // eslint-disable-next-line default-case
    switch (currentModalIndex) {
      case 1:
        return (
          <BulkSettlementSearch
            searchFunction={getBulkSettlement}
            closeModal={closeModal}
          />
        );
      case 2:
        return (
          <DetailsBreakDown
            footer={modalFooter}
            transactionSearchKey={bulkSettlementKey}
            title={breakDownTitle}
            breakDown={details}
            closeModal={closeModal}
          />
        );
    }
  };

  return (
    <ErrorBoundary>
      <div>
        <Toast ref={toast} />
        <div>
          <CustomModal
            closeModal={closeModal}
            onHide={onHide}
            visible={visible}
            modalContent={modalContent}
          />
        </div>
        <div className="page-title p-text-left">Bulk Settlement</div>
        <div className="p-mt-2">
          <CustomBreadcrumb page="Manage Bulk Settlement" />
        </div>
        <div className="floating-buttons desktop-scree">
          <div className="p-grid">
            <div className="p-col-7"></div>
            <div className="p-col-5">
              <div className="p-grid">
                <div className="p-col-6">
                  {/*<button disabled={loading} onClick={()=>openModal(0,false)} className="primary-button hide-btn-text">*/}
                  {/*    <i className="pi pi-plus"/>*/}
                  {/*    <span className="hide-btn-text"> New model</span>*/}
                  {/*</button>*/}
                </div>
                <div className="p-col-6">
                  <div
                    className={
                      HELPER.HAS_AUTHORITY("fo_dcir_get_all_bulk_settlement") &&
                      currentIndex === 1
                        ? "dcir-show"
                        : "dcir-hide"
                    }
                  >
                    <button
                      disabled={loading || transactions?.length === 0}
                      onClick={() => openModal(1, false)}
                      className="primary-button"
                    >
                      <i className="pi pi-filter" />
                      <span className="hide-btn-text"> Filter</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mobile-screen">
          <div className="p-grid">
            <div className="p-col-9"></div>
            <div className="p-col-3">
              <div className="floating-mobile-buttons add-cursor">
                <div
                  className={
                    HELPER.HAS_AUTHORITY("fo_dcir_get_all_bulk_settlement") &&
                    currentIndex === 1
                      ? "dcir-hide"
                      : "dcir-hide"
                  }
                >
                  <i
                    onClick={(e) => op.current.toggle(e)}
                    className="pi pi-ellipsis-v"
                    style={{ fontSize: "1.5em", color: "#464DF2" }}
                  />
                  <OverlayPanel
                    ref={op}
                    id="overlay_panel"
                    style={{ width: "100px" }}
                    className="overlaypanel-demo"
                  >
                    {/*<div className="p-mb-3 p-ml-1"><span onClick={()=>openModal(0,true)} className="custom-over-flow-text"><i className="pi pi-plus"/> New</span></div>*/}
                    <div className="p-mb-2 p-ml-1">
                      <span
                        onClick={() => openModal(1, true)}
                        className="custom-over-flow-text"
                      >
                        <i className="pi pi-filter" /> Filter
                      </span>
                    </div>
                  </OverlayPanel>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            HELPER.HAS_AUTHORITY("fo_dcir_get_all_bulk_settlement")
              ? "dcir-show"
              : "dcir-hide"
          }
        >
          {bulkSettlementView()}
        </div>
        <div
          className={
            HELPER.HAS_AUTHORITY("fo_dcir_get_all_bulk_settlement")
              ? "dcir-hide"
              : "dcir-show"
          }
        >
          <AccessDenied />
        </div>
      </div>
    </ErrorBoundary>
  );
}
