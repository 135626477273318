import { LOCAL_STORAGE_SERVICE } from "./storage-service";

export const GET_BASIC_HEADER = () => {
  const token = LOCAL_STORAGE_SERVICE.GET_TOKEN();
  const tokenBearer = `Bearer ${token}`;
  return {
    "Content-Type": "application/json",
    Authorization: tokenBearer,
  };
};
