import { useState } from "react";
import { BACKOFFICE_SERVICE } from "../../../core/services/backoffice-service";
import { CustomMessage } from "../../../shared/components/alert/custom-message";
import { CustomToast } from "../../../shared/components/alert/custom-toast";
import { CustomLoader } from "../../../shared/components/custom-loader/custom-loader";
import { Optional } from "../../../shared/components/optional/optional";
import { HELPER } from "../../../shared/helper/helper";

export function RequeryActionConfirmation(props) {
  const [currentIndex, setCurrentIndex] = useState(0);
  // const [actionType, setActionType] = useState("SUCCESSFUL");
  const [responseMessage, setResponseMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  function submit() {
    setLoading(true);
    let payload;
    const actionType = props.actionType;
    if (props.transactionType === "disputes") {
      payload = HELPER.TO_URL_STRING({
        disputeStatus: actionType,
      });
    } else {
      payload = HELPER.TO_URL_STRING({
        settlementStatus: actionType,
      });
    }
    BACKOFFICE_SERVICE.UPDATE_TRANSACTION_ON_REQUERY(
      props.transactionId,
      payload,
      props.transactionType
    )
      .then((data) => {
        setResponseMessage(data?.result?.message);
        setLoading(false);
        setCurrentIndex(1);
      })
      .catch((error) => {
        setError(HELPER.PROCESS_ERROR(error));
        setLoading(false);
      });
  }
  function requeryActionView() {
    switch (currentIndex) {
      case 0:
        return (
          <div className="p-text-left">
            <div>
              <h3>Confirm Action.</h3>
            </div>
            <div>
              <p>
                Confirm that you want to mark this transaction as{" "}
                {props.actionType.toLowerCase()}!
              </p>
            </div>
            <Optional show={error}>
              <div className="p-col-12">
                <CustomToast title="Error" description={error} type="error" />
              </div>
            </Optional>
            <div className="p-col-12">
              <div className="p-mt-5">
                <div className="p-grid">
                  <Optional show={!loading}>
                    <div className={loading ? "p-col-12" : "p-col-6"}>
                      {/* {cancelButton()} */}
                      <button
                        className="secondary-button"
                        onClick={props.closeModal}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className={loading ? "p-col-12" : "p-col-6"}>
                      {/* {submitButton()} */}
                      <button className="primary-button" onClick={submit}>
                        Confirm
                      </button>
                    </div>
                  </Optional>
                  <Optional show={loading}>
                    <div className="p-col-12 p-text-center">
                      <CustomLoader loadingText="Loading..." />
                    </div>
                  </Optional>
                </div>
              </div>
            </div>
          </div>
        );
      case 1:
        return (
          <div className="p-text-center p-mt-2">
            <div>
              <CustomMessage messageType="success" />
            </div>
            <div className="success-message-btn-container p-text-center">
              <p>{responseMessage}</p>
              <p
                className="close-modal add-cursor p-mt-3"
                onClick={() => props.closeModal(true)}
              >
                Close
              </p>
            </div>
          </div>
        );
      default:
        break;
    }
  }
  return <div>{requeryActionView()}</div>;
}
