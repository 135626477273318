import { useContext, useEffect, useRef, useState } from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import { SERVICES } from "../../../core/services/services";
import { FormInput } from "../../../shared/components/form-component/form-input";
import { CUSTOM_VALIDATION } from "../../../shared/validation/validation";
import { FormDropdown } from "../../../shared/components/form-component/form-dropdown";
import { FormMultiselect } from "../../../shared/components/form-component/form-multiselect";
import { CustomMessage } from "../../../shared/components/alert/custom-message";
import { HELPER } from "../../../shared/helper/helper";
import { MainContext } from "../../../../App";
import { Toast } from "primereact/toast";
import { InputSwitch } from "primereact/inputswitch";
import { Optional } from "../../../shared/components/optional/optional";
import { ToolTip } from "../../../shared/components/tooltip/tooltip";
import { BACKOFFICE_CONFIGS } from "../../../shared/constants";
import { BACKOFFICE_SERVICE } from "../../../core/services/backoffice-service";
import { BACKOFFICE_API } from "../../../core/apis/backoffice-apis";
import { Dropdown } from "primereact/dropdown";
import { useSingleAppConfigurationValue } from "../../../core/custom-hooks/use-single-configuration-value";

export function ConfigureSettlement(props) {
  const toast = useRef(null);
  const mainContext = useContext(MainContext);
  const isNameEnquiryEnabled = useSingleAppConfigurationValue(
    BACKOFFICE_CONFIGS.IS_NAME_ENQUIRY_ENABLED
  );
  const [loading, setLoading] = useState(false);
  const [settlementTypes, setSettlementTypes] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [successMessage, setSuccessMessage] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [validForm, setValidForm] = useState(false);
  const [chargeTypes, setChargeTypes] = useState([]);
  const [settlementTransitAccounts, setSettlementTransitAccounts] = useState(
    []
  );
  const [accountName, setAccountName] = useState(null);
  const [verifyingAccountNumber, setVerifyingAccountNumber] = useState(false);
  const [verifyingVATAccountNumber, setVerifyingVATAccountNumber] =
    useState(false);
  const [settlementInfo, setSettlementInfo] = useState({
    accountName: null,
    accountNumber: null,
    chargeType: null,
    participants: null,
    settlementType: null,
  });
  const [settlementErrorForm, setSettlementErrorForm] = useState({
    accountName: null,
    accountNumber: null,
    chargeType: null,
    participants: null,
    settlementType: null,
    concessionChargeType: null,
    vatAccount: null,
    vatAccountName: null,
  });
  const [applyConcessionCharge, setApplyConcessionCharge] = useState(
    props.isBulkUpload
      ? false
      : props?.merchantSettlementData?.concessionChargeType?.code
      ? true
      : false
  );
  const [configuredVATRate, setConfiguredVATRate] = useState(null);
  const accountTypes = ["GL", "NUBAN"];
  const [VATAccountType, setVATAccountType] = useState(null);
  const [shouldPerformGlNameEnquiry, setShouldPerformGlNameEnquiry] =
    useState("true");
  const [glAccountSize, setGlAccountSize] = useState(null);

  useEffect(() => {
    console.log("is-name-enquiry", isNameEnquiryEnabled);
  }, [isNameEnquiryEnabled]);

  useEffect(() => {
    function prefillForm() {
      if (props?.isUpdate) {
        setSettlementInfo(props.merchantSettlementData);
      }
    }

    function getSettlementType() {
      SERVICES.GET_SETTLEMENT_TYPE()
        .then((data) => {
          let arr = [];
          data.result.forEach((e) => {
            arr.push({ decs: e, code: e });
          });
          setSettlementTypes(arr);
        })
        .catch((error) => {
          toast.current.show({
            severity: "error",
            summary: "Error getting settlement types!",
            detail: HELPER.PROCESS_ERROR(error, "TOAST"),
            life: 10000,
          });
        });
    }

    function getSettlementParticipants() {
      const params = HELPER.TO_URL_STRING({
        page: 0,
        size: 2000,
        global: false,
      });
      SERVICES.GET_SETTLEMENT_PARTICIPANTS(params)
        .then((data) => {
          let arr = [];
          data.result.content.forEach((e) => {
            arr.push({
              desc: e.participantId,
              code: e.name,
              id: e.participantId,
            });
          });
          setParticipants(arr);
          prefillForm();
        })
        .catch((error) => {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: HELPER.PROCESS_ERROR(error, "TOAST"),
            life: 10000,
          });
        });
    }
    function getChargeTypes() {
      SERVICES.GET_ALL_CHARGE_MODELS()
        .then((data) => {
          let arr = [];
          data?.result?.forEach((e) => {
            arr.push({ desc: e.chargeTypeName, code: e.code });
          });
          setChargeTypes(arr);
        })
        .catch((error) => {
          toast.current.show({
            severity: "error",
            summary: "Error getting charge types!",
            detail: HELPER.PROCESS_ERROR(error, "TOAST"),
            life: 10000,
          });
        });
    }

    function getSettlementTransitAccounts() {
      BACKOFFICE_SERVICE.MAKE_GET_REQUEST(
        BACKOFFICE_API.SETTLEMENT_TRANSIT_ACCOUNTS
      )
        .then((data) => {
          setSettlementTransitAccounts(data?.result);
        })
        .catch((error) => {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: HELPER.PROCESS_ERROR(error, "TOAST"),
            life: 10000,
          });
        });
    }
    //   prefillForm();
    getSettlementType();
    getSettlementParticipants();
    getChargeTypes();
    getSettlementTransitAccounts();
  }, [
    props.isUpdate,
    props?.merchantSettlementData,
    props?.settlementErrorInfo,
    props?.settlementInfo,
    mainContext,
  ]);

  useEffect(() => {
    if (props.isBulkUpload) {
      // setSettlementInfo({ ...settlementInfo, vatEnabled: false });
      setSettlementInfo((settlementInfo) => {
        return { ...settlementInfo, vatEnabled: false };
      });
    }
  }, [props.isBulkUpload]);

  useEffect(() => {
    function getVATRate() {
      SERVICES.GET_SINGLE_CONFIGURATION(BACKOFFICE_CONFIGS.VAT_RATE)
        .then((response) => {
          const vatRate = response?.result?.configValue;
          setConfiguredVATRate(vatRate);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }

    function getGlNameEnquiryOption() {
      SERVICES.GET_SINGLE_CONFIGURATION(
        BACKOFFICE_CONFIGS.GL_NAME_ENQUIRY_OPTION
      )
        .then((response) => {
          const shouldPerformGlNameEnquiry = response?.result?.configValue;
          setShouldPerformGlNameEnquiry(shouldPerformGlNameEnquiry);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }

    function getGlAccountNumberSize() {
      SERVICES.GET_SINGLE_CONFIGURATION(
        BACKOFFICE_CONFIGS.GL_ACCOUNT_NUMBER_SIZE
      )
        .then((response) => {
          const glSize = response?.result?.configValue;
          setGlAccountSize(glSize);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }

    getVATRate();
    getGlNameEnquiryOption();
    getGlAccountNumberSize();
  }, []);

  useEffect(() => {
    function isFormValid() {
      let optionalFields = ["settlementTransitAccountReference"];
      if (!props.isBulkUpload) {
        optionalFields.push("participants");
      }
      let _settlementInfo = { ...settlementInfo };
      if (_settlementInfo?.vatEnabled === false) {
        const { vatAccount, vatAccountName, ...remaininingProperties } =
          _settlementInfo;
        _settlementInfo = { ...remaininingProperties };
      }
      if (props.isBulkUpload) {
        // delete values that don't apply to bulk uploads
        const {
          accountName,
          accountNumber,
          settlementTransitAccountReference,
          ...remaininingProperties
        } = _settlementInfo;
        _settlementInfo = remaininingProperties;
      }
      for (const property in _settlementInfo) {
        if (
          !settlementInfo[property] &&
          // vatEnabled field can have a valid value of "false"
          settlementInfo[property] !== false &&
          // participants is not required
          !optionalFields.includes(property)
        ) {
          return false;
        }
      }
      return true;
    }
    function validateForm() {
      let validForm = isFormValid();
      const validErrorForm =
        CUSTOM_VALIDATION.VALID_OBJ_ANY(settlementErrorForm);
      setValidForm(validForm && !validErrorForm);
    }
    validateForm();
  }, [settlementInfo, settlementErrorForm, props.isBulkUpload]);

  function validateDropdown(e, name) {
    const value = e.target.value;
    if (value) {
      setSettlementErrorForm({ ...settlementErrorForm, [name]: "" });
      setSettlementInfo({ ...settlementInfo, [name]: value });
    }
  }

  function validateMultipleSelect(e, name) {
    const value = e.target.value;
    if (value.length) {
      setSettlementErrorForm({ ...settlementErrorForm, [name]: "" });
      setSettlementInfo({ ...settlementInfo, [name]: value });
    } else {
      // let errorMessage = "Select participants";
      setSettlementInfo({ ...settlementInfo, [name]: null });
      setSettlementErrorForm({ ...settlementErrorForm, [name]: null });
    }
  }

  function verifyAccountNumber(accNo, field) {
    if (field === "accountNumber") {
      setVerifyingAccountNumber(true);
      setAccountName(null);
      setSettlementErrorForm({
        ...settlementErrorForm,
        accountNumber: null,
        accountName: null,
      });
    } else {
      setVerifyingVATAccountNumber(true);
      setSettlementErrorForm({
        ...settlementErrorForm,
        vatAccount: null,
        vatAccountName: null,
      });
    }
    SERVICES.VERIFY_ACCOUNT_NUMBER(accNo)
      .then((data) => {
        const accountName = data?.result?.accountName;
        if (field === "accountNumber") {
          setSettlementInfo({
            ...settlementInfo,
            accountNumber: accNo,
            accountName: accountName,
          });
          setVerifyingAccountNumber(false);
        } else {
          setSettlementInfo({
            ...settlementInfo,
            vatAccount: accNo,
            vatAccountName: accountName,
          });
          document.getElementById("vatAccountName").value = accountName;
          setVerifyingVATAccountNumber(false);
        }
      })
      .catch((error) => {
        if (field === "accountNumber") {
          setSettlementErrorForm({
            ...settlementErrorForm,
            accountNumber: HELPER.PROCESS_ERROR(error, "NAME_ENQUIRY"),
          });
          setVerifyingAccountNumber(false);
        } else {
          setSettlementErrorForm({
            ...settlementErrorForm,
            vatAccount: HELPER.PROCESS_ERROR(error, "NAME_ENQUIRY"),
          });
          setVerifyingVATAccountNumber(false);
        }
      });
  }

  function handleVatAccountValidation(value) {
    if (VATAccountType === "GL" && shouldPerformGlNameEnquiry === "false") {
      const { vatAccountName, ...formFields } = settlementInfo;
      setSettlementInfo({
        ...formFields,
        vatAccount: value,
      });
      setSettlementErrorForm({
        ...settlementErrorForm,
        vatAccount: null,
        vatAccountName: null,
      });
    } else {
      verifyAccountNumber(value, "vatAccount");
    }
  }

  function fillForm(e, field, type, refineName, required) {
    const value = e.target.value;
    const isEmpty = CUSTOM_VALIDATION.IS_EMPTY(value);
    const isValidInput = !isEmpty
      ? CUSTOM_VALIDATION.BASIC_VALIDATION(value, type, glAccountSize)
      : false;
    if (isValidInput) {
      if (field === "accountNumber" && isNameEnquiryEnabled === "true") {
        verifyAccountNumber(value, "accountNumber");
      } else if (field === "vatAccount" && isNameEnquiryEnabled === "true") {
        handleVatAccountValidation(value);
      } else {
        setSettlementInfo({ ...settlementInfo, [field]: value });
        setSettlementErrorForm({
          ...settlementErrorForm,
          [field]: null,
        });
      }
    } else {
      let errorMessage =
        required && isEmpty ? `${refineName} is required` : null;
      if (!isValidInput) {
        errorMessage = `${refineName} is invalid`;
      }
      setSettlementErrorForm({
        ...settlementErrorForm,
        [field]: errorMessage,
      });
    }
  }

  function updateMerchantSettlementData() {
    const settlementInformation = getSettlementPayload();
    SERVICES.UPDATE_MERCHANT_SETTLEMENT(
      settlementInformation,
      settlementInformation?.merchantId
    )
      .then(() => {
        handleSuccess("updated");
      })
      .catch((error) => {
        props.callAlert("Error", HELPER.PROCESS_ERROR(error));
        setLoading(false);
      });
  }

  function submit() {
    setLoading(true);
    if (props.isUpdate) {
      updateMerchantSettlementData();
    } else {
      const settlementInformation = getSettlementPayload();
      const payload = {
        basicInfo: props.basicInfo,
        settlementInfo: settlementInformation,
      };
      SERVICES.CREATE_MERCHANT(payload)
        .then(() => {
          handleSuccess("created");
        })
        .catch((error) => {
          props.callAlert("Error", HELPER.PROCESS_ERROR(error));
          setLoading(false);
        });
    }
  }

  function handleSuccess(action) {
    props.callAlert(null, HELPER.PROCESS_ERROR(""));
    setSuccessMessage(`Super agent ${action} successfully`);
    props.offTitle();
    setCurrentIndex(1);
    setLoading(false);
  }

  function getSettlementPayload() {
    let settlementInformation = { ...settlementInfo };
    let participants = [];
    if (Array.isArray(settlementInformation.participants))
      settlementInformation.participants.forEach((e) => {
        participants.push(e.id);
      });
    settlementInformation = {
      ...settlementInformation,
      participants: participants,
      settlementType: settlementInformation?.settlementType?.code,
      chargeType: settlementInformation?.chargeType?.code,
      concessionChargeType: settlementInformation.concessionChargeType
        ? settlementInformation?.concessionChargeType?.code
        : null,
      settlementTransitAccountReference:
        settlementInformation?.settlementTransitAccountReference
          ?.accountReference,
    };
    return settlementInformation;
  }

  function handleApplyConcessionChargeChange(e) {
    setApplyConcessionCharge(e?.value);
    if (e?.value) {
      setSettlementInfo({ ...settlementInfo, concessionChargeType: null });
    } else {
      const { concessionChargeType, ...validProperties } = settlementInfo;
      setSettlementInfo({ ...validProperties });
    }
  }

  function handleAssociateVATAccountChange(e) {
    setSettlementInfo({ ...settlementInfo, vatEnabled: e?.value });
    if (e?.value) {
      setSettlementInfo({
        ...settlementInfo,
        vatAccount: null,
        vatAccountName: null,
        vatEnabled: e?.value,
      });
      setVATAccountType(null);
    } else {
      // remove fields so as not to affect validation function seeing the values as null and invalid
      const { vatAccount, vatAccountName, ...validProperties } = settlementInfo;
      setSettlementInfo({ ...validProperties, vatEnabled: e?.value });
      setSettlementErrorForm({
        ...settlementErrorForm,
        vatAccount: null,
        vatAccountName: null,
      });
    }
  }

  function resetVatAccountFields() {
    setSettlementInfo({
      ...settlementInfo,
      vatAccountName: null,
      vatAccount: null,
    });
    document.getElementById("vatAccount").value = "";
    document.getElementById("vatAccountName").value = "";
  }

  const customBackButton = () => {
    if (!loading) {
      return (
        <button
          onClick={() => {
            props.fn(0);
          }}
          className="secondary-button"
        >
          Back
        </button>
      );
    } else {
      return <div />;
    }
  };

  const customButton = () => {
    if (!loading) {
      return (
        <div>
          <button
            disabled={!validForm}
            onClick={
              props.isBulkUpload
                ? () => props.onSaveSettlementInformation(settlementInfo)
                : submit
            }
            className="primary-button"
          >
            {props.isBulkUpload ? "Next" : props.isUpdate ? "Update" : "Submit"}
          </button>
        </div>
      );
    } else {
      return (
        <div>
          <span>
            <div className="loading-icon">
              <ProgressSpinner
                style={{ width: "20px", height: "20px" }}
                strokeWidth="4"
              />
            </div>
            <div className="verifying-credentials">Submitting...</div>
          </span>
        </div>
      );
    }
  };

  const settlementForm = () => {
    if (currentIndex) {
      return (
        <div>
          <CustomMessage
            close={true}
            closeModal={props.closeModal}
            message={successMessage}
            messageType="success"
          />
        </div>
      );
    } else {
      return (
        <div className="p-grid">
          <div className="p-col-12">
            <div className="p-mt-1">
              {props.isUpdate && <label>Settlement Type</label>}
              <FormDropdown
                required={true}
                label="code"
                field="settlementType"
                error={settlementErrorForm["settlementType"]}
                disabled={loading}
                value={settlementInfo["settlementType"]}
                fn={validateDropdown}
                options={settlementTypes}
                placeholder="Settlement type *"
              />
            </div>
          </div>
          <Optional show={!props.isBulkUpload}>
            <div className="p-col-6">
              <div className="p-mt-2">
                <FormInput
                  verifyingField="account no"
                  verifying={verifyingAccountNumber}
                  verified={accountName}
                  value={settlementInfo["accountNumber"]}
                  required={true}
                  field="accountNumber"
                  type="NUBAN"
                  error={settlementErrorForm["accountNumber"]}
                  fn={fillForm}
                  loading={loading}
                  placeholder="Account number"
                />
              </div>
            </div>

            <div className="p-col-6">
              <div className="p-mt-2">
                <FormInput
                  value={settlementInfo["accountName"]}
                  required={true}
                  field="accountName"
                  type="NAME"
                  error={settlementErrorForm["accountName"]}
                  fn={fillForm}
                  disabled={isNameEnquiryEnabled === "true"}
                  placeholder="Account name"
                />
              </div>
            </div>
          </Optional>
          <div className="p-col-6">
            <div className="p-mt-2">
              {props.isUpdate ? (
                <label className="p-mb-2">Charge Type *:</label>
              ) : null}
              <FormDropdown
                required={true}
                field="chargeType"
                error={settlementErrorForm["chargeType"]}
                disabled={loading}
                label="desc"
                value={settlementInfo["chargeType"]}
                fn={validateDropdown}
                options={chargeTypes}
                placeholder="Charge type *"
                filter
              />
            </div>
          </div>
          <div className="p-col-6">
            <div className="p-mt-2">
              {props.isUpdate ? (
                <label className="p-mb-2">Participants:</label>
              ) : null}
              <FormMultiselect
                required={props.isBulkUpload ? true : false}
                error={settlementErrorForm["participants"]}
                field="participants"
                label="code"
                value={settlementInfo["participants"]}
                options={participants}
                fn={validateMultipleSelect}
                placeholder={`Participants ${props.isBulkUpload ? "*" : ""}`}
                filter
              />
            </div>
          </div>
          <Optional show={!props.isBulkUpload}>
            <div className="p-col-6">
              <div className="p-mt-2">
                {props.isUpdate ? (
                  <label className="p-mb-2">Settlement Transit Account:</label>
                ) : null}
                <Dropdown
                  value={settlementInfo["settlementTransitAccountReference"]}
                  onChange={(e) =>
                    setSettlementInfo({
                      ...settlementInfo,
                      settlementTransitAccountReference: e.value,
                    })
                  }
                  options={settlementTransitAccounts}
                  optionLabel="accountName"
                  showClear
                  placeholder="Select transit account"
                  className="w-full md:w-14rem"
                />
              </div>
            </div>
          </Optional>
          {/* <div className="p-grid"> */}
          <div className="p-col-12 p-mt-3 flex default">
            <div className="flex default">
              <div style={{ marginBottom: "-0.15rem" }}>
                <ToolTip tip="Associate a concession charge to a merchant. This covers discounts or waived fees." />
              </div>
              <p className="text-small p-my-0 p-ml-1">
                Apply concession charge?
              </p>
              <span className="p-px-4">
                <InputSwitch
                  checked={applyConcessionCharge}
                  onChange={(e) => handleApplyConcessionChargeChange(e)}
                />
              </span>
            </div>
          </div>
          <Optional show={applyConcessionCharge}>
            <div className="p-col-12 p-mt-3">
              <FormDropdown
                required={true}
                field="concessionChargeType"
                error={settlementErrorForm["concessionChargeType"]}
                disabled={loading}
                label="desc"
                value={settlementInfo["concessionChargeType"]}
                fn={validateDropdown}
                options={chargeTypes}
                placeholder="Select concession charge type *"
                filter
              />
            </div>
          </Optional>
          <div className="p-col-12 p-mt-3">
            <div className="flex default">
              <div style={{ marginBottom: "-0.15rem" }}>
                <ToolTip
                  tip={`VAT will be charged on the transaction fee and enabling it will increase the current
                        merchant fee by the configured VAT rate of ${configuredVATRate}%.`}
                />
              </div>
              <p className="text-small p-my-0 p-ml-1">Enable VAT?</p>
              <span className="p-px-4">
                <InputSwitch
                  checked={settlementInfo["vatEnabled"]}
                  onChange={(e) => handleAssociateVATAccountChange(e)}
                />
              </span>
            </div>
          </div>
          <Optional show={settlementInfo["vatEnabled"]}>
            <div className="p-col-12">
              <div className="p-mt-1">
                <FormDropdown
                  required={true}
                  field="VATAccountType"
                  error=""
                  disabled={loading}
                  value={VATAccountType}
                  fn={(e) => {
                    resetVatAccountFields();
                    setVATAccountType(e?.target?.value);
                  }}
                  options={accountTypes}
                  placeholder="Select VAT account type *"
                />
              </div>
            </div>
          </Optional>
          {/* <div className="p-col-12 p-mt-3 p-pr-0 p-text-left flex default">
            <ToolTip tip={`Provide the account number to credit VAT.`} />
          </div> */}
          <Optional show={settlementInfo["vatEnabled"]}>
            <div className="p-col-6 p-mt-2">
              <div className="p-text-left">
                <ToolTip tip={`Provide the account number to credit VAT.`} />
              </div>
              <FormInput
                verifyingField="account no"
                verifying={verifyingVATAccountNumber}
                value={settlementInfo["vatAccount"]}
                required={true}
                field="vatAccount"
                type={VATAccountType}
                error={settlementErrorForm["vatAccount"]}
                fn={fillForm}
                disabled={!VATAccountType}
                placeholder="VAT account number"
              />
            </div>
            <div className="p-col-6" style={{ marginTop: "1.75rem" }}>
              <FormInput
                value={settlementInfo["vatAccountName"]}
                required={true}
                field="vatAccountName"
                type="NAME"
                error={settlementErrorForm["vatAccountName"]}
                fn={fillForm}
                disabled={true}
                placeholder="VAT account name"
              />
            </div>
            {/* </div> */}
          </Optional>
          <div className="p-col-12" style={{ marginBottom: "2rem" }}>
            <div className="p-mt-5">
              <div className="p-grid">
                <div className={loading ? "p-col-12" : "p-col-6"}>
                  {customBackButton()}
                </div>
                <div className={loading ? "p-col-12" : "p-col-6"}>
                  {customButton()}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="super-agents">
      <div>
        <Toast ref={toast} />
      </div>
      <div>{settlementForm()}</div>
    </div>
  );
}
