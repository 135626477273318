import { useEffect, useState } from "react";
// import { BACKOFFICE_SERVICE } from "../../../core/services/backoffice-service";
import { FormDropdown } from "../../../shared/components/form-component/form-dropdown";
import { FormRadioInput } from "../../../shared/components/form-component/form-radio-input";
import { Optional } from "../../../shared/components/optional/optional";

export function UsersFilter(props) {
  const [searchBy, setSearchBy] = useState("username");
  const [username, setUsername] = useState("");
  const [userRoles] = useState(props.roles);
  const [selectedRole, setSelectedRole] = useState("");

  useEffect(() => {
    setUsername("");
    setSelectedRole("");
  }, [props.resetFilters]);

  function handleSearchTypeSelection(e) {
    const value = e?.target?.value;
    setSearchBy(value);
  }

  function handleRoleSelection(e) {
    const value = e.target.value;
    setSelectedRole(value);
  }

  function handleSearch() {
    const payload = searchBy === "username" ? username : selectedRole;
    props?.onSearch(searchBy, payload);
  }

  return (
    <div className="p-grid users-filter">
      <div className="p-col-3">
        <div className="flex default">
          <FormRadioInput
            id="by-username"
            value="username"
            name="search-by"
            checked={searchBy === "username"}
            handleChange={handleSearchTypeSelection}
            label="Search By Username"
          />
          <div className="p-ml-3">
            <FormRadioInput
              id="by-role"
              value="role"
              name="search-by"
              checked={searchBy === "role"}
              handleChange={handleSearchTypeSelection}
              label="Filter By Role"
            />
          </div>
        </div>
      </div>
      <div className="p-col-3">
        <Optional show={searchBy === "username"}>
          <input
            defaultValue={username}
            onChange={(e) => setUsername(e?.target?.value)}
            placeholder="Enter Username"
          />
        </Optional>
        <Optional show={searchBy === "role"}>
          <FormDropdown
            required={false}
            error={null}
            disabled={false}
            value={selectedRole}
            fn={handleRoleSelection}
            options={userRoles}
            placeholder="Select a role"
          />
        </Optional>
      </div>
      <div className="p-col-2">
        <button
          className="primary-button"
          disabled={!selectedRole.length && !username.length}
          onClick={handleSearch}
        >
          Search
        </button>
      </div>
    </div>
  );
}
