import { useRef, useState } from "react";
import { CustomLoader } from "../custom-loader/custom-loader";
import { FormInput } from "../form-component/form-input";
import { CUSTOM_VALIDATION } from "../../validation/validation";
import moment from "moment";
import { Toast } from "primereact/toast";

export function BulkDisputesFilter(props) {
  const toast = useRef(null);
  const [loading] = useState(false);
  const [currentIndex] = useState(0);
  const [filters, setFilters] = useState({
    endDate: moment().format("YYYY-MM-DD"),
    startDate: moment().format("YYYY-MM-DD"),
  });
  const [filtersError, setFiltersError] = useState({
    endDate: null,
    startDate: null,
  });

  function validateForm(e, name, type, refineName, required) {
    let value = e?.target?.value;
    const isEmpty = CUSTOM_VALIDATION.IS_EMPTY(value);
    const isValidInput = !isEmpty
      ? CUSTOM_VALIDATION.BASIC_VALIDATION(value, type)
      : false;
    if (isValidInput) {
      setFilters({ ...filters, [name]: value });
      setFiltersError({ ...filtersError, [name]: null });
    } else {
      let errorMessage =
        required && isEmpty ? `${refineName} is required` : null;
      if (!isValidInput) {
        errorMessage = `${refineName} is invalid`;
      }
      setFilters({ ...filters, [name]: null });
      setFiltersError({ ...filtersError, [name]: errorMessage });
    }
  }

  function getFilters() {
    let payload = {};

    for (const property in filters) {
      if (filters[property]) {
        payload[property] = filters[property];
      }
    }
    return payload;
  }

  function cancelModal() {
    props.closeModal();
  }

  const cancelButton = () => {
    if (!loading) {
      return (
        <button onClick={cancelModal} className="secondary-button">
          Cancel
        </button>
      );
    } else {
      return <div />;
    }
  };

  const submitButton = () => {
    if (!loading) {
      return (
        <button
          onClick={() => props?.searchFunction(getFilters())}
          className="primary-button"
        >
          Filter
        </button>
      );
    } else {
      return (
        <div className="pull-up-element-2">
          <CustomLoader loadingText="Submitting..." />
        </div>
      );
    }
  };

  const bulkDisputesFilterView = () => {
    if (currentIndex) {
      return (
        <div>
          <div className="success-message-btn-container">
            <button
              onClick={() => {
                props.closeModal(true);
              }}
              className="primary-button success-message-btn"
            >
              Close
            </button>
          </div>
        </div>
      );
    } else {
      return <div>{bulkDisputesFilterForm()}</div>;
    }
  };

  const bulkDisputesFilterForm = () => {
    return (
      <div>
        <div className="custom-modal-title p-text-left">Filter</div>
        <div className="custom-dialog-subtitle-container p-mb-5 p-text-left">
          <small>Select date range below.</small>
        </div>
        <div className="p-grid p-mt-5">
          <div className="p-col-6">
            <label>From:</label>
            <FormInput
              inputType="date"
              value={filters["startDate"]}
              required={false}
              field="startDate"
              type="INPUT"
              error={filtersError["startDate"]}
              fn={validateForm}
              loading={loading}
              placeholder="Start date"
            />
          </div>
          <div className="p-col-6">
            <label>To:</label>
            <FormInput
              inputType="date"
              value={filters["endDate"]}
              required={false}
              field="endDate"
              type="INPUT"
              error={filtersError["endDate"]}
              fn={validateForm}
              loading={loading}
              placeholder="End date"
            />
          </div>
          <div className="p-col-12">
            <div className="p-mt-5">
              <div className="p-grid">
                <div className={loading ? "p-col-12" : "p-col-6"}>
                  {cancelButton()}
                </div>
                <div className={loading ? "p-col-12" : "p-col-6"}>
                  {submitButton()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div>
        <Toast ref={toast} />
      </div>
      <div className="p-pb-2">{bulkDisputesFilterView()}</div>
    </div>
  );
}
