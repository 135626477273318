// import "./login.css";
import { Icon } from "../../../shared/icons/icon";
import { useState, useContext, useEffect } from "react";
import { MainContext } from "../../../../App";
import { SERVICES } from "../../../core/services/services";
import { CustomToast } from "../../../shared/components/alert/custom-toast";
import { useHistory } from "react-router-dom";
import { CustomLoader } from "../../../shared/components/custom-loader/custom-loader";
import { persistLoginDataAndRedirect } from "../helpers";
import { useHasCustomLogo } from "../../../core/custom-hooks/has-custom-logo";
import { HELPER } from "../../../shared/helper/helper";
import { LOCAL_STORAGE } from "../../../shared/models/utilities";

export function TwoFactor() {
  let history = useHistory();
  const mainContext = useContext(MainContext);
  const [token, setToken] = useState("");
  const [errorTitle, setErrorTitle] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingText] = useState("Verifying credentials...");
  const [credentialsError, setCredentialsError] = useState({
    token: null,
  });
  const hasCustomLogo = useHasCustomLogo();

  useEffect(() => {
    const secondFactorAuthKey = sessionStorage.getItem(
      LOCAL_STORAGE.TWO_FA_KEY
    );
    if (!secondFactorAuthKey) {
      mainContext.mainDispatch({
        type: "SET_ALERT",
        alert: {
          title: "Error",
          description: "Your previous session expired, please login again!",
          type: "error",
          showAlert: true,
        },
      });
      history.push("/login");
    }
  }, [history, mainContext]);

  function handleTokenChange(e) {
    setCredentialsError({ ...credentialsError, token: null });
    setToken(e.target.value);
  }

  function handlePasswordReset(loginData) {
    mainContext.mainDispatch({
      type: "SET_ALERT",
      alert: {
        title: "Change Default Password",
        description:
          "You just tried to login with a system generated default password. You need to change this password to access your account.",
        type: "error",
        showAlert: true,
      },
    });
    mainContext.mainDispatch({
      type: "TEMPORARILY_SET_LOGIN_DETAILS",
      loginData,
      access_token: loginData?.cosmosResponse?.accessToken,
    });
    history.push("/change-password");
  }

  function submit(e) {
    e.preventDefault();
    closeAlert();
    setErrorTitle("");
    if (token.length) {
      const secondFactorAuthKey = sessionStorage.getItem(
        LOCAL_STORAGE.TWO_FA_KEY
      );
      setLoading(true);
      const payload = {
        token: token,
        secondFactorAuthKey,
      };
      SERVICES.AUTHENTICATE_2FA_CODE(payload)
        .then((data) => {
          if (data?.result?.resetPassword) {
            handlePasswordReset(data?.result);
          } else {
            persistLoginDataAndRedirect(data?.result, mainContext, history);
          }
        })
        .catch((error) => {
          handleErrors(error);
        });
    } else {
      setCredentialsError({
        token: token.length ? null : true,
      });
    }
  }

  function closeAlert() {
    mainContext.mainDispatch({
      type: "SET_ALERT",
      alert: {
        title: "",
        description: "",
        type: "",
        showAlert: false,
      },
    });
  }

  function handleErrors(error) {
    setErrorTitle("Error");
    setErrorMessage(HELPER.PROCESS_ERROR(error));
    // }
    setLoading(false);
  }

  return (
    <div>
      <div className="login-alert-container">
        {errorTitle ? (
          <CustomToast
            title={errorTitle}
            description={errorMessage}
            type="error"
          />
        ) : (
          <div />
        )}
      </div>
      <form onSubmit={submit} className="auth-form">
        <div className="login-container">
          <div className="log-icon-top-container">
            <div className="login-icon-position">
              <div className={`logo-size ${hasCustomLogo ? "" : "default"}`}>
                <Icon icon="logo" />
              </div>
              <h1 className={`app-name ${hasCustomLogo ? "" : "default"}`}>
                Direct Card Issuer Routing
              </h1>
            </div>
          </div>
          <div className="login-card">
            <div className="login-form-container">
              <div>
                <div>
                  <label className="label p-pb-1">
                    Two-factor authentication code:
                  </label>
                  <input
                    disabled={loading}
                    onChange={handleTokenChange}
                    value={token}
                    placeholder="XXXXXX"
                    type="text"
                    className={`two-fa-input ${
                      credentialsError.token ? "validation-error" : ""
                    }`}
                  />
                  {credentialsError.token && (
                    <p className="p-error p-text-left">Token is required</p>
                  )}
                </div>
                <div>
                  <div className="p-mt-5">
                    {!loading ? (
                      <div>
                        <button type="submit" className="primary-button">
                          Verify
                        </button>
                      </div>
                    ) : (
                      <div>
                        <CustomLoader loadingText={loadingText} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
