import "./bulk-settlement.css";
import React, { useState } from "react";
import { SpecialLabelCases } from "../../../shared/models/utilities";
import { SettlementPaymentAttempts } from "./settlement-payment-attempts";
import { HELPER } from "../../../shared/helper/helper";

export function PaymentRequestBreakdown(props) {
  const [currentIndex, setCurrentIndex] = useState(0);
  // const [requestId, setRequestId] = useState(null);

  const transformView = (itemCase, value) => {
    let result = value;
    if (itemCase) {
      SpecialLabelCases.forEach((e) => {
        if (e.case === itemCase) {
          result = e.action(value);
        }
      });
    }
    return result || "___";
  };

  function showAttemptDetails() {
    setCurrentIndex(1);
  }

  function goBack() {
    if (currentIndex === 1) {
      setCurrentIndex(0);
    } else {
      props.goBack();
    }
  }

  const detailsView = () => {
    switch (currentIndex) {
      case 0:
        return props.details.map((details, index) => (
          <div key={index.toString()} className="p-grid dcir-row">
            <div className="p-col-6">
              <p className="details-label">{details.label}:</p>
            </div>
            <div className="p-col-6">
              <p className="details-value">
                {transformView(details.itemCase, details.value)}
              </p>
              {HELPER.HAS_AUTHORITY("bo_dcir_fetch_payment_attempts") &&
                details.label === "Total Attempts" && (
                  <button
                    onClick={showAttemptDetails}
                    className="p-button-success view-details"
                  >
                    View Details
                  </button>
                )}
            </div>
          </div>
        ));
      case 1:
        return (
          <div className="p-text-center">
            <SettlementPaymentAttempts requestId={props.requestId} />
          </div>
        );
      default:
        return <div></div>;
    }
  };

  return (
    <div>
      <div>
        <div className="p-text-left">
          <p onClick={goBack} className="close-modal add-cursor p-ml-4">
            {/* Go Back */}
            <i className="pi pi-arrow-left"></i>
          </p>
          <p className="custom-title p-ml-4">
            {currentIndex === 1 ? "Settlement Attempts" : "Payment Details"}
          </p>
        </div>
        <div className="p-text-left">{detailsView()}</div>
      </div>
    </div>
  );
}
