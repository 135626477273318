export const MAXIMUM_RECEIPT_SIZE_IN_MB = 2;
export const MAXIMUM_BULK_DISPUTE_ZIP_SIZE_IN_MB = 800;
export const API_REQUEST_PAGE_SIZE = 10;
export const SYSTEM_ACCEPTED = "SYSTEM_ACCEPTED";
export const DECLINED = "DECLINED";
export const BACKOFFICE_CONFIGS = {
  DISPUTE_REASON_LIST: "dcir.dispute.reason.list",
  GL_ACCOUNT_NUMBER_SIZE: "dcir.gl.account.number.size",
  GL_NAME_ENQUIRY_OPTION: "dcir.gl.name.enquiry.applicable",
  BACKOFFICE_DISPUTE_RESPONSE_CODES: "dcir.dispute.backoffice.response-codes",
  FRONTOFFICE_DISPUTE_RESPONSE_CODES: "dcir.dispute.merchant.response-codes",
  TRANSACTIONS_DOWNLOAD_DATE_RANGE: "dcir.calendar.date-range-in-days",
  ACTIVE_DIRECTORY_STATUS: "dcir.active-directory-enabled",
  AUTO_DISPUTE_CREATION_STATUS: "dcir.recon.dispute.auto-create",
  VAT_RATE: "dcir.charge.vat.percentage",
  IS_NAME_ENQUIRY_ENABLED: "dcir.name-enquiry-enabled",
  IS_MANUAL_DISPUTE_REFUND_ENABLED: "dcir.enable.manual.dispute-refund",
  IS_MANUAL_SETTLEMENT_PAYMENT_ENABLED: "dcir.enable.manual.settlement-payment",
};
export const API_VERSION = "v1";
export const PASSWORD_VALIDATION_ERROR_MESSAGE = `Your password must contain at least 8 characters, an uppercase character, a lowercase character and a special character.`;

export const DisputeTypes = {
  AgentDisputes: "agent-disputes",
  BankDisputes: "bank-disputes",
  BackofficeFailedResponseDisputes: "backoffice-failed-response-disputes",
  Backoffice: "backoffice",
  BackofficeSuperAgent: "backoffice-superagent",
};

export const ManualPaymentTypes = {
  Settlement: "Settlement",
  Dispute: "Dispute",
};
export const MAX_ANALYTICS_DATE_RANGE_IN_DAYS = 30;

export const DOWNLOADED_FOR_PROCESSING = "DOWNLOADED_FOR_PROCESSING";

export const UserTypes = {
  FrontOffice: "FrontOffice",
  BackOffice: "BackOffice",
};
