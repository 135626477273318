import { useState } from "react";
import { CustomTable } from "../../../shared/components/custom-table/custom-table";

export function DisputeAuditTrail(props) {
  const [pageNo, setPageNo] = useState(0);
  const [totalPages] = useState(1);
  const tableHeaders = [
    { label: "User", value: "updatedBy" },
    { label: "Action Type", value: "actionType" },
    { label: "Resolution Status", value: "status" },
    { label: "Date", value: "logTime" },
  ];
  const [auditItems] = useState(props?.disputeAuditTrail);
  function reload() {
    // console.log("reload");
  }
  return (
    <div style={{ marginTop: "4rem" }}>
      <CustomTable
        isReload={false}
        totalPages={totalPages}
        totalItems={1}
        currentPage={1}
        range={1}
        emptyText="No results found"
        search={true}
        reload={reload}
        error={null}
        items={auditItems}
        headers={tableHeaders}
        nextPage={() => setPageNo(pageNo + 1)}
        prevPage={() => setPageNo(pageNo - 1)}
        goToFirstPage={() => setPageNo(0)}
        goToLastPage={() => setPageNo(totalPages - 1)}
        goToPage={(e) => setPageNo(e)}
      />
    </div>
  );
}
