import { useRef, useState } from "react";
import { HELPER } from "../../../../shared/helper/helper";
import { CustomToast } from "../../../../shared/components/alert/custom-toast";
import { CustomLoader } from "../../../../shared/components/custom-loader/custom-loader";
import { CustomMessage } from "../../../../shared/components/alert/custom-message";
import { Icon } from "../../../../shared/icons/icon";
import { SERVICES } from "../../../../core/services/services";
import { ManualPaymentTypes } from "../../../../shared/constants";
import { BACKOFFICE_API } from "../../../../core/apis/backoffice-apis";

export function ManuelPaymentsBulkFileUpload(props) {
  const message = "Click to upload a .xlsx file";
  const [fileName, setFileName] = useState(message);
  const fileInput = useRef();
  const [isValidUpload, setIsValidUpload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [file, setFile] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");

  function onUpload(e) {
    setErrorMessage(null);
    setFileName(message);
    setIsValidUpload(false);
    const selectedFile = e?.target?.files[0];

    if (selectedFile) {
      const name = selectedFile?.name;
      setFileName(name);
      setFile(selectedFile);
      setIsValidUpload(true);
    }
  }

  const getUploadUrl = () => {
    if (props.manualPaymentType === ManualPaymentTypes.Dispute) {
      return BACKOFFICE_API.MANUAL_PAYMENTS.UPLOAD_DISPUTE_REFUND_BULK;
    } else {
      return BACKOFFICE_API.MANUAL_PAYMENTS.UPLOAD_MANUAL_SETTLEMENT_BULK;
    }
  };

  function submit() {
    setLoading(true);
    const formData = new FormData();
    formData.append("file", file, fileName);
    const url = getUploadUrl();
    SERVICES.MAKE_POST_REQUEST(url, formData)
      .then((res) => {
        setSuccessMessage(res?.result?.message);
        // console.log("res", res);
        setLoading(false);
        setCurrentIndex(1);
      })
      .catch((error) => {
        setErrorMessage(HELPER.PROCESS_ERROR(error));
        setLoading(false);
      });
  }

  const cancelButton = () => {
    if (!loading) {
      return (
        <button onClick={() => props.closeModal()} className="secondary-button">
          Cancel
        </button>
      );
    } else {
      return <div />;
    }
  };

  const submitButton = () => {
    if (!loading) {
      return (
        <button
          onClick={submit}
          disabled={!isValidUpload}
          className="primary-button"
        >
          Submit
        </button>
      );
    } else {
      return (
        <div className="pull-up-element-2 p-mt-5">
          <CustomLoader loadingText="Submitting..." />
        </div>
      );
    }
  };

  const currentView = () => {
    if (currentIndex) {
      return (
        <div className="p-text-center p-mt-2 success-wrapper">
          <div>
            <CustomMessage messageType="success" />
          </div>
          <div className="w-100">
            <p className="success-message-text">
              {/* Bulk dispute uploaded successfully! */}
              {successMessage}
            </p>
          </div>
          <div className="success-message-btn-container p-text-center">
            <p
              className="close-modal add-cursor p-mt-3"
              onClick={() => props.closeModal(true)}
            >
              Close
            </p>
          </div>
        </div>
      );
    } else {
      return (
        <div className="bulk-upload">
          <div>
            <p className="custom-modal-title p-text-left p-mt-0">Bulk Upload</p>
          </div>
          <div className="custom-dialog-subtitle-container-lg">
            <p className="custom-dialog-subtitle p-mb-5">
              Click below to upload file
            </p>
          </div>

          {/* <CustomUpload
            accept=".xlsx"
            title={message}
            fileType="spreadsheet"
            getUploadedFile={(file) => onUpload(file)}
            clearUploadedFile={() => setFile(null)}
            noMaxSizeTip={true}
            maxFileSizeInMb="5"
          /> */}
          <div>
            <input
              style={{ display: "none" }}
              type="file"
              onChange={onUpload}
              ref={fileInput}
              accept=".xlsx"
            />
            <div
              onClick={() => fileInput.current.click()}
              className="custom-upload-container"
            >
              <div className="p-grid p-text-left">
                <span className="p-col-3">
                  <Icon icon="upload" />
                </span>
                <span className="p-col-9">
                  <span className="p-grid upload-grid-position">
                    <span className="p-col-12">
                      <span className="upload-title">{props.title}</span>
                    </span>
                    <span className="p-col-12">
                      <span className="upload-text">{fileName}</span>
                    </span>
                  </span>
                </span>
              </div>
            </div>
          </div>
          {errorMessage && (
            <div>
              <CustomToast
                title="Error"
                description={errorMessage}
                type="error"
              />
            </div>
          )}
          <div className="p-col-12 p-mb-2">
            <div className="p-mt-4">
              <div className="p-grid">
                <div className="p-col-6 p-pl-0">{cancelButton()}</div>
                <div className={`${loading ? "p-col-12" : "p-col-6"} p-pr-0`}>
                  {submitButton()}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  return <div>{currentView()}</div>;
}
