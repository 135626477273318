import "./bulk-disputes.css";
import { Icon } from "../../icons/icon";
import { useRef, useState } from "react";
import { SERVICES } from "../../../core/services/services";
import { HELPER } from "../../helper/helper";
import { CustomToast } from "../alert/custom-toast";
import { CustomLoader } from "../custom-loader/custom-loader";
import { CustomMessage } from "../alert/custom-message";
import { saveAs } from "file-saver";

export function BulkDisputeUpload(props) {
  const [fileName, setFileName] = useState("Click to upload file");
  const fileInput = useRef();
  const [isValidUpload, setIsValidUpload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [file, setFile] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");

  function onUpload(e) {
    setErrorMessage(null);
    setFileName("Click to upload file");
    setIsValidUpload(false);
    const selectedFile = e?.target?.files[0];
    if (selectedFile) {
      const name = selectedFile?.name;
      setFileName(name);
      setFile(selectedFile);
      setIsValidUpload(true);
    }
  }

  function downloadExcelTemplate() {
    saveAs(
      `${process.env.PUBLIC_URL}/bulk_dispute_sample.xlsx`,
      "bulk_dispute_sample.xlsx"
    );
  }

  function downloadCsvTemplate() {
    saveAs(
      `${process.env.PUBLIC_URL}/bulk_dispute_sample.csv`,
      "bulk_dispute_sample.csv"
    );
  }

  function submit() {
    setLoading(true);
    const formData = new FormData();
    formData.append("file", file, fileName);
    SERVICES.UPLOAD_BULK_DISPUTE(formData)
      .then((res) => {
        setSuccessMessage(res.result);
        setLoading(false);
        setCurrentIndex(1);
      })
      .catch((error) => {
        setErrorMessage(HELPER.PROCESS_ERROR(error));
        setLoading(false);
      });
  }

  const cancelButton = () => {
    if (!loading) {
      return (
        <button onClick={() => props.closeModal()} className="secondary-button">
          Cancel
        </button>
      );
    } else {
      return <div />;
    }
  };

  const submitButton = () => {
    if (!loading) {
      return (
        <button
          onClick={submit}
          disabled={!isValidUpload}
          className="primary-button"
        >
          Submit
        </button>
      );
    } else {
      return (
        <div className="pull-up-element-2 p-mt-5">
          <CustomLoader loadingText="Submitting..." />
        </div>
      );
    }
  };

  const currentView = () => {
    if (currentIndex) {
      return (
        <div className="p-text-center p-mt-2 success-wrapper">
          <div>
            <CustomMessage messageType="success" />
          </div>
          <div className="w-100">
            <p className="success-message-text">
              {/* Bulk dispute uploaded successfully! */}
              {successMessage}
            </p>
          </div>
          <div className="success-message-btn-container p-text-center">
            <p
              className="close-modal add-cursor p-mt-3"
              onClick={() => props.closeModal(true)}
            >
              Close
            </p>
          </div>
        </div>
      );
    } else {
      return (
        <div className="bulk-upload">
          <div>
            <p className="custom-modal-title p-text-left p-mt-0">
              Bulk Dispute Upload
            </p>
          </div>
          <div className="custom-dialog-subtitle-container-lg">
            <p className="custom-dialog-subtitle p-mb-5">
              Click below to upload file
            </p>
          </div>
          {errorMessage && (
            <div>
              <CustomToast
                title="Error"
                description={errorMessage}
                type="error"
              />
            </div>
          )}
          <div>
            <input
              style={{ display: "none" }}
              type="file"
              onChange={onUpload}
              ref={fileInput}
            />
            <div
              onClick={() => fileInput.current.click()}
              className="custom-upload-container"
            >
              <div className="p-grid p-text-left">
                <span className="p-col-3">
                  <Icon icon="upload" />
                </span>
                <span className="p-col-9">
                  <span className="p-grid upload-grid-position">
                    <span className="p-col-12">
                      <span className="upload-title">{props.title}</span>
                    </span>
                    <span className="p-col-12">
                      <span className="upload-text">{fileName}</span>
                    </span>
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div className="p-text-left p-mt-4 p-d-flex p-justify-between">
            <button
              className="bare-button"
              onClick={downloadExcelTemplate}
              style={{ textDecoration: "underline", fontSize: "0.9rem" }}
            >
              Download Excel Template
            </button>
            <button
              className="bare-button"
              onClick={downloadCsvTemplate}
              style={{ textDecoration: "underline", fontSize: "0.9rem" }}
            >
              Download CSV Template
            </button>
          </div>
          <div className="p-col-12 p-mb-2">
            <div className="p-mt-4">
              <div className="p-grid">
                <div className="p-col-6">{cancelButton()}</div>
                <div className={loading ? "p-col-12" : "p-col-6"}>
                  {submitButton()}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  return <div>{currentView()}</div>;
}
