// import { Icon } from "../../icons/icon";
import React from "react";
import { Dialog } from "primereact/dialog";
import "./modal.css";

export function CustomModal(props) {
  function onHide() {
    props.onHide();
  }
  // const shouldReload = true;
  // const isCancelButtonClick = true;

  const header = (
    <div>
      <div>
        <div
          style={{ position: "relative", top: "-.4em", left: "-.4em" }}
          className="p-text-left"
        >
          <i
            onClick={() => props.closeModal()}
            className="pi pi-times-circle add-cursor"
            style={{
              fontSize: "1em",
              color: "#ffffff",
              backgroundColor: "#0371AC",
              borderRadius: "50%",
            }}
          />
        </div>
      </div>
    </div>
  );
  return (
    <div>
      <Dialog
        draggable={false}
        header={header}
        blockScroll={true}
        dismissableMask={true}
        closable={false}
        position="top"
        showHeader={true}
        visible={props.visible}
        onHide={() => onHide()}
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
      >
        <div className="custom-modal-content">
          <div className="custom-modal-item">{props.modalContent()}</div>
        </div>
      </Dialog>
    </div>
  );
}
