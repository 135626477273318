import "../login/login.css";
import { Icon } from "../../shared/icons/icon";
import { useState, useContext, useEffect } from "react";
import { MainContext } from "../../../App";
import { SERVICES } from "../../core/services/services";
// import { CONFIG } from "../../shared/config/config";
import { useHistory } from "react-router-dom";
import { LOCAL_STORAGE_SERVICE } from "../../core/services/storage-service";
import { CustomLoader } from "../../shared/components/custom-loader/custom-loader";
import { HELPER } from "../../shared/helper/helper";
import { FormInput } from "../../shared/components/form-component/form-input";
import { useHasCustomLogo } from "../../core/custom-hooks/has-custom-logo";
import { persistLoginDataAndRedirect } from "../login/helpers";
import { CUSTOM_VALIDATION } from "../../shared/validation/validation";
import { PASSWORD_VALIDATION_ERROR_MESSAGE } from "../../shared/constants";

export function ChangePassword() {
  let history = useHistory();
  const mainContext = useContext(MainContext);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [accessToken, setAccessToken] = useState(
    mainContext?.mainState?.access_token
  );
  const [loading, setLoading] = useState(false);
  const [loadingText] = useState("Submitting...");
  const [isValidForm, setIsValidForm] = useState(false);
  const hasCustomLogo = useHasCustomLogo();
  const [passwordValidationError, setPasswordValidationError] = useState(null);
  const [passwordMismatchError, setPasswordMismatchError] = useState(null);

  useEffect(() => {
    if (!accessToken) {
      let token = LOCAL_STORAGE_SERVICE.GET_TOKEN();
      setAccessToken(token);
      if (!token) {
        history.push("/login");
      }
    }
  }, [accessToken, history]);

  useEffect(() => {
    function validateForm() {
      if (
        oldPassword.length &&
        newPassword.length &&
        newPassword === passwordConfirmation &&
        !passwordValidationError
      ) {
        setIsValidForm(true);
      } else {
        setIsValidForm(false);
      }
    }
    validateForm();
  }, [newPassword, passwordConfirmation, oldPassword, passwordValidationError]);

  function handleOldPasswordChange(e) {
    setOldPassword(e.target.value);
  }

  function handleNewPasswordChange(e) {
    const value = e?.target?.value;
    setNewPassword(value);
    if (CUSTOM_VALIDATION.IS_VALID_PASSWORD(value)) {
      setPasswordValidationError(null);
    } else {
      setPasswordValidationError(PASSWORD_VALIDATION_ERROR_MESSAGE);
    }
  }

  function handlePasswordConfirmationChange(e) {
    const value = e?.target?.value;
    setPasswordConfirmation(value);
    if (value !== newPassword) {
      setPasswordMismatchError("Passwords don't match!");
    } else {
      setPasswordMismatchError(null);
    }
  }

  function submit(e) {
    closeAlert();
    e.preventDefault();
    setLoading(true);
    const payload = {
      oldPassword,
      newPassword,
    };
    SERVICES.CHANGE_PASSWORD(accessToken, payload)
      .then((data) => {
        const loginData = mainContext?.mainState?.loginData;
        if (loginData) {
          const isChangePasswordAutoLogin = true;
          persistLoginDataAndRedirect(
            loginData,
            mainContext,
            history,
            isChangePasswordAutoLogin
          );
        } else {
          setLoading(false);
          setTimeout(() => {
            history.push("/login");
          }, 1000);
        }
      })
      .catch((error) => {
        mainContext.mainDispatch({
          type: "SET_ALERT",
          alert: {
            title: "Error",
            description: HELPER.PROCESS_ERROR(error),
            type: "error",
            showAlert: true,
          },
        });
        setLoading(false);
      });
  }

  function closeAlert() {
    mainContext.mainDispatch({
      type: "SET_ALERT",
      alert: {
        title: "",
        description: "",
        type: "",
        showAlert: false,
      },
    });
  }

  return (
    <div>
      <form onSubmit={submit} className="auth-form">
        <div className="login-container">
          <div className="log-icon-top-container">
            <div className="login-icon-position">
              <div className={`logo-size ${hasCustomLogo ? "" : "default"}`}>
                <Icon icon="logo" />
              </div>
              <h1 className={`app-name ${hasCustomLogo ? "" : "default"}`}>
                Direct Card Issuer Routing
              </h1>
            </div>
          </div>
          <div className="login-card">
            <div className="p-text-left">
              <p className="login-note">Change password</p>
            </div>
            <div className="login-form-container">
              <div>
                <div>
                  <FormInput
                    value={oldPassword}
                    required={true}
                    type="INPUT"
                    error={false}
                    fn={handleOldPasswordChange}
                    loading={loading}
                    placeholder="Enter old password"
                    inputType="password"
                  />
                </div>
                <br />
                <div>
                  {/* <input
										disabled={loading}
										onChange={handleNewPasswordChange}
										placeholder="Enter new password"
										type="password"
									/> */}
                  <FormInput
                    value={newPassword}
                    required={true}
                    type="INPUT"
                    error={passwordValidationError}
                    fn={handleNewPasswordChange}
                    loading={loading}
                    placeholder="Enter new password"
                    inputType="password"
                  />
                </div>
                <br />
                <div>
                  {/* <input
										disabled={loading}
										onChange={
											handlePasswordConfirmationChange
										}
										placeholder="Confirm new password"
										type="password"
									/> */}
                  <FormInput
                    value={passwordConfirmation}
                    required={true}
                    type="INPUT"
                    error={passwordMismatchError}
                    fn={handlePasswordConfirmationChange}
                    loading={loading}
                    placeholder="Confirm new password"
                    inputType="password"
                  />
                </div>
                <div>
                  <div className="p-mt-5">
                    {!loading ? (
                      <div>
                        <button
                          disabled={!isValidForm}
                          onClick={submit}
                          className="primary-button"
                        >
                          Submit
                        </button>
                      </div>
                    ) : (
                      <div>
                        <CustomLoader loadingText={loadingText} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
