import "./bulk-settlement.css";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { CustomBreadcrumb } from "../../../shared/components/custom-breadcrumb/custom-breadcrumb";
import { CustomTable } from "../../../shared/components/custom-table/custom-table";
import { SERVICES } from "../../../core/services/services";
import { CustomLoader } from "../../../shared/components/custom-loader/custom-loader";
import { CustomModal } from "../../../shared/components/custom-modal/custom-modal";
import { DetailsBreakDown } from "../../../shared/components/details-break-down/details-break-down";
import { Toast } from "primereact/toast";
import { HELPER } from "../../../shared/helper/helper";
import { AccessDenied } from "../../access-denied/access-denied";
import { BulkSettlementFilter } from "./bulk-settlement-filter";
import { BulkSettlementBreakDown } from "./bulk-settlement-break-down";
import ErrorBoundary from "../../../shared/components/error-boundary/error-boundary";

export function BulkSettlements() {
  const toast = useRef(null);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [error, setError] = useState("");
  const [search, setSearch] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [isMobileTransaction, setIsMobileTransaction] = useState(false);
  const [range] = useState(5);
  const [breakDownTitle] = useState("");
  const [emptyText, setEmptyText] = useState("");
  const [currentModalIndex, setCurrentModalIndex] = useState(0);
  const [bulkSettlementKey, setBulkSettlementKey] = useState("");
  const [merchantDetails, setMerchantDetails] = useState([]);
  const [details, setDetails] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [searchParams, setSearchParams] = useState(null);
  // const [fetchBulkSettlements, setFetchBulkSettlements] = useState(false);

  const tableHeaders = [
    { label: "Super agent", value: "merchantName" },
    { label: "Total Amount", value: "transactionTotalAmount" },
    { label: "Total Transaction Charge", value: "transactionChargeAmount" },
    { label: "Settlement Type", value: "settlementType" },
    { label: "Settlement Status", value: "status", type: "settlementStatus" },
    { label: "Created On", value: "createdAt" },
    { label: "Actions", value: "actions" },
  ];

  const getSettlementDetails = useCallback((e, isMobile) => {
    let arr = [];
    setMerchantDetails([]);
    setDetails([]);
    const dateRange = `${HELPER.FORMAT_DATE(
      e?.transactionTimeBegin
    )} - ${HELPER.FORMAT_DATE(e?.transactionTimeEnd)}`;
    setBulkSettlementKey(e?.bulkSettlementKey);
    arr.push({ label: "Super Agent", value: e?.merchant?.merchantName });
    arr.push({
      label: "Transaction Count",
      value: e?.transactionCount,
    });
    arr.push({
      label: "Total Amount",
      value: e?.transactionTotalAmount,
      itemCase: "transactionAmount",
    });
    arr.push({
      label: "Total Transaction Charge",
      value: e?.transactionChargeAmount,
      itemCase: "transactionAmount",
    });
    arr.push({
      label: "Total VAT Amount",
      value: e?.transactionVatAmount,
      itemCase: "transactionAmount",
    });
    arr.push({
      label: "Super Agent Net Settlement",
      value: e?.transactionMerchantAmount,
      itemCase: "transactionAmount",
    });

    arr.push({ label: "Transaction Date Range", value: dateRange });
    arr.push({
      label: "Settlement Status",
      value: e?.status,
      itemCase: "status",
      type: "settlementStatus",
    });

    arr.push({ label: "Settlement Type", value: e?.settlementType });
    arr.push({
      label: "Report Status",
      value: e?.reportStatus,
      itemCase: "status",
    });
    arr.push({ label: "Bulk Settlement Key", value: e?.bulkSettlementKey });
    arr.push({
      label: "Payment Request Attempt",
      value: e?.paymentRequestAttempt,
    });
    arr.push({
      label: "Report Generation Attempt",
      value: e?.reportGenerationAttempt,
    });
    arr.push({
      label: "Created On",
      value: e?.createdAt,
      itemCase: "transactionTime",
    });
    arr.push({
      label: "Completed On",
      value: e?.completedAt,
      itemCase: "transactionTime",
    });

    setDetails(arr);
    setIsMobileTransaction(isMobile);
    setCurrentIndex(3);
  }, []);

  const openAction = useCallback(
    (e, action, isMobile) => {
      // eslint-disable-next-line default-case
      switch (action) {
        case "VIEW": {
          getSettlementDetails(e, isMobile);
          break;
        }
      }
    },
    [getSettlementDetails]
  );

  function goToPage(pageNo) {
    setPageNo(pageNo);
  }
  // useEffect(() => {
  //   getBulkSettlement();
  // }, [pageNo]);

  const getBulkSettlements = useCallback(
    (queryParams = null, isReload = false) => {
      setCurrentIndex(0);
      setTransactions([]);
      let params = {
        page: pageNo,
        size: 10,
      };
      if (searchParams && !isReload) {
        setVisible(false);
        setSearch(true);
        params = {
          ...params,
          ...searchParams,
          cardAcceptorId: searchParams?.cardAcceptorId?.cardAcceptorId,
          startDate: searchParams?.startDate,
          endDate: searchParams?.endDate,
          bulkSettlementKey: searchParams?.bulkSettlementKey,
          settlementStatus: searchParams?.settlementStatus?.code,
          reportStatus: searchParams?.reportStatus?.code,
        };
        for (const prop in params) {
          if (!params[prop] && params[prop] !== 0) {
            delete params[prop];
          }
        }
      }
      params = HELPER.TO_URL_STRING(params);
      SERVICES.GET_BULK_SETTLEMENT(params)
        .then((data) => {
          const result = data?.result?.content;
          if (!result.length) {
            setEmptyText("No bulk settlement records found ...");
          } else {
            let arr = [];
            setTotalItems(data?.result.totalElements);
            setTotalPages(data?.result.totalPages);
            result.forEach((e) => {
              arr.push({
                ...e,
                merchantName: e.merchant?.merchantName,
                actions: "CR",
                detailsFunction: openAction,
              });
            });
            setTransactions(arr);
          }
          setError(null);
          setCurrentIndex(1);
          setLoading(false);
        })
        .catch((error) => {
          setError(HELPER.PROCESS_ERROR(error));
          setCurrentIndex(1);
          setLoading(false);
        });
    },
    [pageNo, searchParams, openAction]
  );

  useEffect(() => {
    getBulkSettlements();
  }, [getBulkSettlements]);

  function onHide() {}

  function openModal(index, isMobile) {
    setCurrentModalIndex(index);
    if (isMobile) {
      setCurrentIndex(2);
    } else {
      setVisible(true);
    }
  }

  function reload() {
    setPageNo(0);
    setSearchParams(null);
    setLoading(true);
    setSearch(false);
    setCurrentIndex(0);
    getBulkSettlements(null, true);
  }

  function closeModal(isReload) {
    if (visible) {
      setVisible(false);
    }
    if (currentIndex === 2) {
      setCurrentIndex(1);
    }
    if (isReload === true) {
      reload();
    }
  }

  function goBack() {
    setCurrentIndex(1);
  }

  function saveSearchParams(params) {
    setPageNo(0);
    setSearchParams(params);
  }

  const mobileModal = () => {
    // eslint-disable-next-line default-case
    switch (currentModalIndex) {
      case 1:
        return (
          <BulkSettlementFilter
            searchFunction={saveSearchParams}
            closeModal={closeModal}
          />
        );
      case 2:
        return (
          <DetailsBreakDown
            merchantDetails={merchantDetails}
            mobile={true}
            bulkSettlementKey={bulkSettlementKey}
            title={breakDownTitle}
            breakDown={details}
            closeModal={closeModal}
          />
        );
    }
  };

  const modalContent = () => {
    // eslint-disable-next-line default-case
    switch (currentModalIndex) {
      case 1:
        return (
          <BulkSettlementFilter
            searchFunction={saveSearchParams}
            closeModal={closeModal}
          />
        );
      case 2:
        return (
          <DetailsBreakDown
            transactionSearchKey={bulkSettlementKey}
            title={breakDownTitle}
            breakDown={details}
            closeModal={closeModal}
          />
        );
    }
  };

  const bulkSettlementView = () => {
    // eslint-disable-next-line default-case
    switch (currentIndex) {
      case 0:
        return (
          <div className="loading-container">
            <CustomLoader loadingText="Loading bulk settlements..." />
          </div>
        );
      case 1:
        return (
          <CustomTable
            isReload={true}
            totalPages={totalPages}
            totalItems={totalItems}
            currentPage={pageNo + 1}
            range={range}
            emptyText={emptyText}
            search={search}
            reload={reload}
            error={error}
            items={transactions}
            headers={tableHeaders}
            nextPage={() => setPageNo(pageNo + 1)}
            prevPage={() => setPageNo(pageNo - 1)}
            goToFirstPage={() => setPageNo(0)}
            goToLastPage={() => setPageNo(totalPages - 1)}
            goToPage={goToPage}
          />
        );
      case 2:
        return (
          <div className="mobile-modal-container">
            <div className="custom-card">
              <div className="mobile-card-position">{mobileModal()}</div>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="p-mt-2">
            <BulkSettlementBreakDown
              bulkSettlementKey={bulkSettlementKey}
              goBack={goBack}
              details={details}
              mobile={isMobileTransaction}
            />
          </div>
        );
    }
  };

  return (
    <ErrorBoundary>
      <div>
        <Toast ref={toast} />
        <div>
          <CustomModal
            closeModal={closeModal}
            onHide={onHide}
            visible={visible}
            modalContent={modalContent}
          />
        </div>
        <div className="page-title p-text-left">Bulk Settlements</div>
        <div className="p-mt-2">
          <CustomBreadcrumb page="Manage Bulk Settlement" />
        </div>
        <div className="floating-buttons desktop-scree">
          <div className="p-grid">
            <div className="p-col-7"></div>
            <div className="p-col-5">
              <div className="p-grid">
                <div className="p-col-6"></div>
                <div className="p-col-6">
                  <div
                    className={
                      HELPER.HAS_AUTHORITY("bo_dcir_get_all_bulk_settlement") &&
                      currentIndex === 1
                        ? "dcir-show"
                        : "dcir-hide"
                    }
                  >
                    <button
                      disabled={loading}
                      onClick={() => openModal(1, false)}
                      className="primary-button"
                    >
                      <i className="pi pi-filter" />
                      <span className="hide-btn-text"> Filter</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            HELPER.HAS_AUTHORITY("bo_dcir_get_all_bulk_settlement")
              ? "dcir-show"
              : "dcir-hide"
          }
        >
          {bulkSettlementView()}
        </div>
        <div
          className={
            HELPER.HAS_AUTHORITY("bo_dcir_get_all_bulk_settlement")
              ? "dcir-hide"
              : "dcir-show"
          }
        >
          <AccessDenied />
        </div>
      </div>
    </ErrorBoundary>
  );
}
