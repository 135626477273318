import React, { useCallback, useEffect, useRef, useState } from "react";
import { CustomTable } from "../../../shared/components/custom-table/custom-table";
import { SERVICES } from "../../../core/services/services";
import { CustomLoader } from "../../../shared/components/custom-loader/custom-loader";
import { CustomModal } from "../../../shared/components/custom-modal/custom-modal";
import { DetailsBreakDown } from "../../../shared/components/details-break-down/details-break-down";
import { Toast } from "primereact/toast";
import { HELPER } from "../../../shared/helper/helper";
import { AccessDenied } from "../../access-denied/access-denied";
import { PaymentRequestBreakdown } from "./payment-request-break-down";
import { Optional } from "../../../shared/components/optional/optional";
import { CustomConfirmationDialog } from "../../../shared/components/confirmation-dialog/confirmation-dialog";
import { BACKOFFICE_SERVICE } from "../../../core/services/backoffice-service";

export function SettlementPaymentAttempts(props) {
  const toast = useRef(null);
  const [visible, setVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [error, setError] = useState("");
  const [search, setSearch] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [range] = useState(5);
  const [breakDownTitle] = useState("");
  const [emptyText, setEmptyText] = useState("");
  const [currentModalIndex, setCurrentModalIndex] = useState(0);
  const [transactionSearchKey] = useState("");
  const tableHeaders = [
    { label: "Account Name", value: "accountName" },
    { label: "Transaction Ref", value: "transactionRef" },
    { label: "Narration", value: "narration" },
    { label: "Transaction Status", value: "status" },
    { label: "Created On", value: "createdTime" },
    { label: "Actions", value: "actions" },
  ];
  const [details] = useState([]);
  const [participantDetails] = useState([]);
  const [chargeTypeDetails] = useState([]);
  const [settlementAttempts, setSettlementAttempts] = useState([]);
  const [transactionDetails, setTransactionDetails] = useState([]);
  const [settlementStatus, setSettlementStatus] = useState("");
  const [paymentAttemptStatus, setPaymentAttemptStatus] = useState("");
  const [pageNo, setPageNo] = useState(0);
  const [updateSettlementStatusError, setUpdateSettlementStatusError] =
    useState(null);
  const [
    updateSettlementStatusResponseMessage,
    setUpdateSettlementStatusResponseMessage,
  ] = useState(null);

  const showTransactionDetails = useCallback((e, isMobile) => {
    let arr = [];
    arr.push({
      label: "Participant",
      value: e?.paymentRequest?.participant?.name,
    });
    arr.push({
      label: "Account name",
      value: e?.paymentRequest?.participant?.accountName,
    });
    arr.push({
      label: "Account number",
      value: e?.paymentRequest?.participant?.accountNumber,
    });
    arr.push({
      label: "Credit account",
      value: e?.paymentRequest?.creditAccount,
    });
    arr.push({
      label: "Debit account",
      value: e?.paymentRequest?.debitAccount,
    });
    arr.push({
      label: "Status",
      value: e?.status,
      itemCase: "status",
    });
    arr.push({ label: "Transaction Ref", value: e?.transactionRef });
    arr.push({ label: "Comment", value: e?.comment });
    arr.push({ label: "Narration", value: e?.narration });
    arr.push({
      label: "Initiated At",
      value: e?.createdTime,
      itemCase: "createdTime",
    });
    arr.push({
      label: "Completed At",
      value: e?.completedTime,
      itemCase: "completedTime",
    });
    setPaymentAttemptStatus(e?.status);
    setTransactionDetails(arr);
    setCurrentModalIndex(0);
    setVisible(true);
  }, []);

  const openAction = useCallback(
    (e, action, isMobile) => {
      // eslint-disable-next-line default-case
      switch (action) {
        case "VIEW": {
          showTransactionDetails(e, isMobile);
          break;
        }
      }
    },
    [showTransactionDetails]
  );

  const getSettlementAttempts = useCallback(() => {
    setCurrentIndex(0);
    setSettlementAttempts([]);
    let params = {
      page: pageNo,
      size: 10,
    };
    params = HELPER.TO_URL_STRING(params);
    SERVICES.GET_SETTLEMENT_PAYMENT_ATTEMPTS(params, props.requestId)
      .then((data) => {
        const result = data?.result?.content;
        if (!result.length) {
          setEmptyText("No settlement attempts for this transaction...");
        } else {
          let arr = [];
          setTotalItems(data?.result.totalItems); //need adjustment
          setTotalPages(data?.result.totalPages); //need adjustment
          result.forEach((e) => {
            arr.push({
              ...e,
              accountName: e?.paymentRequest.participant?.accountName,
              actions: "VIEW",
              detailsFunction: openAction,
            });
          });
          setSettlementAttempts(arr);
        }
        setError(null);
        setCurrentIndex(1);
        // setLoading(false);
      })
      .catch((error) => {
        setError(HELPER.PROCESS_ERROR(error));
        setCurrentIndex(1);
        // setLoading(false);
      });
  }, [pageNo, props.requestId, openAction]);

  //   function openModal(index, isMobile) {
  //     setCurrentModalIndex(index);
  //     if (isMobile) {
  //       setCurrentIndex(2);
  //     } else {
  //       setVisible(true);
  //     }
  //   }

  useEffect(() => {
    getSettlementAttempts();
  }, [pageNo, getSettlementAttempts]);

  function updateSettlementStatus() {
    // setLoading(true);
    let payload = HELPER.TO_URL_STRING({
      settlementStatus: settlementStatus,
    });
    BACKOFFICE_SERVICE.UPDATE_TRANSACTION_ON_REQUERY(
      props.requestId,
      payload,
      "settlements"
    )
      .then((data) => {
        setUpdateSettlementStatusResponseMessage(data?.result?.message);
        // setLoading(false);
        // setCurrentModalIndex(2);
      })
      .catch((error) => {
        setUpdateSettlementStatusError(HELPER.PROCESS_ERROR(error));
        // setLoading(false);
      });
  }

  function reload() {
    // setLoading(true);
    setSearch(false);
    setCurrentIndex(0);
    getSettlementAttempts();
  }

  function closeModal(isReload) {
    if (visible) {
      setVisible(false);
    }
    if (currentIndex === 2) {
      setCurrentIndex(1);
    }

    // if (isReload) {
    //   reload();
    // }
  }

  function markSettlementStatus(status) {
    setSettlementStatus(status);
    setCurrentModalIndex(1);
  }

  function goBack() {
    setCurrentIndex(1);
  }

  function goToPage(pageNo) {
    setPageNo(pageNo);
  }

  const mobileModal = () => {
    // eslint-disable-next-line default-case
    switch (currentModalIndex) {
      case 0:
        return (
          <DetailsBreakDown
            mobile={true}
            transactionSearchKey={transactionSearchKey}
            title={breakDownTitle}
            breakDown={details}
            closeModal={closeModal}
          />
        );
    }
  };

  const modalContent = () => {
    // eslint-disable-next-line default-case
    switch (currentModalIndex) {
      case 0:
        return (
          <div>
            <DetailsBreakDown
              error={false}
              loading={false}
              title="Transaction"
              breakDown={transactionDetails}
              closeModal={closeModal}
              hideCloseButton={
                paymentAttemptStatus === "AWAITING_RE_QUERY" &&
                HELPER.HAS_AUTHORITY(
                  "bo_update_settlements_on_awaiting_requery"
                )
              }
            />
            <Optional
              show={
                paymentAttemptStatus === "AWAITING_RE_QUERY" &&
                HELPER.HAS_AUTHORITY(
                  "bo_update_settlements_on_awaiting_requery"
                )
              }
            >
              <div className="p-grid">
                <div className="p-col-6 p-pl-0">
                  {/* {cancelButton()} */}
                  <button
                    className="secondary-button"
                    onClick={() => markSettlementStatus("FAILED")}
                  >
                    Mark as failed
                  </button>
                </div>
                <div className="p-col-6 p-pr-0">
                  {/* {submitButton()} */}
                  <button
                    className="primary-button"
                    onClick={() => markSettlementStatus("SUCCESSFUL")}
                  >
                    Mark as successful
                  </button>
                </div>
              </div>
            </Optional>
          </div>
        );
      case 1:
        return (
          <CustomConfirmationDialog
            text={`Confirm that you want to mark this transaction as ${settlementStatus.toLowerCase()}!`}
            action={updateSettlementStatus}
            errorMessage={updateSettlementStatusError}
            responseMessage={updateSettlementStatusResponseMessage}
            closeModal={closeModal}
          />
        );
    }
  };

  const settlementAttemptsView = () => {
    // eslint-disable-next-line default-case
    switch (currentIndex) {
      case 0:
        return (
          <div className="loading-container tab">
            <CustomLoader loadingText="loading settlement attempts..." />
          </div>
        );
      case 1:
        return (
          <div className="p-ml-3">
            <CustomTable
              isReload={true}
              totalPages={totalPages}
              totalItems={totalItems}
              currentPage={pageNo + 1}
              range={range}
              emptyText={emptyText}
              search={search}
              reload={reload}
              error={error}
              items={settlementAttempts}
              headers={tableHeaders}
              nextPage={() => setPageNo(pageNo + 1)}
              prevPage={() => setPageNo(pageNo - 1)}
              goToFirstPage={() => setPageNo(0)}
              goToLastPage={() => setPageNo(totalPages - 1)}
              goToPage={goToPage}
            />
          </div>
        );
      case 2:
        return (
          <div className="mobile-modal-container">
            <div className="custom-card">
              <div className="mobile-card-position">{mobileModal()}</div>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="p-mt-2">
            <PaymentRequestBreakdown
              goBack={goBack}
              isMobile={props.isMobile}
              chargeTypeDetails={chargeTypeDetails}
              participantDetails={participantDetails}
              details={details}
            />
          </div>
        );
    }
  };

  function onHide() {}

  return (
    <div>
      <Toast ref={toast} />
      <div>
        <CustomModal
          onHide={onHide}
          closeModal={closeModal}
          visible={visible}
          modalContent={modalContent}
        />
      </div>
      <div className="p-mt-6"></div>
      <div
        className={
          HELPER.HAS_AUTHORITY("bo_dcir_fetch_payment_attempts")
            ? "dcir-show"
            : "dcir-hide"
        }
      >
        {settlementAttemptsView()}
      </div>
      <div
        className={
          HELPER.HAS_AUTHORITY("bo_dcir_fetch_payment_attempts")
            ? "dcir-hide"
            : "dcir-show"
        }
      >
        <AccessDenied />
      </div>
    </div>
  );
}
