import React, { useState } from "react";
import { SERVICES } from "../../../core/services/services";
import { FormInput } from "../../../shared/components/form-component/form-input";
import { CustomMessage } from "../../../shared/components/alert/custom-message";
import { CustomLoader } from "../../../shared/components/custom-loader/custom-loader";
import { CustomToast } from "../../../shared/components/alert/custom-toast";
import { HELPER } from "../../../shared/helper/helper";
import { SYSTEM_ACCEPTED } from "../../../shared/constants";

export function ReopenDispute(props) {
  const [logCode, setLogCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [message, setMessage] = useState(null);
  const [messageTitle, setMessageTitle] = useState(null);

  function reopenDispute() {
    setLoading(true);
    const payload = {
      disputeLogCode: props.logCode,
      transactionSearchKey: props.transactionKey,
    };
    const userType = props.userType === "front-office" ? "fo" : null;
    const REOPEN_DISPUTE_SERVICE =
      props.disputeStatus === SYSTEM_ACCEPTED
        ? SERVICES.REOPEN_SYSTEM_ACCEPTED_DISPUTE
        : SERVICES.REOPEN_DISPUTE;
    REOPEN_DISPUTE_SERVICE(payload, userType)
      .then((data) => {
        props.getDisputeDetails();
        setSuccessMessage(`Dispute has been reopened!`);
        setShowSuccess(true);
        setLoading(false);
      })
      .catch((error) => {
        setMessageTitle("Error");
        setMessage(HELPER.PROCESS_ERROR(error));
        setLoading(false);
      });
  }

  const actions = () => {
    if (loading) {
      return (
        <div>
          <CustomLoader loadingText="Reopening..." />
        </div>
      );
    } else if (!loading && !showSuccess) {
      return (
        <div className="p-mt-5 p-pb-2">
          <div className="p-grid">
            <div className="p-col-6">
              <button onClick={props.closeModal} className="secondary-button">
                Cancel
              </button>
            </div>
            <div className="p-col-6">
              <button
                disabled={logCode !== props?.logCode}
                onClick={reopenDispute}
                className="primary-button"
              >
                Reopen
              </button>
            </div>
          </div>
        </div>
      );
    }
  };

  const alertView = () => {
    if (messageTitle) {
      return (
        <div>
          <CustomToast
            title={messageTitle}
            description={message}
            type="error"
          />
        </div>
      );
    } else {
      return <div />;
    }
  };

  const resetView = () => {
    if (showSuccess) {
      return (
        <div>
          <CustomMessage
            closeModal={props.closeModal}
            close={true}
            message={successMessage}
            messageType="success"
          />
        </div>
      );
    } else {
      return (
        <div>
          <p className="p-mb-5 p-mt-0 confirm-text">
            Confirm that you want to reopen this dispute!
          </p>
          <div>
            <p className="p-text-left">
              To confirm, enter <b>{props?.logCode}</b> in the text input field
            </p>
            <FormInput
              value={logCode}
              required={true}
              field="reset"
              type="INPUT"
              fn={(e) => setLogCode(e?.target?.value)}
              loading={loading}
              placeholder="Enter log code"
            />
          </div>
        </div>
      );
    }
  };

  return (
    <div className="p-mb-3">
      <div>{resetView()}</div>
      <div className="p-pb-1">{alertView()}</div>
      <div className="p-mt-3">{actions()}</div>
    </div>
  );
}
