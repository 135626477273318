import "./App.css";
import PrimeReact from "primereact/api";
import React, { useReducer, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Login } from "./app/components/login/login";
import { ResetPassword } from "./app/components/reset-password/reset-password";
import { ChangePassword } from "./app/components/change-password/change-password";
import { CustomToast } from "./app/shared/components/alert/custom-toast";
import { MainApp } from "./app/main-app/main-app";
import { reducer } from "./app/core/redux/reducer/reducer";
import { initialState } from "./app/core/redux/state/state";
import { LOCAL_STORAGE_SERVICE } from "./app/core/services/storage-service";
import CacheBuster from "react-cache-buster";
import { version } from "../package.json";
import axios from "axios";
import { TwoFactor } from "./app/components/login/two-factor/two-factor";
// import { AppAuth } from "./app/shared/components/auth/auth";

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      if (window.location.pathname !== "/login") {
        localStorage.clear();
        window.location.href = "/login?session_expired=true";
      }
    }
    return Promise.reject(error);
  }
);

export const MainContext = React.createContext();
PrimeReact.ripple = true;
PrimeReact.zIndex = {
  modal: 1100, // dialog, sidebar
  overlay: 1000, // dropdown, overlaypanel
  menu: 1000, // overlay menus
  tooltip: 1100, // tooltip
  toast: 1200, // toast
};
PrimeReact.autoZIndex = true;
PrimeReact.appendTo = "self"; // Default value is null(document.body).

function App() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(true);
  const isProduction = process.env.NODE_ENV === "production";
  // const [loading, setLoading] = useState(false);

  function checkIfAuthenticated() {
    let isAuthenticated = state.isAuthenticated;
    if (!isAuthenticated) {
      isAuthenticated = LOCAL_STORAGE_SERVICE.IS_LOGIN();
      // if (isAuthenticated) {
      // setUserData();
      setIsUserAuthenticated(isAuthenticated);
      // }
    }
    return isAuthenticated;
  }

  useEffect(() => {
    function setUserData() {
      const credentials = LOCAL_STORAGE_SERVICE.GET_USER_CREDENTIALS();
      dispatch({ type: "PERSIST_LOGIN_DATA", loginData: credentials });
    }
    if (isUserAuthenticated) {
      setUserData();
    }
  }, [isUserAuthenticated]);

  return (
    <CacheBuster
      currentVersion={version}
      isEnabled={isProduction}
      isVerboseMode={false}
    >
      <div className="App">
        {state.alert.showAlert && (
          <CustomToast
            title={state.alert.title}
            description={state.alert.description}
            type={state.alert.type}
          />
        )}
        <Router>
          <div>
            <MainContext.Provider
              value={{ mainState: state, mainDispatch: dispatch }}
            >
              <Switch>
                <Route
                  exact
                  path="/change-password"
                  component={ChangePassword}
                />

                <Route exact path="/login" component={Login} />
                <Route
                  exact
                  path="/login/second-factor"
                  component={TwoFactor}
                />
                <Route exact path="/reset-password" component={ResetPassword} />
                {/* <Route exact path="/" component={Login} /> */}
                <Route
                  path="/"
                  render={() =>
                    checkIfAuthenticated() ? <MainApp /> : <Login />
                  }
                />
              </Switch>
            </MainContext.Provider>
          </div>
        </Router>
      </div>
    </CacheBuster>
  );
}

export default App;
