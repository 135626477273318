import { useEffect, useRef, useState } from "react";
import { CustomLoader } from "../../../shared/components/custom-loader/custom-loader";
import { FormInput } from "../../../shared/components/form-component/form-input";
import { CUSTOM_VALIDATION } from "../../../shared/validation/validation";
import moment from "moment";
import { Toast } from "primereact/toast";
import { BACKOFFICE_SERVICE } from "../../../core/services/backoffice-service";
import { saveAs } from "file-saver";
import { CustomToast } from "../../../shared/components/alert/custom-toast";
import { HELPER } from "../../../shared/helper/helper";
import { Optional } from "../../../shared/components/optional/optional";

export function RequeryTransactionsFilter(props) {
  const toast = useRef(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [currentIndex] = useState(0);
  const [filters, setFilters] = useState({
    creditAccount: null,
    endDate: moment().format("YYYY-MM-DD"),
    startDate: moment().format("YYYY-MM-DD"),
  });
  const [filtersError, setFiltersError] = useState({
    creditAccount: null,
    endDate: null,
    startDate: null,
  });
  const [startDateIsAfter, setStartDateIsAfter] = useState(
    moment(filters["startDate"]).isAfter(moment(filters["endDate"]))
  );

  useEffect(() => {
    const startDateIsAfter = moment(filters["startDate"]).isAfter(
      moment(filters["endDate"])
    );
    setStartDateIsAfter(startDateIsAfter);
    if (startDateIsAfter) {
      setFiltersError({
        ...filtersError,
        endDate: "End date is less than the start date!",
        startDate: true,
      });
    } else {
      setFiltersError({ ...filtersError, endDate: null, startDate: null });
    }
  }, [filters, filtersError]);

  function downloadTransactions() {
    setErrorMessage(null);
    setLoading(true);
    BACKOFFICE_SERVICE.DOWNLOAD_TRANSACTIONS_ON_REQUERY(
      filters,
      props.transactionType
    )
      .then((data) => {
        saveAs(
          data,
          `requery-${
            props.transactionType
          }-${`${filters["startDate"]}-${filters["endDate"]}`}.xlsx`
        );
        props.closeModal();
      })
      .catch((error) => {
        handleError(error);
      });
  }

  async function handleError(error) {
    const errMessage = await HELPER.PARSE_BLOB_ERROR(error);
    setErrorMessage(errMessage);
    setLoading(false);
  }

  function validateForm(e, name, type, refineName, required) {
    let value = e?.target?.value;
    const isEmpty = CUSTOM_VALIDATION.IS_EMPTY(value);
    const isValidInput = !isEmpty
      ? CUSTOM_VALIDATION.BASIC_VALIDATION(value, type)
      : false;
    if (isValidInput) {
      setFilters({ ...filters, [name]: value });
      setFiltersError({ ...filtersError, [name]: null });
    } else {
      let errorMessage =
        required && isEmpty ? `${refineName} is required` : null;
      if (!isValidInput) {
        errorMessage = `${refineName} is invalid`;
      }
      setFilters({ ...filters, [name]: null });
      setFiltersError({ ...filtersError, [name]: errorMessage });
    }
  }

  function getFilters() {
    let payload = {};

    for (const property in filters) {
      if (filters[property]) {
        payload[property] = filters[property];
      }
    }
    return payload;
  }

  function cancelModal() {
    props.closeModal();
  }

  const cancelButton = () => {
    if (!loading) {
      return (
        <button onClick={cancelModal} className="secondary-button">
          Cancel
        </button>
      );
    } else {
      return <div />;
    }
  };

  const downloadButton = () => {
    if (!loading) {
      return (
        <button
          onClick={downloadTransactions}
          className="primary-button download-button"
          disabled={startDateIsAfter}
        >
          Download
        </button>
      );
    }
  };

  const submitButton = () => {
    if (!loading) {
      return (
        <button
          onClick={() => props?.searchFunction(getFilters())}
          className="primary-button"
          disabled={startDateIsAfter}
        >
          Filter
        </button>
      );
    } else {
      return (
        <div className="pull-up-element-2">
          <CustomLoader loadingText="Submitting..." />
        </div>
      );
    }
  };

  const requeryTransactionsFilterView = () => {
    if (currentIndex) {
      return (
        <div>
          <div className="success-message-btn-container">
            <button
              onClick={() => {
                props.closeModal(true);
              }}
              className="primary-button success-message-btn"
            >
              Close
            </button>
          </div>
        </div>
      );
    } else {
      return <div>{requeryTransactionsFilterForm()}</div>;
    }
  };

  const requeryTransactionsFilterForm = () => {
    return (
      <div>
        <div className="custom-modal-title p-text-left">Filter</div>
        <div className="custom-dialog-subtitle-container p-mb-5 p-text-left">
          <small>Select date range below.</small>
        </div>
        <div className="p-grid p-mt-5">
          <div className="p-col-12">
            <FormInput
              value={filters["creditAccount"]}
              required={false}
              field="creditAccount"
              type="NAME"
              error={filtersError["creditAccount"]}
              fn={validateForm}
              placeholder="Credit Account"
            />
          </div>
          <div className="p-col-6 p-mt-3">
            {/* <label>From:</label> */}
            <FormInput
              inputType="date"
              value={filters["startDate"]}
              required={false}
              field="startDate"
              type="INPUT"
              error={filtersError["startDate"]}
              fn={validateForm}
              loading={loading}
              placeholder="Start date"
            />
          </div>
          <div className="p-col-6 p-mt-3">
            {/* <label>To:</label> */}
            <FormInput
              inputType="date"
              value={filters["endDate"]}
              required={false}
              field="endDate"
              type="INPUT"
              error={filtersError["endDate"]}
              fn={validateForm}
              loading={loading}
              placeholder="End date"
            />
          </div>
          <Optional show={errorMessage}>
            <div className="p-col-12">
              <CustomToast
                title="Error"
                description={errorMessage}
                type="error"
              />
            </div>
          </Optional>
          <div className="p-col-12">
            <div className="p-mt-5">
              <div className="p-grid">
                <div className={loading ? "p-col-12" : "p-col-4"}>
                  {cancelButton()}
                </div>
                <div className={loading ? "p-col-12" : "p-col-4"}>
                  {downloadButton()}
                </div>
                <div className={loading ? "p-col-12" : "p-col-4"}>
                  {submitButton()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div>
        <Toast ref={toast} />
      </div>
      <div className="p-pb-2">{requeryTransactionsFilterView()}</div>
    </div>
  );
}
