import { useState } from "react";
import { BACKOFFICE_API } from "../../../../core/apis/backoffice-apis";
import { BACKOFFICE_SERVICE } from "../../../../core/services/backoffice-service";
import { CustomConfirmationDialog } from "../../../../shared/components/confirmation-dialog/confirmation-dialog";
import { HELPER } from "../../../../shared/helper/helper";

export function DisputeInitiationConfirmation(props) {
  const [error, setError] = useState(null);
  const [responseMessage, setResponseMessage] = useState(null);
  function initiateDisputeCreation() {
    let params = {
      disputeDate: props.transactionDate,
    };
    BACKOFFICE_SERVICE.MAKE_PUT_REQUEST(
      BACKOFFICE_API.INITIATE_DISPUTE_ON_FAILED_TRANSACTIONS,
      null,
      HELPER.TO_URL_STRING(params)
    )
      .then((data) => {
        setResponseMessage(data?.result?.message);
      })
      .catch((error) => {
        setError(HELPER.PROCESS_ERROR(error));
      });
  }

  function reinitiateDisputeCreation() {
    let params = {
      date: props.transactionDate,
    };
    BACKOFFICE_SERVICE.MAKE_PUT_REQUEST(
      BACKOFFICE_API.REINITIATE_DISPUTE_ON_FAILED_TRANSACTIONS,
      null,
      HELPER.TO_URL_STRING(params)
    )
      .then((data) => {
        setResponseMessage(data?.result?.message);
      })
      .catch((error) => {
        setError(HELPER.PROCESS_ERROR(error));
      });
  }

  function closeModal(reload) {
    props.closeModal(reload);
  }

  return (
    <CustomConfirmationDialog
      text={`Confirm that you want to ${
        props.isRefresh
          ? "..."
          : "initiate a dispute on all failed transactions?"
      }`}
      action={
        props.isRefresh ? reinitiateDisputeCreation : initiateDisputeCreation
      }
      errorMessage={error}
      responseMessage={responseMessage}
      closeModal={closeModal}
    />
  );
}
