import axios from "axios";
// import qs from "qs";
import { API } from "../apis/apis";
import { CONFIG } from "../../shared/config/config";
import { LOCAL_STORAGE_SERVICE } from "./storage-service";
import { BACKOFFICE_API } from "../apis/backoffice-apis";

function handleFileDownloadProgress(progressEvent, mainContext) {
  var percentDownloaded = Math.round(
    (progressEvent.loaded * 100) / progressEvent.total
  );
  if (progressEvent.total === 0) {
    percentDownloaded = 0;
  }
  mainContext?.mainDispatch({
    type: "SAVE_PERCENT_DOWNLOADED",
    payload: percentDownloaded,
  });
}

function login(payload) {
  const url = `${CONFIG.DCIR_HOST}${API.LOGIN_API}`;
  // eslint-disable-next-line no-useless-concat
  const basicAuth =
    "Basic " + btoa(`${CONFIG.AUTH_USER_NAME}:${CONFIG.AUTH_PASSWORD}`);
  const headers = {
    "Content-Type": "application/json",
    Authorization: basicAuth,
  };
  return new Promise((resolve, reject) => {
    axios
      .post(url, payload, { headers: headers })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function logout() {
  const url = `${CONFIG.DCIR_HOST}${API.LOGOUT}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .put(url, null, { headers: header }) //change to put
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function authenticate2faCode(payload) {
  const url = `${CONFIG.DCIR_HOST}${API.AUTHENTICATE_2FA_CODE}`;
  const headers = {
    "Content-Type": "application/json",
    // Authorization: basicAuth,
  };
  return new Promise((resolve, reject) => {
    axios
      .post(url, payload, { headers: headers })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getUserDetails(token) {
  const url = `${CONFIG.DCIR_HOST}${API.GET_USER_DETAILS}`;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: headers })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function changePassword(token, payload) {
  const url = `${CONFIG.DCIR_HOST}${API.CHANGE_PASSWORD}`;
  const headers = token
    ? {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    : getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .put(url, payload, { headers: headers })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function resetSuperAgentUserPassword(payload, type = null) {
  const url = `${CONFIG.DCIR_HOST}${
    type === "fo"
      ? API.RESET_SUPER_AGENT_USER_PASSWORD_FO
      : API.RESET_SUPER_AGENT_USER_PASSWORD
  }`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .put(url, payload, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getChargeTypes() {
  const url = `${CONFIG.DCIR_HOST}${API.GET_CHARGE_TYPES}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function searchChargeType(params) {
  const url = `${CONFIG.DCIR_HOST}${API.GET_CHARGE_MODEL}?${params}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function searchParticipant(params) {
  const url = `${CONFIG.DCIR_HOST}${API.CREATE_PARTICIPANT}?${params}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function searchTransactions(params, pageParam) {
  const url = `${CONFIG.DCIR_HOST}${API.SEARCH_TRANSACTION}?${pageParam}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .put(url, params, { headers: header }) //change to put
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function deleteChargeModel(e) {
  const url = `${CONFIG.DCIR_HOST}${API.DELETE_CHARGE_MODEL}/${e}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .put(url, {}, { headers: header }) //remember to change to put
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function verifyAccountNumber(params) {
  const url = `${CONFIG.DCIR_HOST}${API.VERIFY_ACCOUNT_NUMBER}${params}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getDisputeByTransactionKey(params, type = null) {
  const url = `${CONFIG.DCIR_HOST}${
    type === "fo"
      ? API.GET_DISPUTE_BY_TRANSACTION_KEY_FO
      : API.GET_DISPUTE_BY_TRANSACTION_KEY
  }${params}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getDisputeByLogCode(logCode, type = null) {
  const url = `${CONFIG.DCIR_HOST}${API.CREATE_DISPUTE}/${logCode}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getFilteredDisputes(payload, params) {
  const url = `${CONFIG.DCIR_HOST}${API.GET_FILTERED_DISPUTES}?${params}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .put(url, payload, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function deleteParticipant(e) {
  const url = `${CONFIG.DCIR_HOST}${API.DELETE_PARTICIPANT}/${e}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .put(url, {}, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function deleteMerchant(e) {
  const url = `${CONFIG.DCIR_HOST}${API.CREATE_MERCHANT}/${e}`; //change to delete api
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .delete(url, { headers: header }) //remember to change to put
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function deactivateMerchant(params) {
  const url = `${CONFIG.DCIR_HOST}${API.DEACTIVATE_MERCHANT}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .put(url, params, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getSettlementType() {
  const url = `${CONFIG.DCIR_HOST}${API.GET_SETTLEMENT_TYPE}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getSettlementParticipants(params) {
  const url = `${CONFIG.DCIR_HOST}${API.GET_SETTLEMENT_PARTICIPANTS}?${params}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getSettlementParticipantNonGlobal() {
  const url = `${CONFIG.DCIR_HOST}${API.GET_SETTLEMENT_PARTICIPANT_NON_GLOBAL}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function downloadBulkSettlement(settlementKey, type = null, mainContext) {
  const url = `${CONFIG.DCIR_HOST}${
    type === "fo"
      ? API.DOWNLOAD_BULK_SETTLEMENT_FO
      : API.DOWNLOAD_BULK_SETTLEMENT
  }${settlementKey}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: header,
        responseType: "blob",
        onDownloadProgress: function (progressEvent) {
          handleFileDownloadProgress(progressEvent, mainContext);
        },
      })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getAllChargeModels() {
  const url = `${CONFIG.DCIR_HOST}${API.GET_CHARGE_MODEL}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getChargeModels(params) {
  const url = `${CONFIG.DCIR_HOST}${BACKOFFICE_API.GET_CHARGE_MODELS}?${params}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getChargeModelByCode(code) {
  const url = `${CONFIG.DCIR_HOST}${BACKOFFICE_API.GET_CHARGE_MODEL_BY_CODE}/${code}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function viewChargeModel(params) {
  const url = `${CONFIG.DCIR_HOST}${API.GET_CHARGE_MODEL}/${params}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function viewParticipant(params) {
  const url = `${CONFIG.DCIR_HOST}${API.CREATE_PARTICIPANT}/${params}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getParticipants(params) {
  const url = `${CONFIG.DCIR_HOST}${API.CREATE_PARTICIPANT}?${params}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getTransactions(params) {
  const url = `${CONFIG.DCIR_HOST}${API.GET_TRANSACTIONS}?${params}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getFrontOfficeTransactions(params) {
  const url = `${CONFIG.DCIR_HOST}${API.GET_FRONT_OFFICE_TRANSACTIONS}?${params}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getBulkSettlement(params) {
  const url = `${CONFIG.DCIR_HOST}${API.GET_BULK_SETTLEMENT}?${params}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getFrontOfficeBulkSettlement(params) {
  const url = `${CONFIG.DCIR_HOST}${API.GET_FRONT_OFFICE_BULK_SETTLEMENT}?${params}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getBulkSettlementTransactions(params, bulkSettlementKey) {
  const url = `${CONFIG.DCIR_HOST}${API.GET_BULK_SETTLEMENT_TRANSACTION}${bulkSettlementKey}?${params}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getFrontOfficeBulkSettlementTransactions(params, bulkSettlementKey) {
  const url = `${CONFIG.DCIR_HOST}${API.GET_FRONT_OFFICE_BULK_SETTLEMENT_TRANSACTION}${bulkSettlementKey}?${params}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getPaymentRequest(params, bulkSettlementKey) {
  const url = `${CONFIG.DCIR_HOST}${API.GET_PAYMENT_REQUEST}${bulkSettlementKey}?${params}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getSettlementPaymentAttempts(params, requestId) {
  const url = `${CONFIG.DCIR_HOST}${API.GET_SETTLEMENT_PAYMENT_ATTEMPTS}${requestId}?${params}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getFrontOfficePaymentRequest(params, bulkSettlementKey) {
  const url = `${CONFIG.DCIR_HOST}${API.GET_PAYMENT_REQUEST}${bulkSettlementKey}?${params}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getDisputes(params) {
  const url = `${CONFIG.DCIR_HOST}${API.CREATE_DISPUTE}?${params}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getSuperAgentDisputes(params) {
  const url = `${CONFIG.DCIR_HOST}${API.GET_SUPER_AGENT_DISPUTES}?${params}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getSuperAgentBankDisputes(params) {
  const url = `${CONFIG.DCIR_HOST}${API.GET_SUPER_AGENT_BANK_DISPUTES}?${params}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getFilteredBankDisputes(payload, params) {
  const url = `${CONFIG.DCIR_HOST}${API.GET_FILTERED_BANK_DISPUTES}?${params}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .put(url, payload, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}
function getFilteredAgentDisputes(payload, params) {
  const url = `${CONFIG.DCIR_HOST}${API.GET_FILTERED_AGENT_DISPUTES}??${params}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .put(url, payload, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getDisputeCodes(type = null) {
  const url = `${CONFIG.DCIR_HOST}${
    type === "fo" ? API.GET_DISPUTE_CODES_FO : API.GET_DISPUTE_CODES
  }`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getBackofficeDisputes(params) {
  const url = `${CONFIG.DCIR_HOST}${BACKOFFICE_API.GET_BACKOFFICE_DISPUTES}?${params}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getBackofficeSuperagentDisputes(params) {
  const url = `${CONFIG.DCIR_HOST}${BACKOFFICE_API.GET_SUPERAGENT_DISPUTES}?${params}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getFilteredBackofficeDisputes(payload) {
  const url = `${CONFIG.DCIR_HOST}${BACKOFFICE_API.GET_FILTERED_BACKOFFICE_DISPUTES}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .put(url, payload, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getFilteredSuperagentDisputes(payload) {
  const url = `${CONFIG.DCIR_HOST}${BACKOFFICE_API.GET_FILTERED_SUPERAGENT_DISPUTES}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .put(url, payload, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function downloadBackofficeSuperagentDisputes(payload, mainContext) {
  const url = `${CONFIG.DCIR_HOST}${BACKOFFICE_API.DOWNLOAD_SUPERAGENT_DISPUTES}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .put(url, payload, {
        headers: header,
        responseType: "blob",
        onUploadProgress: function (progressEvent) {
          handleFileDownloadProgress(progressEvent, mainContext);
        },
      })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getMerchants(params) {
  const url = `${CONFIG.DCIR_HOST}${API.CREATE_MERCHANT}?${params}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getAllMerchants() {
  const url = `${CONFIG.DCIR_HOST}${API.GET_ALL_MERCHANTS}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getMerchantsTiedToUser() {
  const url = `${CONFIG.DCIR_HOST}${API.GET_MERCHANTS_TIED_TO_USER}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getMerchantUsers(params) {
  const url = `${CONFIG.DCIR_HOST}${API.GET_MERCHANT_USER}?${params}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getMerchantUserRoles(params) {
  const url = `${CONFIG.DCIR_HOST}${API.GET_MERCHANT_USER_ROLES}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function changeSuperAgentUserStatus(payload, type = null) {
  const url = `${CONFIG.DCIR_HOST}${
    type === "fo"
      ? API.CHANGE_SUPER_AGENT_USER_STATUS_FO
      : API.CHANGE_SUPER_AGENT_USER_STATUS
  }`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .put(url, payload, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getFrontOfficeMerchantUsers(params) {
  const url = `${CONFIG.DCIR_HOST}${API.CREATE_FRONT_OFFICE_MERCHANT_USER}?${params}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function viewMerchantUser(params) {
  const url = `${CONFIG.DCIR_HOST}${API.GET_MERCHANT_USER}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .put(url, params, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getMerchant(e) {
  const url = `${CONFIG.DCIR_HOST}${API.CREATE_MERCHANT}/${e}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getFrontOfficeUserRoles() {
  const url = `${CONFIG.DCIR_HOST}${API.GET_FRONT_OFFICE_MERCHANT_ROLES}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function createMerchant(params) {
  const url = `${CONFIG.DCIR_HOST}${API.CREATE_MERCHANT}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .post(url, params, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function uploadBulkMerchants(payload) {
  const url = `${CONFIG.DCIR_HOST}${API.UPLOAD_BULK_MERCHANT}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .post(url, payload, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function updateMerchantSettlement(payload, merchantId) {
  const url = `${CONFIG.DCIR_HOST}${API.UPDATE_MERCHANT_SETTLEMENT}${merchantId}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .post(url, payload, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function updateMerchant(params) {
  const url = `${CONFIG.DCIR_HOST}${API.CREATE_MERCHANT}/${params.merchantId}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .put(url, params, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function createParticipant(params) {
  const url = `${CONFIG.DCIR_HOST}${API.CREATE_PARTICIPANT}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .post(url, params, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function createDispute(params, type = null) {
  const url = `${CONFIG.DCIR_HOST}${
    type === "fo" ? API.CREATE_DISPUTE_FO : API.CREATE_DISPUTE
  }`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .post(url, params, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function downloadDisputes(payload, mainContext) {
  const url = `${CONFIG.DCIR_HOST}${API.DOWNLOAD_DISPUTES}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .put(url, payload, {
        headers: header,
        responseType: "blob",
        onUploadProgress: function (progressEvent) {
          handleFileDownloadProgress(progressEvent, mainContext);
        },
        // timeout: 30000,
      })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function downloadSuperagentDisputes(payload, mainContext) {
  const url = `${CONFIG.DCIR_HOST}${API.DOWNLOAD_SUPERAGENT_DISPUTES}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .put(url, payload, {
        headers: header,
        responseType: "blob",
        onUploadProgress: function (progressEvent) {
          handleFileDownloadProgress(progressEvent, mainContext);
        },
      })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function downloadBackofficeDisputes(payload, mainContext) {
  // console.log("download-dispute");
  const url = `${CONFIG.DCIR_HOST}${API.DOWNLOAD_BACKOFFICE_DISPUTES}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .put(url, payload, {
        headers: header,
        responseType: "blob",
        onUploadProgress: function (progressEvent) {
          handleFileDownloadProgress(progressEvent, mainContext);
        },
      })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function reopenDispute(payload, type = null) {
  const url = `${CONFIG.DCIR_HOST}${
    type === "fo" ? API.REOPEN_DISPUTE_FO : API.REOPEN_DISPUTE
  }`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .post(url, payload, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function reopenSystemAcceptedDispute(payload, type = null) {
  const url = `${CONFIG.DCIR_HOST}${API.REOPEN_SYSTEM_ACCEPTED_DISPUTE}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .post(url, payload, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function updateDispute(params, type = null) {
  const url = `${CONFIG.DCIR_HOST}${
    type === "fo" ? API.UPDATE_DISPUTE_FO : API.CREATE_DISPUTE
  }`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .put(url, params, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function createMerchantUsers(params) {
  const url = `${CONFIG.DCIR_HOST}${API.GET_MERCHANT_USER}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .post(url, params, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function createFrontOfficeMerchantUsers(params) {
  const url = `${CONFIG.DCIR_HOST}${API.CREATE_FRONT_OFFICE_MERCHANT_USER}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .post(url, params, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function filterMerchantUsers(params, payload, type = null) {
  // console.log(type)
  // let serviceUsed =
  const url = `${CONFIG.DCIR_HOST}${
    type === "fo" ? API.FILTER_MERCHANT_USERS_FO : API.FILTER_MERCHANT_USERS
  }?${params}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .put(url, payload, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function createChargeTypeModel(params) {
  const url = `${CONFIG.DCIR_HOST}${API.CREATE_CHARGE_TYPE_MODEL}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .post(url, params, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function updateChargeTypeModel(params) {
  //re work if needed
  const url = `${CONFIG.DCIR_HOST}${API.CREATE_CHARGE_TYPE_MODEL}/${params?.id}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .put(url, params, { headers: header }) //change to put
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function updateParticipant(params) {
  //re work if needed
  const url = `${CONFIG.DCIR_HOST}${API.CREATE_PARTICIPANT}/${params?.id}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .put(url, params, { headers: header }) //change to put
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function createPublicHoliday(payload) {
  const url = `${CONFIG.DCIR_HOST}${API.CREATE_PUBLIC_HOLIDAY}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .post(url, payload, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getPublicHolidays(params) {
  const url = `${CONFIG.DCIR_HOST}${API.GET_PUBLIC_HOLIDAYS}?${params}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function updatePublicHoliday(payload, code) {
  const url = `${CONFIG.DCIR_HOST}${API.UPDATE_PUBLIC_HOLIDAY}${code}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .put(url, payload, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function filterPublicHolidays(params, payload) {
  const url = `${CONFIG.DCIR_HOST}${API.FILTER_PUBLIC_HOLIDAYS}?${params}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .put(url, payload, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function deletePublicHoliday(code) {
  const url = `${CONFIG.DCIR_HOST}${API.DELETE_PUBLIC_HOLIDAY}${code}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .delete(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function downloadTransactions(payload, type = null) {
  // const url = `${CONFIG.DCIR_HOST}${API.DOWNLOAD_TRANSACTIONS}`;
  const url = `${CONFIG.DCIR_HOST}${
    type === "fo" ? API.DOWNLOAD_TRANSACTIONS_FO : API.DOWNLOAD_TRANSACTIONS
  }`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .put(url, payload, { headers: header, responseType: "blob" })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getConfigurations(params) {
  const url = `${CONFIG.DCIR_HOST}${API.GET_CONFIGURATIONS}?${params}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getSingleConfiguration(configKey) {
  const url = `${CONFIG.DCIR_HOST}${API.GET_SINGLE_CONFIGURATION}${configKey}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function updateConfiguration(payload, configKey) {
  const url = `${CONFIG.DCIR_HOST}${API.UPDATE_CONFIGURATION}${configKey}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .put(url, payload, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getCustomerInformation(transactionKey) {
  const url = `${CONFIG.DCIR_HOST}${API.GET_CUSTOMER_INFORMATION}${transactionKey}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getParticipantUsers(params) {
  const url = `${CONFIG.DCIR_HOST}${API.GET_PARTICIPANT_USERS}?${params}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function createParticipantUser(payload) {
  const url = `${CONFIG.DCIR_HOST}${API.CREATE_PARTICIPANT_USER}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .post(url, payload, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function updateParticipantUser(payload) {
  const url = `${CONFIG.DCIR_HOST}${API.UPDATE_PARTICIPANT_USER}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .put(url, payload, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getParticipantsForUser(params) {
  const url = `${CONFIG.DCIR_HOST}${API.GET_PARTICIPANTS_FOR_USER}?${params}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getUserParticipants() {
  const url = `${CONFIG.DCIR_HOST}${API.GET_USER_PARTICIPANTS}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function validateParticipantUsername(username) {
  const url = `${CONFIG.DCIR_HOST}${API.VALIDATE_PARTICIPANT_USERNAME}?${username}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getParticipantReports(params) {
  const url = `${CONFIG.DCIR_HOST}${API.GET_PARTICIPANT_REPORTS}?${params}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function retryParticipantReportGeneration(reportId) {
  const url = `${CONFIG.DCIR_HOST}${BACKOFFICE_API.RETRY_PARTICIPANT_REPORT_REGENERATION}/${reportId}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .put(url, {}, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function downloadSettlementReport(payload, mainContext) {
  const url = `${CONFIG.DCIR_HOST}${API.DOWNLOAD_PARTICIPANT_REPORT}${payload.ownerId}/${payload.detailId}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: header,
        responseType: "blob",
        onDownloadProgress: function (progressEvent) {
          handleFileDownloadProgress(progressEvent, mainContext);
        },
      })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function filterParticipantReports(payload, params) {
  const url = `${CONFIG.DCIR_HOST}${API.FILTER_PARTICIPANT_REPORTS}?${params}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .put(url, payload, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getBulkDisputes(params) {
  const url = `${CONFIG.DCIR_HOST}${API.GET_BULK_DISPUTES}?${params}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function uploadBulkDispute(payload) {
  const url = `${CONFIG.DCIR_HOST}${API.UPLOAD_BULK_DISPUTE}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .post(url, payload, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getBulkDisputeTransactions(batchId, payload, params) {
  const url = `${CONFIG.DCIR_HOST}${API.GET_BULK_DISPUTE_TRANSACTIONS}${batchId}?${params}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .put(url, payload, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getBulkDisputeUploadSummary(batchId) {
  const url = `${CONFIG.DCIR_HOST}${BACKOFFICE_API.GET_BULK_DISPUTE_UPLOAD_SUMMARY}${batchId}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function downloadBulkDisputeTransactions(batchId, payload = {}, mainContext) {
  const url = `${CONFIG.DCIR_HOST}${API.DOWNLOAD_BULK_DISPUTE_TRANSACTIONS}/${batchId}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .put(url, payload, {
        headers: header,
        responseType: "blob",
        onUploadProgress: function (progressEvent) {
          handleFileDownloadProgress(progressEvent, mainContext);
        },
      })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getBackofficeUserDetails(params) {
  const url = `${CONFIG.DCIR_HOST}${API.GET_BACKOFFICE_USER_DETAILS}?${params}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function changeBackofficeUserStatus(payload) {
  const url = `${CONFIG.DCIR_HOST}${API.CHANGE_BACKOFFICE_USER_STATUS}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .put(url, payload, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function resetBackofficeUserPassword(payload) {
  const url = `${CONFIG.DCIR_HOST}${API.RESET_BACKOFFICE_USER_PASSWORD}`;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .put(url, payload, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

const getBasicHeader = () => {
  const token = LOCAL_STORAGE_SERVICE.GET_TOKEN();
  const tokenBearer = `Bearer ${token}`;
  return {
    "Content-Type": "application/json",
    Authorization: tokenBearer,
    // 'Access-Control-Allow-Origin': '*',
  };
};

function makeGetRequest(url, params, isBlob = false) {
  const header = getBasicHeader();
  url = params ? `${url}?${params}` : url;
  return new Promise((resolve, reject) => {
    axios
      .get(`${CONFIG.DCIR_HOST}${url}`, {
        headers: header,
        ...(isBlob && { responseType: "blob" }),
      })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function makePutRequest(url, payload, params) {
  url = params ? `${url}?${params}` : url;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .put(`${CONFIG.DCIR_HOST}${url}`, payload, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function makePostRequest(url, payload, params) {
  url = params ? `${url}?${params}` : url;
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    axios
      .post(`${CONFIG.DCIR_HOST}${url}`, payload, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getBankFeatures() {
  const url = `${CONFIG.DCIR_HOST}${API.GET_BANK_FEATURES}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

export const SERVICES = {
  LOGIN: login,
  LOGOUT: logout,
  AUTHENTICATE_2FA_CODE: authenticate2faCode,
  GET_USER_DETAILS: getUserDetails,
  CHANGE_PASSWORD: changePassword,
  RESET_SUPER_AGENT_USER_PASSWORD: resetSuperAgentUserPassword,
  CREATE_MERCHANT: createMerchant,
  UPLOAD_BULK_MERCHANTS: uploadBulkMerchants,
  UPDATE_MERCHANT_SETTLEMENT: updateMerchantSettlement,
  CREATE_PARTICIPANT: createParticipant,
  CREATE_CHARGE_TYPE_MODEL: createChargeTypeModel,
  UPDATE_CHARGE_TYPE_MODEL: updateChargeTypeModel,
  GET_SETTLEMENT_PARTICIPANT_NON_GLOBAL: getSettlementParticipantNonGlobal,
  DOWNLOAD_BULK_SETTLEMENT: downloadBulkSettlement,
  GET_SETTLEMENT_PARTICIPANTS: getSettlementParticipants,
  GET_SETTLEMENT_TYPE: getSettlementType,
  GET_CHARGE_TYPES: getChargeTypes,
  DELETE_CHARGE_MODEL: deleteChargeModel,
  SEARCH_CHARGE_MODEL: searchChargeType,
  GET_ALL_CHARGE_MODELS: getAllChargeModels,
  GET_CHARGE_MODELS: getChargeModels,
  GET_CHARGE_MODEL_BY_CODE: getChargeModelByCode,
  VIEW_CHARGE_MODEL: viewChargeModel,
  GET_PARTICIPANTS: getParticipants,
  DELETE_PARTICIPANT: deleteParticipant,
  UPDATE_PARTICIPANT: updateParticipant,
  SEARCH_PARTICIPANT: searchParticipant,
  GET_MERCHANTS: getMerchants,
  GET_ALL_MERCHANTS: getAllMerchants,
  GET_MERCHANTS_TIED_TO_USER: getMerchantsTiedToUser,
  GET_MERCHANT: getMerchant,
  DELETE_MERCHANT: deleteMerchant,
  UPDATE_MERCHANT: updateMerchant,
  GET_TRANSACTION: getTransactions,
  SEARCH_TRANSACTIONS: searchTransactions,
  GET_DISPUTE_CODES: getDisputeCodes,
  CREATE_DISPUTE: createDispute,
  DOWNLOAD_DISPUTES: downloadDisputes,
  DOWNLOAD_SUPERAGENT_DISPUTES: downloadSuperagentDisputes,
  DOWNLOAD_BACKOFFICE_DISPUTES: downloadBackofficeDisputes,
  REOPEN_DISPUTE: reopenDispute,
  REOPEN_SYSTEM_ACCEPTED_DISPUTE: reopenSystemAcceptedDispute,
  UPDATE_DISPUTE: updateDispute,
  GET_DISPUTE: getDisputes,
  GET_SUPER_AGENT_DISPUTES: getSuperAgentDisputes,
  GET_SUPER_AGENT_BANK_DISPUTES: getSuperAgentBankDisputes,
  GET_FILTERED_BANK_DISPUTES: getFilteredBankDisputes,
  GET_FILTERED_AGENT_DISPUTES: getFilteredAgentDisputes,
  GET_MERCHANT_USER: getMerchantUsers,
  GET_MERCHANT_USER_ROLES: getMerchantUserRoles,
  CHANGE_SUPER_AGENT_USER_STATUS: changeSuperAgentUserStatus,
  CREATE_MERCHANT_USER: createMerchantUsers,
  CREATE_FRONT_OFFICE_MERCHANT_USER: createFrontOfficeMerchantUsers,
  FILTER_MERCHANT_USERS: filterMerchantUsers,
  VIEW_PARTICIPANT: viewParticipant,
  VIEW_MERCHANT_USER: viewMerchantUser,
  GET_BULK_SETTLEMENT: getBulkSettlement,
  GET_FRONT_OFFICE_BULK_SETTLEMENT: getFrontOfficeBulkSettlement,
  GET_BULK_SETTLEMENT_TRANSACTION: getBulkSettlementTransactions,
  GET_FRONT_OFFICE_BULK_SETTLEMENT_TRANSACTION:
    getFrontOfficeBulkSettlementTransactions,
  GET_PAYMENT_REQUEST: getPaymentRequest,
  GET_SETTLEMENT_PAYMENT_ATTEMPTS: getSettlementPaymentAttempts,
  GET_FRONT_OFFICE_PAYMENT_REQUEST: getFrontOfficePaymentRequest,
  GET_FRONT_OFFICE_MERCHANT_ROLES: getFrontOfficeUserRoles,
  GET_FRONT_OFFICE_MERCHANT_USERS: getFrontOfficeMerchantUsers,
  GET_FRONT_OFFICE_TRANSACTIONS: getFrontOfficeTransactions,
  DEACTIVATE_MERCHANT: deactivateMerchant,
  VERIFY_ACCOUNT_NUMBER: verifyAccountNumber,
  GET_DISPUTE_BY_TRANSACTION_KEY: getDisputeByTransactionKey,
  GET_DISPUTE_BY_LOG_CODE: getDisputeByLogCode,
  GET_FILTERED_DISPUTES: getFilteredDisputes,
  CREATE_PUBLIC_HOLIDAY: createPublicHoliday,
  GET_PUBLIC_HOLIDAYS: getPublicHolidays,
  UPDATE_PUBLIC_HOLIDAY: updatePublicHoliday,
  FILTER_PUBLIC_HOLIDAYS: filterPublicHolidays,
  DELETE_PUBLIC_HOLIDAY: deletePublicHoliday,
  DOWNLOAD_TRANSACTIONS: downloadTransactions,
  GET_CONFIGURATIONS: getConfigurations,
  GET_SINGLE_CONFIGURATION: getSingleConfiguration,
  UPDATE_CONFIGURATION: updateConfiguration,
  GET_CUSTOMER_INFORMATION: getCustomerInformation,
  GET_PARTICIPANT_USERS: getParticipantUsers,
  CREATE_PARTICIPANT_USER: createParticipantUser,
  UPDATE_PARTICIPANT_USER: updateParticipantUser,
  GET_PARTICIPANTS_FOR_USER: getParticipantsForUser,
  GET_USER_PARTICIPANTS: getUserParticipants,
  VALIDATE_PARTICIPANT_USERNAME: validateParticipantUsername,
  GET_PARTICIPANT_REPORTS: getParticipantReports,
  RETRY_PARTICIPANT_REPORT_REGENERATION: retryParticipantReportGeneration,
  DOWNLOAD_SETTLEMENT_REPORT: downloadSettlementReport,
  FILTER_PARTICIPANT_REPORTS: filterParticipantReports,
  UPLOAD_BULK_DISPUTE: uploadBulkDispute,
  GET_BULK_DISPUTES: getBulkDisputes,
  GET_BULK_DISPUTE_TRANSACTIONS: getBulkDisputeTransactions,
  DOWNLOAD_BULK_DISPUTE_TRANSACTIONS: downloadBulkDisputeTransactions,
  GET_BACKOFFICE_USER_DETAILS: getBackofficeUserDetails,
  CHANGE_BACKOFFICE_USER_STATUS: changeBackofficeUserStatus,
  RESET_BACKOFFICE_USER_PASSWORD: resetBackofficeUserPassword,
  GET_BULK_DISPUTE_UPLOAD_SUMMARY: getBulkDisputeUploadSummary,
  GET_BACKOFFICE_DISPUTES: getBackofficeDisputes,
  GET_BACKOFFICE_SUPERAGENT_DISPUTES: getBackofficeSuperagentDisputes,
  GET_FILTERED_BACKOFFICE_DISPUTES: getFilteredBackofficeDisputes,
  GET_FILTERED_SUPERAGENT_DISPUTES: getFilteredSuperagentDisputes,
  DOWNLOAD_BACKOFFICE_SUPERAGENT_DISPUTES: downloadBackofficeSuperagentDisputes,
  MAKE_GET_REQUEST: makeGetRequest,
  MAKE_POST_REQUEST: makePostRequest,
  MAKE_PUT_REQUEST: makePutRequest,
  GET_BANK_FEATURES: getBankFeatures
};
