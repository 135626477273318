import React, { useContext, useState } from "react";
import { SERVICES } from "../../../core/services/services";
import { saveAs } from "file-saver";
import { CustomToast } from "../../../shared/components/alert/custom-toast";
// import { CustomLoader } from "../../../shared/components/custom-loader/custom-loader";
import { HELPER } from "../../../shared/helper/helper";
import moment from "moment";
import { MainContext } from "../../../../App";
import { Optional } from "../../../shared/components/optional/optional";
import { DownloadProgress } from "../../../shared/components/download-progress/download-progress";

export function ConfirmationDialog(props) {
  const mainContext = useContext(MainContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  function downloadReport() {
    setLoading(true);
    const payload = {
      ownerId: props?.selectedParticipant?.ownerId,
      detailId: props?.selectedParticipant.detailId,
    };
    let date = props?.selectedParticipant?.reportTime;
    if (date && date?.length === 7) {
      date.pop();
    }
    date = moment(date, "YYYY MM DD HH mm ss").format("YYYY-MM-DD");
    SERVICES.DOWNLOAD_SETTLEMENT_REPORT(payload, mainContext)
      .then((data) => {
        saveAs(
          data,
          `${props?.selectedParticipant?.ownerName}-settlement_report_at_${date}.xlsx`
        );
        props.closeModal();
      })
      .catch((error) => {
        handleError(error);
      });
  }

  async function handleError(error) {
    const errMessage = await HELPER.PARSE_BLOB_ERROR(error);
    setError(errMessage);
    setLoading(false);
  }

  const dialogView = () => {
    if (props.showSuccess) {
      return <div></div>;
    } else {
      return (
        <div>
          <h4 className="p-text-left p-mt-0">Confirm Download!</h4>
          <p className="p-text-left">{props.warningText}</p>
          <div className="p-pb-1">
            {error && (
              <CustomToast title="Error" description={error} type="error" />
            )}
          </div>
          <Optional show={loading}>
            <DownloadProgress />
          </Optional>
          {/* {loading ? (
            <CustomLoader loadingText="Loading..." />
          ) : ( */}
          <Optional show={!loading}>
            <div className="p-mt-4 p-pb-2">
              <div className="p-grid">
                <div className="p-col-6">
                  <button
                    onClick={props.closeModal}
                    className="secondary-button"
                  >
                    Cancel
                  </button>
                </div>
                <div className="p-col-6">
                  <button onClick={downloadReport} className="primary-button">
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </Optional>
          {/* )} */}
        </div>
      );
    }
  };

  return dialogView();
}
