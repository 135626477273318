import { useCallback, useEffect, useState } from "react";
import { BACKOFFICE_SERVICE } from "../../../../core/services/backoffice-service";
import { CustomLoader } from "../../../../shared/components/custom-loader/custom-loader";
import { CustomTable } from "../../../../shared/components/custom-table/custom-table";
import { HELPER } from "../../../../shared/helper/helper";

export function FileUploadErrors(props) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pageNo, setPageNo] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [emptyText, setEmptyText] = useState("");
  const [uploadErrorsList, setUploadErrorList] = useState([]);
  const tableHeaders = [{ label: "Error Message", value: "errorMessage" }];

  const getUploadErrors = useCallback(() => {
    const params = HELPER.TO_URL_STRING({
      page: pageNo,
      size: 10,
      reconUploadErrorType: props.reconUploadErrorType,
    });
    BACKOFFICE_SERVICE.GET_RECON_FILE_UPLOAD_ERRORS(props.fileKey, params)
      .then((data) => {
        const result = data?.result?.content;
        if (!result.length) {
          setEmptyText("No results found!");
        } else {
          // let arr = [];
          setTotalItems(data?.result.totalElements);
          setTotalPages(data?.result.totalPages);
          // result.forEach((e) => {
          //   arr.push({
          //     ...e,
          //     merchantName: e.merchant?.merchantName,
          //     actions: "CR",
          //     detailsFunction: openAction,
          //   });
          // });
          setUploadErrorList(data?.result?.content);
        }
        setError(null);
        // setCurrentIndex(1);
        setLoading(false);
      })
      .catch((error) => {
        setError(HELPER.PROCESS_ERROR(error));
        setLoading(false);
      });
  }, [pageNo, props.fileKey, props.reconUploadErrorType]);
  useEffect(() => {
    getUploadErrors();
  }, [getUploadErrors]);
  return (
    <div className="recon">
      {loading ? (
        <div style={{ margin: "10rem 0" }}>
          <CustomLoader />
        </div>
      ) : (
        <CustomTable
          isReload={false}
          totalPages={totalPages}
          totalItems={totalItems}
          currentPage={pageNo + 1}
          emptyText={emptyText}
          search={true}
          reload={getUploadErrors}
          error={error}
          items={uploadErrorsList}
          headers={tableHeaders}
          nextPage={() => setPageNo(pageNo + 1)}
          prevPage={() => setPageNo(pageNo - 1)}
          goToFirstPage={() => setPageNo(0)}
          goToLastPage={() => setPageNo(totalPages - 1)}
          goToPage={(no) => setPageNo(no)}
        />
      )}
    </div>
  );
}
