import { Disputes } from "./disputes";
import { TabPanel, TabView } from "primereact/tabview";
import { useEffect, useState } from "react";
import { SERVICES } from "../../../core/services/services";
import { BACKOFFICE_CONFIGS, DisputeTypes } from "../../../shared/constants";
import "./dispute.css";
import { CustomBreadcrumb } from "../../../shared/components/custom-breadcrumb/custom-breadcrumb";

export function BackofficeDisputes() {
  // const [hasFailedResponseDisputes, setHasFailedResponseDisputes] = useState(false)
  const [failedResponseDisputeCodes, setFailedResponseDisputeCodes] =
    useState("");

  useEffect(() => {
    function getDisputeableCodes() {
      SERVICES.GET_SINGLE_CONFIGURATION(
        BACKOFFICE_CONFIGS.BACKOFFICE_DISPUTE_RESPONSE_CODES
      )
        .then((data) => {
          let disputeableCodes = data?.result?.configValue?.split(",");
          disputeableCodes = disputeableCodes.map((code) => {
            // Trim cause config takes user entered input and can possibly have whitespaces
            return code.trim();
          });
          handleDisputeCodesResponse(disputeableCodes ? disputeableCodes : []);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    function handleDisputeCodesResponse(disputeableCodes) {
      if (disputeableCodes?.length && disputeableCodes?.length > 1) {
        if (disputeableCodes.includes("00")) {
          disputeableCodes.splice(disputeableCodes.indexOf("00"), 1);
          setFailedResponseDisputeCodes(disputeableCodes.join(","));
        } else {
          setFailedResponseDisputeCodes(disputeableCodes?.join(","));
        }
      }
    }
    getDisputeableCodes();
  }, []);
  return (
    <div className="back-office-disputes">
      <div className="page-title p-text-left">Back Office Disputes</div>
      <div className="p-mt-2">
        <CustomBreadcrumb page="Manage Disputes" />
      </div>
      <div className="p-mt-5">
        <TabView>
          <TabPanel header="All Disputes">
            <div className="p-mt-5">
              <Disputes disputeType={DisputeTypes.Backoffice} />
            </div>
          </TabPanel>
          {failedResponseDisputeCodes?.length ? (
            <TabPanel header="Disputes Pending Approval">
              <div className="p-mt-5">
                <Disputes
                  disputeType={DisputeTypes.BackofficeFailedResponseDisputes}
                  failedResponseDisputeCodes={failedResponseDisputeCodes}
                />
              </div>
            </TabPanel>
          ) : (
            <TabPanel></TabPanel>
          )}
        </TabView>
      </div>
    </div>
  );
}
