import React, { useState } from "react";
import { SERVICES } from "../../../core/services/services";
import { FormInput } from "../../../shared/components/form-component/form-input";
import { CustomMessage } from "../../../shared/components/alert/custom-message";
import { CustomLoader } from "../../../shared/components/custom-loader/custom-loader";
import { CustomToast } from "../../../shared/components/alert/custom-toast";
import { HELPER } from "../../../shared/helper/helper";

export function ResetPassword(props) {
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [message, setMessage] = useState(null);
  const [messageTitle, setMessageTitle] = useState(null);

  function resetUserPassword() {
    setLoading(true);
    const payload = {
      username: username,
    };
    SERVICES.RESET_BACKOFFICE_USER_PASSWORD(payload)
      .then((data) => {
        setSuccessMessage(`${props.username}'s password has been reset.`);
        setShowSuccess(true);
        setLoading(false);
      })
      .catch((error) => {
        setMessageTitle("Error");
        setMessage(HELPER.PROCESS_ERROR(error));
        setLoading(false);
      });
  }

  const actions = () => {
    if (loading) {
      return (
        <div className="p-my-2">
          <CustomLoader loadingText="Resetting..." />
        </div>
      );
    } else if (!loading && !showSuccess) {
      return (
        <div className="p-mt-4 p-pb-2">
          <div className="p-grid">
            <div className="p-col-6">
              <button onClick={props.cancel} className="secondary-button">
                Cancel
              </button>
            </div>
            <div className="p-col-6">
              <button
                disabled={username !== props.username}
                onClick={resetUserPassword}
                className="primary-button"
              >
                Reset
              </button>
            </div>
          </div>
        </div>
      );
    }
  };

  const alertView = () => {
    if (messageTitle) {
      return (
        <div>
          <CustomToast
            title={messageTitle}
            description={message}
            type="error"
          />
        </div>
      );
    } else {
      return <div />;
    }
  };

  const resetView = () => {
    if (showSuccess) {
      return (
        <div>
          <CustomMessage
            closeModal={props.closeModal}
            close={true}
            message={successMessage}
            messageType="success"
          />
        </div>
      );
    } else {
      return (
        <div>
          <p className="p-mb-4 p-mt-0 confirm-text">
            Confirm that you want to reset this users password!
          </p>
          <div>
            <p className="p-text-left">
              To confirm, enter <b>{props.username}</b> in the text input field
            </p>
            <FormInput
              value={username}
              required={true}
              field="reset"
              type="INPUT"
              fn={(e) => setUsername(e?.target?.value)}
              loading={loading}
              placeholder="Enter username"
            />
          </div>
        </div>
      );
    }
  };

  return (
    <div className="p-my-3">
      <div className="p-pb-1">{alertView()}</div>
      <div>{resetView()}</div>
      <div>{actions()}</div>
    </div>
  );
}
