import React, {
  useCallback,
  // useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { CustomBreadcrumb } from "../../../shared/components/custom-breadcrumb/custom-breadcrumb";
import { CustomTable } from "../../../shared/components/custom-table/custom-table";
import { SERVICES } from "../../../core/services/services";
import { CustomLoader } from "../../../shared/components/custom-loader/custom-loader";
import { CustomModal } from "../../../shared/components/custom-modal/custom-modal";
import { Toast } from "primereact/toast";
import { HELPER } from "../../../shared/helper/helper";
import { AccessDenied } from "../../access-denied/access-denied";
import ErrorBoundary from "../../../shared/components/error-boundary/error-boundary";
import { BACKOFFICE_API } from "../../../core/apis/backoffice-apis";
import { ManualPaymentsFilter } from "./manual-payments-filter";
import { ManualPaymentDetails } from "./payment-details";
import {
  DOWNLOADED_FOR_PROCESSING,
  ManualPaymentTypes,
} from "../../../shared/constants";
import { Optional } from "../../../shared/components/optional/optional";
import { ManualPaymentsBulkUploads } from "./bulk-uploads/bulk-uploads";
import { TabPanel, TabView } from "primereact/tabview";
import "./styles.css";

export function ManualSettlementPayments() {
  const toast = useRef(null);
  const [visible, setVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [error, setError] = useState("");
  const [search, setSearch] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [range] = useState(5);
  const [emptyText, setEmptyText] = useState("");
  const [currentModalIndex, setCurrentModalIndex] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [searchParams, setSearchParams] = useState(null);
  const [paymentDetails, setPaymentDetails] = useState([]);
  const tableActions = ["FILTER"];
  const tableHeaders = [
    { label: "Bulk Settlement Key", value: "bulkSettlementKey" },
    { label: "Credit Account Name", value: "creditAccountName" },
    { label: "Credit Account", value: "creditAccount" },
    { label: "Amount", value: "amount" },
    { label: "Status", value: "formattedStatus" },
    { label: "Created On", value: "createdAt" },
    { label: "Actions", value: "actions" },
  ];
  const [manualDisputes, setManualDisputes] = useState([]);
  const [formattedPaymentDetails, setFormattedPaymentDetails] = useState(null);

  const getDisputeDetails = useCallback((e, isMobile) => {
    let arr = [];
    setFormattedPaymentDetails([]);

    arr.push({
      label: "Amount",
      value: e?.amount,
      itemCase: "transactionAmount",
    });
    arr.push({
      label: "Bulk Settlement Key",
      value: e?.bulkSettlementRecord?.bulkSettlementKey,
    });
    // arr.push({
    //   label: "Recipient Type",
    //   value: e?.recipientType,
    // });
    if (e?.merchantPayment) {
      arr.push({
        label: "Super Agent",
        value: e?.bulkSettlementRecord?.merchant?.merchantName,
      });
    }
    if (e?.participant) {
      arr.push({
        label: "Participant",
        value: e?.participant?.name,
      });
    }
    arr.push({
      label: "Status",
      value: e?.formattedStatus,
      itemCase: "status",
    });

    arr.push({
      label: "Credit Account",
      value: e?.creditAccount,
    });
    arr.push({
      label: "Credit Account Name",
      value: e?.creditAccountName,
    });
    arr.push({ label: "Debit Account", value: e?.debitAccount });
    arr.push({ label: "Debit Account Name", value: e?.debitAccountName });

    arr.push({
      label: "Narration",
      value: e?.narration,
    });
    arr.push({
      label: "Payment Request Id",
      value: e?.requestId,
    });
    arr.push({ label: "Processed By", value: e?.processedBy });

    arr.push({
      label: "Created On",
      value: e?.createdAt,
      itemCase: "createdAt",
    });
    arr.push({
      label: "Last Updated On",
      value: e?.lastUpdatedTime,
      itemCase: "transactionTime",
    });
    // arr.push({ label: "Completed On", value: e?.completedAt });
    setPaymentDetails(e);
    setFormattedPaymentDetails(arr);
    setCurrentIndex(3);
  }, []);

  const openAction = useCallback(
    (e, action, isMobile) => {
      // eslint-disable-next-line default-case
      switch (action) {
        case "VIEW": {
          getDisputeDetails(e, isMobile);
          break;
        }
      }
    },
    [getDisputeDetails]
  );

  const getSettlementPayments = useCallback(() => {
    if (searchParams) {
      setSearch(true);
    }
    setCurrentIndex(0);
    setManualDisputes([]);
    let params = {
      page: pageNo,
      size: 10,
      ...searchParams,
    };
    params = HELPER.TO_URL_STRING(params);
    SERVICES.MAKE_GET_REQUEST(
      BACKOFFICE_API.MANUAL_PAYMENTS.GET_PENDING_SETTLEMENT_PAYMENTS,
      params
    )
      .then((data) => {
        const result = data?.result;
        if (!result?.content?.length) {
          setEmptyText("No pending settlement payments found...");
        } else {
          let arr = [];
          setTotalItems(result?.totalElements); //need adjustment
          setTotalPages(result?.totalPages); //need adjustment
          result.content.forEach((e) => {
            arr.push({
              ...e,
              bulkSettlementKey: e?.bulkSettlementRecord?.bulkSettlementKey,
              actions: "CR",
              formattedStatus:
                e?.status === DOWNLOADED_FOR_PROCESSING
                  ? "DOWNLOADED"
                  : e?.status,
              detailsFunction: openAction,
            });
          });
          setManualDisputes(arr);
        }
        setError(null);
        setCurrentIndex(1);
      })
      .catch((error) => {
        setError(HELPER.PROCESS_ERROR(error));
        setCurrentIndex(1);
      });
  }, [pageNo, openAction, searchParams]);

  useEffect(() => {
    getSettlementPayments();
  }, [getSettlementPayments]);

  function goToPage(pageNo) {
    setPageNo(pageNo);
  }

  function onHide() {}

  function openModal(index, isMobile) {
    setCurrentModalIndex(index);
    if (isMobile) {
      setCurrentIndex(2);
    } else {
      setVisible(true);
    }
  }

  function reload() {
    setPageNo(0);
    setSearchParams(null);
    setSearch(false);
    setCurrentIndex(0);
    getSettlementPayments();
  }

  function closeModal(isReload) {
    if (visible) {
      setVisible(false);
    }
    if (currentIndex === 2) {
      setCurrentIndex(1);
    }
    if (isReload === true) {
      reload();
    }
  }

  function goBack(reload) {
    if (reload === true) {
      getSettlementPayments();
      return;
    }
    setCurrentIndex(1);
  }

  const transactionsView = () => {
    // eslint-disable-next-line default-case
    switch (currentIndex) {
      case 0:
        return (
          <div className="loading-container">
            <CustomLoader loadingText="Loading pending settlements..." />
          </div>
        );
      case 1:
        return (
          <CustomTable
            tableActionFunction={mobileFilter}
            tableActions={tableActions}
            isReload={true}
            totalPages={totalPages}
            totalItems={totalItems}
            currentPage={pageNo + 1}
            range={range}
            emptyText={emptyText}
            search={search}
            reload={reload}
            error={error}
            items={manualDisputes}
            headers={tableHeaders}
            nextPage={() => setPageNo(pageNo + 1)}
            prevPage={() => setPageNo(pageNo - 1)}
            goToFirstPage={() => setPageNo(0)}
            goToLastPage={() => setPageNo(totalPages - 1)}
            goToPage={goToPage}
          />
        );
      case 2:
        return (
          <div className="mobile-modal-container">
            <div className="custom-card">
              <div className="mobile-card-position">{mobileModal()}</div>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="p-mt-2">
            <ManualPaymentDetails
              goBack={goBack}
              formattedPaymentDetails={formattedPaymentDetails}
              manualPaymentType={ManualPaymentTypes.Settlement}
              paymentDetails={paymentDetails}
            />
          </div>
        );
    }
  };

  function mobileFilter() {
    openModal(1, true);
  }

  function saveSearchParams(params) {
    setVisible(false);
    setPageNo(0);
    setSearchParams(params);
  }

  const mobileModal = () => {
    // eslint-disable-next-line default-case
    switch (currentModalIndex) {
      case 1:
        return (
          <ManualPaymentsFilter
            searchFunction={saveSearchParams}
            closeModal={closeModal}
            manualPaymentType={ManualPaymentTypes.Settlement}
          />
        );
    }
  };

  const modalContent = () => {
    // eslint-disable-next-line default-case
    switch (currentModalIndex) {
      case 1:
        return (
          <ManualPaymentsFilter
            searchFunction={saveSearchParams}
            closeModal={closeModal}
            manualPaymentType={ManualPaymentTypes.Settlement}
          />
        );
    }
  };

  return (
    <ErrorBoundary>
      <div className="manual-payments">
        <Toast ref={toast} />
        <div>
          <CustomModal
            closeModal={closeModal}
            onHide={onHide}
            visible={visible}
            modalContent={modalContent}
          />
        </div>
        <div className="page-title p-text-left">
          Pending Settlement Payments
        </div>
        <div className="p-mt-2">
          <CustomBreadcrumb page="Manage Pending Settlement Payments" />
        </div>
        <div style={{ marginTop: "4rem" }}>
          <TabView>
            <TabPanel header="Pending Settlements">
              <div style={{ marginTop: "4rem" }}>
                <div className="floating-buttons desktop-scree">
                  <div className="p-grid">
                    <div className="p-col-7"></div>
                    <div className="p-col-5">
                      <div className="p-grid">
                        <div className="p-col-6"></div>
                        <div className="p-col-6">
                          <Optional
                            show={
                              HELPER.HAS_AUTHORITY(
                                "bo_view_manual_payment_request"
                              ) && currentIndex === 1
                            }
                          >
                            <button
                              disabled={false}
                              onClick={() => openModal(1, false)}
                              className="primary-button"
                            >
                              <i className="pi pi-filter" />
                              <span className="hide-btn-text"> Filter</span>
                            </button>
                          </Optional>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Optional
                  show={HELPER.HAS_AUTHORITY("bo_view_manual_payment_request")}
                >
                  {transactionsView()}
                </Optional>
                <Optional
                  show={!HELPER.HAS_AUTHORITY("bo_view_manual_payment_request")}
                >
                  <AccessDenied />
                </Optional>
              </div>
            </TabPanel>
            <TabPanel header="Bulk Uploads">
              <ManualPaymentsBulkUploads
                manualPaymentType={ManualPaymentTypes.Settlement}
              />
            </TabPanel>
          </TabView>
        </div>
      </div>
    </ErrorBoundary>
  );
}
