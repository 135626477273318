import { LOCAL_STORAGE_SERVICE } from "../../core/services/storage-service";
import { HELPER } from "../../shared/helper/helper";

export function persistLoginDataAndRedirect(
  data,
  mainContext,
  history,
  isChangePasswordAutoLogin = false
) {
  let loginRoute = HELPER.GET_USER_LOGIN_ROUTE(
    data?.cosmosResponse?.authorities
  );
  let selectedSideNav = loginRoute.split("/")[2].split("-").join(" ");
  let loginData = {
    username: data?.username,
    email: data?.email,
    access_token: data?.cosmosResponse?.accessToken,
    authorities: data?.cosmosResponse?.authorities,
    expires_in: data?.cosmosResponse?.expiresIn,
    firstName: data?.firstName,
    lastName: data?.lastName,
    roles: data?.cosmosResponse?.roles,
    isAuthenticated: true,
    name: data?.firstname + " " + data?.lastname,
    selectedSideNav: selectedSideNav,
    selectedSideNavIndex: 0,
    merchantId: data?.merchantId,
    merchantName: data?.branch,
  };
  // const role = data.roles[0];
  // loginData.selectedSideNavIndex =
  //   role === "DCIR_MERCHANT_SUPER_ADMIN" ? 0 : 1;
  LOCAL_STORAGE_SERVICE.SAVE_USER_CREDENTIAL(loginData);
  mainContext.mainDispatch({
    type: "PERSIST_LOGIN_DATA",
    loginData: loginData,
  });
  // Changing deafult password requires temporarily storing the users login data which might still have
  // the "resetPassword" property as true.
  if (data.resetPassword && !isChangePasswordAutoLogin) {
    history.push("/change-password");
  } else {
    history.push(loginRoute);
  }
}
