import "./dispute.css";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { CustomBreadcrumb } from "../../../shared/components/custom-breadcrumb/custom-breadcrumb";
import { CustomTable } from "../../../shared/components/custom-table/custom-table";
import { SERVICES } from "../../../core/services/services";
import { CustomLoader } from "../../../shared/components/custom-loader/custom-loader";
import { CustomModal } from "../../../shared/components/custom-modal/custom-modal";
import { Toast } from "primereact/toast";
import { OverlayPanel } from "primereact/overlaypanel";
import { DisputeBreakdown } from "./dispute-breakdown";
import { DisputesFilter } from "./disputes-filter";
import { HELPER } from "../../../shared/helper/helper";
import ErrorBoundary from "../../../shared/components/error-boundary/error-boundary";
import { Optional } from "../../../shared/components/optional/optional";
import { DisputeTypes } from "../../../shared/constants";
import { useBankFeature } from "../../../core/custom-hooks/use-bank-feature";

export function Disputes(props) {
  const toast = useRef(null);
  const op = useRef(null);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [error, setError] = useState("");
  const [search, setSearch] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [range] = useState(5);
  const [emptyText, setEmptyText] = useState("");
  const [mobile, setMobile] = useState(false);
  const [logCode, setLogCode] = useState(null);
  const [currentModalIndex, setCurrentModalIndex] = useState(0);
  const [disputeDetails, setDisputeDetails] = useState([]);
  const [disputes, setDisputes] = useState([]);
  const [disputeExpiringDate, setDisputeExpiringDate] = useState(null);
  const [disputeStatus, setDisputeStatus] = useState(null);
  const [loggedBy, setLoggedBy] = useState(null);
  const [pageNo, setPageNo] = useState(0);
  const [searchParams, setSearchParams] = useState(null);
  const [receipt, setReceipt] = useState(null);
  const [transactionSearchKey, setTransactionSearchKey] = useState(null);
  const [disputeAuditTrail, setDisputeAuditTrail] = useState([]);
  const isReversal = useBankFeature("refundFromOriginaltransaction");

  const tableHeaders = [
    { label: "Log code", value: "logCode" },
    { label: "Customer Name", value: "customerAccountName" },
    { label: "Amount", value: "transactionAmount" },
    { label: "Transaction Time", value: "transactionTime" },
    { label: "Log Date", value: "createdOn" },
    { label: "TAT", value: "tatExpiryDate" },
    { label: "Dispute status", value: "status" },
    { label: "Resolution status", value: "resolutionStatus" },
    { label: "Actions", value: "actions" },
  ];

  const populateDetails = useCallback((e, isReversal) => {
    // const arr = [];
    setLogCode(e?.logCode);
    setLoggedBy(e?.loggedBy);
    setDisputeStatus(e?.status);
    setTransactionSearchKey(e?.transactionSearchKey);
    setDisputeExpiringDate(e?.tatExpiryDate);
    setReceipt(e?.receiptData);
    const arr = HELPER.GET_DISPUTE_DETAILS_ARRAY(e, isReversal);
    setDisputeAuditTrail(JSON.parse(e?.statusLogInfo));
    setDisputeDetails(arr);
  }, []);

  const getDisputeDetails = useCallback(
    (e, isMobile) => {
      populateDetails(e, isReversal);
      setMobile(!!isMobile);
      setCurrentIndex(3);
    },
    [populateDetails, isReversal]
  );

  const openAction = useCallback(
    (e, action, isMobile) => {
      // eslint-disable-next-line default-case
      switch (action) {
        case "VIEW": {
          getDisputeDetails(e, isMobile);
          break;
        }
      }
    },
    [getDisputeDetails]
  );

  const getDisputes = useCallback(() => {
    setCurrentIndex(0);
    setVisible(false);
    setLoading(true);
    setDisputes([]);
    // setSearch(true);
    let params = {
      page: pageNo,
      size: 10,
    };
    params = HELPER.TO_URL_STRING(params);
    const DISPUTE_SERVICE =
      props.disputeType === DisputeTypes.Backoffice
        ? SERVICES.GET_DISPUTE
        : SERVICES.GET_BACKOFFICE_SUPERAGENT_DISPUTES;
    DISPUTE_SERVICE(params)
      .then((data) => {
        const result = data.result.content;
        if (!result.length) {
          setEmptyText("No disputes found...");
        } else {
          let arr = [];
          setTotalItems(data?.result?.totalElements);
          setTotalPages(data?.result?.totalPages);
          result.forEach((e) => {
            arr.push({
              ...e,
              actions: "CR",
              detailsFunction: openAction,
            });
          });
          setDisputes(arr);
        }
        setError(null);
        setCurrentIndex(1);
        setLoading(false);
      })
      .catch((error) => {
        setError(HELPER.PROCESS_ERROR(error));
        setCurrentIndex(1);
        setLoading(false);
      });
  }, [pageNo, openAction, props.disputeType]);

  const searchDisputes = useCallback(
    (searchParams, isReload = false) => {
      setCurrentIndex(0);
      setVisible(false);
      setLoading(true);
      setDisputes([]);
      setSearch(true);
      // let payload;
      const params = HELPER.TO_URL_STRING({
        page: pageNo,
        size: 10,
      });
      const FILTER_SERVICE =
        props.disputeType === DisputeTypes.Backoffice ||
        props.disputeType === DisputeTypes.BackofficeFailedResponseDisputes
          ? SERVICES.GET_FILTERED_DISPUTES
          : SERVICES.GET_FILTERED_SUPERAGENT_DISPUTES;
      FILTER_SERVICE(searchParams, params)
        .then((data) => {
          const result = data.result.content;
          if (!result.length) {
            setEmptyText("No disputes found ...");
          } else {
            let arr = [];
            setTotalItems(data?.result?.totalElements);
            setTotalPages(data?.result?.totalPages);
            result.forEach((e) => {
              arr.push({
                ...e,
                actions: "CR",
                detailsFunction: openAction,
              });
            });
            setDisputes(arr);
          }
          setError(null);
          setCurrentIndex(1);
          setLoading(false);
        })
        .catch((error) => {
          setError(HELPER.PROCESS_ERROR(error));
          setCurrentIndex(1);
          setLoading(false);
        });
    },
    [pageNo, openAction, props.disputeType]
  );

  useEffect(() => {
    // if (mounted && pageNo)
    if (props.disputeType === DisputeTypes.BackofficeFailedResponseDisputes) {
      if (searchParams) {
        searchDisputes(searchParams);
      } else {
        searchDisputes({
          responseCode: props.failedResponseDisputeCodes,
          status: "PENDING,SYSTEM_ACCEPTED",
        });
      }
    } else {
      if (searchParams) {
        searchDisputes(searchParams);
      } else {
        getDisputes();
      }
    }
  }, [
    searchParams,
    searchDisputes,
    getDisputes,
    props.disputeType,
    props.failedResponseDisputeCodes,
  ]);

  function goToPage(pageNo) {
    setPageNo(pageNo);
  }

  function openModal(index, isMobile) {
    setCurrentModalIndex(index);
    if (isMobile) {
      setCurrentIndex(2);
    } else {
      setVisible(true);
    }
  }

  function reset() {
    setPageNo(0);
    setSearchParams(null);
    setLoading(true);
    setSearch(false);
    setCurrentIndex(0);
    if (props.disputeTpe === DisputeTypes.BackofficeFailedResponseDisputes) {
      searchDisputes({
        responseCode: props.failedResponseDisputeCodes,
        status: "PENDING,SYSTEM_ACCEPTED",
      });
    } else {
      getDisputes();
    }
  }

  function reload() {
    setLoading(true);
    setCurrentIndex(0);
    if (props.disputeTpe === DisputeTypes.BackofficeFailedResponseDisputes) {
      if (searchParams) {
        searchDisputes(searchParams);
      } else {
        searchDisputes({
          responseCode: props.failedResponseDisputeCodes,
          status: "PENDING,SYSTEM_ACCEPTED",
        });
      }
    } else {
      if (searchParams) {
        searchDisputes(searchParams);
      } else {
        getDisputes();
      }
    }
  }

  function closeModal(isReload) {
    if (visible) {
      setVisible(false);
    }
    if (currentIndex === 2) {
      setCurrentIndex(1);
    }

    if (isReload) {
      reload();
    }
  }

  function saveSearchParams(searchParams) {
    setPageNo(0);
    setSearchParams(searchParams);
  }

  const mobileModal = () => {
    // eslint-disable-next-line default-case
    switch (currentModalIndex) {
      case 1:
        return (
          <DisputesFilter
            searchFunction={saveSearchParams}
            closeModal={closeModal}
            disputeType={props.disputeType}
            failedResponseDisputeCodes={props.failedResponseDisputeCodes}
          />
        );
    }
  };

  const modalContent = () => {
    // eslint-disable-next-line default-case
    switch (currentModalIndex) {
      case 1:
        return (
          <DisputesFilter
            searchFunction={saveSearchParams}
            closeModal={closeModal}
            disputeType={props.disputeType}
            failedResponseDisputeCodes={props.failedResponseDisputeCodes}
          />
        );
    }
  };

  function onHide() {}

  function goBack(refresh) {
    if (refresh === true) {
      reload();
    }
    setCurrentIndex(1);
  }

  const filterView = () => {
    if (currentIndex === 1) {
      return (
        <button
          disabled={loading}
          onClick={() => openModal(1, false)}
          className="primary-button"
        >
          <i className="pi pi-filter" />
          <span className="hide-btn-text"> Filter</span>
        </button>
      );
    } else {
      return <div />;
    }
  };

  const viewMobileFilter = () => {
    if (currentIndex === 1) {
      return (
        <div className="floating-mobile-buttons add-cursor">
          <i
            onClick={(e) => op.current.toggle(e)}
            className="pi pi-ellipsis-v"
            style={{ fontSize: "1.5em", color: "#464DF2" }}
          />
          <OverlayPanel
            ref={op}
            id="overlay_panel"
            style={{ width: "100px" }}
            className="overlaypanel-demo"
          >
            <div className="p-mb-2 p-ml-1">
              <span
                onClick={() => openModal(1, true)}
                className="custom-over-flow-text"
              >
                <i className="pi pi-filter" /> Filter
              </span>
            </div>
          </OverlayPanel>
        </div>
      );
    } else {
      return <div />;
    }
  };

  const disputeView = () => {
    // eslint-disable-next-line default-case
    switch (currentIndex) {
      case 0:
        return (
          <div className="loading-container">
            <CustomLoader loadingText="Loading disputes..." />
          </div>
        );
      case 1:
        return (
          <CustomTable
            isReload={true}
            totalPages={totalPages}
            totalItems={totalItems}
            currentPage={pageNo + 1}
            range={range}
            emptyText={emptyText}
            search={search}
            reload={reset}
            error={error}
            items={disputes}
            headers={tableHeaders}
            nextPage={() => setPageNo(pageNo + 1)}
            prevPage={() => setPageNo(pageNo - 1)}
            goToFirstPage={() => setPageNo(0)}
            goToLastPage={() => setPageNo(totalPages - 1)}
            goToPage={goToPage}
          />
        );
      case 2:
        return (
          <div className="mobile-modal-container">
            <div className="custom-card">
              <div className="mobile-card-position">{mobileModal()}</div>
            </div>
          </div>
        );
      case 3:
        return (
          <DisputeBreakdown
            loggedBy={loggedBy}
            disputeExpiringDate={disputeExpiringDate}
            disputeStatus={disputeStatus}
            logCode={logCode}
            disputeDetails={disputeDetails}
            isReversal={isReversal}
            goBack={goBack}
            mobile={mobile}
            receipt={receipt}
            transactionSearchKey={transactionSearchKey}
            disputeAuditTrail={disputeAuditTrail}
          />
        );
    }
  };

  return (
    <ErrorBoundary>
      <div className="disputes">
        <Toast ref={toast} />
        <div>
          <CustomModal
            closeModal={closeModal}
            onHide={onHide}
            visible={visible}
            modalContent={modalContent}
          />
        </div>
        <Optional
          show={
            props.disputeType !== DisputeTypes.Backoffice &&
            props.disputeType !== DisputeTypes.BackofficeFailedResponseDisputes
          }
        >
          <div className="page-title p-text-left">
            {props.disputeType === DisputeTypes.Backoffice
              ? "Back Office"
              : "Super Agent"}{" "}
            Disputes
          </div>
          <div className="p-mt-2">
            <CustomBreadcrumb
              page={`Manage ${
                props.disputeType === DisputeTypes.Backoffice
                  ? "Back Office"
                  : "Super Agent"
              } Disputes`}
            />
          </div>
        </Optional>
        <div className="floating-buttons desktop-scree">
          <div className="p-grid">
            <div className="p-col-7"></div>
            <div className="p-col-5">
              <div className="p-grid">
                <div className="p-col-6"></div>
                <div className="p-col-6">{filterView()}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="dcir-hide">
          <div className="mobile-screen">
            <div className="p-grid">
              <div className="p-col-9"></div>
              <div className="p-col-3">{viewMobileFilter()}</div>
            </div>
          </div>
        </div>
        <div>{disputeView()}</div>
      </div>
    </ErrorBoundary>
  );
}
