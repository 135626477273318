import React, { useCallback, useEffect, useState } from "react";
import { HELPER } from "../../../shared/helper/helper";
import { Icon } from "../../../shared/icons/icon";
import { CustomLoader } from "../../../shared/components/custom-loader/custom-loader";
import { CustomTable } from "../../../shared/components/custom-table/custom-table";
import { TabPanel, TabView } from "primereact/tabview";
import { useWindowSize } from "../../../core/custom-hooks/use-window-size";
import { CustomModal } from "../../../shared/components/custom-modal/custom-modal";
import { BulkDisputeCreationResultFilter } from "./creation-result-filter";
import { DisputeCreationResultDownload } from "./dispute-creation-result-download";
import { FRONTOFFICE_SERVICE } from "../../../core/services/frontoffice-service";

export function BulkDisputeCreationResult(props) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [bulkTransactions, setBulkTransactions] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [emptyText, setEmptyText] = useState("No transactions found...");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const windowSize = useWindowSize();
  const [visible, setVisible] = useState(false);
  const [searchParams, setSearchParams] = useState(null);
  const [currentModalIndex, setCurrentModalIndex] = useState(0);
  const [bulkUploadSummary, setBulkUploadSummary] = useState({
    failed: 0,
    pending: 0,
    successful: 0,
  });

  const tableHeaders = [
    { label: "Log Code", value: "logCode" },
    { label: "Created At", value: "creationTime" },
    { label: "Resolution Status", value: "resolutionStatus" },
    { label: "Creation Status", value: "creationStatus" },
    { label: "Description", value: "creationMessage" },
  ];

  const getTransactionDetails = useCallback((e, isMobile) => {
    let arr = [];
    // setTransactionDetails([]);
    arr.push({
      label: "Transaction amount",
      value: e?.amount,
      itemCase: "transactionAmount",
    });
    arr.push({
      label: "Transaction time",
      value: e?.transactionTime,
      itemCase: "transactionTime",
    });
    arr.push({
      label: "Dispute creation status",
      value: e?.disputeCreationStatus,
      itemCase: "disputeCreationStatus",
    });
    arr.push({
      label: "Dispute creation message",
      value: e?.creationMessage,
    });
    arr.push({
      label: "Creation attempts",
      value: e?.creationAttempts,
    });
    arr.push({ label: "Masked pan", value: e?.maskedPan });
    arr.push({ label: "Rrn", value: e?.rrn });
    arr.push({ label: "Stan", value: e?.stan });
    arr.push({ label: "Terminal id", value: e?.terminalId });

    // setTransactionDetails(arr);
    setCurrentIndex(2);
  }, []);

  const openAction = useCallback(
    (e, action, isMobile) => {
      // eslint-disable-next-line default-case
      switch (action) {
        case "VIEW": {
          getTransactionDetails(e, isMobile);
          break;
        }
      }
    },
    [getTransactionDetails]
  );

  const getDisputeCreationResult = useCallback(
    (searchParams = {}) => {
      setCurrentIndex(0);
      setBulkTransactions([]);
      const params = HELPER.TO_URL_STRING({
        page: pageNo,
        size: 10,
        batchId: props.batchId,
        ...searchParams,
      });
      FRONTOFFICE_SERVICE.GET_BULK_DISPUTE_RESPONSE_TRANSACTIONS(params)
        .then((data) => {
          const result = data?.result?.content;
          if (!result.length) {
            setEmptyText("No transactions found ...");
          } else {
            let arr = [];
            setTotalItems(data?.result.totalElements);
            setTotalPages(data?.result.totalPages);
            result.forEach((e) => {
              arr.push({
                ...e,
                actions: "CR",
                detailsFunction: openAction,
              });
            });
            setBulkTransactions(arr);
          }
          setError(null);
          setCurrentIndex(1);
          setLoading(false);
        })
        .catch((error) => {
          setError(HELPER.PROCESS_ERROR(error));
          setLoading(false);
          setCurrentIndex(1);
        });
    },
    [pageNo, openAction, props.batchId]
  );

  useEffect(() => {
    if (searchParams) {
      getDisputeCreationResult(searchParams);
    } else {
      getDisputeCreationResult();
    }
  }, [getDisputeCreationResult, searchParams]);

  useEffect(() => {
    function getUploadSummary() {
      FRONTOFFICE_SERVICE.GET_BULK_DISPUTE_RESPONSE_UPLOAD_SUMMARY(
        props.batchId
      )
        .then((data) => {
          const result = data?.result;
          const summary = {
            pending: result?.pending,
            successful: result?.success,
            failed: result?.failed,
          };
          setBulkUploadSummary(summary);
        })
        .catch((error) => {
          // setError(HELPER.PROCESS_ERROR(error));
          console.log("error", error);
        });
    }

    getUploadSummary();
  }, [props.batchId]);

  function reload() {
    setSearchParams(null);
    setPageNo(0);
    setLoading(true);
    getDisputeCreationResult(null, true);
  }

  function isTabMobile() {
    return windowSize.width <= 948;
  }

  function openModal(index) {
    setCurrentModalIndex(index);
    setVisible(true);
  }

  function closeModal() {
    setVisible(false);
  }

  function saveSearchParams(params) {
    setSearchParams(params);
    closeModal();
  }

  function onHide() {}

  const modalContent = () => {
    switch (currentModalIndex) {
      case 0:
        return (
          <BulkDisputeCreationResultFilter
            searchFunction={saveSearchParams}
            closeModal={closeModal}
            batchId={props.batchId}
          />
        );
      case 1:
        return (
          <DisputeCreationResultDownload
            closeModal={closeModal}
            batchId={props.batchId}
          />
        );
      default:
        return;
    }
  };

  const transactionsView = () => {
    switch (currentIndex) {
      case 0:
        return (
          <div className="loading-container">
            <CustomLoader loadingText="Loading transactions..." />
          </div>
        );
      case 1:
        return (
          <CustomTable
            isReload={true}
            totalPages={totalPages}
            totalItems={totalItems}
            currentPage={pageNo + 1}
            emptyText={emptyText}
            search={true}
            reload={reload}
            error={error}
            items={bulkTransactions}
            headers={tableHeaders}
            nextPage={() => setPageNo(pageNo + 1)}
            prevPage={() => setPageNo(pageNo - 1)}
            goToFirstPage={() => setPageNo(0)}
            goToLastPage={() => setPageNo(totalPages - 1)}
            goToPage={(no) => setPageNo(no)}
          />
        );
      case 2:
        return (
          // <BulkTransactionBreakDown
          //   goBack={goBack}
          //   isMobile={props.isMobile}
          //   details={transactionDetails}
          // />
          <div></div>
        );
      default:
        return;
    }
  };

  const disputeCreationResultView = () => {
    return (
      <div
        style={{
          backgroundColor: "#ffffff",
          paddingTop: "1em",
          paddingBottom: currentIndex === 0 ? "8rem" : "2rem",
        }}
        className="p-shadow-1"
      >
        <div className="p-text-left p-ml-3 p-mt-2 p-pb-3">
          <span onClick={props?.goBack} className="add-cursor">
            <span>
              <Icon icon="go-back-icon" />
            </span>
          </span>
        </div>
        <TabView>
          <TabPanel header={isTabMobile() ? "Transactions" : "Transactions"}>
            <div className="">
              <div className="p-grid">
                <div className="p-col-12">
                  <div>
                    <div className="p-text-left">
                      <p
                        className={
                          isTabMobile()
                            ? "dcir-show custom-title p-ml-4"
                            : "dcir-hide"
                        }
                      >
                        Transaction Details
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        marginBottom: "2rem",
                      }}
                    >
                      {bulkTransactions.length > 0 && (
                        <button
                          onClick={() => openModal(1)}
                          className="download-button"
                          style={{ fontSize: "0.75rem", marginRight: "1rem" }}
                        >
                          <i className="pi pi-download"></i> Download
                          Transactions
                        </button>
                      )}
                      <button
                        disabled={loading}
                        onClick={() => openModal(0)}
                        className="primary-button"
                        style={{
                          width: "135px",
                          fontSize: "0.75rem",
                        }}
                      >
                        <i className="pi pi-filter"></i> Filter
                      </button>
                    </div>
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      <div>
                        <p>Successful</p>
                        <div
                          className="custom-badge custom-badge-text custom-badge-success"
                          style={{ width: "3rem", margin: "0 auto" }}
                        >
                          {bulkUploadSummary.successful}
                        </div>
                      </div>
                      <div className="p-ml-4">
                        <p>Failed</p>
                        <div
                          className="custom-badge custom-badge-text custom-badge-error"
                          style={{ width: "3rem", margin: "0 auto" }}
                        >
                          {bulkUploadSummary.failed}
                        </div>
                      </div>
                      <div className="p-ml-4">
                        <p>Pending</p>
                        <div
                          className="custom-badge custom-badge-text custom-badge-pending"
                          style={{ width: "3rem", margin: "0 auto" }}
                        >
                          {bulkUploadSummary.pending}
                        </div>
                      </div>
                    </div>
                    {transactionsView()}
                  </div>
                </div>

                <div className="p-col-12">
                  <div style={{ marginTop: "-2.2em" }}>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
        </TabView>
      </div>
    );
  };
  return (
    <div className="dispute-response">
      <div>
        <CustomModal
          closeModal={closeModal}
          onHide={onHide}
          visible={visible}
          modalContent={modalContent}
        />
      </div>
      <div>{disputeCreationResultView()}</div>
    </div>
  );
}
