import { useEffect, useRef, useState } from "react";
import { CustomLoader } from "../../../shared/components/custom-loader/custom-loader";
import { FormInput } from "../../../shared/components/form-component/form-input";
import { CUSTOM_VALIDATION } from "../../../shared/validation/validation";
import { SERVICES } from "../../../core/services/services";
import { HELPER } from "../../../shared/helper/helper";
import { Toast } from "primereact/toast";
import { CustomToast } from "../../../shared/components/alert/custom-toast";
import { FormDropdown } from "../../../shared/components/form-component/form-dropdown";
import { CustomMessage } from "../../../shared/components/alert/custom-message";

export function EditConfiguration(props) {
  const toast = useRef(null);
  const [loading, setLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isInValidConfig, setIsInValidConfig] = useState(false);
  const [errorTitle, setErrorTitle] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [config, setConfig] = useState({
    configName: "",
    configDesc: "",
    key: "",
    configValue: "",
    valueType: "",
  });
  const [configFormError, setConfigFormError] = useState({
    configName: null,
    configDesc: null,
    key: null,
    configValue: null,
  });

  const configBoolValues = [
    { name: "True", value: true },
    { name: "False", value: false },
  ];

  useEffect(() => {
    let config = {
      ...props.config,
    };
    if (props.config.valueType === "DATETIME") {
      // Convert from api format to format understandable by input type datetime-local
      config.configValue = config.configValue.split(" ").join("T");
      setConfig(config);
    } else if (props.config.valueType === "BOOLEAN") {
      config.configValue = config.configValue === "true" ? true : false;
      setConfig(config);
    } else {
      setConfig(props.config);
    }
  }, [props.config]);

  function validateForm(e, name, type, refineName, required) {
    let value = e?.target?.value;
    const isEmpty = CUSTOM_VALIDATION.IS_EMPTY(value);
    const isValidInput = !isEmpty
      ? CUSTOM_VALIDATION.BASIC_VALIDATION(value, type)
      : false;
    if (isValidInput) {
      setIsInValidConfig(false);
      setConfig({
        ...config,
        [name]: value,
      });
      setConfigFormError({ ...configFormError, [name]: null });
    } else {
      let errorMessage =
        required && isEmpty ? `${refineName} is required` : null;
      if (!isValidInput) {
        errorMessage = `${refineName} is invalid`;
      }
      setIsInValidConfig(true);
      setConfigFormError({ ...configFormError, [name]: errorMessage });
    }
  }

  function updateConfig() {
    let payload = {
      ...config,
    };
    if (config.valueType === "DATETIME") {
      // format datetime value to match api requirement
      payload.configValue = payload.configValue.split("T").join(" ");
    }
    setLoading(true);
    SERVICES.UPDATE_CONFIGURATION(payload, props.configKey)
      .then((response) => {
        setErrorTitle(null);
        // props.closeModal(true);
        setCurrentIndex(1);
      })
      .catch((error) => {
        // console.log(error);
        setErrorTitle("Error");
        setErrorMessage(HELPER.PROCESS_ERROR(error));
        setLoading(false);
      });
  }

  function cancelModal() {
    props.closeModal();
  }

  const cancelButton = () => {
    if (!loading) {
      return (
        <button onClick={cancelModal} className="secondary-button">
          Cancel
        </button>
      );
    } else {
      return <div />;
    }
  };

  const submitButton = () => {
    if (!loading) {
      return (
        <button
          onClick={updateConfig}
          disabled={isInValidConfig}
          className="primary-button"
        >
          Update
        </button>
      );
    } else {
      return (
        <div className="pull-up-element-2">
          <CustomLoader loadingText="Submitting..." />
        </div>
      );
    }
  };

  function validateDropdown(e, name) {
    const value = e.target.value;
    setConfigFormError({ ...configFormError, [name]: "" });
    setConfig({ ...config, [name]: value });
  }

  function getInputValidationType() {
    let value = props?.config?.valueType.toLowerCase();
    switch (value) {
      case "string":
        return "INPUT";
      case "double":
        return "NUMBER";
      case "integer":
        return "NUMBER";
      case "datetime":
        return "INPUT";
      case "date":
        return "INPUT";
      default:
        return "INPUT";
    }
  }

  function getInputType() {
    let value = props?.config?.valueType.toLowerCase();
    switch (value) {
      case "date":
        return "date";
      case "datetime":
        return "datetime-local";
      default:
        return "text";
    }
  }

  const configFormView = () => {
    if (currentIndex) {
      return (
        <div>
          <div>
            <CustomMessage messageType="success" />
          </div>
          <div>
            <p className="success-message-text">
              Configuration updated successfully!
            </p>
          </div>
          <div className="success-message-btn-container p-text-center">
            <p
              onClick={() => {
                props.closeModal(true);
              }}
              className="close-modal add-cursor"
            >
              Close
            </p>
          </div>
        </div>
      );
    } else {
      return <div>{configForm()}</div>;
    }
  };

  const configForm = () => {
    return (
      <div>
        <div className="custom-modal-title p-text-left">
          Update Configuration
        </div>
        <div className="custom-dialog-subtitle-container p-mb-5">
          <p className="custom-dialog-subtitle">* Required Fields</p>
        </div>
        <div>
          {errorTitle && (
            <CustomToast
              title={errorTitle}
              description={errorMessage}
              type="error"
            />
          )}
        </div>
        <div className="p-grid">
          <div className="p-col-12">
            <FormInput
              value={config["configName"]}
              required={true}
              field="configName"
              type="INPUT"
              error={configFormError["configName"]}
              fn={validateForm}
              loading={true}
              placeholder="Config Name"
            />
          </div>
          <div className="p-col-12">
            <FormInput
              value={config["configDesc"]}
              required={true}
              field="configDesc"
              type="INPUT"
              error={configFormError["configDesc"]}
              fn={validateForm}
              loading={true}
              placeholder="Config description"
            />
          </div>
          <div className="p-col-12">
            <FormInput
              value={config["key"]}
              required={true}
              field="key"
              type="INPUT"
              error={configFormError["key"]}
              fn={validateForm}
              loading={true}
              placeholder="Config key"
            />
          </div>
          <div className="p-col-12">
            {config.valueType === "BOOLEAN" && <label>Value</label>}
            {config.valueType === "BOOLEAN" ? (
              <FormDropdown
                required={true}
                label="name"
                field="configValue"
                error={configFormError["configValue"]}
                disabled={loading}
                value={config["configValue"]}
                fn={validateDropdown}
                options={configBoolValues}
                placeholder="Value"
              />
            ) : (
              <FormInput
                inputType={getInputType()}
                value={config["configValue"]}
                required={true}
                field="configValue"
                type={getInputValidationType()}
                error={configFormError["configValue"]}
                fn={validateForm}
                loading={loading}
                placeholder="Config Value"
                noMax={true}
              />
            )}
          </div>

          <div className="p-col-12">
            <div className="p-mt-5">
              <div className="p-grid">
                <div className={loading ? "p-col-12" : "p-col-6"}>
                  {cancelButton()}
                </div>
                <div className={loading ? "p-col-12" : "p-col-6"}>
                  {submitButton()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div>
        <Toast ref={toast} />
      </div>
      <div className="p-pb-2">{configFormView()}</div>
    </div>
  );
}
