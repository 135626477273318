import axios from "axios";
import { CONFIG } from "../../shared/config/config";
import { GET_BASIC_HEADER } from "./api-header";
import { FRONTOFFICE_API } from "../apis/frontoffice-apis";

const Axios = axios.create({
  baseURL: CONFIG.DCIR_HOST,
});

function getBulkDisputeResponses(params) {
  const url = `${FRONTOFFICE_API.GET_BULK_DISPUTE_RESPONSES}?${params}`;
  const header = GET_BASIC_HEADER();
  return new Promise((resolve, reject) => {
    Axios.get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function uploadBulkDisputeResponse(payload) {
  const url = FRONTOFFICE_API.UPLOAD_BULK_DISPUTE_RESPONSE;
  const header = GET_BASIC_HEADER();
  return new Promise((resolve, reject) => {
    Axios.post(url, payload, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getBulkDisputeResponseTransactions(params) {
  const url = `${FRONTOFFICE_API.GET_BULK_DISPUTE_RESPONSE_TRANSACTIONS}?${params}`;
  const header = GET_BASIC_HEADER();
  return new Promise((resolve, reject) => {
    Axios.get(url, {
      headers: header,
    })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getBulkDisputeResponseUploadSummary(batchId) {
  const url = `${FRONTOFFICE_API.GET_BULK_DISPUTE_RESPONSE_UPLOAD_SUMMARY}/${batchId}`;
  const header = GET_BASIC_HEADER();
  return new Promise((resolve, reject) => {
    Axios.get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function downloadBulkDisputeResponseTransactions(batchId) {
  const url = `${FRONTOFFICE_API.DOWNLOAD_BULK_DISPUTE_RESPONSE_TRANSACTIONS}?${batchId}`;
  const header = GET_BASIC_HEADER();
  return new Promise((resolve, reject) => {
    Axios.get(url, { headers: header, responseType: "blob" })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

export const FRONTOFFICE_SERVICE = {
  GET_BULK_DISPUTE_RESPONSES: getBulkDisputeResponses,
  UPLOAD_BULK_DISPUTE_RESPONSE: uploadBulkDisputeResponse,
  GET_BULK_DISPUTE_RESPONSE_TRANSACTIONS: getBulkDisputeResponseTransactions,
  GET_BULK_DISPUTE_RESPONSE_UPLOAD_SUMMARY: getBulkDisputeResponseUploadSummary,
  DOWNLOAD_BULK_DISPUTE_RESPONSE_TRANSACTIONS:
    downloadBulkDisputeResponseTransactions,
};
