import { useEffect, useState } from "react";
import { BACKOFFICE_CONFIGS } from "../../shared/constants";
import { SERVICES } from "../services/services";

export function useGlNameEnquiryOption() {
  const [glNameEnquiryOption, setGlNameEnquiryOption] = useState(null);
  useEffect(() => {
    function getGlNameEnquiryOption() {
      SERVICES.GET_SINGLE_CONFIGURATION(
        BACKOFFICE_CONFIGS.GL_NAME_ENQUIRY_OPTION
      )
        .then((response) => {
          const nameEnquiry = response?.result?.configValue;
          setGlNameEnquiryOption(nameEnquiry);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    getGlNameEnquiryOption();
  }, []);
  return glNameEnquiryOption;
}
