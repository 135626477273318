import { useEffect, useState } from "react";
import { LOCAL_STORAGE_SERVICE } from "../../../core/services/storage-service";
import { HELPER } from "../../helper/helper";
import { Skeleton } from "primereact/skeleton";
import "./dashboard.css";

export function MetricsSummaryCards({
  overallMetrics,
  userType,
  currentIndex,
  selectedDateRange,
  currentTime,
  disputeMetricsBreakdown,
  loading,
  selectedReport,
  reload,
}) {
  const [merchantName, setMerchantName] = useState("");
  useEffect(() => {
    setMerchantName(LOCAL_STORAGE_SERVICE.GET_MERCHANT_NAME());
    // getHistoricTransactionAnalytics();
  }, []);

  function getPercentage(value) {
    let totalValue = overallMetrics?.totalCount;
    const percentage = (value / totalValue) * 100;
    return `${percentage ? `(${percentage.toFixed(2)}%)` : ""}`;
  }

  return (
    <div>
      {selectedDateRange?.code === "today" && (
        <div
          onClick={() => reload()}
          className="p-text-right custom-table-refresh dashboard"
        >
          <span>
            <i className="pi pi-refresh p-px-1" />
            <span className="p-px-1">Refresh</span>
          </span>
        </div>
      )}
      <div className="general-metrics">
        <div className="metric-card">
          {loading ? (
            <div className="p-my-3">
              <Skeleton />
              <Skeleton className="p-mt-3" />
              <Skeleton className="p-mt-3" />
            </div>
          ) : (
            <div>
              <p>
                {currentIndex === 0 ? "Total Transactions" : "Logged Disputes"}{" "}
                {selectedDateRange?.code === "today" && (
                  <span>as at {currentTime}</span>
                )}
              </p>
              <span style={{ fontSize: "0.8rem" }} className="p-text-primary">
                Value:
              </span>{" "}
              <h1 className="p-mt-0">
                {HELPER.TO_CURRENCY_FORMAT(overallMetrics?.totalAmount)}
              </h1>{" "}
              <span style={{ fontSize: "0.8rem" }} className="p-text-primary">
                Count:
              </span>{" "}
              <h3 className="p-mt-0">
                {HELPER.TO_NUMBER_FORMAT(overallMetrics?.totalCount)}
              </h3>
              {userType === "front-office" &&
                selectedReport?.code === "disputes" && (
                  <div>
                    <hr />
                    <div className="p-grid">
                      <div className="p-col-6">
                        <p>By {merchantName}</p>
                        <p>
                          {HELPER.TO_CURRENCY_FORMAT(
                            disputeMetricsBreakdown?.agentTotalValue
                          )}
                        </p>
                        <p>{disputeMetricsBreakdown?.agentTotalCount}</p>
                      </div>
                      <div className="p-col-6">
                        <p>By Bank</p>
                        <p>
                          {HELPER.TO_CURRENCY_FORMAT(
                            disputeMetricsBreakdown?.backofficeTotalValue
                          )}
                        </p>
                        <p>{disputeMetricsBreakdown?.backofficeTotalCount}</p>
                      </div>
                    </div>
                  </div>
                )}
            </div>
          )}
        </div>

        <div className="metric-card">
          {loading ? (
            <div className="p-my-3">
              <Skeleton />
              <Skeleton className="p-mt-3" />
              <Skeleton className="p-mt-3" />
            </div>
          ) : (
            <div>
              <p>
                {currentIndex === 0
                  ? "Successful Transactions"
                  : "Accepted Disputes"}{" "}
                {selectedDateRange?.code === "today" && (
                  <span>as at {currentTime}</span>
                )}
              </p>
              <span style={{ fontSize: "0.8rem" }} className="p-text-primary">
                Value:
              </span>{" "}
              <h1 className="p-mt-0">
                {HELPER.TO_CURRENCY_FORMAT(overallMetrics?.successfulAmount)}
              </h1>
              <span style={{ fontSize: "0.8rem" }} className="p-text-primary">
                Count:
              </span>{" "}
              <h3 className="p-mt-0">
                {HELPER.TO_NUMBER_FORMAT(overallMetrics?.successfulCount)}{" "}
                {getPercentage(overallMetrics?.successfulCount)}
              </h3>
              {userType === "front-office" &&
                selectedReport?.code === "disputes" && (
                  <div>
                    <hr />
                    <div className="p-grid">
                      <div className="p-col-6">
                        <p>By {merchantName}</p>
                        <p>
                          {HELPER.TO_CURRENCY_FORMAT(
                            disputeMetricsBreakdown?.agentAcceptedValue
                          )}
                        </p>
                        <p>{disputeMetricsBreakdown?.agentAcceptedCount}</p>
                      </div>
                      <div className="p-col-6">
                        <p>By Bank</p>
                        <p>
                          {HELPER.TO_CURRENCY_FORMAT(
                            disputeMetricsBreakdown?.backofficeAcceptedValue
                          )}
                        </p>
                        <p>
                          {disputeMetricsBreakdown?.backofficeAcceptedCount}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
            </div>
          )}
        </div>
        <div className="metric-card">
          {loading ? (
            <div className="p-my-3">
              <Skeleton />
              <Skeleton className="p-mt-3" />
              <Skeleton className="p-mt-3" />
            </div>
          ) : (
            <div>
              <p>
                {currentIndex === 0
                  ? "Failed Transactions"
                  : "Rejected Disputes"}{" "}
                {selectedDateRange?.code === "today" && (
                  <span>as at {currentTime}</span>
                )}
              </p>
              <span style={{ fontSize: "0.8rem" }} className="p-text-primary">
                Value:
              </span>{" "}
              <h1 className="p-mt-0">
                {HELPER.TO_CURRENCY_FORMAT(overallMetrics?.failedAmount)}
              </h1>
              <span style={{ fontSize: "0.8rem" }} className="p-text-primary">
                Count:
              </span>{" "}
              <h3 className="p-mt-0">
                {HELPER.TO_NUMBER_FORMAT(overallMetrics?.failedCount)}{" "}
                {getPercentage(overallMetrics?.failedCount)}
              </h3>
              {userType === "front-office" &&
                selectedReport?.code === "disputes" && (
                  <div>
                    <hr />
                    <div className="p-grid">
                      <div className="p-col-6">
                        <p>By {merchantName}</p>
                        <p>
                          {HELPER.TO_CURRENCY_FORMAT(
                            disputeMetricsBreakdown?.agentRejectedValue
                          )}
                        </p>
                        <p>{disputeMetricsBreakdown?.agentRejectedCount}</p>
                      </div>
                      <div className="p-col-6">
                        <p>By Bank</p>
                        <p>
                          {HELPER.TO_CURRENCY_FORMAT(
                            disputeMetricsBreakdown?.backofficeRejectedValue
                          )}
                        </p>
                        <p>
                          {disputeMetricsBreakdown?.backofficeRejectedCount}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
