import React, { useCallback, useEffect, useRef, useState } from "react";
import { CustomBreadcrumb } from "../../../shared/components/custom-breadcrumb/custom-breadcrumb";
import { CustomTable } from "../../../shared/components/custom-table/custom-table";
import { SERVICES } from "../../../core/services/services";
import "./public-holidays.css";
import { CustomLoader } from "../../../shared/components/custom-loader/custom-loader";
import { CustomModal } from "../../../shared/components/custom-modal/custom-modal";
import { DetailsBreakDown } from "../../../shared/components/details-break-down/details-break-down";
import { CustomConfirmDialog } from "../../../shared/components/custom-confirm-dialog/custom-confirm-dialog";
import { Toast } from "primereact/toast";
import { PublicHolidayForm } from "./public-holiday-form";
import { HolidaysFilter } from "./holidays-filter";
import { HELPER } from "../../../shared/helper/helper";
import moment from "moment";
import ErrorBoundary from "../../../shared/components/error-boundary/error-boundary";

export function PublicHolidays() {
  const toast = useRef(null);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [error, setError] = useState("");
  const [search, setSearch] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [confirmText, setConfirmText] = useState("");
  const [itemIdForDelete, setItemIdForDelete] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [range] = useState(5);
  const [breakDownTitle, setBreakDownTitle] = useState("");
  const [publicHolidayForEdit, setPublicHolidayForEdit] = useState(null);
  const [pageNo, setPageNo] = useState(0);

  const [emptyText, setEmptyText] = useState("");
  const [currentModalIndex, setCurrentModalIndex] = useState(0);
  const [toastError, setToastError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const tableHeaders = [
    { label: "Name", value: "name" },
    { label: "Description", value: "description" },
    { label: "Code", value: "code" },
    { label: "Date", value: "theDate" },
    { label: "Actions", value: "actions" },
  ];

  const participantAuthorities = [
    { label: "UPDATE", value: "bo_dcir_manage_public_holidays" },
    { label: "DELETE", value: "bo_dcir_manage_public_holidays" },
  ];

  const [details, setDetails] = useState([]);
  const [publicHolidays, setPublicHolidays] = useState([]);
  const [searchParams, setSearchParams] = useState(null);

  function deletePublicHoliday(e) {
    setModalLoading(true);
    SERVICES.DELETE_PUBLIC_HOLIDAY(e)
      .then((data) => {
        setSuccessMessage("Public holiday deleted successfully");
        setModalLoading(false);
      })
      .catch((error) => {
        setModalLoading(false);
        setToastError(HELPER.PROCESS_ERROR(error));
      });
  }

  const openModal = useCallback((index, isMobile) => {
    setCurrentModalIndex(index);
    if (isMobile) {
      setCurrentIndex(2);
    } else {
      setVisible(true);
    }
  }, []);

  const viewPublicHoliday = useCallback(
    (publicHoliday, isMobile) => {
      // console.log("viewing");
      // console.log(publicHoliday);
      let arr = [];
      setDetails([]);
      arr.push({ label: "Name", value: publicHoliday?.name });
      arr.push({
        label: "Description",
        value: publicHoliday?.description,
      });
      arr.push({ label: "Code", value: publicHoliday?.code });
      arr.push({
        label: "Recurring",
        value: publicHoliday?.recurring ? "Yes" : "No",
      });
      arr.push({
        label: "Date",
        value: publicHoliday?.theDate,
        itemCase: "theDate",
      });

      setDetails(arr);
      setBreakDownTitle("Public Holiday");
      openModal(2, isMobile);
    },
    [openModal]
  );

  const openAction = useCallback(
    (e, action, isMobile) => {
      // eslint-disable-next-line default-case
      switch (action) {
        case "VIEW": {
          viewPublicHoliday(e, isMobile);
          break;
        }
        case "DELETE":
          setItemIdForDelete(e?.code); //remember to change to chargeCode
          setConfirmText(`${e?.name?.toString()?.toLowerCase()}`);
          openModal(3, isMobile);
          break;
        case "UPDATE":
          let joinedDate = moment(e?.theDate, "YYYY MM DD HH mm ss").format(
            "YYYY-MM-DD"
          );
          const publicHolidayModel = {
            name: e?.name,
            recurring: e?.recurring,
            description: e?.description,
            date: joinedDate,
            code: e?.code,
          };
          setPublicHolidayForEdit(publicHolidayModel);
          openModal(4, isMobile);
      }
    },
    [viewPublicHoliday, openModal]
  );

  const getPublicHolidays = useCallback(() => {
    setCurrentIndex(0);
    setPublicHolidays([]);
    let params = {
      page: pageNo,
      size: 10,
    };
    params = HELPER.TO_URL_STRING(params);
    SERVICES.GET_PUBLIC_HOLIDAYS(params)
      .then((data) => {
        const result = data?.result?.content;
        if (!result?.length) {
          setEmptyText("No public holidays found ...");
        } else {
          let arr = [];
          setTotalItems(data?.result?.totalElements);
          setTotalPages(data?.result?.totalPages);
          result?.forEach((e) => {
            arr.push({
              ...e,
              actions: "CRUD",
              detailsFunction: openAction,
            });
          });
          // console.log(arr);
          setPublicHolidays(arr);
        }
        setError(null);
        setCurrentIndex(1);
        setLoading(false);
      })
      .catch((error) => {
        setError(HELPER.PROCESS_ERROR(error));
        setCurrentIndex(1);
        setLoading(false);
      });
  }, [openAction, pageNo]);

  const searchPublicHolidays = useCallback(
    (e) => {
      setCurrentIndex(0);
      setVisible(false);
      setLoading(true);
      setSearch(true);
      setPublicHolidays([]);
      let params = {
        page: pageNo,
        size: 10,
      };
      if (e) {
        setSearchParams({ ...e });
      }
      let payload = e ? e : searchParams;
      params = HELPER.TO_URL_STRING(params);
      SERVICES.FILTER_PUBLIC_HOLIDAYS(params, payload)
        .then((data) => {
          const result = data?.result?.content;
          if (!result?.length) {
            setEmptyText("No public holidays found ...");
          } else {
            let arr = [];
            setTotalItems(data?.result?.totalElements);
            setTotalPages(data?.result?.totalPages);
            result?.forEach((e) => {
              arr.push({
                ...e,
                actions: "CRUD",
                detailsFunction: openAction,
              });
            });
            setPublicHolidays(arr);
          }
          setError(null);
          setCurrentIndex(1);
          setLoading(false);
        })
        .catch((error) => {
          setError(HELPER.PROCESS_ERROR(error));
          setCurrentIndex(1);
          setLoading(false);
        });
    },
    [pageNo, openAction, searchParams]
  );

  useEffect(() => {
    if (searchParams) {
      searchPublicHolidays();
    } else {
      getPublicHolidays();
    }
  }, [searchParams, searchPublicHolidays, getPublicHolidays]);

  function onHide() {}

  function reload() {
    setPageNo(0);
    setSearchParams(null);
    setLoading(true);
    setSearch(false);
    setCurrentIndex(0);
    getPublicHolidays();
  }

  function closeModal(isReload) {
    if (visible) {
      setVisible(false);
    }
    if (currentIndex === 2) {
      setCurrentIndex(1);
    }

    if (isReload) {
      reload();
    }
  }

  function goToPage(pageNo) {
    setPageNo(pageNo);
  }

  const mobileModal = () => {
    // eslint-disable-next-line default-case
    switch (currentModalIndex) {
      case 0:
        return <PublicHolidayForm closeModal={closeModal} />;
      case 1:
        return (
          <HolidaysFilter
            searchFunction={searchPublicHolidays}
            closeModal={closeModal}
          />
        );
      case 2:
        return (
          <DetailsBreakDown
            title={breakDownTitle}
            breakDown={details}
            closeModal={closeModal}
          />
        );
      case 3:
        return (
          <CustomConfirmDialog
            success={successMessage}
            toastError={toastError}
            itemId={itemIdForDelete}
            confirmText={confirmText}
            loading={modalLoading}
            loadingText="Deleting public holiday..."
            fn={deletePublicHoliday}
            closeModal={closeModal}
          />
        );
      case 4:
        return (
          <PublicHolidayForm
            editedPublicHoliday={publicHolidayForEdit}
            isUpdate={true}
            closeModal={closeModal}
          />
        );
    }
  };

  const publicHolidayView = () => {
    // eslint-disable-next-line default-case
    switch (currentIndex) {
      case 0:
        return (
          <div className="loading-container">
            <CustomLoader loadingText="Loading holidays..." />
          </div>
        );
      case 1:
        return (
          <CustomTable
            authorities={participantAuthorities}
            totalPages={totalPages}
            totalItems={totalItems}
            currentPage={pageNo + 1}
            range={range}
            emptyText={emptyText}
            search={search}
            isReload={true}
            reload={reload}
            error={error}
            items={publicHolidays}
            headers={tableHeaders}
            nextPage={() => setPageNo(pageNo + 1)}
            prevPage={() => setPageNo(pageNo - 1)}
            goToFirstPage={() => setPageNo(0)}
            goToLastPage={() => setPageNo(totalPages - 1)}
            goToPage={goToPage}
          />
        );
      case 2:
        return (
          <div className="mobile-modal-container">
            <div className="custom-card">
              <div className="mobile-card-position">{mobileModal()}</div>
            </div>
          </div>
        );
    }
  };

  const modalContent = () => {
    // eslint-disable-next-line default-case
    switch (currentModalIndex) {
      case 0:
        return <PublicHolidayForm closeModal={closeModal} />;
      case 1:
        return (
          <HolidaysFilter
            searchFunction={searchPublicHolidays}
            closeModal={closeModal}
          />
        );
      case 2:
        return (
          <DetailsBreakDown
            title={breakDownTitle}
            breakDown={details}
            closeModal={closeModal}
          />
        );
      case 3:
        return (
          <CustomConfirmDialog
            success={successMessage}
            toastError={toastError}
            itemId={itemIdForDelete}
            confirmText={confirmText}
            loading={modalLoading}
            loadingText="Deleting public holiday..."
            fn={deletePublicHoliday}
            closeModal={closeModal}
          />
        );
      case 4:
        return (
          <PublicHolidayForm
            editedPublicHoliday={publicHolidayForEdit}
            isUpdate={true}
            closeModal={closeModal}
          />
        );
    }
  };

  return (
    <ErrorBoundary>
      <div>
        <Toast ref={toast} />
        <div>
          <CustomModal
            closeModal={closeModal}
            onHide={onHide}
            visible={visible}
            modalContent={modalContent}
          />
        </div>
        <div className="page-title p-text-left">Public Holidays</div>
        <div className="p-mt-2">
          <CustomBreadcrumb page="Manage Public Holidays" />
        </div>
        <div className="floating-buttons desktop-scree">
          <div className="p-grid">
            <div className="p-col-7"></div>
            <div className="p-col-5">
              <div className="p-grid">
                <div className="p-col-6 p-d-flex p-jc-end p-ai-center">
                  <button
                    disabled={loading || publicHolidays?.length === 0}
                    onClick={() => openModal(1, false)}
                    className="filter-button"
                  >
                    <i className="pi pi-filter" />
                    <span className="hide-btn-text"> Filter</span>
                  </button>
                </div>
                <div className="p-col-6">
                  <div
                    className={
                      HELPER.HAS_AUTHORITY("bo_dcir_manage_public_holidays")
                        ? "dcir-show"
                        : "dcir-hide"
                    }
                  >
                    <button
                      disabled={loading}
                      onClick={() => openModal(0, false)}
                      className="primary-button hide-btn-text"
                    >
                      <i className="pi pi-plus" />
                      <span className="hide-btn-text"> New Holiday</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>{publicHolidayView()}</div>
      </div>
    </ErrorBoundary>
  );
}
