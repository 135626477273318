const cosmos = document.getElementById("cosmos-url").innerHTML?.length
  ? document.getElementById("cosmos-url").innerHTML
  : "http://cosmos.dcir.development.teamapt.com";
const dcir = document.getElementById("service-url").innerHTML?.length
  ? document.getElementById("service-url").innerHTML
  : "https://dcir-management-service.development.teamapt.com";

// : "https://dcir-staging.teamapt.com/svc";
// "https://service.dcir.development.teamapt.com";

const authUserName = "dcir-management-service";

const authPassword = "secret";

const grant_type = "password";

const authenticationDomain = document.getElementById("auth-domain").innerHTML
  ?.length
  ? document.getElementById("auth-domain").innerHTML
  : "DCIR";

const app_data = "mock";

export const CONFIG = {
  DCIR_HOST: `${dcir}/api/v1`,
  COSMOS: `${cosmos}`,
  APP_DATA: `${app_data}`,
  AUTH_USER_NAME: `${authUserName}`,
  AUTH_PASSWORD: `${authPassword}`,
  DOMAIN_ID: "MONIEPOINT",
  GRANT_TYPE: `${grant_type}`,
  AUTHENTICATION_DOMAIN: `${authenticationDomain}`,
  CLIENT_ID: "WEB",
  mock: true,
  APP_TIMEOUT: 2000,
  REDIRECT_ACCESS_TOKEN: null,
  REDIRECT_LOGIN_USER_TYPE: "1",
};
