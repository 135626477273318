import { useEffect, useRef, useState } from "react";
import { CustomLoader } from "../../../shared/components/custom-loader/custom-loader";
import { FormInput } from "../../../shared/components/form-component/form-input";
import { CUSTOM_VALIDATION } from "../../../shared/validation/validation";
import { SERVICES } from "../../../core/services/services";
import { saveAs } from "file-saver";
import { HELPER } from "../../../shared/helper/helper";
import moment from "moment";
import { Toast } from "primereact/toast";
import { FormDropdown } from "../../../shared/components/form-component/form-dropdown";
import { CustomToast } from "../../../shared/components/alert/custom-toast";
import { SHARED_SERVICES } from "../../../core/services/shared-services";
import "./transactions.css";
import { Optional } from "../../../shared/components/optional/optional";
import { BACKOFFICE_CONFIGS } from "../../../shared/constants";

export function TransactionsFilter(props) {
  const toast = useRef(null);
  const [loading, setLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  // const [showDownloadRangeError, setDownloadRangeError] = useState(false);
  const [dateRangeError, setDateRangeError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [transaction, setTransaction] = useState({
    cardAcceptorId: "",
    responseCodeState: "",
    otherResponseCode: "",
    endDate: moment().format("YYYY-MM-DD"),
    maskedPan: "",
    rrn: "",
    startDate: moment().format("YYYY-MM-DD"),
    terminalId: "",
  });
  const [transactionError, setTransactionError] = useState({
    cardAcceptorId: null,
    responseCodeState: null,
    otherResponseCode: null,
    endDate: null,
    maskedPan: null,
    rrn: null,
    startDate: null,
    terminalId: null,
  });
  const [merchants, setMerchants] = useState([]);
  const [selectedTransactionStatus, setSelectedTransactionStatus] =
    useState(null);
  const transactionStatuses = [
    { desc: "SUCCESS", code: "SUCCESS" },
    { desc: "FAILED - INCONCLUSIVE", code: "FAILED_INCONCLUSIVE" },
    { desc: "FAILED - NO DEBIT", code: "FAILED_NO_DEBIT" },
    { desc: "OTHER", code: "OTHER" },
  ];
  const [reportGenerationProgress, setReportGenerationProgress] = useState(0);
  const [reportId, setReportId] = useState(null);
  const [showReportDownloadButton, setShowReportDownloadButton] =
    useState(false);
  const [reportMessage, setReportMessage] = useState(
    "Your request is being processed. You can access the ‘Transactions Report’ page to monitor progress and download your report."
  );
  const getReportStatus = useRef(null);
  const [maxDownloadDateRange, setMaxDownloadDateRange] = useState(null);

  useEffect(() => {
    function getMerchants() {
      SERVICES.GET_ALL_MERCHANTS()
        .then((data) => {
          const result = data?.result;
          setMerchants(result);
        })
        .catch((error) => {
          toast.current.show({
            severity: "error",
            summary: "Error getting merchants!",
            detail: HELPER.PROCESS_ERROR(error, "TOAST"),
            life: 10000,
          });
        });
    }

    function getMaxDownloadDateRange() {
      SERVICES.GET_SINGLE_CONFIGURATION(
        BACKOFFICE_CONFIGS.TRANSACTIONS_DOWNLOAD_DATE_RANGE
      )
        .then((response) => {
          const range = response?.result?.configValue;
          setMaxDownloadDateRange(Number(range));
        })
        .catch((error) => {
          setMaxDownloadDateRange(7);
        });
    }
    getMaxDownloadDateRange();

    if (props.type !== "fo") {
      getMerchants();
    }
  }, [props.type]);

  function validateForm(e, name, type, refineName, required) {
    let value = e?.target?.value;
    const isEmpty = CUSTOM_VALIDATION.IS_EMPTY(value);
    const isValidInput = !isEmpty
      ? CUSTOM_VALIDATION.BASIC_VALIDATION(value, type)
      : false;
    if (isValidInput) {
      setTransaction({ ...transaction, [name]: value });
      setTransactionError({ ...transactionError, [name]: null });
    } else {
      let errorMessage =
        required && isEmpty ? `${refineName} is required` : null;
      if (!isValidInput) {
        errorMessage = `${refineName} is invalid`;
      }
      if (isEmpty && !required) {
        setTransactionError({ ...transactionError, [name]: null });
      } else {
        setTransactionError({
          ...transactionError,
          [name]: errorMessage,
        });
      }
    }
  }

  function getFilters() {
    let payload = {};

    for (const property in transaction) {
      if (transaction[property]) {
        if (property === "cardAcceptorId") {
          payload[property] = transaction[property]?.cardAcceptorId;
        } else if (property === "responseCodeState") {
          payload[property] = transaction[property]?.code;
        } else {
          payload[property] = transaction[property];
        }
      }
    }

    return payload;
  }

  function cancelModal() {
    props.closeModal();
  }

  function downloadTransactionReport() {
    setErrorMessage(null);
    setLoading(true);
    SHARED_SERVICES.DOWNLOAD_TRANSACTION_REPORT(reportId, props.type)
      .then((data) => {
        props.closeModal();
        saveAs(
          data,
          `${transaction["startDate"]}To${transaction["endDate"]}-Transactions.xlsx`
        );
        setLoading(false);
      })
      .catch((error) => {
        handleError(error);
      });
  }

  async function handleError(error) {
    const errMessage = await HELPER.PARSE_BLOB_ERROR(error);
    setErrorMessage(errMessage);
    setLoading(false);
  }

  function generateTransactionReport() {
    setErrorMessage(null);
    setLoading(true);
    let payload = getFilters();
    SHARED_SERVICES.GENERATE_TRANSACTION_REPORT(payload, props.type)
      .then((data) => {
        const generatedReportId = data?.result?.recordId;
        setReportId(generatedReportId);
        getTransactionReport(generatedReportId);
        setReportGenerationProgress(data?.result?.percentageProcessed);
        setCurrentIndex(1);
        setLoading(false);
      })
      .catch((error) => {
        // handleError(error);
        setErrorMessage(HELPER.PROCESS_ERROR(error));
        setLoading(false);
      });
  }

  useEffect(() => {
    return () => {
      clearTimeout(getReportStatus.current);
    };
  }, []);

  function getTransactionReport(reportId) {
    SHARED_SERVICES.GET_SINGLE_TRANSACTION_REPORT(reportId, props.type)
      .then((data) => {
        const reportStatus = data?.result?.reportGenerationStatus;
        if (reportStatus === "FAILED") {
          setReportMessage("An error occured while generating your report!");
          setReportGenerationProgress(0);
          return;
        }
        if (reportStatus !== "COMPLETED") {
          setReportGenerationProgress(data?.result?.percentageProcessed);
          // getReportStatus(data?.result?.recordId);
          getReportStatus.current = setTimeout(() => {
            getTransactionReport(reportId);
          }, 5000);
        }
        if (reportStatus === "COMPLETED") {
          setReportMessage("Your report is now available to be downloaded!");
          setShowReportDownloadButton(true);
        }
      })
      .catch((error) => {
        setErrorMessage(HELPER.PROCESS_ERROR(error));
        setLoading(false);
      });
  }

  function validateDropdown(e, name) {
    const value = e.target.value;
    if (name === "responseCodeState") {
      setSelectedTransactionStatus(value.code);
    }
    if (value) {
      setTransactionError({ ...transactionError, [name]: "" });
      setTransaction({ ...transaction, [name]: value });
    } else {
      let errorMessage = "Select";
      setTransactionError({ ...transactionError, [name]: errorMessage });
    }
  }

  function validateAndDownload() {
    let startDate = new Date(transaction.startDate);
    let endDate = new Date(transaction.endDate);
    let dateRange =
      Math.abs(startDate.getTime() - endDate.getTime()) / 1000 / 60 / 60 / 24;

    if (dateRange > maxDownloadDateRange - 1 || dateRange < 0) {
      // setDownloadRangeError(true);
      setTransactionError({
        ...transactionError,
        startDate: true,
        endDate: true,
      });
      setDateRangeError(
        `Date range for report generation cannot exceed ${maxDownloadDateRange} day(s). Please select a date range not longer than ${maxDownloadDateRange} day.`
      );
    } else {
      setTransactionError({
        ...transactionError,
        startDate: null,
        endDate: null,
      });
      setDateRangeError(null);
      if (
        selectedTransactionStatus === "OTHER" &&
        transaction["otherResponseCode"]?.length === 0
      ) {
        // setErrorMessage("Enter response codes");
        setTransactionError({
          ...transactionError,
          otherResponseCode: "Enter response codes",
        });
      } else {
        generateTransactionReport();
      }
    }
  }

  function validateFilters() {
    // const dateRange = getDateDifference();
    if (
      selectedTransactionStatus === "OTHER" &&
      transaction["otherResponseCode"]?.length === 0
    ) {
      setTransactionError({
        ...transactionError,
        otherResponseCode: "Enter response code(s)",
      });
      return;
    }
    props?.searchFunction(getFilters());
  }

  const cancelButton = () => {
    if (!loading) {
      return (
        <button onClick={cancelModal} className="secondary-button">
          Cancel
        </button>
      );
    }
  };

  const submitButton = () => {
    if (!loading) {
      return (
        <button onClick={validateFilters} className="primary-button">
          Filter
        </button>
      );
    } else {
      return (
        <div className="pull-up-element-2">
          <CustomLoader loadingText="Submitting..." />
        </div>
      );
    }
  };
  const reportGenerationButton = () => {
    if (!loading) {
      return (
        <button
          onClick={validateAndDownload}
          className="primary-button download-button"
        >
          Generate Report
        </button>
      );
    }
  };

  const viewAlert = () => {
    if (errorMessage) {
      return (
        <div>
          <CustomToast title="Error" description={errorMessage} type="error" />
        </div>
      );
    } else {
      return <div />;
    }
  };

  const reportDownloadButton = () => {
    if (loading) {
      return (
        <div className="pull-up-element-2" style={{ marginTop: "5rem" }}>
          <CustomLoader loadingText="Loading..." />
        </div>
      );
    } else {
      return (
        <button
          className="bare-button p-mt-3"
          onClick={downloadTransactionReport}
        >
          Download Report
          <span className="p-ml-3">
            <i className="pi pi-download"></i>
          </span>
        </button>
      );
    }
  };

  const generatedReportView = () => {
    return (
      <div>
        <div>
          <p>{reportMessage}</p>
        </div>
        {showReportDownloadButton ? (
          <div>{reportDownloadButton()}</div>
        ) : (
          <div className="">
            <p className="p-mt-5">Report generation progress</p>
            <div className="progress-bar-outer">
              <div
                className="progress-bar-inner"
                style={{ width: `${reportGenerationProgress}%` }}
              >
                <p>{reportGenerationProgress}%</p>
              </div>
            </div>
          </div>
        )}
        <div className="p-pb-1">{viewAlert()}</div>
        {!loading && (
          <div className="p-mt-5 p-mb-4">
            <button onClick={cancelModal} className="secondary-button">
              Dismiss
            </button>
          </div>
        )}
      </div>
    );
  };

  const transactionFilterView = () => {
    switch (currentIndex) {
      case 0:
        return <div>{searchTransactionsFormView()}</div>;
      case 1:
        return <div>{generatedReportView()}</div>;
      default:
        return <div></div>;
    }
  };

  const searchTransactionsFormView = () => {
    return (
      <div>
        <div className="custom-modal-title p-text-left">Filter</div>
        <div className="custom-dialog-subtitle-container p-mb-5"></div>
        <div className="p-grid">
          {props.type !== "fo" && (
            <div className="p-col-12">
              <FormDropdown
                required={true}
                label="merchantName"
                field="cardAcceptorId"
                error={transactionError["cardAcceptorId"]}
                disabled={loading}
                value={transaction["cardAcceptorId"]}
                fn={validateDropdown}
                options={merchants}
                placeholder="Select a super agent"
                filter
                filterBy="merchantName"
              />
            </div>
          )}
          {props.canDownload && (
            <div className="p-col-12">
              <FormDropdown
                required={false}
                label="desc"
                field="responseCodeState"
                error={transactionError["responseCodeState"]}
                disabled={loading}
                value={transaction["responseCodeState"]}
                fn={validateDropdown}
                options={transactionStatuses}
                placeholder="Select transaction status"
              />
            </div>
          )}
          {selectedTransactionStatus === "OTHER" && (
            <div className="p-col-12 p-text-left">
              <FormInput
                value={transaction["otherResponseCode"]}
                required={true}
                field="otherResponseCode"
                type="INPUT"
                error={transactionError["otherResponseCode"]}
                fn={validateForm}
                loading={loading}
                placeholder="Response code(s)"
              />
              <small style={{ fontSize: "0.8rem", opacity: "0.7" }}>
                You can enter multiple comma seperated response codes.
              </small>
            </div>
          )}
          <div className="p-col-12">
            <FormInput
              value={transaction["maskedPan"]}
              required={false}
              field="maskedPan"
              type="INPUT"
              error={transactionError["maskedPan"]}
              fn={validateForm}
              loading={loading}
              placeholder="Masked pan"
            />
          </div>
          <div className="p-col-12">
            <FormInput
              value={transaction["rrn"]}
              required={false}
              field="rrn"
              type="INPUT"
              error={transactionError["rrn"]}
              fn={validateForm}
              loading={loading}
              placeholder="RRN"
            />
          </div>
          <div className="p-col-12">
            <FormInput
              value={transaction["terminalId"]}
              required={false}
              field="terminalId"
              type="INPUT"
              error={transactionError["terminalId"]}
              fn={validateForm}
              loading={loading}
              placeholder="Terminal id"
            />
          </div>
          <div className="p-col-6">
            <FormInput
              inputType="date"
              value={transaction["startDate"]}
              required={false}
              field="startDate"
              type="INPUT"
              error={transactionError["startDate"]}
              fn={validateForm}
              loading={loading}
              placeholder="Start date"
            />
          </div>
          <div className="p-col-6">
            <FormInput
              inputType="date"
              value={transaction["endDate"]}
              required={false}
              field="endDate"
              type="INPUT"
              error={transactionError["endDate"]}
              fn={validateForm}
              loading={loading}
              placeholder="End date"
            />
          </div>
          <Optional show={props.canDownload}>
            <div style={{ marginTop: "-0.5rem" }}>
              <p
                className="text-small p-ml-2"
                style={{
                  color: "#5D7F8D",
                }}
              >
                Note:{" "}
                {`Date range for report generation cannot exceed ${maxDownloadDateRange} day(s).`}
              </p>
            </div>
          </Optional>
          {dateRangeError && (
            <div className="p-col-12 p-text-left">
              <p className="p-error p-my-0">{dateRangeError}</p>
            </div>
          )}
          <div className="p-col-12">
            <div className="p-pb-">{viewAlert()}</div>
          </div>
          <div className="p-col-12">
            <div className="p-mt-2">
              <div className="p-grid">
                <div
                  className={
                    loading
                      ? "p-col-12"
                      : props.canDownload
                      ? "p-col-4"
                      : "p-col-6"
                  }
                >
                  {cancelButton()}
                </div>
                {props.canDownload && (
                  <div
                    className={
                      loading
                        ? "p-col-12"
                        : props.canDownload
                        ? "p-col-4"
                        : "p-col-6"
                    }
                  >
                    {reportGenerationButton()}
                  </div>
                )}
                <div
                  className={
                    loading
                      ? "p-col-12"
                      : props.canDownload
                      ? "p-col-4"
                      : "p-col-6"
                  }
                >
                  {submitButton()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div>
        <Toast ref={toast} />
      </div>
      <div className="p-pb-2">{transactionFilterView()}</div>
    </div>
  );
}
