import moment from "moment";
const options = {
  plugins: {
    title: {
      display: true,
      text: "",
    },
  },
  responsive: true,
  maintainAspectRatio: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

function getValueInMajor(val) {
  return Number(val) / 100;
}

function getChartOptions(chartTitle) {
  const options = {
    plugins: {
      title: {
        display: true,
        text: `${chartTitle}`,
      },
    },
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };
  return options;
}

function getTransactionsChartData(labels, successData, failureData) {
  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Successsful Transactions",
        data: successData,
        backgroundColor: "rgb(75, 192, 192)",
      },
      {
        label: "Failed Transactions",
        data: failureData,
        backgroundColor: "rgb(255, 99, 132)",
      },
    ],
  };
  return chartData;
}

function getDisputesChartData(labels, successData, failureData) {
  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Accepted Disputes",
        data: successData,
        backgroundColor: "rgb(75, 192, 192)",
      },
      {
        label: "Rejected Disputes",
        data: failureData,
        backgroundColor: "rgb(255, 99, 132)",
      },
    ],
  };
  return chartData;
}

function formatBackofficeCurrentDayTransactionsData(data, valueType = "count") {
  const chartOptions = getChartOptions(
    `Transactions by ${valueType} as at ${moment().format("HH:mm")}`
  );
  const labels = Object.keys(data?.merchantMetrics);
  let metricsObject = {
    successfulTransactionsByValue: [],
    successfulTransactionsByCount: [],
    failedTransactionsByValue: [],
    failedTransactionsByCount: [],
  };
  labels.forEach((label) => {
    const metrics = data?.merchantMetrics[label];
    metricsObject.successfulTransactionsByValue.push(
      getValueInMajor(metrics?.successfulAmount)
    );
    metricsObject.successfulTransactionsByCount.push(metrics?.successfulCount);
    metricsObject.failedTransactionsByValue.push(
      getValueInMajor(metrics?.failedAmount)
    );
    metricsObject.failedTransactionsByCount.push(metrics?.failedCount);
  });
  const {
    successfulTransactionsByValue,
    successfulTransactionsByCount,
    failedTransactionsByValue,
    failedTransactionsByCount,
  } = metricsObject;
  const chartDataByCount = getTransactionsChartData(
    labels,
    successfulTransactionsByCount,
    failedTransactionsByCount
  );
  const chartDataByValue = getTransactionsChartData(
    labels,
    successfulTransactionsByValue,
    failedTransactionsByValue
  );
  return { chartOptions, chartDataByCount, chartDataByValue };
}

function formatFrontofficeCurrentDayTransactionsData(data) {
  let dataArr = [];
  let countDataArr = [];
  let valueDataArr = [];
  // if (valueType === "count") {
  countDataArr.push(data?.successfulCount);
  countDataArr.push(data?.failedCount);
  // } else {
  valueDataArr.push(getValueInMajor(data?.successfulAmount));
  valueDataArr.push(getValueInMajor(data?.failedAmount));
  // }
  const labels = ["Successful Transactions", "Failed Transactions"];

  const chartDataByCount = {
    labels,
    datasets: [
      {
        label: "Transactions",
        data: countDataArr,
        backgroundColor: ["rgb(75, 192, 192)", "rgb(255, 99, 132)"],
        borderColor: ["rgb(75, 192, 192)", "rgb(255, 99, 132)"],
        borderWidth: 1,
      },
    ],
  };
  const chartDataByValue = {
    labels,
    datasets: [
      {
        label: "Transactions",
        data: valueDataArr,
        backgroundColor: ["rgb(75, 192, 192)", "rgb(255, 99, 132)"],
        borderColor: ["rgb(75, 192, 192)", "rgb(255, 99, 132)"],
        borderWidth: 1,
      },
    ],
  };
  return { chartOptions: null, chartDataByCount, chartDataByValue };
}

function formatBackofficeHistoricTransactionsData(data, valueType = "count") {
  let chartOptions = {
    ...options,
    plugins: {
      title: {
        display: true,
        text: `Transactions by ${valueType}`,
      },
    },
  };
  const labels = Object.keys(data?.analyticsSummaryDistributedByDays);
  const superagentsWithData = Object.keys(
    data?.analyticsSummaryDistributedBySuperAgentByDay
  );
  let successfulTransactionsByValue = [];
  let successfulTransactionsByCount = [];
  let failedTransactionsByValue = [];
  let failedTransactionsByCount = [];
  labels.forEach((label) => {
    const metrics = data?.analyticsSummaryDistributedByDays[label];
    successfulTransactionsByValue.push(
      getValueInMajor(metrics?.successfulTransactionValue)
    );
    successfulTransactionsByCount.push(metrics?.successfulTransactionCount);
    failedTransactionsByValue.push(
      getValueInMajor(metrics?.failedTransactionValue)
    );
    failedTransactionsByCount.push(metrics?.failedTransactionCount);
  });
  const chartDataByCount = getTransactionsChartData(
    labels,
    successfulTransactionsByCount,
    failedTransactionsByCount
  );
  const chartDataByValue = getTransactionsChartData(
    labels,
    successfulTransactionsByValue,
    failedTransactionsByValue
  );
  const metricsSummary = data?.generalAnalyticsSummary;
  const totalCount =
    metricsSummary?.totalSuccessfulTransactionCount +
    metricsSummary?.totalFailedTransactionCount;

  const overallMetrics = {
    totalCount: totalCount,
    totalAmount:
      metricsSummary?.totalSuccessfulTransactionValue +
      metricsSummary?.totalFailedTransactionValue,
    successfulCount: metricsSummary?.totalSuccessfulTransactionCount,
    successfulAmount: metricsSummary?.totalSuccessfulTransactionValue,
    failedCount: metricsSummary?.totalFailedTransactionCount,
    failedAmount: metricsSummary?.totalFailedTransactionValue,
  };

  return {
    chartOptions,
    chartDataByValue,
    chartDataByCount,
    overallMetrics,
    superagentsWithData,
  };
}

function formatFrontofficeHistoricTransactionsData(data, valueType = "count") {
  let chartOptions = {
    ...options,
    plugins: {
      title: {
        display: true,
        text: `Transactions by ${valueType}`,
      },
    },
  };
  const superagents = Object.keys(
    data?.analyticsSummaryDistributedBySuperAgentByDay
  );
  const superagent = superagents[0];
  const labels = Object.keys(
    data?.analyticsSummaryDistributedBySuperAgentByDay[superagent]
  );
  let successfulTransactionsByValue = [];
  let successfulTransactionsByCount = [];
  let failedTransactionsByValue = [];
  let failedTransactionsByCount = [];

  labels.forEach((label) => {
    const metrics =
      data?.analyticsSummaryDistributedBySuperAgentByDay[superagent][label];
    successfulTransactionsByValue.push(
      getValueInMajor(metrics?.successfulTransactionValue)
    );
    successfulTransactionsByCount.push(metrics?.successfulTransactionCount);
    failedTransactionsByValue.push(
      getValueInMajor(metrics?.failedTransactionValue)
    );
    failedTransactionsByCount.push(metrics?.failedTransactionCount);
  });
  const chartDataByCount = getTransactionsChartData(
    labels,
    successfulTransactionsByCount,
    failedTransactionsByCount
  );
  const chartDataByValue = getTransactionsChartData(
    labels,
    successfulTransactionsByValue,
    failedTransactionsByValue
  );

  return { chartOptions, chartDataByCount, chartDataByValue };
}

function getOverallTransactionMetrics(data, superagent) {
  const selectedAgentTransactionSummaryByDays =
    data?.analyticsSummaryDistributedBySuperAgentByDay[superagent];
  const daysInSummary = Object.keys(selectedAgentTransactionSummaryByDays);
  let totalCount = 0;
  let totalAmount = 0;
  let successfulCount = 0;
  let successfulAmount = 0;
  let failedCount = 0;
  let failedAmount = 0;
  daysInSummary.forEach((day) => {
    const data = selectedAgentTransactionSummaryByDays[day];
    totalCount += data["totalTransactionCount"];
    totalAmount +=
      data["failedTransactionValue"] + data["successfulTransactionValue"];
    successfulCount += data["successfulTransactionCount"];
    successfulAmount += data["successfulTransactionValue"];
    failedCount += data["failedTransactionCount"];
    failedAmount += data["failedTransactionValue"];
  });

  const overallMetrics = {
    totalCount,
    totalAmount,
    successfulCount,
    successfulAmount,
    failedCount,
    failedAmount,
  };
  return overallMetrics;
}

function formatSelectedSuperAgentTransactionsData(
  data,
  superagent,
  valueType = "count"
) {
  let chartOptions = {
    ...options,
    plugins: {
      title: {
        display: true,
        text: `Transactions by ${valueType}`,
      },
    },
  };
  const labels = Object.keys(
    data?.analyticsSummaryDistributedBySuperAgentByDay[superagent]
  );
  let successfulTransactionsByValue = [];
  let successfulTransactionsByCount = [];
  let failedTransactionsByValue = [];
  let failedTransactionsByCount = [];
  labels.forEach((label) => {
    const metrics =
      data?.analyticsSummaryDistributedBySuperAgentByDay[superagent][label];
    successfulTransactionsByValue.push(
      getValueInMajor(metrics?.successfulTransactionValue)
    );
    successfulTransactionsByCount.push(metrics?.successfulTransactionCount);
    failedTransactionsByValue.push(
      getValueInMajor(metrics?.failedTransactionValue)
    );
    failedTransactionsByCount.push(metrics?.failedTransactionCount);
  });
  const chartDataByCount = getTransactionsChartData(
    labels,
    successfulTransactionsByCount,
    failedTransactionsByCount
  );
  const chartDataByValue = getTransactionsChartData(
    labels,
    successfulTransactionsByValue,
    failedTransactionsByValue
  );
  const overallMetrics = getOverallTransactionMetrics(data, superagent);
  return { chartOptions, chartDataByCount, chartDataByValue, overallMetrics };
}

function getOverallDisputeMetrics(data, superagent) {
  const selectedAgentDisputeSummaryByDays =
    data?.disputeAnalysisDistributedBySuperAgentsByDays[superagent];
  const daysInSummary = Object.keys(selectedAgentDisputeSummaryByDays);

  let totalCount = 0;
  let totalAmount = 0;
  let successfulCount = 0;
  let successfulAmount = 0;
  let failedCount = 0;
  let failedAmount = 0;

  daysInSummary.forEach((day) => {
    const selectedDayMetrics = selectedAgentDisputeSummaryByDays[day];
    totalCount +=
      selectedDayMetrics?.backofficeLoggedCount +
      selectedDayMetrics?.merchantLoggedCount;
    totalAmount +=
      selectedDayMetrics?.backofficeLoggedValue +
      selectedDayMetrics?.merchantLoggedValue;
    successfulCount +=
      selectedDayMetrics?.backofficeAcceptedCount +
      selectedDayMetrics?.merchantAcceptedCount;
    successfulAmount +=
      selectedDayMetrics?.backofficeAcceptedValue +
      selectedDayMetrics?.merchantAcceptedValue;
    failedCount +=
      selectedDayMetrics?.backofficeRejectedCount +
      selectedDayMetrics?.merchantRejectedCount;
    failedAmount +=
      selectedDayMetrics?.backofficeRejectedValue +
      selectedDayMetrics?.merchantRejectedValue;
  });
  const overallMetrics = {
    totalCount,
    totalAmount,
    successfulCount,
    successfulAmount,
    failedCount,
    failedAmount,
  };
  return overallMetrics;
}

function formatSelectedSuperAgentDisputeData(
  data,
  superagent,
  valueType = "count"
) {
  let chartOptions = getChartOptions(`Disputes by ${valueType}`);
  const labels = Object.keys(
    data?.disputeAnalysisDistributedBySuperAgentsByDays[superagent]
  );
  let acceptedDisputesByValue = [];
  let acceptedDisputesByCount = [];
  let rejectedDisputesByCount = [];
  let rejectedDisputesByValue = [];
  labels.forEach((label) => {
    // const metrics = data?.disputeAnalysisDistributedByDays[label];
    const metrics =
      data?.disputeAnalysisDistributedBySuperAgentsByDays[superagent][label];
    acceptedDisputesByValue.push(
      getValueInMajor(
        metrics?.backofficeAcceptedValue + metrics?.merchantAcceptedValue
      )
    );
    acceptedDisputesByCount.push(
      metrics?.backofficeAcceptedCount + metrics?.merchantAcceptedCount
    );
    rejectedDisputesByCount.push(
      metrics?.backofficeRejectedCount + metrics?.merchantRejectedCount
    );
    rejectedDisputesByValue.push(
      getValueInMajor(
        metrics?.backofficeRejectedValue + metrics?.merchantRejectedValue
      )
    );
  });
  const chartDataByCount = getDisputesChartData(
    labels,
    acceptedDisputesByCount,
    rejectedDisputesByCount
  );
  const chartDataByValue = getDisputesChartData(
    labels,
    acceptedDisputesByValue,
    rejectedDisputesByValue
  );
  // const { metrics } = data?.generalAnalyticSummaryBySuperagent[superagent];
  // const overallMetrics = {
  //   totalCount: metrics?.backofficeLoggedCount + metrics?.merchantLoggedCount,
  //   totalAmount: metrics?.backofficeLoggedValue + metrics?.merchantLoggedValue,
  //   successfulCount:
  //     metrics?.backofficeAcceptedCount + metrics?.merchantAcceptedCount,
  //   successfulAmount:
  //     metrics?.backofficeAcceptedValue + metrics?.merchantAcceptedValue,
  //   failedCount:
  //     metrics?.backofficeRejectedCount + metrics?.merchantRejectedCount,
  //   failedAmount:
  //     metrics?.backofficeRejectedValue + metrics?.merchantRejectedValue,
  // };
  const overallMetrics = getOverallDisputeMetrics(data, superagent);
  return { chartOptions, chartDataByValue, chartDataByCount, overallMetrics };
}

function formatBackofficeCurrentDayDisputesData(data, valueType = "count") {
  let chartOptions = {
    ...options,
    plugins: {
      title: {
        display: true,
        text: `Disputes by ${valueType}`,
      },
    },
  };
  const labels = Object.keys(data?.merchantMetrics);
  let acceptedDisputesByValue = [];
  let acceptedDisputesByCount = [];
  let rejectedDisputesByCount = [];
  let rejectedDisputesByValue = [];
  labels.forEach((label) => {
    const metrics = data?.merchantMetrics[label];
    acceptedDisputesByValue.push(
      getValueInMajor(
        metrics?.backofficeAcceptedValue + metrics?.merchantAcceptedValue
      )
    );
    acceptedDisputesByCount.push(
      metrics?.backofficeAcceptedCount + metrics?.merchantAcceptedCount
    );
    rejectedDisputesByCount.push(
      metrics?.backofficeRejectedCount + metrics?.merchantRejectedCount
    );
    rejectedDisputesByValue.push(
      getValueInMajor(
        metrics?.backofficeRejectedValue + metrics?.merchantRejectedValue
      )
    );
  });
  const chartDataByCount = getDisputesChartData(
    labels,
    acceptedDisputesByCount,
    rejectedDisputesByCount
  );
  const chartDataByValue = getDisputesChartData(
    labels,
    acceptedDisputesByValue,
    rejectedDisputesByValue
  );
  return { chartOptions, chartDataByCount, chartDataByValue };
}

function formatFrontofficeCurrentDayDisputesData(data, valueType = "count") {
  let countDataArr = [];
  let valueDataArr = [];
  // if (valueType === "count") {
  countDataArr.push(
    data?.backofficeAcceptedCount + data?.merchantAcceptedCount
  );
  countDataArr.push(
    data?.backofficeRejectedCount + data?.merchantRejectedCount
  );
  // } else {
  valueDataArr.push(
    getValueInMajor(data?.backofficeAcceptedValue + data?.merchantAcceptedValue)
  );
  valueDataArr.push(
    getValueInMajor(data?.backofficeRejectedValue + data?.merchantRejectedValue)
  );
  // }
  const labels = ["Accepted Disputes", "Rejected Disputes"];
  const chartDataByCount = {
    labels,
    datasets: [
      {
        label: "Disputes",
        data: countDataArr,
        backgroundColor: ["rgb(75, 192, 192)", "rgb(255, 99, 132)"],
        borderColor: ["rgb(75, 192, 192)", "rgb(255, 99, 132)"],
        borderWidth: 1,
      },
    ],
  };
  const chartDataByValue = {
    labels,
    datasets: [
      {
        label: "Disputes",
        data: valueDataArr,
        backgroundColor: ["rgb(75, 192, 192)", "rgb(255, 99, 132)"],
        borderColor: ["rgb(75, 192, 192)", "rgb(255, 99, 132)"],
        borderWidth: 1,
      },
    ],
  };
  return { chartOptions: null, chartDataByCount, chartDataByValue };
}

function formatBackofficeHistoricDisputesData(data, valueType = "count") {
  let chartOptions = getChartOptions(`Disputes by ${valueType}`);
  const labels = Object.keys(data?.disputeAnalysisDistributedByDays);
  const superagentsWithData = Object.keys(
    data?.disputeAnalysisDistributedBySuperAgentsByDays
  );
  let acceptedDisputesByValue = [];
  let acceptedDisputesByCount = [];
  let rejectedDisputesByCount = [];
  let rejectedDisputesByValue = [];
  labels.forEach((label) => {
    const metrics = data?.disputeAnalysisDistributedByDays[label];
    acceptedDisputesByValue.push(
      getValueInMajor(
        metrics?.backofficeAcceptedValue + metrics?.merchantAcceptedValue
      )
    );
    acceptedDisputesByCount.push(
      metrics?.backofficeAcceptedCount + metrics?.merchantAcceptedCount
    );
    rejectedDisputesByCount.push(
      metrics?.backofficeRejectedCount + metrics?.merchantRejectedCount
    );
    rejectedDisputesByValue.push(
      getValueInMajor(
        metrics?.backofficeRejectedValue + metrics?.merchantRejectedValue
      )
    );
  });
  const chartDataByCount = getDisputesChartData(
    labels,
    acceptedDisputesByCount,
    rejectedDisputesByCount
  );
  const chartDataByValue = getDisputesChartData(
    labels,
    acceptedDisputesByValue,
    rejectedDisputesByValue
  );
  const summary = data?.generalSummary;
  const totalCount =
    summary?.backofficeLoggedCount + summary?.merchantLoggedCount;
  const overallMetrics = {
    totalCount: totalCount,
    totalAmount: summary?.backofficeLoggedValue + summary?.merchantLoggedValue,
    successfulCount:
      summary?.backofficeAcceptedCount + summary?.merchantAcceptedCount,
    successfulAmount:
      summary?.backofficeAcceptedValue + summary?.merchantAcceptedValue,
    failedCount:
      summary?.backofficeRejectedCount + summary?.merchantRejectedCount,
    failedAmount:
      summary?.backofficeRejectedValue + summary?.merchantRejectedValue,
  };

  return {
    chartOptions,
    chartDataByCount,
    chartDataByValue,
    overallMetrics,
    superagentsWithData,
  };
}

function formatFrontofficeHistoricDisputesData(data, valueType = "count") {
  let chartOptions = getChartOptions(`Disputes by ${valueType}`);
  // const superagent = data?.disputeAnalysisDistributedBySuperAgentsByDays;
  const superagents = Object.keys(
    data?.disputeAnalysisDistributedBySuperAgentsByDays
  );
  const superagent = superagents[0];
  const labels = Object.keys(
    data?.disputeAnalysisDistributedBySuperAgentsByDays[superagent]
  );
  let acceptedDisputesByValue = [];
  let acceptedDisputesByCount = [];
  let rejectedDisputesByCount = [];
  let rejectedDisputesByValue = [];
  labels.forEach((label) => {
    const metrics =
      data?.disputeAnalysisDistributedBySuperAgentsByDays[superagent][label];
    acceptedDisputesByValue.push(
      getValueInMajor(
        metrics?.backofficeAcceptedValue + metrics?.merchantAcceptedValue
      )
    );
    acceptedDisputesByCount.push(
      metrics?.backofficeAcceptedCount + metrics?.merchantAcceptedCount
    );
    rejectedDisputesByCount.push(
      metrics?.backofficeRejectedCount + metrics?.merchantRejectedCount
    );
    rejectedDisputesByValue.push(
      getValueInMajor(
        metrics?.backofficeRejectedValue + metrics?.merchantRejectedValue
      )
    );
  });
  const chartDataByCount = getDisputesChartData(
    labels,
    acceptedDisputesByCount,
    rejectedDisputesByCount
  );
  const chartDataByValue = getDisputesChartData(
    labels,
    acceptedDisputesByValue,
    rejectedDisputesByValue
  );
  return { chartOptions, chartDataByCount, chartDataByValue };
}

export const DASHBOARD_HELPERS = {
  FORMAT_BACKOFFICE_CURRENT_DAY_TRANSACTIONS_DATA:
    formatBackofficeCurrentDayTransactionsData,
  FORMAT_FRONTOFFICE_CURRENT_DAY_TRANSACTIONS_DATA:
    formatFrontofficeCurrentDayTransactionsData,
  FORMAT_BACKOFFICE_HISTORIC_TRANSACTIONS_DATA:
    formatBackofficeHistoricTransactionsData,
  FORMAT_FRONTOFFICE_HISTORIC_TRANSACTIONS_DATA:
    formatFrontofficeHistoricTransactionsData,
  FORMAT_SELECTED_SUPERAGENT_TRANSACTIONS_DATA:
    formatSelectedSuperAgentTransactionsData,
  FORMAT_BACKOFFICE_CURRENT_DAY_DISPUTE_DATA:
    formatBackofficeCurrentDayDisputesData,
  FORMAT_FRONTOFFICE_CURRENT_DAY_DISPUTE_DATA:
    formatFrontofficeCurrentDayDisputesData,
  FORMAT_BACKOFFICE_HISTORIC_DISPUTES_DATA:
    formatBackofficeHistoricDisputesData,
  FORMAT_FRONTOFFICE_HISTORIC_DISPUTES_DATA:
    formatFrontofficeHistoricDisputesData,
  FORMAT_SELECTED_SUPERAGENT_DISPUTES_DATA: formatSelectedSuperAgentDisputeData,
};
