import "./main-app.css";
import { Header } from "../general/header/header";
import React, { useEffect, useState, useContext } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import Sider from "../general/sider/sider";
import { PageNotFound } from "../components/404/404-page";
import { FrontOfficeDashboard } from "../components/front-office/dashboard/front-office-dashboard";
import { SuperAgents } from "../components/back-office/super-agents/super-agents";
import { ChargeTypes } from "../components/back-office/charge-types/charge-types";
import { Participants } from "../components/back-office/participants/participants";
import { Transactions } from "../components/back-office/transactions/transactions";
import { BackofficeDisputes } from "../components/back-office/disputes/backoffice-disputes";
import { BackofficeSuperagentDisputes } from "../components/back-office/disputes/superagent-disputes";
import { FrontOfficeAgentDisputes } from "../components/front-office/disputes/agent-disputes";
import { FrontOfficeBankDisputes } from "../components/front-office/disputes/bank-disputes";
import { SuperAgentUsers } from "../components/back-office/super-agent-users/super-agent-users";
import { BulkSettlements } from "../components/back-office/bulk-settlements/bulk-settlements";
import { FrontOfficeBulkSettlement } from "../components/front-office/bulk-settlement/front-office-bulk-settlement";
import { FrontOfficeSuperAgentUsers } from "../components/front-office/super-agent-user/front-office-super-agent-users";
import { FrontOfficeTransactions } from "../components/front-office/transactions/front-office-transactions";
import { BackOfficeDashboard } from "../components/back-office/dashboard/back-office-dashboard";
// import { UserProfile } from "../shared/components/user-profile/user-profile";
import { PublicHolidays } from "../components/back-office/public-holidays/public-holidays";
import { Configurations } from "../components/back-office/configurations/configurations";
import { Home } from "../components/home/home";
import { ChangePassword } from "../shared/components/change-password/change-password";
import { ParticipantUsers } from "../components/back-office/participant-users/participant-users";
import { LOCAL_STORAGE_SERVICE } from "../core/services/storage-service";
import { ParticipantReports } from "../components/back-office/participant-reports/participant-reports";
import { BulkDisputes } from "../shared/components/bulk-disputes/bulk-disputes";
import { BackofficeTransactionReports } from "../components/back-office/transaction-reports/transaction-reports";
import { FrontofficeTransactionReports } from "../components/front-office/transaction-reports/transaction-reports";
import { UserManagement } from "../components/back-office/user-management/user-management";
import { BulkDisputeResponse } from "../components/front-office/bulk-dispute-response/bulk-dispute-response";
import { RequeryManagement } from "../components/back-office/requery-management/requery-management";
import { AllWorkflowRequests } from "../components/back-office/workflow/all-workflow-requests";
import { SelfWorkflowRequests } from "../components/back-office/workflow/self-workflow-requests";
import { UploadedReconciliationFiles } from "../components/back-office/reconciliation/statement-upload/uploaded-files";
import { ReconciliationDailyUploads } from "../components/back-office/reconciliation/daily-uploads/daily-upload";
import { CreditReconciliation } from "../components/back-office/reconciliation/credit-debit-reconciliation/credit-reconciliation";
import { DebitReconciliation } from "../components/back-office/reconciliation/credit-debit-reconciliation/debit-reconciliation";
import { MainContext } from "../../App";
import { ReconciliationConfigurations } from "../components/back-office/reconciliation/configurations/reconciliation-configurations";
import { SERVICES } from "../core/services/services";
import { TransitAccounts } from "../components/back-office/transit-account-setup/transit-accounts";
import { ManualDisputeRefunds } from "../components/back-office/manual-payments/manual-dispute-refunds";
import { ManualSettlementPayments } from "../components/back-office/manual-payments/manual-settlement-payments";

export const MainApp = () => {
  const [idleTimeInMinutes] = useState(5);
  const mainContext = useContext(MainContext);
  let history = useHistory();

  useEffect(() => {
    function setIdleTime() {
      const idleTimeInMilliseconds = idleTimeInMinutes * 60 * 1000;
      localStorage.setItem("_expiredTime", Date.now() + idleTimeInMilliseconds);
    }
    window.addEventListener("click", setIdleTime);
    window.addEventListener("mousemove", setIdleTime);
    window.addEventListener("scroll", setIdleTime);
    window.addEventListener("keydown", setIdleTime);

    function logout() {
      SERVICES.LOGOUT()
        .then(() => {
          mainContext.mainDispatch({ type: "LOG_OUT" });
          LOCAL_STORAGE_SERVICE.CLEAR_STORAGE();
          history.push("/login");
        })
        .catch(() => {
          mainContext.mainDispatch({ type: "LOG_OUT" });
          LOCAL_STORAGE_SERVICE.CLEAR_STORAGE();
          history.push("/login");
        });
    }

    setIdleTime();
    const interval = setInterval(() => {
      const expiredTime = localStorage.getItem("_expiredTime");
      if (expiredTime < Date.now()) {
        mainContext.mainDispatch({
          type: "SET_ALERT",
          alert: {
            title: "Info",
            description: "Your session has timed out, please login again.",
            type: "error",
            showAlert: true,
          },
        });
        logout();
      }
    }, 1000);
    return () => {
      clearInterval(interval);
      window.removeEventListener("click", setIdleTime);
      window.removeEventListener("mousemove", setIdleTime);
      window.removeEventListener("scroll", setIdleTime);
      window.removeEventListener("keydown", setIdleTime);
    };
  }, [history, idleTimeInMinutes, mainContext]);

  return (
    <div>
      <div>
        <Sider />
      </div>
      <div id="main">
        <div>
          <Header />
        </div>
        <div className="main-body inner-content">
          <Switch>
            <Route exact path="/" component={Home} />
            <Route
              exact
              path="/front-office/dashboard"
              component={FrontOfficeDashboard}
            />
            <Route
              exact
              path="/back-office/dashboard"
              component={BackOfficeDashboard}
            />
            {/*<Route path="/dashboard/:id" component={FrontOfficeDashboard} />*/}
            {/* <Route exact path="/user-profile" component={UserProfile} /> */}
            <Route path="/back-office/transactions" component={Transactions} />
            <Route
              path="/back-office/requery-management"
              component={RequeryManagement}
            />
            <Route
              path="/back-office/transaction-reports"
              component={BackofficeTransactionReports}
            />
            <Route
              path="/front-office/transaction-reports"
              component={FrontofficeTransactionReports}
            />
            <Route
              path="/front-office/transactions"
              component={FrontOfficeTransactions}
            />
            <Route
              path="/back-office/bulk-settlements"
              component={BulkSettlements}
            />
            <Route
              path="/front-office/bulk-settlements"
              component={FrontOfficeBulkSettlement}
            />
            <Route
              path="/front-office/super-agent-users"
              component={FrontOfficeSuperAgentUsers}
            />
            <Route path="/back-office/super-agents" component={SuperAgents} />
            <Route
              path="/back-office/disputes/backoffice"
              component={BackofficeDisputes}
            />
            <Route
              path="/back-office/disputes/super-agents"
              component={BackofficeSuperagentDisputes}
            />
            <Route
              path="/back-office/disputes/pending-manual-refunds"
              component={ManualDisputeRefunds}
            />
            <Route
              path="/back-office/disputes/pending-manual-settlements"
              component={ManualSettlementPayments}
            />
            <Route
              path="/front-office/agent-disputes"
              component={FrontOfficeAgentDisputes}
            />
            <Route
              path="/front-office/bank-disputes"
              component={FrontOfficeBankDisputes}
            />
            <Route
              path="/front-office/bulk-dispute-response"
              component={BulkDisputeResponse}
            />
            <Route
              path="/back-office/super-agent-users"
              component={SuperAgentUsers}
            />
            <Route path="/back-office/charge-types" component={ChargeTypes} />
            <Route
              path="/back-office/settlement-participants"
              component={Participants}
            />
            <Route
              path="/back-office/public-holidays"
              component={PublicHolidays}
            />
            <Route
              path="/back-office/configurations"
              component={Configurations}
            />
            <Route path="/account/change-password" component={ChangePassword} />

            <Route
              path="/back-office/user-management"
              component={UserManagement}
            />
            <Route
              path="/back-office/participant-users"
              component={ParticipantUsers}
            />
            <Route
              path="/back-office/participant-reports"
              component={ParticipantReports}
            />
            <Route
              path="/back-office/workflow-requests/all"
              component={AllWorkflowRequests}
            />
            <Route
              path="/back-office/workflow-requests/self"
              component={SelfWorkflowRequests}
            />
            <Route
              path="/back-office/reconciliation/file-upload"
              component={UploadedReconciliationFiles}
            />
            <Route
              path="/back-office/reconciliation/daily-uploads"
              component={ReconciliationDailyUploads}
            />
            <Route
              path="/back-office/reconciliation/credit-reconciliation"
              component={CreditReconciliation}
            />
            <Route
              path="/back-office/reconciliation/debit-reconciliation"
              component={DebitReconciliation}
            />
            <Route
              path="/back-office/reconciliation/configurations"
              component={ReconciliationConfigurations}
            />
            <Route path="/back-office/bulk-disputes" component={BulkDisputes} />
            <Route
              path="/back-office/transit-accounts"
              component={TransitAccounts}
            />
            <Route path="**" component={PageNotFound} />
          </Switch>
        </div>
      </div>
    </div>
  );
};
