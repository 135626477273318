import { useEffect, useState } from "react";
import { SERVICES } from "../services/services";

export function useSingleAppConfigurationValue(configKey) {
  const [configValue, setConfigValue] = useState(null);
  useEffect(() => {
    function getConfigValue() {
      SERVICES.GET_SINGLE_CONFIGURATION(configKey)
        .then((response) => {
          const value = response?.result?.configValue;
          setConfigValue(value);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    getConfigValue();
  }, [configKey]);
  return configValue;
}
