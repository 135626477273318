import { useContext, useEffect, useState } from "react";
import { MainContext } from "../../../../App";
import { BACKOFFICE_SERVICE } from "../../../core/services/backoffice-service";
import { CustomMessage } from "../../../shared/components/alert/custom-message";
import { CustomToast } from "../../../shared/components/alert/custom-toast";
import { CustomLoader } from "../../../shared/components/custom-loader/custom-loader";
import { FormTextArea } from "../../../shared/components/form-component/form-text-area";
import { Optional } from "../../../shared/components/optional/optional";
import { HELPER } from "../../../shared/helper/helper";
import { SpecialLabelCases } from "../../../shared/models/utilities";
import { CUSTOM_VALIDATION } from "../../../shared/validation/validation";
import ReactJson from "react-json-view";

export function WorkflowRequestDetails(props) {
  const [requestData] = useState(props.requestData);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [actionType, setActionType] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [responseMessage, setResponseMessage] = useState("");
  const mainContext = useContext(MainContext);
  const [authorizationComment, setAuthorizationComment] = useState("");
  const [authorizationCommentError, setAuthorizationCommentError] =
    useState(null);
  const [requestTransactionDetails, setRequestTransactionDetails] = useState(
    []
  );
  const [showTransactionDetails, setShowTransactionDetails] = useState(false);
  const [requestTransactionDetailsProps] = useState(props.targetRecordData);

  function submit() {
    setLoading(true);
    let payload = {
      approved: actionType === "APPROVE" ? true : false,
      authorizersComment: authorizationComment,
    };
    BACKOFFICE_SERVICE.PROCESS_WORKFLOW_REQUEST(props.requestId, payload)
      .then((data) => {
        console.log("data", data);
        setResponseMessage(
          `You have successfully ${
            actionType === "APPROVE" ? "approved" : "declined"
          } this request.`
        );
        setLoading(false);
        setCurrentIndex(2);
      })
      .catch((error) => {
        setError(HELPER.PROCESS_ERROR(error));
        setLoading(false);
      });
  }

  useEffect(() => {
    function setTransactionDetails() {
      let arr = [];
      if (requestTransactionDetailsProps?.paymentRequest) {
        const transaction = requestTransactionDetailsProps?.paymentRequest;
        arr.push({
          label: "Participant",
          value: transaction?.participant?.name,
        });
        arr.push({
          label: "Amount",
          value: transaction?.amount,
          itemCase: "amount",
        });
        arr.push({
          label: "Debit Account",
          value: transaction?.debitAccount,
        });
        arr.push({
          label: "Debit Account Name",
          value: transaction?.debitAccountName,
        });
        arr.push({
          label: "Credit Account",
          value: transaction?.creditAccount,
        });
        arr.push({
          label: "Credit Account Name",
          value: transaction?.creditAccountName,
        });
        arr.push({
          label: "Narration",
          value: transaction?.narration,
        });
        arr.push({
          label: "Transaction Reference",
          value: requestTransactionDetailsProps?.transactionRef,
        });
      } else if (requestTransactionDetailsProps?.refundTransaction) {
        const transaction = requestTransactionDetailsProps?.refundTransaction;
        arr.push({
          label: "Amount",
          value: transaction?.amount,
          itemCase: "amount",
        });
        arr.push({
          label: "Source Account",
          value: transaction?.sourceAccount,
        });
        arr.push({
          label: "Source Account Name",
          value: transaction?.sourceAccountName,
        });
        arr.push({
          label: "Destination Account",
          value: transaction?.destinationAccount,
        });
        arr.push({
          label: "Destination Account Name",
          value: transaction?.destinationAccountName,
        });
        arr.push({
          label: "Narration",
          value: transaction?.narration,
        });
        arr.push({
          label: "Transaction Search Key",
          value: transaction?.transactionSearchKey,
        });
        arr.push({
          label: "Transaction Reference",
          value: requestTransactionDetailsProps?.transactionRef,
        });
      } else if (requestTransactionDetailsProps?.chargeType) {
        arr.push({
          label: "Charge Type Name",
          value:
            requestTransactionDetailsProps.chargeTypeName ||
            requestTransactionDetailsProps.chargeName,
        });
        arr.push({
          label: "Type",
          value: requestTransactionDetailsProps?.chargeType,
        });
        arr.push({
          label: "Description",
          value: requestTransactionDetailsProps?.chargeTypeDesc,
        });
        arr.push({
          label: "FLAT",
          value: HELPER.TO_CURRENCY_FORMAT(
            requestTransactionDetailsProps["flat"]
          ),
        });
        arr.push({
          label: "Min Cap",
          value: HELPER.TO_CURRENCY_FORMAT(
            requestTransactionDetailsProps?.minCap
          ),
        });
        arr.push({
          label: "Max Cap",
          value: HELPER.TO_CURRENCY_FORMAT(
            requestTransactionDetailsProps?.maxCap
          ),
        });
        arr.push({
          label: "Percent",
          value: requestTransactionDetailsProps?.percent,
        });
      }
      setRequestTransactionDetails(arr);
    }
    setTransactionDetails();
  }, [requestTransactionDetailsProps]);

  function validateForm(e, name, type, refineName, required) {
    let value = e?.target?.value;
    const isEmpty = CUSTOM_VALIDATION.IS_EMPTY(value);
    const isValidInput = !isEmpty
      ? CUSTOM_VALIDATION.BASIC_VALIDATION(value, type)
      : false;

    if (isValidInput) {
      setAuthorizationComment(value);
      setAuthorizationCommentError(null);
    } else {
      let errorMessage = isEmpty ? `Authorization comment is required` : null;
      if (!isValidInput) {
        errorMessage = `Enter valid authorization comment`;
      }
      setAuthorizationCommentError(errorMessage);
    }
  }

  function saveAction(action) {
    setActionType(action);
    setCurrentIndex(1);
  }

  function showActionButtons() {
    return (
      // !loading &&
      (HELPER.HAS_AUTHORITY("bo_approve_workflow_request") ||
        HELPER.HAS_AUTHORITY("bo_cancel_workflow_request")) &&
      HELPER.HAS_AUTHORITY(props.requiredAuthority) &&
      props.requestStatus === "PENDING" &&
      props.requestInitiator !== mainContext?.mainState?.username
    );
  }

  const transformView = (itemCase, value) => {
    let result = value;
    if (itemCase) {
      SpecialLabelCases.forEach((e) => {
        if (e.case === itemCase) {
          if (value) {
            result = e.action(value);
          }
        }
      });
    }
    return result ? result : "___";
  };

  const requestDetails = requestData.map((details, index) => (
    <div key={index.toString()} className="p-grid dcir-row">
      <div className="p-col-5 p-pl-0">
        <p className="details-label">{details.label}:</p>
      </div>
      <div className="p-col-7 p-pl-0">
        <div className="flex default">
          <p className="details-value">
            {transformView(details.itemCase, details.value)}
          </p>
        </div>
      </div>
    </div>
  ));

  // const transactionDetails = requestTransactionDetails.map((details, index) => (
  //   <div key={index.toString()} className="p-grid dcir-row">
  //     <div className="p-col-5 p-pl-0">
  //       <p className="details-label">{details.label}:</p>
  //     </div>
  //     <div className="p-col-7 p-pl-0">
  //       <div className="flex default">
  //         <p className="details-value">
  //           {transformView(details.itemCase, details.value)}
  //         </p>
  //       </div>
  //     </div>
  //   </div>
  // ));

  function requestDetailsView() {
    switch (currentIndex) {
      case 0:
        return (
          <div>
            <div>
              <p className="details-title p-mt-0">Authorization Details</p>
              <p className="details-subtitle">
                Find details on this authorization request below
              </p>
            </div>
            <div>{requestDetails}</div>
            <Optional show={showTransactionDetails}>
              <p className="sub-heading p-mt-5 p-mb-2">Target Record:</p>
              {/* <div>{transactionDetails}</div> */}
              <div style={{ width: "100%" }}>
                <ReactJson
                  src={props.targetRecordData}
                  name={null}
                  style={{
                    fontSize: "0.8rem",
                    maxWidth: "100%",
                    textOverflow: "ellipsis",
                    wordBreak: "break-word",
                  }}
                  collapsed={1}
                  enableClipboard={false}
                  displayObjectSize={false}
                  displayDataTypes={false}
                />
              </div>
            </Optional>
            <Optional show={requestTransactionDetails?.length}>
              <div className="p-mt-3">
                <button
                  className="bare-button underline p-pl-0 p-ml-0"
                  onClick={() =>
                    setShowTransactionDetails(!showTransactionDetails)
                  }
                >
                  Show {showTransactionDetails ? "less" : "more"} details
                </button>
              </div>
            </Optional>
            <Optional show={props.statusBeforeChange}>
              <div className="p-col-12  p-pl-0">
                <p className="sub-heading p-mt-3 p-mb-0">Record Changes:</p>
              </div>
              {props?.statusBeforeChange?.map((status) => (
                <div className="p-grid dcir-row " key={status?.key}>
                  <div className="p-col-5 p-pl-0">
                    <p className="details-label">{status?.key}:</p>
                  </div>
                  <div className="p-col-7 p-pl-0">
                    <div className="flex default">
                      <p className="details-value">{status?.value}</p>
                    </div>
                  </div>
                </div>
              ))}
              {/* <ReactJson
                src={props.statusBeforeChange}
                name={null}
                style={{
                  fontSize: "0.8rem",
                  maxWidth: "100%",
                  textOverflow: "ellipsis",
                  wordBreak: "break-word",
                }}
                collapsed={2}
                enableClipboard={false}
                displayObjectSize={false}
                displayDataTypes={false}
              /> */}
            </Optional>
            <Optional show={props.statusAfterChange}>
              <div className="p-col-12 p-pl-0">
                <p className="sub-heading p-mt-0">After Data:</p>
              </div>
              {/* <ReactJson
                src={props.statusAfterChange}
                name={null}
                style={{
                  fontSize: "0.8rem",
                  maxWidth: "100%",
                  textOverflow: "ellipsis",
                  wordBreak: "break-word",
                }}
                collapsed={2}
                enableClipboard={false}
                displayObjectSize={false}
                displayDataTypes={false}
              /> */}
              <div className="p-grid dcir-row no-underline">
                <div className="p-col-5 p-pl-0">
                  <p className="details-label p-mt-0">Status (New value):</p>
                </div>
                <div className="p-col-7 p-pl-0">
                  <div className="flex default">
                    <p className="details-value p-mt-0">
                      <span className="custom-badge custom-badge-text custom-badge-success">
                        {props.statusAfterChange}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </Optional>
            <Optional show={showActionButtons()}>
              <div className="p-col-12">
                <div className="p-mt-5">
                  <div className="p-grid">
                    <div className="p-col-6 p-pl-0">
                      {/* {cancelButton()} */}
                      <button
                        className="secondary-button"
                        onClick={() => saveAction("DECLINE")}
                      >
                        Decline
                      </button>
                    </div>
                    <div className="p-col-6 p-pr-0">
                      {/* {submitButton()} */}
                      <button
                        className="primary-button"
                        onClick={() => saveAction("APPROVE")}
                      >
                        Approve
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Optional>
          </div>
        );
      case 1:
        return (
          <div>
            <div>
              <h3 className="p-my-0">Confirm Action</h3>
            </div>
            <div>
              <p className="p-mt-0 p-mb-4 text-small">
                Confirm that you want to {actionType.toLowerCase()} this
                request!
              </p>
            </div>
            <div className="p-grid">
              <div className="p-col-12">
                <label>Authorization comment:</label>
                <FormTextArea
                  required={true}
                  field="authorizationComment"
                  type="INPUT"
                  error={authorizationCommentError}
                  fn={validateForm}
                  loading={loading}
                  placeholder=""
                />
              </div>
            </div>
            <Optional show={error}>
              <div className="p-col-12">
                <CustomToast title="Error" description={error} type="error" />
              </div>
            </Optional>
            <div className="p-col-12">
              <div className="p-grid p-mb-3">
                <Optional show={!loading}>
                  <div className="p-col-6 p-pl-0">
                    {/* {cancelButton()} */}
                    <button
                      className="secondary-button"
                      onClick={() => setCurrentIndex(0)}
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="p-col-6 p-pr-0">
                    {/* {submitButton()} */}
                    <button
                      className="primary-button"
                      onClick={submit}
                      disabled={
                        !authorizationComment.length ||
                        authorizationCommentError
                      }
                    >
                      Confirm
                    </button>
                  </div>
                </Optional>
                <Optional show={loading}>
                  <div className="p-col-12 p-mt-3 p-text-center">
                    <CustomLoader loadingText="Loading..." />
                  </div>
                </Optional>
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="p-text-center p-mt-2">
            <div>
              <CustomMessage messageType="success" />
            </div>
            <div className="success-message-btn-container p-text-center">
              <p>{responseMessage}</p>
              <p
                className="close-modal add-cursor p-mt-3"
                onClick={() => props.closeModal(true)}
              >
                Close
              </p>
            </div>
          </div>
        );
      default:
        break;
    }
  }

  return (
    <div className="p-text-left requery-management p-pl-2 p-mb-5">
      {requestDetailsView()}
    </div>
  );
}
