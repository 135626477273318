import "./participant-reports.css";
import { useEffect, useRef, useState } from "react";
import { CustomLoader } from "../../../shared/components/custom-loader/custom-loader";
import { FormInput } from "../../../shared/components/form-component/form-input";
import { CUSTOM_VALIDATION } from "../../../shared/validation/validation";
import moment from "moment";
import { HELPER } from "../../../shared/helper/helper";
import { SERVICES } from "../../../core/services/services";
// import { MainContext } from "../../../../App";
import { Toast } from "primereact/toast";
import { FormMultiselect } from "../../../shared/components/form-component/form-multiselect";
import { LOCAL_STORAGE_SERVICE } from "../../../core/services/storage-service";

export function ReportsFilter(props) {
  const toast = useRef(null);
  // const mainContext = useContext(MainContext);
  const [loading] = useState(false);
  const [currentIndex] = useState(0);
  const [filter, setFilter] = useState({
    toDate: moment().format("YYYY-MM-DD"),
    fromDate: moment().format("YYYY-MM-DD"),
    ownerType: "",
    ownerIds: [],
  });
  const [filterErrors, setFilterErrors] = useState({
    toDate: null,
    fromDate: null,
    ownerType: null,
    ownerIds: null,
  });
  const [participants, setParticipants] = useState([]);

  useEffect(() => {
    let mounted = true;
    function getParticipants() {
      const username = LOCAL_STORAGE_SERVICE.GET_USERNAME();
      const params = HELPER.TO_URL_STRING({
        username: username,
      });
      SERVICES.GET_PARTICIPANTS_FOR_USER(params)
        .then((data) => {
          const result = data?.result;
          setParticipants(result);
        })
        .catch((error) => {
          toast.current.show({
            severity: "error",
            summary: "Error getting participants!",
            detail: HELPER.PROCESS_ERROR(error, "TOAST"),
            life: 10000,
          });
        });
    }
    if (mounted) {
      getParticipants();
    }
    return () => {
      mounted = false;
    };
  }, []);

  function validateForm(e, name, type, refineName, required) {
    let value = e?.target?.value;
    const isEmpty = CUSTOM_VALIDATION.IS_EMPTY(value);
    const isValidInput = !isEmpty
      ? CUSTOM_VALIDATION.BASIC_VALIDATION(value, type)
      : false;
    if (isValidInput) {
      setFilter({ ...filter, [name]: value });
      setFilterErrors({ ...filterErrors, [name]: null });
    } else {
      let errorMessage =
        required && isEmpty ? `${refineName} is required` : null;
      if (!isValidInput) {
        errorMessage = `${refineName} is invalid`;
      }
      setFilterErrors({ ...filterErrors, [name]: errorMessage });
    }
  }

  function filterSearch() {
    let payload = {};
    for (const property in filter) {
      if (filter[property] && filter[property].length) {
        if (property === "ownerIds") {
          payload[property] = filter["ownerIds"].map(
            (owner) => owner.participantId
          );
        } else {
          payload[property] = filter[property];
        }
      }
    }
    return payload;
  }

  function cancelModal() {
    props.closeModal();
  }

  const cancelButton = () => {
    if (!loading) {
      return (
        <button onClick={cancelModal} className="secondary-button">
          Cancel
        </button>
      );
    } else {
      return <div />;
    }
  };

  function validateMultipleSelect(e, name) {
    let value = e.target.value;
    if (value.length) {
      setFilterErrors({ ...filterErrors, [name]: "" });
      setFilter({ ...filter, [name]: value });
    } else {
      setFilterErrors({
        ...filterErrors,
        [name]: "Select participants",
      });
      setFilter({ ...filter, [name]: null });
    }
  }

  const submitButton = () => {
    if (!loading) {
      return (
        <button
          onClick={() => props?.searchFunction(filterSearch())}
          className="primary-button"
        >
          Filter
        </button>
      );
    } else {
      return (
        <div className="pull-up-element-2">
          <CustomLoader loadingText="Submitting..." />
        </div>
      );
    }
  };

  const reportFilterView = () => {
    if (currentIndex) {
      return (
        <div>
          <div className="success-message-btn-container">
            <button
              onClick={() => {
                props.closeModal(true);
              }}
              className="primary-button success-message-btn"
            >
              Close
            </button>
          </div>
        </div>
      );
    } else {
      return <div>{reportsFilterForm()}</div>;
    }
  };

  const reportsFilterForm = () => {
    return (
      <div>
        <div className="custom-modal-title p-text-left">Filter</div>
        <div className="custom-dialog-subtitle-container p-mb-5"></div>
        <div className="p-grid">
          <div className="p-col-12">
            <FormMultiselect
              required={true}
              error={filterErrors["ownerIds"]}
              field="ownerIds"
              label="name"
              value={filter["ownerIds"]}
              options={participants}
              fn={validateMultipleSelect}
              placeholder="Select participants"
            />
          </div>
          <div className="p-col-12 p-text-left text-small">
            <p className="p-mb-0">Settlement Date</p>
          </div>
          <div className="p-col-6">
            <label>From:</label>
            <FormInput
              inputType="date"
              value={filter["fromDate"]}
              required={false}
              field="fromDate"
              type="INPUT"
              error={filterErrors["fromDate"]}
              fn={validateForm}
              loading={loading}
              placeholder="Start date"
            />
          </div>
          <div className="p-col-6">
            <label>To:</label>
            <FormInput
              inputType="date"
              value={filter["toDate"]}
              required={false}
              field="toDate"
              type="INPUT"
              error={filterErrors["toDate"]}
              fn={validateForm}
              loading={loading}
              placeholder="End date"
            />
          </div>
          {/* <div>
            <small>
              Please note that the date filters is for settlement date not
              report generation date.
            </small>
          </div> */}
          <div className="p-col-12 actions">
            <div className="p-mt-5">
              <div className="p-grid">
                <div className={loading ? "p-col-12" : "p-col-6"}>
                  {cancelButton()}
                </div>
                <div className={loading ? "p-col-12" : "p-col-6"}>
                  {submitButton()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div>
        <Toast ref={toast} />
      </div>
      <div className="p-pb-2">{reportFilterView()}</div>
    </div>
  );
}
