import React, { useCallback, useEffect, useRef, useState } from "react";
import { CustomBreadcrumb } from "../../../../shared/components/custom-breadcrumb/custom-breadcrumb";
import { CustomTable } from "../../../../shared/components/custom-table/custom-table";
// import { SERVICES } from "../../../core/services/services";
// import "./settlement.css";
import { CustomLoader } from "../../../../shared/components/custom-loader/custom-loader";
import { CustomModal } from "../../../../shared/components/custom-modal/custom-modal";
import { DetailsBreakDown } from "../../../../shared/components/details-break-down/details-break-down";
import { Toast } from "primereact/toast";
import { HELPER } from "../../../../shared/helper/helper";
import { EditReconConfiguration } from "./edit-configuration";
// import ErrorBoundary from "../../../shared/components/error-boundary/error-boundary";
import ErrorBoundary from "../../../../shared/components/error-boundary/error-boundary";
import { BACKOFFICE_SERVICE } from "../../../../core/services/backoffice-service";

export function ReconciliationConfigurations() {
  const toast = useRef(null);
  const [visible, setVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [error, setError] = useState("");
  const [search, setSearch] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [range] = useState(5);
  const [breakDownTitle, setBreakDownTitle] = useState("");
  const [configForEdit, setConfigForEdit] = useState(null);
  const [pageNo, setPageNo] = useState(0);
  const [emptyText, setEmptyText] = useState("");
  const [currentModalIndex, setCurrentModalIndex] = useState(0);
  const [configKey, setConfigKey] = useState(null);

  const tableHeaders = [
    { label: "Field Name", value: "fieldName" },
    { label: "Spreadsheet column name", value: "columnHeader" },
    { label: "Value Type", value: "valueType" },
    { label: "Mandatory", value: "isMandatory" },
    { label: "Actions", value: "actions" },
  ];

  const configAuthorities = [
    { label: "UPDATE", value: "bo_manage_configurations" },
    // { label: "DELETE", value: "bo_manage_configurations" },
  ];

  const [details, setDetails] = useState([]);

  const [configurations, setConfigurations] = useState([]);

  const openModal = useCallback((index, isMobile) => {
    setCurrentModalIndex(index);
    if (isMobile) {
      setCurrentIndex(2);
    } else {
      setVisible(true);
    }
  }, []);

  const viewConfiguration = useCallback(
    (config, isMobile) => {
      let arr = [];
      setDetails([]);
      arr.push({ label: "Name", value: config?.configName });
      arr.push({ label: "Description", value: config?.configDesc });
      arr.push({ label: "Key", value: config?.configKey });
      arr.push({ label: "Value", value: config?.configValue });
      setDetails(arr);
      setBreakDownTitle("Configuration");
      openModal(0, isMobile);
    },
    [openModal]
  );

  const openAction = useCallback(
    (e, action, isMobile) => {
      // eslint-disable-next-line default-case
      switch (action) {
        case "VIEW": {
          viewConfiguration(e, isMobile);
          break;
        }
        case "UPDATE":
          setConfigKey(e?.configKey);
          const config = {
            ...e,
          };
          setConfigForEdit(config);
          openModal(1, isMobile);
      }
    },
    [openModal, viewConfiguration]
  );

  const getConfigs = useCallback(() => {
    setCurrentIndex(0);
    setConfigurations([]);
    let params = {
      page: pageNo,
      size: 10,
    };
    params = HELPER.TO_URL_STRING(params);
    BACKOFFICE_SERVICE.GET_RECON_CONFIGURATIONS(params)
      .then((data) => {
        const result = data?.result;
        if (!result?.length) {
          setEmptyText("No configurations found...");
        } else {
          let arr = [];
          setTotalItems(data?.result?.totalElements);
          setTotalPages(data?.result?.totalPages);
          result?.forEach((e) => {
            arr.push({
              ...e,
              isMandatory: e?.mandatory ? "True" : "False",
              actions: "EDIT",
              detailsFunction: openAction,
            });
          });
          setConfigurations(arr);
        }
        setError(null);
        setCurrentIndex(1);
      })
      .catch((error) => {
        setError(HELPER.PROCESS_ERROR(error));
        setCurrentIndex(1);
      });
  }, [pageNo, openAction]);

  useEffect(() => {
    getConfigs();
  }, [getConfigs]);

  function onHide() {}

  function reload() {
    setSearch(false);
    setCurrentIndex(0);
    getConfigs();
  }

  function closeModal(isReload) {
    if (visible) {
      setVisible(false);
    }
    if (currentIndex === 2) {
      setCurrentIndex(1);
    }

    if (isReload === true) {
      reload();
    }
  }

  function goToPage(pageNo) {
    setPageNo(pageNo);
  }

  const mobileModal = () => {
    // eslint-disable-next-line default-case
    switch (currentModalIndex) {
      case 0:
        return (
          <DetailsBreakDown
            title={breakDownTitle}
            breakDown={details}
            closeModal={closeModal}
          />
        );
      case 1:
        return (
          <EditReconConfiguration
            config={configForEdit}
            closeModal={closeModal}
            configKey={configKey}
          />
        );
      case 2:
        return <div></div>;
    }
  };

  const modalContent = () => {
    // eslint-disable-next-line default-case
    switch (currentModalIndex) {
      case 0:
        return (
          <DetailsBreakDown
            title={breakDownTitle}
            breakDown={details}
            closeModal={closeModal}
          />
        );
      case 1:
        return (
          <EditReconConfiguration
            config={configForEdit}
            closeModal={closeModal}
            configKey={configKey}
          />
        );
      case 2:
        return <div></div>;
    }
  };

  const configurationsView = () => {
    // eslint-disable-next-line default-case
    switch (currentIndex) {
      case 0:
        return (
          <div className="loading-container">
            <CustomLoader loadingText="Loading configurations..." />
          </div>
        );
      case 1:
        return (
          <CustomTable
            authorities={configAuthorities}
            totalPages={totalPages}
            totalItems={totalItems}
            currentPage={pageNo + 1}
            range={range}
            emptyText={emptyText}
            search={search}
            reload={reload}
            error={error}
            items={configurations}
            headers={tableHeaders}
            nextPage={() => setPageNo(pageNo + 1)}
            prevPage={() => setPageNo(pageNo - 1)}
            goToFirstPage={() => setPageNo(0)}
            goToLastPage={() => setPageNo(totalPages - 1)}
            goToPage={goToPage}
          />
        );
      case 2:
        return (
          <div className="mobile-modal-container">
            <div className="custom-card">
              <div className="mobile-card-position">{mobileModal()}</div>
            </div>
          </div>
        );
    }
  };

  return (
    <ErrorBoundary>
      <div>
        <Toast ref={toast} />
        <div>
          <CustomModal
            closeModal={closeModal}
            onHide={onHide}
            visible={visible}
            modalContent={modalContent}
          />
        </div>
        <div className="page-title p-text-left">Spreadsheet Configurations</div>
        <div className="p-mt-2">
          <CustomBreadcrumb page="Spreadsheet Configurations" />
        </div>
        <div className="p-mt-5">{configurationsView()}</div>
      </div>
    </ErrorBoundary>
  );
}
