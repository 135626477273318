import { useState } from "react";
import { Icon } from "../../icons/icon";
import { Optional } from "../optional/optional";
import "./tooltip.css";
// import DOMPurify from "dompurify";

export function ToolTip(props) {
  const [showTip, setShowTip] = useState(false);
  // const sanitizedTip = DOMPurify.sanitize(props.tip);
  function handleMouseEnter() {
    setShowTip(true);
  }
  function handleMouseLeave() {
    setShowTip(false);
  }
  return (
    <div
      className="tooltip-wrapper"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="tooltip-icon">
        <Icon icon={props.icon ? props.icon : `tooltip`} />
      </div>
      <Optional show={showTip}>
        <div className={`tip ${props.tipPosition}`}>
          <Optional show={props.isHTML}>
            <div>{props.tip}</div>
          </Optional>
          <Optional show={!props.isHTML}>
            <p className="p-my-0">{props.tip}</p>
          </Optional>
        </div>
      </Optional>
    </div>
  );
}
