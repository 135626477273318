import "./new-table.css";
import "./custom-table.css";
import { useEffect } from "react";
import { CustomPagination } from "../custom-pagination/custom-pagination";
import { Icon } from "../../icons/icon";
import { SpecialLabelCases } from "../../models/utilities";
import { HELPER } from "../../helper/helper";
import { Optional } from "../optional/optional";
import { useSingleAppConfigurationValue } from "../../../core/custom-hooks/use-single-configuration-value";
import { BACKOFFICE_CONFIGS } from "../../constants";

export function DesktopTable(props) {
  const isManualSettlementPaymentEnabled = useSingleAppConfigurationValue(
    BACKOFFICE_CONFIGS.IS_MANUAL_SETTLEMENT_PAYMENT_ENABLED
  );

  useEffect(() => {
    function reDivideColumns() {
      const columns = props.showCheckbox ? props.columns - 1 : props.columns;
      const maxWidth = props.showCheckbox ? 95 : 100;
      let i;
      const newColumnSize = maxWidth / columns;
      let elements = document.getElementsByClassName("dcir-column");
      for (i = 0; i < elements.length; i++) {
        elements[i].style.width = `${newColumnSize}%`;
      }
      if (props.showCheckbox) {
        let checkboxColumns = document.getElementsByClassName("checkbox-col");
        for (i = 0; i < checkboxColumns.length; i++) {
          checkboxColumns[i].style.width = `5%`;
        }
      }
    }
    if (props?.columns !== 5) {
      reDivideColumns();
    }
  }, [props.columns, props.showCheckbox]);

  const tableAction = (actions, item) => {
    // eslint-disable-next-line default-case
    switch (actions) {
      case "CRUD":
        return (
          <span className="dcir-tb-action-position">
            <Optional
              show={HELPER.CAN_PERFORM_ACTION(props?.authorities, "DELETE")}
            >
              <span className="dcir-show table-action-icon-delete">
                <span
                  onClick={() =>
                    item?.detailsFunction(item, "DELETE", props.isMobile)
                  }
                  className="add-cursor"
                >
                  <i className="pi pi-trash" />
                </span>
              </span>
            </Optional>
            <Optional
              show={HELPER.CAN_PERFORM_ACTION(props?.authorities, "UPDATE")}
            >
              <span className="dcir-show table-action-icon">
                <span
                  onClick={() =>
                    item?.detailsFunction(item, "UPDATE", props.isMobile)
                  }
                  className="p-ml-4 add-cursor"
                >
                  <i className="pi pi-pencil" />
                </span>
              </span>
            </Optional>

            <span
              onClick={() =>
                item?.detailsFunction(item, "VIEW", props.isMobile)
              }
              className="p-ml-4 add-cursor table-action-icon"
            >
              <i className="pi pi-eye" />
            </span>
          </span>
        );
      case "CRD":
        return (
          <span className="dcir-tb-action-position">
            <Optional
              show={HELPER.CAN_PERFORM_ACTION(props?.authorities, "DELETE")}
            >
              <span className="dcir-show table-action-icon-delete">
                <span
                  onClick={() =>
                    item?.detailsFunction(item, "DELETE", props.isMobile)
                  }
                  className="add-cursor"
                >
                  <i className="pi pi-trash" />
                </span>
              </span>
            </Optional>

            <span
              onClick={() =>
                item?.detailsFunction(item, "VIEW", props.isMobile)
              }
              className="p-ml-4 add-cursor table-action-icon"
            >
              <i className="pi pi-eye" />
            </span>
          </span>
        );
      case "CRU":
        return (
          <span className="dcir-tb-action-position">
            <Optional
              show={HELPER.CAN_PERFORM_ACTION(props?.authorities, "UPDATE")}
            >
              <span className="dcir-show">
                <span
                  onClick={() =>
                    item?.detailsFunction(item, "UPDATE", props.isMobile)
                  }
                  className="add-cursor table-action-icon"
                >
                  <i className="pi pi-pencil" />
                </span>
              </span>
            </Optional>

            <span
              onClick={() =>
                item?.detailsFunction(item, "VIEW", props.isMobile)
              }
              className="p-ml-4 add-cursor table-action-icon"
            >
              <i className="pi pi-eye" />
            </span>
          </span>
        );
      case "CRUDE":
        return (
          <span className="dcir-tb-action-position">
            <Optional
              show={HELPER.CAN_PERFORM_ACTION(props?.authorities, "UPDATE")}
            >
              <span className="dcir-show">
                <span
                  onClick={() =>
                    item?.detailsFunction(item, "UPDATE", props.isMobile)
                  }
                  className="add-cursor table-action-icon"
                >
                  <i className="pi pi-pencil" />
                </span>
              </span>
            </Optional>

            <span
              onClick={() =>
                item?.detailsFunction(item, "VIEW", props.isMobile)
              }
              className="p-ml-4 add-cursor table-action-icon"
            >
              <i className="pi pi-eye" />
            </span>
            <Optional
              show={HELPER.CAN_PERFORM_ACTION(props?.authorities, "DEACTIVATE")}
            >
              <span className="dcir-show">
                <span
                  onClick={() =>
                    item?.detailsFunction(item, "DEACTIVATE", props.isMobile)
                  }
                  className="p-ml-4 add-cursor table-action-icon-delete"
                >
                  <i className="pi pi-eye-slash" />
                </span>
              </span>
            </Optional>
          </span>
        );
      case "CRUA":
        return (
          <span className="dcir-tb-action-position">
            <Optional
              show={HELPER.CAN_PERFORM_ACTION(props?.authorities, "UPDATE")}
            >
              <span className="dcir-show">
                <span
                  onClick={() =>
                    item?.detailsFunction(item, "UPDATE", props.isMobile)
                  }
                  className="add-cursor table-action-icon"
                >
                  <i className="pi pi-pencil" />
                </span>
              </span>
            </Optional>

            <span
              onClick={() =>
                item?.detailsFunction(item, "VIEW", props.isMobile)
              }
              className="p-ml-4 add-cursor table-action-icon"
            >
              <i className="pi pi-eye" />
            </span>
            <Optional
              show={HELPER.CAN_PERFORM_ACTION(props?.authorities, "ACTIVATE")}
            >
              <span className="dcir-show">
                <span
                  onClick={() =>
                    item?.detailsFunction(item, "ACTIVATE", props.isMobile)
                  }
                  className="p-ml-4 add-cursor table-action-icon"
                >
                  <i className="pi pi-eye-slash" />
                </span>
              </span>
            </Optional>
          </span>
        );
      case "CR":
        return (
          <span
            onClick={() => item?.detailsFunction(item, "VIEW", props.isMobile)}
            className="mobile-table-icon add-cursor"
          >
            <Icon icon="back" />
          </span>
        );
      case "VIEW":
        return (
          <span
            onClick={() => item?.detailsFunction(item, "VIEW", props.isMobile)}
            className="add-cursor table-action-icon view"
          >
            <i className="pi pi-eye" />
          </span>
        );
      case "DOWNLOAD":
        return (
          <span className="dcir-tb-action-position">
            <Optional
              show={HELPER.CAN_PERFORM_ACTION(props?.authorities, "DOWNLOAD")}
            >
              <span className="dcir-show">
                <span
                  onClick={() =>
                    item?.detailsFunction(item, "DOWNLOAD", props.isMobile)
                  }
                  className="p-ml-4 add-cursor table-action-icon"
                >
                  <i className="pi pi-download" />
                </span>
              </span>
            </Optional>
          </span>
        );
      case "RETRY":
        return (
          <span className="dcir-tb-action-position">
            <Optional
              show={HELPER.CAN_PERFORM_ACTION(props?.authorities, "RETRY")}
            >
              <span className="dcir-show">
                <span
                  onClick={() =>
                    item?.detailsFunction(item, "RETRY", props.isMobile)
                  }
                  className="p-ml-4 add-cursor table-action-icon"
                >
                  <i className="pi pi-refresh" />
                </span>
              </span>
            </Optional>
          </span>
        );
      case "EDIT":
        return (
          <span className="dcir-tb-action-position">
            <Optional
              show={HELPER.CAN_PERFORM_ACTION(props?.authorities, "UPDATE")}
            >
              <span className="dcir-show">
                <span
                  onClick={() =>
                    item?.detailsFunction(item, "UPDATE", props.isMobile)
                  }
                  className="add-cursor table-action-icon"
                >
                  <i className="pi pi-pencil" />
                </span>
              </span>
            </Optional>
          </span>
        );
      default:
        return <span />;
    }
  };

  const headers = () => {
    return props.headers.map((item, index) => {
      return (
        <div key={index.toString()}>
          <>
            <div
              className={`dcir-column ${
                item.label === "checkbox" ? "checkbox-col" : ""
              }`}
            >
              <Optional show={item.label !== "checkbox"}>
                <p>{item.label}</p>
              </Optional>
              <Optional show={item.label === "checkbox"}>
                <input
                  type="checkbox"
                  className="checkbox"
                  onChange={props.selectAllItems}
                />
              </Optional>
            </div>
          </>
        </div>
      );
    });
  };

  const transformView = (itemCase, value, type) => {
    let result = value;
    if (itemCase) {
      SpecialLabelCases.forEach((e) => {
        if (e.case === itemCase) {
          if (type === "dateOnly") {
            result = HELPER.FORMAT_DATE_ONLY(value);
          } else if (
            type === "settlementStatus" &&
            isManualSettlementPaymentEnabled === "true" &&
            value.toLowerCase() === "started"
          ) {
            result = HELPER.SETTLEMENT_WARNING_STATUS(value);
          } else {
            result = e.action(value, itemCase);
          }
        }
      });
    }
    return result ? result : result === 0 ? result : "___";
  };

  const tableContent = (item, label, type = null) => {
    if (label === "actions") {
      return (
        <div key={`${item.id} ${label}`} className="dcir-column">
          {tableAction(item[label], item)}
        </div>
      );
    } else {
      return (
        <div
          className={`dcir-column ${
            label === "checkbox" ? "checkbox-col" : ""
          }`}
        >
          <Optional show={label !== "checkbox"}>
            <p>{transformView(label, item[label], type)}</p>
          </Optional>
          <Optional show={label === "checkbox"}>
            <input
              type="checkbox"
              className="checkbox"
              onChange={() => props.selectItem(item.value)}
            />
          </Optional>
        </div>
      );
    }
  };

  const tableValues = () => {
    return props.items.map((item, index) => {
      return (
        <div key={index.toString()} className="dcir-row table-body-text">
          <>
            {props.headers.map((headerContent, index) => {
              return (
                <div key={index.toString()}>
                  {tableContent(item, headerContent.value, headerContent.type)}
                </div>
              );
            })}
          </>
        </div>
      );
    });
  };

  return (
    <div style={{ position: "relative" }} className="table">
      <div
        style={{ display: props?.isReload ? "block" : "none" }}
        onClick={() => props?.reload()}
        className="custom-table-refresh"
      >
        <span>
          <i className="pi pi-refresh p-px-1" />
          <span className="p-px-1">Reset</span>
        </span>
      </div>
      <div className="table-container">
        <div
          style={{ marginTop: props.isReload ? "1.4em" : "0" }}
          className="table-card"
        >
          <div className="dcir-row table-header">{headers()}</div>
          {tableValues()}
        </div>
      </div>
      <div className="custom-page-card">
        <CustomPagination
          totalPages={props.totalPages}
          totalItems={props.totalItems}
          currentPage={props.currentPage}
          range={props.range}
          nextPage={props.nextPage}
          prevPage={props.prevPage}
          goToFirstPage={props.goToFirstPage}
          goToLastPage={props.goToLastPage}
          goToPage={props.goToPage}
        />
      </div>
    </div>
  );
}
