import React, { useCallback, useEffect, useRef, useState } from "react";
import { CustomBreadcrumb } from "../../../shared/components/custom-breadcrumb/custom-breadcrumb";
import { CustomTable } from "../../../shared/components/custom-table/custom-table";
import { CustomLoader } from "../../../shared/components/custom-loader/custom-loader";
import { CustomModal } from "../../../shared/components/custom-modal/custom-modal";
import { Toast } from "primereact/toast";
import { HELPER } from "../../../shared/helper/helper";
import ErrorBoundary from "../../../shared/components/error-boundary/error-boundary";
import { WorkflowRequestDetails } from "./workflow-request-details";
import { BACKOFFICE_SERVICE } from "../../../core/services/backoffice-service";
import { WorkflowRequestsFilter } from "./workflow-requests-filter";
import { Optional } from "../../../shared/components/optional/optional";

export function WorkflowRequests(props) {
  const toast = useRef(null);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [error, setError] = useState("");
  const [search, setSearch] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [range] = useState(5);
  const [emptyText, setEmptyText] = useState("");
  const [currentModalIndex, setCurrentModalIndex] = useState(0);
  const tableHeaders = [
    { label: "Action Name", value: "description" },
    { label: "Status", value: "requestStatus" },
    { label: "Created On", value: "createdOn" },
    { label: "Updated On", value: "lastModifiedOn" },
    { label: "Actions", value: "actions" },
  ];
  const authorities = [
    { label: "VIEW", value: "bo_view_all_workflow_requests" },
  ];
  const [workflowRequests, setWorkflowRequests] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [searchParams, setSearchParams] = useState(null);
  const [requestData, setRequestData] = useState([]);
  const [statusBeforeChange, setStatusBeforeChange] = useState(null);
  const [actionName, setActionName] = useState(null);
  const [statusAfterChange, setStatusAfterChange] = useState(null);
  const [workflowRequestId, setWorkflowRequestId] = useState(null);
  const [requestInitiator, setInitiator] = useState(null);
  const [requestStatus, setRequestStatus] = useState("");
  const [requestAuthority, setRequestAuthority] = useState("");
  const [targetRecordData, setTargetRecordData] = useState(null);

  const openAction = useCallback((e, action, isMobile) => {
    // eslint-disable-next-line default-case
    switch (action) {
      case "VIEW":
        let arr = [];
        arr.push({
          label: "Action",
          value: e?.description,
        });
        arr.push({
          label: "Initiated By",
          value: e?.initiator,
        });
        arr.push({
          label: "Date Created",
          value: e?.createdOn,
          itemCase: "createdOn",
        });
        arr.push({
          label: "Status",
          value: e?.requestStatus,
          itemCase: "status",
        });
        arr.push({
          label: "Record Id",
          value: e?.targetRecordId,
          // itemCase: "status",
        });
        if (e?.authorizer) {
          arr.push({
            label: "Authorized By",
            value: e?.authorizer,
          });
          arr.push({
            label: "Date Authorized",
            value: e?.lastModifiedOn,
            itemCase: "lastModifiedOn",
          });
          arr.push({
            label: "Comment",
            value: e?.authorizersComment,
          });
        }

        if (e?.beforeData || e?.afterData) {
          if (e?.beforeData) {
            const beforeData = JSON.parse(e?.beforeData);
            setStatusBeforeChange(beforeData);
          }
          if (e?.afterData) {
            const afterData = JSON.parse(e?.afterData);
            setStatusAfterChange(afterData[0]?.value);
          }
        } else {
          setStatusAfterChange(null);
          setStatusBeforeChange(null);
        }
        // const val = JSON.parse(e?.actionParameters);
        setWorkflowRequestId(e?.reference);
        setActionName(e?.description);
        setInitiator(e?.initiator);
        setRequestData(arr);
        setRequestAuthority(e?.requiredAuthority);
        setRequestStatus(e?.requestStatus);
        if (e?.targetRecordData) {
          // Check if the value can be parsed
          if (e?.targetRecordData.charAt(0) === "{") {
            setTargetRecordData(JSON.parse(e?.targetRecordData));
          } else {
            setTargetRecordData(e?.targetRecordData);
          }
        }
        openModal(0, isMobile);
        break;
    }
  }, []);

  const getWorkflowRequests = useCallback(() => {
    setCurrentIndex(0);
    setWorkflowRequests([]);
    setVisible(false);
    const params = HELPER.TO_URL_STRING({
      page: pageNo,
      size: 10,
      ...searchParams,
    });

    BACKOFFICE_SERVICE.GET_WORKFLOW_REQUESTS(params, props.requestType)
      .then((data) => {
        const result = data?.result?.content;
        if (!result?.length) {
          setEmptyText("No results found ...");
        } else {
          let arr = [];
          setTotalItems(data?.result?.totalElements);
          setTotalPages(data?.result.totalPages);
          data?.result?.content?.forEach((e) => {
            arr.push({
              ...e,
              actions: "VIEW",
              detailsFunction: openAction,
            });
          });
          setWorkflowRequests(arr);
        }
        setError(null);
        setCurrentIndex(1);
        setLoading(false);
      })
      .catch((error) => {
        setError(HELPER.PROCESS_ERROR(error));
        setCurrentIndex(1);
        setLoading(false);
      });
  }, [openAction, pageNo, searchParams, props.requestType]);

  function onHide() {}

  function openModal(index, isMobile) {
    setCurrentModalIndex(index);
    if (isMobile) {
      setCurrentIndex(2);
    } else {
      setVisible(true);
    }
  }

  useEffect(() => {
    getWorkflowRequests();
  }, [getWorkflowRequests]);

  function reload(pageNo = 0) {
    setPageNo(pageNo);
    setLoading(true);
    setSearch(false);
    setCurrentIndex(0);
    setSearchParams(null);
    getWorkflowRequests();
  }

  function closeModal(isReload, isCancelButtonClick) {
    if (visible) {
      setVisible(false);
    }
    if (currentIndex === 2) {
      setCurrentIndex(1);
    }
    if (isReload === true && !isCancelButtonClick) {
      reload(pageNo);
    }
  }

  const mobileModal = () => {
    // eslint-disable-next-line default-case
    switch (currentModalIndex) {
      case 0:
        return (
          <WorkflowRequestDetails
            closeModal={closeModal}
            requestData={requestData}
            actionName={actionName}
            statusBeforeChange={statusBeforeChange}
            statusAfterChange={statusAfterChange}
            requestId={workflowRequestId}
            requestInitiator={requestInitiator}
            requestStatus={requestStatus}
            requiredAuthority={requestAuthority}
            targetRecordData={targetRecordData}
          />
        );
      case 1:
        return (
          <WorkflowRequestsFilter
            closeModal={closeModal}
            searchFunction={(e) => setSearchParams(e)}
          />
        );
    }
  };

  const modalContent = () => {
    // eslint-disable-next-line default-case
    switch (currentModalIndex) {
      case 0:
        return (
          <WorkflowRequestDetails
            closeModal={closeModal}
            requestData={requestData}
            actionName={actionName}
            statusBeforeChange={statusBeforeChange}
            statusAfterChange={statusAfterChange}
            requestId={workflowRequestId}
            requestInitiator={requestInitiator}
            requestStatus={requestStatus}
            requiredAuthority={requestAuthority}
            targetRecordData={targetRecordData}
          />
        );
      case 1:
        return (
          <WorkflowRequestsFilter
            closeModal={closeModal}
            searchFunction={(e) => setSearchParams(e)}
          />
        );
    }
  };

  const requeryManagementView = () => {
    // eslint-disable-next-line default-case
    switch (currentIndex) {
      case 0:
        return (
          <div className="loading-container">
            <CustomLoader loadingText="" />
          </div>
        );
      case 1:
        return (
          <CustomTable
            isReload={true}
            authorities={authorities}
            totalPages={totalPages}
            totalItems={totalItems}
            currentPage={pageNo + 1}
            range={range}
            emptyText={emptyText}
            search={search}
            reload={reload}
            error={error}
            items={workflowRequests}
            headers={tableHeaders}
            nextPage={() => setPageNo(pageNo + 1)}
            prevPage={() => setPageNo(pageNo - 1)}
            goToFirstPage={() => setPageNo(0)}
            goToLastPage={() => setPageNo(totalPages - 1)}
            goToPage={(pageNo) => setPageNo(pageNo)}
          />
        );
      case 2:
        return (
          <div className="mobile-modal-container">
            <div className="custom-card">
              <div className="mobile-card-position">{mobileModal()}</div>
            </div>
          </div>
        );
    }
  };

  return (
    <ErrorBoundary>
      <div className="workflow-requests">
        <Toast ref={toast} />
        <div className={currentModalIndex === 1 ? "filter" : ""}>
          <CustomModal
            closeModal={closeModal}
            onHide={onHide}
            visible={visible}
            modalContent={modalContent}
          />
        </div>
        <div className="page-title p-text-left">Workflow Requests</div>
        <div className="p-mt-2">
          <CustomBreadcrumb page={"Workflow Requests"} />
        </div>
        <div className="floating-buttons desktop-scree">
          <div className="p-grid">
            <div className="p-col-7"></div>
            <div className="p-col-5">
              <div className="p-grid">
                <div className="p-col-6"></div>
                <div className="p-col-6">
                  <Optional
                    show={HELPER.HAS_AUTHORITY("bo_view_all_workflow_requests")}
                  >
                    <button
                      disabled={loading || !workflowRequests.length}
                      onClick={() => openModal(1, false)}
                      className="primary-button hide-btn-text"
                    >
                      <i className="pi pi-filter" />
                      <span className="hide-btn-text"> Filter</span>
                    </button>
                  </Optional>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>{requeryManagementView()}</div>
      </div>
    </ErrorBoundary>
  );
}
