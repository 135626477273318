import React, { useCallback, useEffect, useState } from "react";
import { CustomLoader } from "../../../../shared/components/custom-loader/custom-loader";
import { CustomTable } from "../../../../shared/components/custom-table/custom-table";
import { CustomModal } from "../../../../shared/components/custom-modal/custom-modal";
import { Optional } from "../../../../shared/components/optional/optional";
import { BACKOFFICE_SERVICE } from "../../../../core/services/backoffice-service";
import { HELPER } from "../../../../shared/helper/helper";
import { ReconFileDownload } from "../statement-upload/download-recon-statement";
// import { FormDropdown } from "../../../../shared/components/form-component/form-dropdown";
import { SpecialLabelCases } from "../../../../shared/models/utilities";
import { BACKOFFICE_API } from "../../../../core/apis/backoffice-apis";
import { DisputeInitiationConfirmation } from "./dispute-initiation-confirmation";
import { ResolveException } from "./resolve-exception";
import { SERVICES } from "../../../../core/services/services";
import { DisputeBreakdown } from "../../disputes/dispute-breakdown";
import { BACKOFFICE_CONFIGS } from "../../../../shared/constants";
import { FormRadioInput } from "../../../../shared/components/form-component/form-radio-input";

export function ReconFileTransactions(props) {
  const unmatched_account_entries = "Unmatched account entries";
  const unmatched_in_dcir_db = "Unmatched in DCIR DB";
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [emptyText, setEmptyText] = useState("No results found...");
  const [isSearch, setIsSearch] = useState(false);
  const [error, setError] = useState(null);
  // const [loading, setLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [visible, setVisible] = useState(false);
  const [searchParams, setSearchParams] = useState(null);
  const [currentModalIndex, setCurrentModalIndex] = useState(0);
  const [reportDownloadStatus] = useState(null);
  const [fileDownloadKey] = useState(null);
  const [exceptionType, setExceptionType] = useState(unmatched_account_entries);
  const [disputeLogCode, setDisputeLogCode] = useState(null);
  const [transDetails, setTransDetails] = useState([]);
  const [exceptionReferenceId, setExceptionReferenceId] = useState(null);
  // const [disputeBreakdownProps, setDisputeBreakdownProps] = useState(null);
  const [autoDisputeCreationStatus, setAutoDisputeCreationStatus] =
    useState(false);
  // const [disputeCreationStatus, setDisputeCreatio]

  // const exceptionTypes = [unmatched_account_entries, unmatched_in_dcir_db];

  const [tableHeaders, setTableHeaders] = useState([]);

  const getTableHeaders = useCallback(
    (obj) => {
      let properties = [];
      for (const property in obj) {
        if (obj[property]) {
          properties.push(property);
        }
      }
      const possibleTableHeaders = [
        "transactionDate",
        "narration",
        "maskedPan",
        "rrn",
        "stan",
        "amount",
        "terminalId",
        "isDebit",
        "disputeCreationStatus",
      ];
      let tableHeaders = [];
      properties.forEach((property) => {
        if (possibleTableHeaders.includes(property)) {
          switch (property) {
            case "transactionDate":
              tableHeaders.push({
                label: "Date",
                value: "transactionDate",
                type: "dateOnly",
              });
              break;
            case "narration":
              tableHeaders.push({
                label: "Narration",
                value: "narration",
              });
              break;
            case "maskedPan":
              tableHeaders.push({
                label: "Masked Pan",
                value: "maskedPan",
              });
              break;
            case "rrn":
              tableHeaders.push({
                label: "Rrn",
                value: "rrn",
              });
              break;
            case "stan":
              tableHeaders.push({
                label: "Stan",
                value: "stan",
              });
              break;
            case "amount":
              tableHeaders.push({
                label: "Amount",
                value: "amount",
              });
              break;
            case "terminalId":
              tableHeaders.push({
                label: "Terminal Id",
                value: "terminalId",
              });
              break;
            case "isDebit":
              tableHeaders.push({
                label: "Transaction Type",
                value: "transactionType",
              });
              break;
            default:
              break;
          }
        }
      });
      if (props.transactionType === "failed") {
        tableHeaders.push({
          label: "Dispute Status",
          value: "disputeCreationStatus",
        });
      }
      if (
        props.transactionType === "failed" ||
        exceptionType === unmatched_account_entries
      ) {
        tableHeaders.push({
          label: "Actions",
          value: "actions",
        });
      }
      setTableHeaders(tableHeaders);
    },
    [props.transactionType, exceptionType]
  );

  const saveTransactionDetails = useCallback((e) => {
    let arr = [];
    arr.push({
      label: "Transaction amount",
      value: e?.amount,
      itemCase: "transactionAmount",
    });
    // arr.push({
    //   label: "Charge amount",
    //   value: e?.chargeAmount,
    //   itemCase: "chargeAmount",
    // });
    arr.push({
      label: "Transaction Date",
      value: e?.transactionDate,
      itemCase: "transactionTime",
    });
    arr.push({
      label: "Response code",
      value: e?.responseCode || e?.transactionResponseCode,
      itemCase: "responseCode",
    });

    arr.push({ label: "Card acceptor Id", value: e?.cardAcceptorId });
    arr.push({ label: "RRN", value: e?.rrn });
    arr.push({ label: "STAN", value: e?.stan });
    arr.push({ label: "Masked pan", value: e?.maskedPan });
    arr.push({ label: "Terminal Id", value: e?.terminalId });
    arr.push({ label: "Bulk key", value: e?.bulkSettlementKey });
    setTransDetails(arr);
  }, []);

  const openAction = useCallback(
    (e) => {
      setDisputeLogCode(e?.logCode);
      // if (props.transactionType === "failed") {
      //   props.showDisputeDetails(e?.disputeLogCode);
      // } else {
      saveTransactionDetails(e);
      if (props.transactionType === "failed") {
        // getDisputeByTransactionKey(e?.transactionId);
      }
      if (
        props.transactionType === "exceptions" &&
        exceptionType === unmatched_account_entries
      ) {
        setExceptionReferenceId(e?.referenceId);
      }
      setCurrentPageIndex(2);
      // }
    },
    [saveTransactionDetails, exceptionType, props.transactionType]
  );

  const handleSuccess = useCallback(
    (data) => {
      const result = data?.result?.content;
      if (!result.length) {
        setEmptyText("No results found!");
      } else {
        let arr = [];
        setTotalItems(data?.result.totalElements);
        setTotalPages(data?.result.totalPages);
        getTableHeaders(result[0]);
        // setTransactions(result);
        result.forEach((e) => {
          arr.push({
            ...e,
            transactionType: e?.isDebit ? "Debit" : "Credit",
            actions:
              props.transactionType === "failed" ||
              props.transactionType === "exceptions"
                ? "CR"
                : "",
            detailsFunction: openAction,
          });
        });

        setTransactions(arr);
      }
      setError(null);
      setCurrentPageIndex(1);
      // setLoading(false);
    },
    [props.transactionType, getTableHeaders, openAction]
  );

  const getTransactions = useCallback(() => {
    // closeModal();
    setVisible(false);
    setTransactions([]);
    setCurrentPageIndex(0);
    let payload = {
      page: pageNo,
      size: 10,
      transactionDate: props.transactionDate,
      transactionIndicator: props.transactionIndicator,
      reconStatus: props.reconStatus,
    };
    if (searchParams) {
      setIsSearch(true);
      payload = {
        ...payload,
        ...searchParams,
      };
    }
    BACKOFFICE_SERVICE.GET_RECON_FILE_ACCOUNT_ENTRIES(payload)
      .then((data) => {
        handleSuccess(data);
      })
      .catch((error) => {
        setError(HELPER.PROCESS_ERROR(error));
      });
  }, [
    pageNo,
    searchParams,
    handleSuccess,
    props.reconStatus,
    props.transactionDate,
    props.transactionIndicator,
  ]);

  const getUnmatchedDBExceptions = useCallback(() => {
    // closeModal();
    setVisible(false);
    setTransactions([]);
    setCurrentPageIndex(0);
    let payload = {
      page: pageNo,
      size: 10,
      transactionDate: props.transactionDate,
    };
    if (searchParams) {
      setIsSearch(true);
      payload = {
        ...payload,
        ...searchParams,
      };
    }
    BACKOFFICE_SERVICE.MAKE_GET_REQUEST(
      BACKOFFICE_API.GET_UNMATCHED_DB_EXCEPTIONS,
      HELPER.TO_URL_STRING(payload)
    )
      .then((data) => {
        handleSuccess(data);
      })
      .catch((error) => {
        setError(HELPER.PROCESS_ERROR(error));
      });
  }, [pageNo, searchParams, handleSuccess, props.transactionDate]);

  useEffect(() => {
    if (props.transactionType !== "exceptions") {
      getTransactions();
    }
  }, [getTransactions, props.transactionType]);

  useEffect(() => {
    if (props.transactionType === "exceptions") {
      if (exceptionType === unmatched_account_entries) {
        getTransactions();
      } else {
        getUnmatchedDBExceptions();
      }
    }
  }, [
    exceptionType,
    props.transactionType,
    getTransactions,
    getUnmatchedDBExceptions,
  ]);

  useEffect(() => {
    function getAutoDisputeCreationStatus() {
      SERVICES.GET_SINGLE_CONFIGURATION(
        BACKOFFICE_CONFIGS.AUTO_DISPUTE_CREATION_STATUS
      )
        .then((data) => {
          setAutoDisputeCreationStatus(data?.result?.configValue);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    getAutoDisputeCreationStatus();
  }, []);

  function closeModal(reload) {
    setVisible(false);
    if (reload === true) {
      if (props.transactionType === "failed") {
        getTransactions();
      }
    }
  }

  function openModal(index) {
    setCurrentModalIndex(index);
    setVisible(true);
  }

  function reload() {
    setSearchParams(null);
    setPageNo(0);
    setIsSearch(false);
  }

  function showDisputeDetails() {
    setCurrentPageIndex(3);
  }

  const transformView = (itemCase, value) => {
    let result = value;
    if (itemCase) {
      SpecialLabelCases.forEach((e) => {
        if (e.case === itemCase) {
          result = e.action(value);
        }
      });
    }
    return result;
  };

  function currentView() {
    switch (currentPageIndex) {
      case 0:
        return <CustomLoader loadingText="Loading" />;
      case 1:
        return (
          <CustomTable
            isReload={true}
            totalPages={totalPages}
            totalItems={totalItems}
            currentPage={pageNo + 1}
            range={0}
            emptyText={emptyText}
            search={isSearch}
            reload={reload}
            error={error}
            items={transactions}
            headers={tableHeaders}
            nextPage={() => setPageNo(pageNo + 1)}
            prevPage={() => setPageNo(pageNo - 1)}
            goToFirstPage={() => setPageNo(0)}
            goToLastPage={() => setPageNo(totalPages - 1)}
            goToPage={(pageNo) => setPageNo(pageNo)}
          />
        );
      case 2:
        return (
          <div>
            <Optional show={props.transactionType === "failed"}>
              <div className="p-col-12 p-text-left">
                <div className={`info-card resolved`}>
                  <div className="">
                    <span className="info-card-text">
                      Dispute Log Code:{" "}
                      <button
                        className="bare-button"
                        onClick={showDisputeDetails}
                      >
                        {disputeLogCode}
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </Optional>
            {transDetails.map((details, index) => (
              <div
                key={index.toString()}
                className="p-grid dcir-row p-text-left"
              >
                <div className="p-col-6">
                  <p className="details-label">{details.label}:</p>
                </div>
                <div className="p-col-6">
                  <p className="details-value">
                    {transformView(details.itemCase, details.value)}
                  </p>
                </div>
              </div>
            ))}
          </div>
        );
      case 3:
        return (
          <DisputeBreakdown
            logCode={disputeLogCode}
            getDisputeByLogCode={true}
          />
        );
      default:
        break;
    }
  }

  const modalContent = () => {
    switch (currentModalIndex) {
      case 0:
        return (
          <ReconFileDownload
            closeModal={closeModal}
            fileKey={props.fileKey}
            reportDownloadStatus={reportDownloadStatus}
            downloadKey={fileDownloadKey}
            transactionDate={props.transactionDate}
            isTransactionsByDays={true}
          />
        );
      case 1:
        return (
          <ResolveException
            closeModal={closeModal}
            onSuccess={closeModal}
            referenceId={exceptionReferenceId}
          />
        );
      case 2:
        return (
          <DisputeInitiationConfirmation
            closeModal={closeModal}
            transactionDate={props.transactionDate}
          />
        );
      case 3:
        return (
          <DisputeInitiationConfirmation
            closeModal={closeModal}
            transactionDate={props.transactionDate}
            isRefresh={true}
          />
        );
      default:
        return;
    }
  };

  return (
    <div>
      <div>
        <CustomModal
          closeModal={closeModal}
          onHide={closeModal}
          visible={visible}
          modalContent={modalContent}
        />
      </div>
      <Optional show={currentPageIndex > 1}>
        <p
          onClick={() => setCurrentPageIndex(currentPageIndex - 1)}
          className="close-modal add-cursor p-ml-4 p-text-left"
        >
          <i className="pi pi-arrow-left"></i>
        </p>
      </Optional>
      <Optional
        show={
          props.transactionType === "failed" &&
          currentPageIndex === 1 &&
          autoDisputeCreationStatus === "true" &&
          transactions.length
        }
      >
        <div className="flex default end" style={{ margin: "1rem 0 3rem 0" }}>
          <button className="primary-button" onClick={() => openModal(2)}>
            Create Dispute
          </button>
          <button
            className="bare-button p-pl-5 p-pr-2"
            onClick={() => openModal(3)}
          >
            <i
              className="pi pi-refresh"
              style={{ fontSize: "2em", color: "#6d8c98" }}
            ></i>
          </button>
        </div>
      </Optional>
      <Optional
        show={props.transactionType === "exceptions" && currentPageIndex !== 2}
      >
        {/* <div className="p-grid p-mt-3 p-mb-5">
          <div className="p-col-4">
            <label>Exception Type:</label>
            <FormDropdown
              required={true}
              disabled={false}
              value={exceptionType}
              fn={(e) => setExceptionType(e?.target?.value)}
              options={exceptionTypes}
              placeholder="Exception Type"
            />
          </div>
        </div> */}
        <div className="flex default p-mb-4 p-mt-4 p-ml-0">
          <FormRadioInput
            id="account-exceptions"
            value={unmatched_account_entries}
            name="exception-type"
            checked={exceptionType === unmatched_account_entries}
            handleChange={(e) => setExceptionType(e?.target?.value)}
            label={unmatched_account_entries}
          />
          <div className="p-ml-3">
            <FormRadioInput
              id="db-exceptions"
              value={unmatched_in_dcir_db}
              name="exception-type"
              checked={exceptionType === unmatched_in_dcir_db}
              handleChange={(e) => setExceptionType(e?.target.value)}
              label={unmatched_in_dcir_db}
            />
          </div>
        </div>
      </Optional>
      <Optional
        show={
          props.transactionType === "exceptions" &&
          currentPageIndex === 2 &&
          exceptionType === unmatched_account_entries
        }
      >
        <div className="flex default end" style={{ margin: "1rem 0 3rem 0" }}>
          <button className="primary-button" onClick={() => openModal(1)}>
            Resolve
          </button>
        </div>
      </Optional>

      <div>{currentView()}</div>
    </div>
  );
}
