import "./dispute.css";
import { TabPanel, TabView } from "primereact/tabview";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { CustomLoader } from "../../../shared/components/custom-loader/custom-loader";
import { Icon } from "../../../shared/icons/icon";
import { SpecialLabelCases } from "../../../shared/models/utilities";
import { UpdateDispute } from "./update-dispute";
import { CustomModal } from "../../../shared/components/custom-modal/custom-modal";
import { useWindowSize } from "../../../core/custom-hooks/use-window-size";
import { MainContext } from "../../../../App";
// import { EmptyView } from "../../../shared/components/empty-view/empty-view";
import { ReopenDispute } from "./reopen-dispute";
import { HELPER } from "../../../shared/helper/helper";
import { SERVICES } from "../../../core/services/services";
import { ImageView } from "../../../shared/components/image-view/image-view";
import { Toast } from "primereact/toast";
import { DisputeRefundAttempts } from "./dispute-refund-attempts";
import { Optional } from "../../../shared/components/optional/optional";
import { TransactionBreakdown } from "../transactions/transaction-breakdown";
import { DisputeAuditTrail } from "./dispute-audit-trail";
import { DECLINED, SYSTEM_ACCEPTED } from "../../../shared/constants";

export function DisputeBreakdown(props) {
  const toast = useRef(null);
  const mainContext = useContext(MainContext);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [visible, setVisible] = useState(false);
  const [currentModalIndex, setCurrentModalIndex] = useState(1);
  const [error, setError] = useState(null);
  const [detailsContent, setDetailsContent] = useState([]);
  // const [disputeStatus, setDisputeStatus] = useState();
  const [countdown, setCountdown] = useState(null);
  const [isDisputeExpired, setIsDisputeExpired] = useState(false);
  const [isDisputePending, setIsDisputePending] = useState(null);
  const [resolutionStatus, setResolutionStatus] = useState(null);
  const [transactionKey, setTransactionkey] = useState(null);
  const [showReceipt, setShowReceipt] = useState(false);
  const [isDisputeExpiryClose, setIsDisputeExpiryClose] = useState(false);
  const [showRefundAttempts, setShowRefundAttempts] = useState(false);
  const counter = useRef(null);
  const windowSize = useWindowSize();
  const [transactionResponseCode, setTransactionResponseCode] = useState(null);
  const [transactionDetails, setTransactionDetails] = useState([]);
  const [transactionSearchKey, setTransactionSearchKey] = useState(null);
  const [showDisputeAuditTrail, setShowDisputeAuditTrail] = useState(false);
  const [disputeStatus, setDisputeStatus] = useState(null);
  const [shouldReloadOnGoingBack, setShouldReloadOnGoingBack] = useState(false);

  function isTabMobile() {
    return windowSize.width <= 930;
  }

  const showTransactionDetails = useCallback((e) => {
    setTransactionResponseCode(e?.transactionResponseCode);
    let arr = [];
    setTransactionDetails([]);
    setTransactionResponseCode(e?.responseCode);
    setTransactionSearchKey(e?.transactionSearchKey);
    arr.push({
      label: "Transaction Amount",
      value: e?.transactionAmount,
      itemCase: "transactionAmount",
    });
    arr.push({
      label: "Charge Amount",
      value: e?.chargeAmount,
      itemCase: "chargeAmount",
    });
    arr.push({
      label: "Transaction Time",
      value: e?.transactionTime,
      itemCase: "transactionTime",
    });
    arr.push({
      label: "Response Code",
      value: e?.disputeTransactionDetail?.responseCode,
      itemCase: "responseCode",
    });
    arr.push({
      label: "Card Acceptor Id",
      value: e?.disputeTransactionDetail?.cardAcceptorId,
    });
    arr.push({ label: "RRN", value: e?.disputeTransactionDetail?.rrn });
    arr.push({ label: "STAN", value: e?.disputeTransactionDetail?.stan });

    arr.push({
      label: "Masked Pan",
      value: e?.disputeTransactionDetail?.maskedPan,
    });
    arr.push({
      label: "Terminal Id",
      value: e?.disputeTransactionDetail?.terminalId,
    });

    setTransactionDetails(arr);
    setCurrentIndex(5);
  }, []);

  const initializeCountdown = useCallback(() => {
    counter.current = setInterval(function () {
      let countdown = HELPER.SET_COUNTDOWN(props.disputeExpiringDate);
      if (countdown) {
        setCountdown(countdown);
      } else {
        clearInterval(counter);
        setIsDisputeExpired(true);
      }
    }, 1000);
  }, [props.disputeExpiringDate]);

  const checkIfExpiryDateIsClose = useCallback(() => {
    let expiryHour = HELPER.GET_EXPIRY_HOUR(props.disputeExpiringDate);
    let criticalHour = 5;
    if (expiryHour !== null) {
      if (expiryHour <= criticalHour) {
        setIsDisputeExpiryClose(true);
      }
    }
  }, [props.disputeExpiringDate]);

  const getTransactionKey = useCallback(() => {
    let transactionKey = props.disputeDetails.filter((item) => {
      return item.label === "Transaction search key";
    })[0];
    setTransactionkey(transactionKey.value);
  }, [props.disputeDetails]);

  const getDispute = useCallback(() => {
    // setDisputeStatus(props.disputeStatus);
    setDetailsContent(props.disputeDetails);
    setCurrentIndex(1);
  }, [props.disputeDetails]);

  const checkDisputeStatus = useCallback(() => {
    let disputeStatus = props.disputeDetails.filter((item) => {
      return item.label === "Dispute status";
    })[0];
    let resolutionStatus = props.disputeDetails.filter((item) => {
      return item.label === "Resolution status";
    })[0];
    setResolutionStatus(resolutionStatus.value);
    if (disputeStatus.value.toLowerCase() === "pending") {
      setIsDisputePending(true);
      initializeCountdown();
    } else {
      setIsDisputePending(false);
    }
  }, [props.disputeDetails, initializeCountdown]);

  const getDisputeByLogCode = useCallback(() => {
    SERVICES.GET_DISPUTE_BY_LOG_CODE(props.logCode)
      .then((data) => {
        setDetailsContent(
          HELPER.GET_DISPUTE_DETAILS_ARRAY(data?.result, props.isReversal)
        );
        // console.log("data", data);
        setCurrentIndex(1);
      })
      .catch((error) => {
        setError(HELPER.PROCESS_ERROR(error));
        toast.current.show({
          severity: "error",
          summary: "Error getting dispute details!",
          detail: HELPER.PROCESS_ERROR(error, "TOAST"),
          life: 10000,
        });
        setCurrentIndex(1);
      });
  }, [props.logCode]);

  useEffect(() => {
    if (props.getDisputeByLogCode) {
      getDisputeByLogCode();
    } else {
      getTransactionKey();
      getDispute();
      checkDisputeStatus();
      checkIfExpiryDateIsClose();
    }
    // getLogCode();
    setDisputeStatus(props.disputeStatus);
    return () => {
      clearInterval(counter.current);
    };
  }, [
    getTransactionKey,
    getDispute,
    checkDisputeStatus,
    checkIfExpiryDateIsClose,
    props.disputeStatus,
    getDisputeByLogCode,
    props.getDisputeByLogCode,
  ]);

  function getDisputeDetails() {
    const userType = props.userType === "front-office" ? "fo" : null;
    SERVICES.GET_DISPUTE_BY_TRANSACTION_KEY(transactionKey, userType)
      .then((data) => {
        const res = data?.result;
        setDisputeStatus(res.status);
        setResolutionStatus(res?.resolutionStatus);
        setDetailsContent(HELPER.GET_DISPUTE_DETAILS_ARRAY(res));
        setShouldReloadOnGoingBack(true);
      })
      .catch((error) => {
        setError(HELPER.PROCESS_ERROR(error));
        toast.current.show({
          severity: "error",
          summary: "Error getting dispute details!",
          detail: HELPER.PROCESS_ERROR(error, "TOAST"),
          life: 10000,
        });
        setCurrentIndex(1);
      });
  }

  // function updateDisputeDetails(dispute) {
  //   setDetailsContent(HELPER.GET_DISPUTE_DETAILS_ARRAY(dispute));
  // }

  function viewDisputeRefundAttempts() {
    setShowRefundAttempts(true);
  }

  function viewDisputeAuditTrail() {
    setShowDisputeAuditTrail(true);
  }

  const transformView = (itemCase, value) => {
    let result = value;
    if (itemCase) {
      SpecialLabelCases.forEach((e) => {
        if (e.case === itemCase) {
          result = e.action(value);
        }
      });
    }
    return result;
  };

  const detailsView = detailsContent.map((detail, index) => (
    <div key={index.toString()} className="p-grid dcir-row">
      <div className="p-col-6">
        <p className="details-label">{detail.label}:</p>
      </div>
      <div className="p-col-6">
        <div>
          <Optional show={detail.action === "viewTransaction"}>
            <p
              className="details-value cursor-pointer underline"
              style={{ color: "#0371A0" }}
              onClick={() => showTransactionDetails(detail.transactionDetails)}
            >
              {transformView(detail.itemCase, detail.value)}
            </p>
          </Optional>
          <Optional show={!detail.action}>
            <p className="details-value">
              {transformView(detail.itemCase, detail.value)}
            </p>
          </Optional>
        </div>

        <Optional show={detail.label === "Refund attempts"}>
          <button
            className="p-button-success view-details"
            onClick={viewDisputeRefundAttempts}
          >
            View Details
          </button>
        </Optional>
        <Optional
          show={detail.label === "Resolution status" && props.disputeAuditTrail}
        >
          <button
            className="p-button-success view-details"
            onClick={viewDisputeAuditTrail}
          >
            View Details
          </button>
        </Optional>
      </div>
    </div>
  ));

  const errorView = () => {
    return (
      <div>
        <div className="empty-container">
          <Icon icon="error-message-icon" />
          <div>
            <p className="empty-text">{error}</p>
          </div>
          <div className="p-mt-3">
            <div style={{ maxWidth: "200px" }}>
              <button onClick={() => getDispute()} className="primary-button">
                Reload
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const showResolveButton = () => {
    if (props.disputeStatus === "RESOLVED") {
      return <div />;
    } else {
      if (props.loggedBy === mainContext?.mainState?.username) {
        return <div />;
      } else {
        return (
          <button
            onClick={() => openModal(1)}
            className="primary-button p-ml-2"
          >
            Resolve
          </button>
        );
      }
    }
  };

  const showReopenButton = () => {
    if (
      resolutionStatus === DECLINED ||
      (disputeStatus === SYSTEM_ACCEPTED && props.userType !== "front-office")
    ) {
      return (
        <button onClick={() => openModal(2)} className="tertiary-button">
          Reopen
        </button>
      );
    } else {
      return <div />;
    }
  };

  function resolvedSuccessful() {
    props.goBack(true);
  }

  function handleGoBack() {
    if (currentIndex > 1 || showRefundAttempts || showDisputeAuditTrail) {
      setShowRefundAttempts(false);
      setShowDisputeAuditTrail(false);
      setCurrentIndex(1);
    } else {
      props.goBack(shouldReloadOnGoingBack);
    }
  }

  const disputeTabView = () => {
    return (
      <div
        style={{ backgroundColor: "#ffffff", paddingTop: "1em" }}
        className="p-shadow-1 p-pb-5"
      >
        <div className="p-text-left p-ml-3 p-mt-2 p-pb-3">
          <span onClick={handleGoBack} className="add-cursor">
            <span>
              <Icon icon="go-back-icon" />
            </span>
          </span>
        </div>
        <TabView>
          <TabPanel
            header={
              showRefundAttempts
                ? "Dispute Refund Attempts"
                : showDisputeAuditTrail
                ? "Dispute Status Log"
                : "Dispute Details"
            }
          >
            <Optional show={showRefundAttempts}>
              <DisputeRefundAttempts
                transactionSearchKey={props.transactionSearchKey}
                requestType={props.userType}
              />
            </Optional>
            <Optional show={showDisputeAuditTrail}>
              <DisputeAuditTrail disputeAuditTrail={props?.disputeAuditTrail} />
            </Optional>
            <Optional show={!showRefundAttempts && !showDisputeAuditTrail}>
              <div className="p-text-left">
                <div className="p-grid p-mt-2">
                  <div className="p-col-8" />
                  <div className="p-col-4">
                    <Optional
                      show={
                        HELPER.HAS_AUTHORITY(
                          "bo_dcir_update_dispute_transaction"
                        ) || HELPER.HAS_AUTHORITY("fo_dcir_update_disputes")
                      }
                    >
                      <div style={{ width: "135px", float: "right" }}>
                        {showResolveButton()}
                      </div>
                    </Optional>
                    <Optional
                      show={
                        HELPER.HAS_AUTHORITY(
                          "bo_dcir_reopen_dispute_transaction"
                        ) ||
                        HELPER.HAS_AUTHORITY(
                          "fo_dcir_reopen_dispute_transaction"
                        )
                      }
                    >
                      <div style={{ width: "135px", float: "right" }}>
                        {showReopenButton()}
                      </div>
                    </Optional>
                  </div>
                </div>
                <Optional
                  show={isDisputePending && !isDisputeExpired && countdown}
                >
                  <div className="p-col-12">
                    <div className="info-card">
                      <div className="dcir-show dispute-lodged-title">
                        {/* <span>TAT Countdown: {countdown}</span> */}
                      </div>
                      <div className="p-mt-3">
                        <span
                          className={`info-card-text ${
                            isDisputeExpiryClose ? "p-error" : ""
                          }`}
                        >
                          Expires in: {countdown}
                        </span>
                      </div>
                    </div>
                  </div>
                </Optional>

                <div>
                  <div className="centralize">
                    <p
                      className={
                        isTabMobile()
                          ? "custom-title p-ml-4 dcir-show"
                          : "dcir-hide"
                      }
                    >
                      Dispute details
                    </p>
                    {detailsView}
                    <Optional
                      show={props.receipt && resolutionStatus === "DECLINED"}
                    >
                      <div className="p-grid dcir-row">
                        <div className="p-col-6">
                          <p className="details-label">Receipt:</p>
                        </div>
                        <div className="p-col-6">
                          <p className="details-value">
                            <button
                              className="view-receipt bare-button"
                              onClick={() => setShowReceipt(true)}
                            >
                              View
                            </button>
                          </p>
                        </div>
                      </div>
                    </Optional>
                  </div>
                </div>
              </div>
            </Optional>
          </TabPanel>
        </TabView>
      </div>
    );
  };

  const modalContent = () => {
    // eslint-disable-next-line default-case
    switch (currentModalIndex) {
      case 1:
        return (
          <UpdateDispute
            resolvedSuccessful={resolvedSuccessful}
            logCode={props.logCode}
            closeModal={closeModal}
            userType={props.userType}
          />
        );
      case 2:
        return (
          <ReopenDispute
            closeModal={closeModal}
            logCode={props.logCode}
            transactionKey={transactionKey}
            getDisputeDetails={getDisputeDetails}
            userType={props.userType}
            disputeStatus={props.disputeStatus}
          />
        );
    }
  };

  function onHide() {}

  function closeModal(reload) {
    if (props.mobile) {
      setCurrentIndex(1);
    } else {
      setVisible(false);
    }
    // if (reload) {
    //   // setCurrentIndex(0);
    //   // getDispute();
    // }
  }

  function openModal(index) {
    if (props.mobile) {
      setCurrentIndex(3);
    } else {
      setCurrentModalIndex(index);
      setVisible(true);
    }
  }

  const disputeView = () => {
    // eslint-disable-next-line default-case
    switch (currentIndex) {
      case 0:
        return (
          <div className="p-mt-5">
            <CustomLoader loadingText="Loading dispute details ..." />
          </div>
        );
      case 1:
        return disputeTabView();
      case 2:
        return errorView();
      case 3:
        return (
          <div className="custom-mobile-table-card">
            <UpdateDispute
              resolvedSuccessful={resolvedSuccessful}
              logCode={props.logCode}
              closeModal={closeModal}
              userType={props.userType}
            />
          </div>
        );
      case 4:
        return (
          <DisputeRefundAttempts
            disputeId={props.disputeId}
            goBack={() => setCurrentIndex(1)}
            requestType={props.userType}
          />
        );
      case 5:
        return (
          <TransactionBreakdown
            responseCode={transactionResponseCode}
            transactionSearchKey={transactionSearchKey}
            goBack={() => setCurrentIndex(1)}
            details={transactionDetails}
            mobile={isTabMobile()}
            userType={props.userType}
            isDisputePage={true}
          />
        );
    }
  };

  return (
    <div>
      <div>
        <Toast ref={toast} />
      </div>
      <div>
        <CustomModal
          closeModal={closeModal}
          onHide={onHide}
          visible={visible}
          modalContent={modalContent}
        />
      </div>
      <Optional show={showReceipt}>
        <ImageView image={props.receipt} close={() => setShowReceipt(false)} />
      </Optional>
      {disputeView()}
    </div>
  );
}
