import React, { useCallback, useEffect, useRef, useState } from "react";
import { CustomBreadcrumb } from "../../../shared/components/custom-breadcrumb/custom-breadcrumb";
import { CustomTable } from "../../../shared/components/custom-table/custom-table";
import { SERVICES } from "../../../core/services/services";
import "./merchant.css";
import { CustomLoader } from "../../../shared/components/custom-loader/custom-loader";
import { CustomModal } from "../../../shared/components/custom-modal/custom-modal";
import { DetailsBreakDown } from "../../../shared/components/details-break-down/details-break-down";
import { CustomConfirmDialog } from "../../../shared/components/custom-confirm-dialog/custom-confirm-dialog";
import { Toast } from "primereact/toast";
import { SuperAgentForm } from "./super-agent-form";
import { HELPER } from "../../../shared/helper/helper";
import ErrorBoundary from "../../../shared/components/error-boundary/error-boundary";
import { Optional } from "../../../shared/components/optional/optional";
import { SuperAgentsFilter } from "./super-agents-filter";
import { BACKOFFICE_SERVICE } from "../../../core/services/backoffice-service";
import { BACKOFFICE_API } from "../../../core/apis/backoffice-apis";
import { BulkSuperAgentsCreationForm } from "./bulk-super-agents-creation-form";

export function SuperAgents() {
  const toast = useRef(null);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [error, setError] = useState("");
  const [search, setSearch] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  // const [modalLoadingText, setModalLoadingText] = useState("");
  const [confirmText, setConfirmText] = useState("");
  const [itemIdForDeactivation, setItemIdForDeactivation] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [range] = useState(5);
  const [confirmModalError, setConfirmModalError] = useState(null);
  const [confirmModalSuccess, setConfirmModalSuccess] = useState(null);
  const [breakDownTitle, setBreakDownTitle] = useState("");
  const [merchantForEdit, setMerchantForEdit] = useState(null);
  const [merchantSettlementData, setMerchantSettlementData] = useState(null);
  const [merchantAction, setMerchantAction] = useState("DEACTIVATE");
  const [emptyText, setEmptyText] = useState("");
  const [currentModalIndex, setCurrentModalIndex] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [gettingMerchantDetails, setGettingMerchantDetails] = useState(false);
  const [searchParams, setSearchParams] = useState(null);

  const tableHeaders = [
    { label: "Super Agent Id", value: "merchantId" },
    { label: "Super Agent Name", value: "merchantName" },
    { label: "Email", value: "mainEmail" },
    { label: "Phone Number", value: "phoneNumber" },
    // { label: "Support Email", value: "supportEmail" },
    { label: "Status", value: "active" },
    { label: "Actions", value: "actions" },
  ];

  const merchantAuthorities = [
    { label: "UPDATE", value: "bo_dcir_update_merchant" },
    { label: "DEACTIVATE", value: "bo_dcir_update_merchant_status" },
    { label: "ACTIVATE", value: "bo_dcir_update_merchant_status" },
  ];

  const [details, setDetails] = useState([]);

  const [participants, setParticipants] = useState([]);

  const openModal = useCallback((index, isMobile) => {
    setCurrentModalIndex(index);
    if (isMobile) {
      setCurrentIndex(2);
    } else {
      setVisible(true);
    }
  }, []);

  function deactivateUser(e) {
    setModalLoading(true);
    setConfirmModalError(null);
    setConfirmModalSuccess(null);
    const params = {
      id: e,
      status: merchantAction === "DEACTIVATE" ? false : true,
    };
    SERVICES.DEACTIVATE_MERCHANT(params)
      .then((data) => {
        setConfirmModalSuccess(
          `Super Agent ${merchantAction.toLowerCase()}d successfully`
        );
        setModalLoading(false);
      })
      .catch((error) => {
        setConfirmModalError(HELPER.PROCESS_ERROR(error));
        setModalLoading(false);
      });
  }

  const getMerchantDetails = useCallback(
    (id, isMobile) => {
      setGettingMerchantDetails(true);
      openModal(1, isMobile);
      let arr = [];
      setDetails([]);
      SERVICES.GET_MERCHANT(id)
        .then((data) => {
          const e = data.result;
          arr.push({ label: "Super Agent", value: e?.merchantName });
          arr.push({ label: "email", value: e?.mainEmail });
          arr.push({ label: "Phone number", value: e?.phoneNumber });
          arr.push({
            label: "Card Acceptor Id",
            value: e?.cardAcceptorId,
          });
          arr.push({
            label: "Support Email",
            value: e?.supportEmail,
          });
          arr.push({
            label: "Settlement Email",
            value: e?.settlementEmail,
          });
          arr.push({
            label: "Status",
            value: e?.active ? "Active" : "Inactive",
            itemCase: "active",
          });
          arr.push({
            label: "Dispute Email",
            value: e?.disputeEmail,
          });
          arr.push({ label: "Account name", value: e?.accountName });
          arr.push({
            label: "Account Number",
            value: e?.accountNumber,
          });
          arr.push({
            label: "Charge Type",
            value: e?.chargeTypeModel?.chargeTypeName,
          });
          arr.push({
            label: "Settlement Type",
            value: e?.settlementType,
          });
          arr.push({
            label: "Concession Charge",
            value: e?.concessionChargeType?.chargeTypeName,
          });
          arr.push({
            label: "Has VAT Enabled?",
            value: e?.vatEnabled?.toString(),
            itemCase: "status",
          });
          arr.push({
            label: "VAT Account",
            value: e?.vatAccount,
          });
          arr.push({
            label: "VAT Account Name",
            value: e?.vatAccountName,
          });
          arr.push({
            label: "Transit Account",
            value: e?.settlementTransitAccount?.accountNumber,
          });
          arr.push({
            label: "Transit Account Name",
            value: e?.settlementTransitAccount?.accountName,
          });
          setDetails(arr);
          setBreakDownTitle("Super Agent");

          setGettingMerchantDetails(false);
        })
        .catch((error) => {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: HELPER.PROCESS_ERROR(error, "TOAST"),
            life: 10000,
          });
          setVisible(false);
        });
    },
    [openModal]
  );

  const deactivateMerchant = useCallback(
    (e, isMobile) => {
      setConfirmModalError(null);
      setConfirmModalSuccess(null);
      setModalLoading(true);
      openModal(3, isMobile);
      setItemIdForDeactivation(e?.merchantId);
      setConfirmText(`${e.merchantName?.toString()?.toLowerCase()}`);
      setModalLoading(false);
    },
    [openModal]
  );

  const getMerchantForEdit = useCallback(
    (id, isMobile) => {
      SERVICES.GET_MERCHANT(id)
        .then((data) => {
          const e = data.result;
          const updateData = {
            disputeEmail: e?.disputeEmail,
            mainEmail: e?.mainEmail,
            merchantId: e?.merchantId,
            merchantName: e?.merchantName,
            phoneNumber: e?.phoneNumber,
            settlementEmail: e?.settlementEmail,
            supportEmail: e.supportEmail,
          };
          // let participants = [];
          let participants = e?.settlementParticipants.map((participant) => {
            return {
              desc: participant.participantId,
              code: participant.name,
              id: participant.participantId,
            };
          });
          const settlementData = {
            chargeType: {
              desc: e?.chargeTypeModel.chargeTypeName,
              code: e?.chargeTypeModel.code,
            },
            accountNumber: e?.accountNumber,
            accountName: e?.accountName,
            participants: participants,
            settlementType: {
              code: e?.settlementType,
              decs: e?.settlementType,
            },
            merchantId: e?.merchantId,
            concessionChargeType: {
              desc: e?.concessionChargeType?.chargeTypeName,
              code: e?.concessionChargeType?.code,
            },
            settlementTransitAccountReference: e?.settlementTransitAccount,
            vatEnabled: e?.vatEnabled,
            vatAccount: e?.vatAccount,
            vatAccountName: e?.vatAccountName,
          };
          setMerchantForEdit(updateData);
          setMerchantSettlementData(settlementData);
          openModal(2, isMobile);
        })
        .catch((error) => {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: HELPER.PROCESS_ERROR(error, "TOAST"),
            life: 10000,
          });
        });
    },
    [openModal]
  );

  const openAction = useCallback(
    (e, action, isMobile) => {
      // eslint-disable-next-line default-case
      setMerchantAction(action);
      switch (action) {
        case "VIEW":
          getMerchantDetails(e?.merchantId, isMobile);
          break;
        case "UPDATE":
          getMerchantForEdit(e?.merchantId, isMobile);
          break;
        case "DEACTIVATE":
          deactivateMerchant(e, isMobile);
          break;
        case "ACTIVATE":
          deactivateMerchant(e, isMobile);
          break;
        default:
          return <div></div>;
      }
    },
    [getMerchantDetails, getMerchantForEdit, deactivateMerchant]
  );

  const handleSuccess = useCallback(
    (data) => {
      const result = data?.result?.content;
      if (!result?.length) {
        setEmptyText("No super agent found...");
      } else {
        let arr = [];
        setTotalItems(data?.result?.totalElements);
        setTotalPages(data?.result.totalPages);
        result.forEach((e) => {
          arr.push({
            ...e,
            active: e?.active ? "Active" : "Inactive",
            actions: e?.active ? "CRUDE" : "CRUA",
            detailsFunction: openAction,
          });
        });
        setParticipants(arr);
      }
      setError(null);
      setCurrentIndex(1);
      setLoading(false);
    },
    [openAction]
  );

  const handleError = useCallback((error) => {
    setError(HELPER.PROCESS_ERROR(error));
    setCurrentIndex(1);
    setLoading(false);
  }, []);

  const getMerchants = useCallback(() => {
    setCurrentIndex(0);
    setParticipants([]);
    let params = {
      page: pageNo,
      size: 10,
    };
    params = HELPER.TO_URL_STRING(params);
    SERVICES.GET_MERCHANTS(params)
      .then((data) => {
        handleSuccess(data);
      })
      .catch((error) => {
        handleError(error);
      });
  }, [pageNo, handleSuccess, handleError]);

  useEffect(() => {
    if (!searchParams) {
      getMerchants();
    }
  }, [getMerchants, searchParams]);

  const getFilteredMerchants = useCallback(() => {
    setCurrentIndex(0);
    setParticipants([]);
    setSearch(true);
    const queryParams = HELPER.TO_URL_STRING({
      page: pageNo,
      size: 10,
    });
    BACKOFFICE_SERVICE.MAKE_PUT_REQUEST(
      BACKOFFICE_API.GET_FILTERED_MERCHANTS,
      searchParams,
      queryParams
    )
      .then((data) => {
        handleSuccess(data);
      })
      .catch((error) => {
        handleError(error);
      });
  }, [searchParams, pageNo, handleSuccess, handleError]);

  useEffect(() => {
    if (searchParams) {
      getFilteredMerchants();
    }
  }, [getFilteredMerchants, searchParams]);

  function onHide() {}

  function reload() {
    setLoading(true);
    setSearch(false);
    setCurrentIndex(0);
    setSearchParams(null);
    getMerchants();
  }

  function goToPage(pageNo) {
    setPageNo(pageNo);
  }

  function closeModal(isReload) {
    if (visible) {
      setVisible(false);
    }
    if (currentIndex === 2) {
      setCurrentIndex(1);
    }

    if (isReload === true) {
      reload();
    }
  }

  function saveFilters(filters) {
    closeModal();
    // console.log("filters", filters);
    setSearchParams(filters);
  }

  const superAgentsView = () => {
    // eslint-disable-next-line default-case
    switch (currentIndex) {
      case 0:
        return (
          <div className="loading-container">
            <CustomLoader loadingText="Loading super agents..." />
          </div>
        );
      case 1:
        return (
          <CustomTable
            authorities={merchantAuthorities}
            totalPages={totalPages}
            totalItems={totalItems}
            currentPage={pageNo + 1}
            range={range}
            emptyText={emptyText}
            search={search}
            isReload={true}
            reload={reload}
            error={error}
            items={participants}
            headers={tableHeaders}
            nextPage={() => setPageNo(pageNo + 1)}
            prevPage={() => setPageNo(pageNo - 1)}
            goToFirstPage={() => setPageNo(0)}
            goToLastPage={() => setPageNo(totalPages - 1)}
            goToPage={goToPage}
          />
        );
      case 2:
        return (
          <div className="mobile-modal-container">
            <div className="custom-card">
              <div className="mobile-card-position">{mobileModal()}</div>
            </div>
          </div>
        );
    }
  };

  const modalContent = () => {
    // eslint-disable-next-line default-case
    switch (currentModalIndex) {
      case 0:
        return <SuperAgentForm closeModal={closeModal} />;
      case 1:
        return (
          <DetailsBreakDown
            title={breakDownTitle}
            breakDown={details}
            closeModal={closeModal}
            loading={gettingMerchantDetails}
          />
        );
      case 2:
        return (
          <SuperAgentForm
            editMerchant={merchantForEdit}
            merchantSettlementData={merchantSettlementData}
            isUpdate={true}
            closeModal={closeModal}
          />
        );
      case 3:
        return (
          <CustomConfirmDialog
            deactivate={merchantAction}
            success={confirmModalSuccess}
            toastError={confirmModalError}
            itemId={itemIdForDeactivation}
            confirmText={confirmText}
            loading={modalLoading}
            loadingText={
              merchantAction === "DEACTIVATE"
                ? "Deactivating super agent..."
                : "Activating super agent..."
            }
            fn={deactivateUser}
            closeModal={closeModal}
          />
        );
      case 4:
        return (
          <SuperAgentsFilter
            closeModal={closeModal}
            searchFunction={saveFilters}
          />
        );
      case 5:
        return <BulkSuperAgentsCreationForm closeModal={closeModal} />;
    }
  };

  const mobileModal = () => {
    // eslint-disable-next-line default-case
    switch (currentModalIndex) {
      case 0:
        return <SuperAgentForm closeModal={closeModal} />;
      case 1:
        return (
          <DetailsBreakDown
            title={breakDownTitle}
            breakDown={details}
            closeModal={closeModal}
            loading={gettingMerchantDetails}
          />
        );
      case 2:
        return (
          <SuperAgentForm
            editMerchant={merchantForEdit}
            merchantSettlementData={merchantSettlementData}
            isUpdate={true}
            closeModal={closeModal}
          />
        );
      case 3:
        return (
          <CustomConfirmDialog
            deactivate={merchantAction}
            success={confirmModalSuccess}
            toastError={confirmModalError}
            itemId={itemIdForDeactivation}
            confirmText={confirmText}
            loading={modalLoading}
            loadingText={
              merchantAction === "DEACTIVATE"
                ? "Deactivating super agent..."
                : "Activating super agent..."
            }
            fn={deactivateUser}
            closeModal={closeModal}
          />
        );
      case 4:
        return (
          <SuperAgentsFilter
            closeModal={closeModal}
            searchFunction={saveFilters}
          />
        );
      case 5:
        return <BulkSuperAgentsCreationForm closeModal={closeModal} />;
    }
  };

  return (
    <ErrorBoundary>
      <div className="super-agents">
        <Toast ref={toast} />
        <div>
          <CustomModal
            closeModal={closeModal}
            onHide={onHide}
            visible={visible}
            modalContent={modalContent}
          />
        </div>
        <div className="page-title p-text-left">Super Agents</div>
        <div className="p-mt-2">
          <CustomBreadcrumb page="Manage Super Agents" />
        </div>
        <div className="floating-buttons desktop-scree">
          <div className="p-grid">
            <div className="p-col-6"></div>
            <div className="p-col-6">
              <div className="p-grid">
                <div className="p-col-2">
                  <button
                    disabled={loading}
                    onClick={() => openModal(4, false)}
                    className="filter-button"
                  >
                    <i className="pi pi-filter" />
                    <span className="hide-btn-text"> Filter</span>
                  </button>
                </div>
                <div className="p-col-5">
                  <Optional
                    show={HELPER.HAS_AUTHORITY("bo_dcir_create_merchant")}
                  >
                    <button
                      disabled={loading}
                      onClick={() => openModal(5, false)}
                      className="tertiary-button hide-btn-text text-small"
                    >
                      <i
                        className="pi pi-plus"
                        style={{ fontSize: "0.8rem" }}
                      />{" "}
                      <span className="hide-btn-text">
                        Bulk Super Agent Creation
                      </span>
                    </button>
                  </Optional>
                </div>
                <div className="p-col-5">
                  <Optional
                    show={HELPER.HAS_AUTHORITY("bo_dcir_create_merchant")}
                  >
                    <button
                      disabled={loading}
                      onClick={() => openModal(0, false)}
                      className="primary-button hide-btn-text"
                    >
                      <i className="pi pi-plus" />
                      <span className="hide-btn-text"> New Agent</span>
                    </button>
                  </Optional>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Optional show={HELPER.HAS_AUTHORITY("bo_dcir_get_merchants")}>
          <div className="p-mt-4">{superAgentsView()}</div>
        </Optional>
      </div>
    </ErrorBoundary>
  );
}
