import { useEffect, useRef, useState } from "react";
import { FormInput } from "../../../shared/components/form-component/form-input";
import { CUSTOM_VALIDATION } from "../../../shared/validation/validation";
// import moment from "moment";
import { SERVICES } from "../../../core/services/services";
import { HELPER } from "../../../shared/helper/helper";
import { saveAs } from "file-saver";
import { BACKOFFICE_API } from "../../../core/apis/backoffice-apis";
import { Optional } from "../../../shared/components/optional/optional";
import { CustomLoader } from "../../../shared/components/custom-loader/custom-loader";
import { ManualPaymentTypes } from "../../../shared/constants";
import { Toast } from "primereact/toast";
import { FormDropdown } from "../../../shared/components/form-component/form-dropdown";
import { CustomMessage } from "../../../shared/components/alert/custom-message";
import { FormRadioInput } from "../../../shared/components/form-component/form-radio-input";

export function ManualPaymentsFilter(props) {
  const toast = useRef(null);
  const [filters, setFilters] = useState({
    merchantId: "",
    logCode: "",
    bulkSettlementKey: "",
    creditAccount: "",
    // startDate: moment().format("YYYY-MM-DD"),
    // endDate: moment().format("YYYY-MM-DD"),
    startDate: "",
    endDate: "",
  });
  const [formErrors, setFormErrors] = useState({
    merchantId: null,
    logCode: null,
    bulkSettlementKey: null,
    creditAccount: null,
    startDate: null,
    endDate: null,
  });
  const [loading, setLoading] = useState(false);
  const [merchants, setMerchants] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [downloadMessage, setDownloadMessage] = useState(null);
  const [gettingMerchants, setGettingMerchants] = useState(false);
  const [checkingDownloadStatus, setCheckingDownloadStatus] = useState(false);
  const [filterBy, setFilterBy] = useState(
    props.manualPaymentType === ManualPaymentTypes.Settlement
      ? "bulkSettlementKey"
      : "logCode"
  );

  useEffect(() => {
    function getMerchants() {
      setGettingMerchants(true);
      SERVICES.GET_ALL_MERCHANTS()
        .then((data) => {
          const result = data?.result;
          setMerchants(result);
          setGettingMerchants(false);
        })
        .catch((error) => {
          toast.current.show({
            severity: "error",
            summary: "Error getting merchants!",
            detail: HELPER.PROCESS_ERROR(error, "TOAST"),
            life: 10000,
          });
          setGettingMerchants(false);
        });
    }
    getMerchants();
  }, []);

  function getDownloadUrl() {
    let url;
    if (props.manualPaymentType === ManualPaymentTypes.Dispute) {
      url = BACKOFFICE_API.MANUAL_PAYMENTS.DOWNLOAD_DISPUTE_REFUNDS;
    } else {
      url = BACKOFFICE_API.MANUAL_PAYMENTS.DOWNLOAD_SETTLEMENT_PAYMENTS;
    }
    return url;
  }

  function getDownloadCheckUrl() {
    let url;
    if (props.manualPaymentType === ManualPaymentTypes.Dispute) {
      url =
        BACKOFFICE_API.MANUAL_PAYMENTS
          .CHECK_PENDING_DISPUTE_REFUNDS_DOWNLOAD_STATUS;
    } else {
      url =
        BACKOFFICE_API.MANUAL_PAYMENTS.CHECK_MANUAL_SETTLEMENTS_DOWNLOAD_STATUS;
    }
    return url;
  }

  const checkDownloadStatus = () => {
    setCheckingDownloadStatus(true);
    const params = HELPER.TO_URL_STRING(getFilters());
    const url = getDownloadCheckUrl();
    SERVICES.MAKE_GET_REQUEST(url, params)
      .then((data) => {
        const { isDownloaded, message } = data?.result;
        if (isDownloaded) {
          setDownloadMessage(message);
          setCurrentIndex(1);
        } else {
          download();
        }
        setCheckingDownloadStatus(false);
      })
      .catch((error) => {
        // console.log("error", error);
        toast.current.show({
          severity: "error",
          summary: "Error!",
          detail: HELPER.PROCESS_ERROR(error),
          life: 10000,
        });
        setCheckingDownloadStatus(false);
      });
  };

  function download() {
    setLoading(true);
    const url = getDownloadUrl();
    const params = HELPER.TO_URL_STRING(getFilters());
    const isBlob = true;
    SERVICES.MAKE_GET_REQUEST(url, params, isBlob)
      .then((data) => {
        // saveAs(
        //   data,
        //   `manual-${props.manualPaymentType.toLowerCase()}s-${
        //     filters["startDate"]
        //   }TO${filters["endDate"]}.xlsx`
        // );
        saveAs(
          data,
          `manual-${props.manualPaymentType.toLowerCase()}s-${HELPER.TO_URL_STRING(
            getFilters()
          )}.xlsx`
        );
        props.closeModal();
        setLoading(false);
      })
      .catch((error) => {
        handleError(error);
      });
  }

  async function handleError(error) {
    const errMessage = await HELPER.PARSE_BLOB_ERROR(error);
    toast.current.show({
      severity: "error",
      summary: "Error!",
      detail: errMessage,
      life: 10000,
    });
    setLoading(false);
  }

  function validateForm(e, name, type, refineName, required) {
    let value = e?.target?.value;
    const isEmpty = CUSTOM_VALIDATION.IS_EMPTY(value);
    const isValidInput = !isEmpty
      ? CUSTOM_VALIDATION.BASIC_VALIDATION(value, type)
      : false;
    if (isValidInput) {
      setFilters({ ...filters, [name]: value });
      setFormErrors({ ...formErrors, [name]: null });
    } else {
      let errorMessage =
        required && isEmpty ? `${refineName} is required` : null;
      if (!isValidInput && !isEmpty) {
        errorMessage = `${refineName} is invalid`;
      }
      setFormErrors({ ...formErrors, [name]: errorMessage });
    }
  }

  function validateDropdown(e, name) {
    const value = e.target.value;
    if (value) {
      setFormErrors({ ...formErrors, [name]: null });
      setFilters({ ...filters, [name]: value });
    } else {
      let errorMessage = "Select super agent";
      setFormErrors({ ...formErrors, [name]: errorMessage });
    }
  }

  function getFilters() {
    let payload = {};

    for (const property in filters) {
      if (filters[property]) {
        payload[property] =
          property === "merchantId"
            ? filters[property].merchantId
            : filters[property];
      }
    }
    return payload;
  }

  function cancelModal() {
    props.closeModal();
  }

  function canDownload() {
    return (
      (props.manualPaymentType === ManualPaymentTypes.Dispute &&
        HELPER.HAS_AUTHORITY("bo_download_manual_dispute_refund")) ||
      (props.manualPaymentType === ManualPaymentTypes.Settlement &&
        HELPER.HAS_AUTHORITY("bo_download_manual_payment_request"))
    );
  }

  const renderDownloadWarningView = () => {
    return (
      <div>
        <CustomMessage
          messageType="warning"
          message={downloadMessage}
          closeModal={cancelModal}
        />
        <div className="p-grid p-mt-3">
          <div className={`p-col-6`}>
            <button
              onClick={() => setCurrentIndex(0)}
              className="bare-button secondary-button error-text"
            >
              Back
            </button>
          </div>
          <div className={`p-col-6`}>
            <Optional show={!loading}>
              <button onClick={download} className="bare-button">
                Proceed with download
              </button>
            </Optional>
            <Optional show={loading}>
              <div>
                <CustomLoader loadingText="" />
              </div>
            </Optional>
          </div>
        </div>
      </div>
    );
  };

  function handleFilterBySelection(e) {
    const value = e?.target?.value;
    // resetForm();
    setFilterBy(value);
  }

  const renderFormView = () => {
    return (
      <div>
        <div className="custom-modal-title p-text-left">Filter</div>
        <div className="p-mt-3">
          <div>
            <label>Filter By:</label>
          </div>
          <div className="flex default p-mb-4">
            <Optional
              show={props.manualPaymentType === ManualPaymentTypes.Settlement}
            >
              <FormRadioInput
                id="bulkSettlementKey"
                value="bulkSettlementKey"
                name="filter-by"
                checked={filterBy === "bulkSettlementKey"}
                handleChange={handleFilterBySelection}
                label="Bulk Settlement Key"
              />
            </Optional>
            <Optional
              show={props.manualPaymentType === ManualPaymentTypes.Dispute}
            >
              <FormRadioInput
                id="logCode"
                value="logCode"
                name="filter-by"
                checked={filterBy === "logCode"}
                handleChange={handleFilterBySelection}
                label="Log Code"
              />
            </Optional>

            <div className="p-ml-3">
              <FormRadioInput
                id="custom"
                value="custom"
                name="filter-by"
                checked={filterBy === "custom"}
                handleChange={handleFilterBySelection}
                label="Custom"
              />
            </div>
          </div>
        </div>
        <div className="p-grid p-mt-3">
          <Optional show={filterBy === "custom"}>
            <div className="p-col-12">
              <FormDropdown
                required={false}
                label="merchantName"
                field="merchantId"
                error={formErrors["merchantId"]}
                value={filters["merchantId"]}
                fn={validateDropdown}
                options={merchants}
                placeholder="Select a super agent"
                filter
                filterBy="merchantName"
                isLoading={gettingMerchants}
              />
            </div>
          </Optional>
          <Optional
            show={
              props.manualPaymentType === ManualPaymentTypes.Dispute &&
              filterBy === "logCode"
            }
          >
            <div className="p-col-12">
              <FormInput
                value={filters["logCode"]}
                required={false}
                field="logCode"
                type="INPUT"
                error={formErrors["logCode"]}
                fn={validateForm}
                placeholder="Log code"
              />
            </div>
          </Optional>
          <Optional
            show={
              props.manualPaymentType === ManualPaymentTypes.Settlement &&
              filterBy === "bulkSettlementKey"
            }
          >
            <div className="p-col-12">
              <FormInput
                value={filters["bulkSettlementKey"]}
                required={false}
                field="bulkSettlementKey"
                type="INPUT"
                error={formErrors["bulkSettlementKey"]}
                fn={validateForm}
                placeholder="Bulk settlement key"
              />
            </div>
          </Optional>
          <Optional
            show={
              props.manualPaymentType === ManualPaymentTypes.Settlement &&
              filterBy === "custom"
            }
          >
            <div className="p-col-12">
              <FormInput
                value={filters["creditAccount"]}
                required={false}
                field="creditAccount"
                type="INPUT"
                error={formErrors["creditAccount"]}
                fn={validateForm}
                placeholder="Credit account"
              />
            </div>
          </Optional>
          <Optional show={filterBy === "custom"}>
            <div className="p-col-6">
              <FormInput
                inputType="date"
                value={filters["startDate"]}
                required={false}
                field="startDate"
                type="INPUT"
                error={formErrors["startDate"]}
                fn={validateForm}
                placeholder="Start date"
              />
            </div>
            <div className="p-col-6">
              <FormInput
                inputType="date"
                value={filters["endDate"]}
                required={false}
                field="endDate"
                type="INPUT"
                error={formErrors["endDate"]}
                fn={validateForm}
                placeholder="End date"
              />
            </div>
          </Optional>
          <div className="p-col-12 p-mt-3">
            <Optional show={!loading && !checkingDownloadStatus}>
              <div className="p-mt-2">
                <div className="p-grid">
                  <div className={`p-col-${canDownload() ? 4 : 6}`}>
                    <button onClick={cancelModal} className="secondary-button">
                      Cancel
                    </button>
                  </div>
                  <Optional show={canDownload()}>
                    <div className="p-col-4">
                      <button
                        onClick={checkDownloadStatus}
                        className="primary-button download-button"
                      >
                        Download
                      </button>
                    </div>
                  </Optional>
                  <div className={`p-col-${canDownload() ? 4 : 6}`}>
                    <button
                      onClick={() => props?.searchFunction(getFilters())}
                      className="primary-button"
                    >
                      Filter
                    </button>
                  </div>
                </div>
              </div>
            </Optional>
            <Optional show={loading || checkingDownloadStatus}>
              <div className="pull-up-element-2 p-mt-5">
                <CustomLoader
                  loadingText={
                    checkingDownloadStatus ? "Checking" : "Loading..."
                  }
                />
              </div>
            </Optional>
          </div>
        </div>
      </div>
    );
  };

  const renderCurrentView = () => {
    switch (currentIndex) {
      case 0:
        return renderFormView();
      case 1:
        return renderDownloadWarningView();
      default:
        break;
    }
  };

  return (
    <div className="p-pb-2">
      <Toast ref={toast} />
      {renderCurrentView()}
    </div>
  );
}
