import { useEffect, useRef, useState } from "react";
import { SERVICES } from "../services/services";
import { Toast } from "primereact/toast";
import { HELPER } from "../../shared/helper/helper";

export function useMerchantsList() {
  const toast = useRef(null);
  // const [error, setError] = useState(null);
  const [merchants, setMerchants] = useState([]);
  useEffect(() => {
    function getMerchants() {
      SERVICES.GET_ALL_MERCHANTS()
        .then((data) => {
          const result = data?.result;
          setMerchants(result);
        })
        .catch((error) => {
          // setError(error);
          toast?.current?.show({
            severity: "error",
            summary: "Error getting merchants!",
            detail: HELPER.PROCESS_ERROR(error, "TOAST"),
            life: 10000,
          });
        });
    }
    getMerchants();
  }, []);
  if (merchants.length) {
    return merchants;
  }
  // if (error) {
  return <Toast ref={toast} />;
  // }
  // return merchants;
}
