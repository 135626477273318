import { initialState } from "../state/state";

export const reducer = (state, action) => {
  switch (action.type) {
    case "CLICKED_SIDE_NAV":
      state = {
        ...state,
        selectedSideNav: action.selectedSideNav,
        selectedSideNavIndex: action.selectedSideNavIndex,
      };
      return state;
    case "PERSIST_LOGIN_DATA":
      state = {
        ...state,
        username: action?.loginData?.username,
        access_token: action?.loginData?.access_token,
        authorities: action?.loginData?.authorities,
        expires_in: action?.loginData?.expires_in,
        firstName: action?.loginData?.firstName,
        lastName: action?.loginData?.lastName,
        name: action?.loginData?.name,
        roles: action?.loginData?.roles,
        selectedSideNav: action?.loginData?.selectedSideNav,
        selectedSideNavIndex: action?.loginData?.selectedSideNavIndex,
        isAuthenticated: action?.loginData?.isAuthenticated,
        merchantId: action?.loginData?.merchantId,
        email: action?.loginData?.email,
      };
      return state;
    case "SET_TOKEN":
      state = {
        ...state,
        access_token: action.access_token,
      };
      return state;
    case "SET_ALERT":
      state = {
        ...state,
        alert: action.alert,
      };
      return state;
    case "LOG_OUT":
      state = {
        ...state,
        username: null,
        access_token: null,
        authorities: [],
        expires_in: 0,
        firstName: null,
        lastName: null,
        name: null,
        roles: [],
        isAuthenticated: false,
      };
      return state;
    case "SHOW_DIALOG":
      state = {
        ...state,
        showDialog: action.showDialog,
      };
      return state;
    case "ON_PAGE_CHANGE":
      state = {
        ...state,
        currentPage: action.currentPage,
      };
      return state;
    case "SAVE_PERCENT_DOWNLOADED":
      state = {
        ...state,
        percentDownloaded: action.payload,
      };
      return state;
    case "TEMPORARILY_SET_LOGIN_DETAILS":
      state = {
        ...state,
        loginData: action.loginData,
        access_token: action.access_token,
      };
      return state;
    default:
      return initialState;
  }
};
