import React, { useCallback, useEffect, useRef, useState } from "react";
import { CustomTable } from "../../../shared/components/custom-table/custom-table";
import { CustomLoader } from "../../../shared/components/custom-loader/custom-loader";
import { CustomModal } from "../../../shared/components/custom-modal/custom-modal";
import { Toast } from "primereact/toast";
import { HELPER } from "../../../shared/helper/helper";
import { BulkDisputeResponseUpload } from "./dispute-response-upload";
import { BulkDisputeCreationResult } from "./dispute-creation-result";
import { BulkDisputeResponseFilter } from "./dispute-response-filter";
import ErrorBoundary from "../../../shared/components/error-boundary/error-boundary";

import { CustomBreadcrumb } from "../../../shared/components/custom-breadcrumb/custom-breadcrumb";
import { FRONTOFFICE_SERVICE } from "../../../core/services/frontoffice-service";
import { Optional } from "../../../shared/components/optional/optional";

export function BulkDisputeResponse() {
  const toast = useRef(null);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [error, setError] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [range] = useState(5);
  const [pageNo, setPageNo] = useState(0);
  const [emptyText, setEmptyText] = useState("");
  const [currentModalIndex, setCurrentModalIndex] = useState(0);
  const tableHeaders = [
    { label: "Uploaded By", value: "uploadedBy" },
    { label: "Batch Id", value: "batchId" },
    { label: "Uploaded At", value: "uploadedAt" },
    { label: "Action", value: "actions" },
  ];
  const [bulkResponses, setBulkResponses] = useState([]);
  const [selectedBatchId, setSelectedBatchId] = useState(null);
  const [searchParams, setSearchParams] = useState(null);

  const openModal = useCallback((index, isMobile) => {
    setCurrentModalIndex(index);
    if (isMobile) {
      setCurrentIndex(2);
    } else {
      setVisible(true);
    }
  }, []);

  const openAction = useCallback((e, action, isMobile) => {
    switch (action) {
      case "VIEW": {
        setSelectedBatchId(e?.batchId);
        setCurrentIndex(3);
        break;
      }
      default:
        return;
    }
  }, []);

  const getBulkDisputeResponse = useCallback(
    (searchParams = {}) => {
      setCurrentIndex(0);
      setBulkResponses([]);
      setEmptyText("No bulk dispute response found...");
      const params = HELPER.TO_URL_STRING({
        page: pageNo,
        size: 10,
        ...searchParams,
      });
      FRONTOFFICE_SERVICE.GET_BULK_DISPUTE_RESPONSES(params)
        .then((data) => {
          const result = data?.result?.content;
          if (!result.length) {
            setEmptyText("No bulk dispute responses found...");
          } else {
            let arr = [];
            setTotalItems(data?.result.totalElements);
            setTotalPages(data?.result.totalPages);
            result.forEach((e) => {
              arr.push({
                ...e,
                actions: "CR",
                detailsFunction: openAction,
              });
            });
            setBulkResponses(arr);
          }
          setError(null);
          setCurrentIndex(1);
          setLoading(false);
        })
        .catch((error) => {
          setError(HELPER.PROCESS_ERROR(error));
          setCurrentIndex(1);
          setLoading(false);
        });
    },
    [openAction, pageNo]
  );

  useEffect(() => {
    if (searchParams) {
      getBulkDisputeResponse(searchParams);
    } else {
      getBulkDisputeResponse();
    }
  }, [getBulkDisputeResponse, searchParams]);

  function onHide() {
    // console.log("");
  }

  function reload() {
    setSearchParams(null);
    setLoading(true);
    setCurrentIndex(0);
    getBulkDisputeResponse();
  }

  function closeModal(isReload) {
    if (visible) {
      setVisible(false);
    }
    if (currentIndex === 2) {
      setCurrentIndex(1);
    }

    if (isReload) {
      reload();
    }
  }

  function goToPage(pageNo) {
    setPageNo(pageNo);
  }

  function saveFilters(filters) {
    closeModal();
    setPageNo(0);
    setSearchParams(filters);
  }

  const mobileModal = () => {
    // eslint-disable-next-line default-case
    switch (currentModalIndex) {
      case 0:
        return <BulkDisputeResponseUpload closeModal={closeModal} />;
      case 1:
        return (
          <BulkDisputeResponseFilter
            closeModal={closeModal}
            searchFunction={saveFilters}
          />
        );
    }
  };

  const modalContent = () => {
    // eslint-disable-next-line default-case
    switch (currentModalIndex) {
      case 0:
        return <BulkDisputeResponseUpload closeModal={closeModal} />;
      case 1:
        return (
          <BulkDisputeResponseFilter
            closeModal={closeModal}
            searchFunction={saveFilters}
          />
        );
    }
  };

  const bulkDisputeView = () => {
    // eslint-disable-next-line default-case
    switch (currentIndex) {
      case 0:
        return (
          <div className="loading-container">
            <CustomLoader loadingText="Loading bulk dispute response..." />
          </div>
        );
      case 1:
        return (
          <CustomTable
            isReload={true}
            totalPages={totalPages}
            totalItems={totalItems}
            currentPage={pageNo + 1}
            range={range}
            emptyText={emptyText}
            search={true}
            reload={reload}
            error={error}
            items={bulkResponses}
            headers={tableHeaders}
            nextPage={() => setPageNo(pageNo + 1)}
            prevPage={() => setPageNo(pageNo - 1)}
            goToFirstPage={() => setPageNo(0)}
            goToLastPage={() => setPageNo(totalPages - 1)}
            goToPage={goToPage}
          />
        );
      case 2:
        return (
          <div className="mobile-modal-container">
            <div className="custom-card">
              <div className="mobile-card-position">{mobileModal()}</div>
            </div>
          </div>
        );
      case 3:
        return (
          <BulkDisputeCreationResult
            batchId={selectedBatchId}
            goBack={() => setCurrentIndex(1)}
          />
        );
    }
  };

  return (
    <ErrorBoundary>
      <div>
        <Toast ref={toast} />
        <div>
          <CustomModal
            closeModal={closeModal}
            onHide={onHide}
            visible={visible}
            modalContent={modalContent}
          />
        </div>
        <div className="page-title p-text-left">Bulk Dispute Response</div>
        <div className="p-mt-2">
          <CustomBreadcrumb page="Manage Bulk Dispute Response" />
        </div>
        <div className="floating-buttons desktop-scree">
          <div className="p-grid">
            <div className="p-col-7"></div>
            <div className="p-col-5">
              {currentIndex === 1 && (
                <div className="p-grid">
                  <div className="p-col-6 p-d-flex p-jc-end p-ai-center">
                    <button
                      disabled={loading || bulkResponses?.length === 0}
                      onClick={() => openModal(1, false)}
                      className="filter-button"
                    >
                      <i className="pi pi-filter" />
                      <span className="hide-btn-text"> Filter</span>
                    </button>
                  </div>
                  <div className="p-col-6">
                    <Optional
                      show={HELPER.HAS_AUTHORITY("fo_dcir_update_disputes")}
                    >
                      <div className="dcir-show">
                        <button
                          disabled={loading}
                          onClick={() => openModal(0, false)}
                          className="primary-button hide-btn-text text-small"
                        >
                          <span className="hide-btn-text">
                            <i className="pi pi-plus" /> New Bulk Response
                            Upload
                          </span>
                        </button>
                      </div>
                    </Optional>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div>{bulkDisputeView()}</div>
      </div>
    </ErrorBoundary>
  );
}
