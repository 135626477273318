import { useCallback, useEffect, useState } from "react";
import { BACKOFFICE_SERVICE } from "../../../../core/services/backoffice-service";
import { CustomBreadcrumb } from "../../../../shared/components/custom-breadcrumb/custom-breadcrumb";
import { CustomLoader } from "../../../../shared/components/custom-loader/custom-loader";
import { CustomModal } from "../../../../shared/components/custom-modal/custom-modal";
import { CustomTable } from "../../../../shared/components/custom-table/custom-table";
import { Optional } from "../../../../shared/components/optional/optional";
import { HELPER } from "../../../../shared/helper/helper";
import { ReconciliationFileUpload } from "./file-upload";
import { StatementUploadsFilter } from "./files-filter";
import { FileUploadBreakdown } from "./upload-breakdown";

export function UploadedReconciliationFiles() {
  const [visible, setVisible] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [error, setError] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const [pageNo, setPageNo] = useState(0);
  const [isSearch, setIsSearch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileKey, setFileKey] = useState(null);
  const [emptyText, setEmptyText] = useState("");
  const [currentModalIndex, setCurrentModalIndex] = useState(0);
  const [uploadTotalCount, setUploadTotalCount] = useState(0);
  const [searchParams, setSearchParams] = useState(null);
  const [fileDetails, setFileDetails] = useState(null);

  const tableHeaders = [
    { label: "File Name", value: "fileName" },
    { label: "Upload Date", value: "uploadedAt" },
    // { label: "Upload Status", value: "status" },
    { label: "Total Records", value: "uploadTotalCount" },
    { label: "Success Count", value: "successCount" },
    // { label: "Failed Count", value: "failedCount" },
    { label: "Actions", value: "actions" },
  ];

  function openAction(e) {
    // setCurrentPageIndex(2);
    setFileKey(e?.fileKey);
    setUploadTotalCount(e?.uploadTotalCount);
    setFileDetails({
      successCount: e?.successCount,
      failedCount: e?.failedCount,
      duplicateCount: e?.duplicateCount,
    });
    setCurrentPageIndex(2);
  }

  const getUploadedFiles = useCallback(() => {
    setUploadedFiles([]);
    setCurrentPageIndex(0);
    setVisible(false);
    let payload = {
      page: pageNo,
      size: 10,
    };
    if (searchParams) {
      setIsSearch(true);
      payload = {
        ...payload,
        ...searchParams,
      };
    }
    payload = HELPER.TO_URL_STRING(payload);
    BACKOFFICE_SERVICE.GET_UPLOADED_RECONCILIATION_FILES(payload)
      .then((data) => {
        const result = data?.result?.content;
        if (!result.length) {
          setEmptyText("No results found!");
        } else {
          let arr = [];
          setTotalItems(data?.result.totalElements);
          setTotalPages(data?.result.totalPages);
          result.forEach((e) => {
            arr.push({
              ...e,
              actions: "CR",
              detailsFunction: openAction,
            });
          });
          setUploadedFiles(arr);
        }
        setError(null);
        setCurrentPageIndex(1);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        setError(HELPER.PROCESS_ERROR(error));
      });
  }, [pageNo, searchParams]);

  useEffect(() => {
    getUploadedFiles();
  }, [getUploadedFiles]);

  function openModal(index) {
    setCurrentModalIndex(index);
    setVisible(true);
  }

  function closeModal(isReload) {
    setVisible(false);

    if (isReload === true) {
      reload(false);
    }
  }

  function reload() {
    setSearchParams(null);
    setPageNo(0);
    setIsSearch(false);
    setCurrentPageIndex(1);
    getUploadedFiles();
  }

  const modalContent = () => {
    switch (currentModalIndex) {
      case 0:
        return <ReconciliationFileUpload closeModal={closeModal} />;
      case 1:
        return (
          <StatementUploadsFilter
            closeModal={closeModal}
            searchFunction={(params) => setSearchParams(params)}
          />
        );
      default:
        break;
    }
    return;
  };
  const pageContent = () => {
    switch (currentPageIndex) {
      case 0:
        return <CustomLoader loadingText="Loading..." />;
      case 1:
        return (
          <CustomTable
            isReload={true}
            totalPages={totalPages}
            totalItems={totalItems}
            currentPage={pageNo + 1}
            range={0}
            emptyText={emptyText}
            search={isSearch}
            reload={reload}
            error={error}
            items={uploadedFiles}
            headers={tableHeaders}
            nextPage={() => setPageNo(pageNo + 1)}
            prevPage={() => setPageNo(pageNo - 1)}
            goToFirstPage={() => setPageNo(0)}
            goToLastPage={() => setPageNo(totalPages - 1)}
            goToPage={(pageNo) => setPageNo(pageNo)}
          />
        );
      case 2:
        return (
          <FileUploadBreakdown
            goBack={() => setCurrentPageIndex(1)}
            fileKey={fileKey}
            uploadTotalCount={uploadTotalCount}
            fileDetails={fileDetails}
          />
        );
      default:
        break;
    }
  };
  return (
    <div className="recon-statements">
      <div>
        <CustomModal
          closeModal={closeModal}
          onHide={() => setVisible(false)}
          visible={visible}
          modalContent={modalContent}
        />
      </div>
      <div className="page-title p-text-left">
        Reconciliation Statement Upload
      </div>
      <div className="p-mt-2">
        <CustomBreadcrumb page="Reconciliation Statement Upload" />
      </div>
      <Optional show={currentPageIndex === 1}>
        <div className="p-grid">
          <div className="p-col-7"></div>
          <div className="p-col-5">
            <div className="p-grid">
              <div className="p-col-6 p-d-flex p-jc-end p-ai-center">
                <button
                  disabled={loading || uploadedFiles?.length === 0}
                  onClick={() => openModal(1, false)}
                  className="filter-button"
                >
                  <i className="pi pi-filter" />
                  <span className="hide-btn-text"> Filter</span>
                </button>
              </div>
              <div className="p-col-6">
                <Optional show={true}>
                  <button
                    disabled={loading}
                    onClick={() => openModal(0, false)}
                    className="primary-button hide-btn-text"
                  >
                    <i className="pi pi-plus" />
                    <span className="hide-btn-text"> Upload new file</span>
                  </button>
                </Optional>
              </div>
            </div>
          </div>
        </div>
      </Optional>
      <div className="p-mt-4">{pageContent()}</div>
    </div>
  );
}
