import "./custom-confirm-dialog.css";
import { CustomLoader } from "../custom-loader/custom-loader";
import { CustomToast } from "../alert/custom-toast";
import { CustomMessage } from "../alert/custom-message";
import { FormInput } from "../form-component/form-input";
import { useState } from "react";
import { HELPER } from "../../helper/helper";

export function CustomConfirmDialog(props) {
  const [deletionValue, setDeletionValue] = useState(null);
  const [validForm, setValidForm] = useState(false);

  function validateForm(e, name, type, refineName, required) {
    let value = e?.target?.value;
    setDeletionValue(value);
    setValidForm(value === props.confirmText);
  }

  const cancelButton = () => {
    if (props.loading) {
      return <div />;
    } else {
      return (
        <button onClick={props.closeModal} className="secondary-button">
          Cancel
        </button>
      );
    }
  };

  const submitButton = () => {
    if (props.loading) {
      return (
        <div className="p-my-2">
          <CustomLoader loadingText={props.loadingText} />
        </div>
      );
    } else {
      return (
        <button
          disabled={!validForm}
          onClick={() => props.fn(props.itemId)}
          className="primary-button"
        >
          {HELPER.TITLE_CASE(getActionText())}
        </button>
      );
    }
  };

  function getActionText() {
    switch (props.deactivate) {
      case "DEACTIVATE":
        return "deactivate";
      case "ACTIVATE":
        return "activate";
      default:
        return "delete";
    }
  }

  function getConfirmActionText() {
    switch (props.deactivate) {
      case "DEACTIVATE":
        return "deactivation";
      case "ACTIVATE":
        return "activation";
      default:
        return "deletion";
    }
  }

  const currentView = () => {
    if (props?.success) {
      return (
        <div className="p-mt-2 p-pb-1">
          <CustomMessage messageType="success" message={props.success} />
          <div className="p-mt-2 p-pb-1">
            <p
              onClick={() => props.closeModal(true)}
              className="close-modal add-cursor"
            >
              Close
            </p>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className="p-pb-1">
            <p className="confirm-text p-mt-0">
              Confirm that you want to {getActionText()} {props.confirmText}!
            </p>
          </div>
          <div style={{ marginTop: "-1.5em" }}>
            <p className="p-text-left">
              To confirm {getConfirmActionText()}, type{" "}
              <b> {props.confirmText}</b> in the text input field
            </p>
            <FormInput
              value={deletionValue}
              required={false}
              field="deletion"
              type="INPUT"
              fn={validateForm}
              loading={props.loading}
              placeholder="Enter"
            />
          </div>
          <div
            style={{ display: props.toastError ? "block" : "none" }}
            className="p-mt-1"
          >
            <CustomToast
              title="Error"
              description={props.toastError}
              type="error"
            />
          </div>
          <div className="p-mt-4 p-pb-2">
            <div className="p-grid">
              <div className={props.loading ? "p-col-12" : "p-col-6"}>
                {cancelButton()}
              </div>
              <div className={props.loading ? "p-col-12" : "p-col-6"}>
                {submitButton()}
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  return <div>{currentView()}</div>;
}
