import { useState } from "react";
import { FormInput } from "../../../../shared/components/form-component/form-input";
import { CUSTOM_VALIDATION } from "../../../../shared/validation/validation";

export function TransactionByDaysFilter(props) {
  const [loading] = useState(false);
  const [transactionFilters, setTransactionFilters] = useState({
    amount: "",
    maskedPan: "",
    narration: "",
    terminalId: "",
  });
  const [formErrors, setFormErrors] = useState({
    amount: null,
    maskedPan: null,
    narration: null,
    terminalId: null,
  });
  // const [tableHeaders] = useState(props.tableHeaders);

  function getFilters() {
    let payload = {};
    for (const property in transactionFilters) {
      if (transactionFilters[property]) {
        if (property === "amount") {
          payload[property] = Number(transactionFilters["amount"]);
        } else {
          payload[property] = transactionFilters[property];
        }
      }
    }
    return payload;
  }

  function validateForm(e, name, type, refineName, required) {
    let value = e?.target?.value;
    const isEmpty = CUSTOM_VALIDATION.IS_EMPTY(value);
    const isValidInput = !isEmpty
      ? CUSTOM_VALIDATION.BASIC_VALIDATION(value, type)
      : false;
    if (isValidInput) {
      setTransactionFilters({ ...transactionFilters, [name]: value });
      setFormErrors({ ...formErrors, [name]: null });
    } else {
      let errorMessage =
        required && isEmpty ? `${refineName} is required` : null;
      if (!isValidInput) {
        errorMessage = `${refineName} is invalid`;
      }
      if (isEmpty && !required) {
        setFormErrors({ ...formErrors, [name]: null });
      } else {
        setFormErrors({
          ...formErrors,
          [name]: errorMessage,
        });
      }
    }
  }

  return (
    <div>
      <div className="custom-modal-title p-text-left">Filter</div>
      <div className="custom-dialog-subtitle-container p-mb-5"></div>
      {/* <div className="p-pb-1">{viewAlert()}</div> */}
      <div className="p-grid">
        <div className="p-col-12">
          <FormInput
            value={transactionFilters["amount"]}
            required={false}
            field="amount"
            type="CASH_INPUT"
            error={formErrors["flat"]}
            fn={validateForm}
            loading={loading}
            placeholder="Amount"
          />
        </div>
        <div className="p-col-12">
          <FormInput
            value={transactionFilters["maskedPan"]}
            required={false}
            field="maskedPan"
            type="INPUT"
            error={formErrors["maskedPan"]}
            fn={validateForm}
            loading={loading}
            placeholder="Masked pan"
          />
        </div>
        <div className="p-col-12">
          <FormInput
            value={transactionFilters["terminalId"]}
            required={false}
            field="terminalId"
            type="INPUT"
            error={formErrors["terminalId"]}
            fn={validateForm}
            loading={loading}
            placeholder="Terminal Id"
          />
        </div>
        <div className="p-col-12">
          <FormInput
            value={transactionFilters["narration"]}
            required={false}
            field="narration"
            type="INPUT"
            error={formErrors["narration"]}
            fn={validateForm}
            loading={loading}
            placeholder="Narration"
          />
        </div>

        <div className="p-col-12">
          <div className="p-mt-5">
            <div className="p-grid">
              <div className={loading ? "p-col-12" : "p-col-6"}>
                <button onClick={props.closeModal} className="secondary-button">
                  Cancel
                </button>
              </div>
              <div className={loading ? "p-col-12" : "p-col-6"}>
                <button
                  onClick={() => props.searchFunction(getFilters())}
                  className="primary-button"
                >
                  Filter
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
