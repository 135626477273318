import axios from "axios";
import { CONFIG } from "../../shared/config/config";
import { BACKOFFICE_API } from "../apis/backoffice-apis";
import { FRONTOFFICE_API } from "../apis/frontoffice-apis";
import { GET_BASIC_HEADER } from "./api-header";

function generateTransactionReport(payload, type = null) {
  const url = `${CONFIG.DCIR_HOST}${
    type === "fo"
      ? FRONTOFFICE_API.GENERATE_TRANSACTION_REPORT
      : BACKOFFICE_API.GENERATE_TRANSACTION_REPORT
  }`;
  const header = GET_BASIC_HEADER();
  return new Promise((resolve, reject) => {
    axios
      .put(url, payload, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getSingleTransactionReport(reportId, type = null) {
  const url = `${CONFIG.DCIR_HOST}${
    type === "fo"
      ? FRONTOFFICE_API.GET_SINGLE_TRANSACTION_REPORT
      : BACKOFFICE_API.GET_SINGLE_TRANSACTION_REPORT
  }/${reportId}`;
  const header = GET_BASIC_HEADER();
  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getGeneratedTransactionReports(params, type = null) {
  const url = `${CONFIG.DCIR_HOST}${
    type === "fo"
      ? FRONTOFFICE_API.GET_GENERATED_TRANSACTION_REPORTS
      : BACKOFFICE_API.GET_GENERATED_TRANSACTION_REPORTS
  }?${params}`;
  const header = GET_BASIC_HEADER();
  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function downloadTransactionReport(reportId, type = null, mainContext) {
  const url = `${CONFIG.DCIR_HOST}${
    type === "fo"
      ? FRONTOFFICE_API.DOWNLOAD_TRANSACTION_REPORT
      : BACKOFFICE_API.DOWNLOAD_TRANSACTION_REPORT
  }/${reportId}`;
  const header = GET_BASIC_HEADER();
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: header,
        responseType: "blob",
        onDownloadProgress: function (progressEvent) {
          var percentDownloaded = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          if (progressEvent.total === 0) {
            percentDownloaded = 0;
          }
          mainContext?.mainDispatch({
            type: "SAVE_PERCENT_DOWNLOADED",
            payload: percentDownloaded,
          });
        },
      })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getCurrentDayTransactionAnalytics(requestType = null) {
  const url = `${CONFIG.DCIR_HOST}${
    requestType === "front-office"
      ? FRONTOFFICE_API.GET_CURRENT_DAY_TRANSACTION_ANALYTICS
      : BACKOFFICE_API.GET_CURRENT_DAY_TRANSACTION_ANALYTICS
  }`;
  const header = GET_BASIC_HEADER();
  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getHistoricTransactionAnalytics(params, requestType = null) {
  const url = `${CONFIG.DCIR_HOST}${
    requestType === "front-office"
      ? FRONTOFFICE_API.GET_HISTORIC_TRANSACTION_ANALYTICS
      : BACKOFFICE_API.GET_HISTORIC_TRANSACTION_ANALYTICS
  }?${params}`;
  const header = GET_BASIC_HEADER();
  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getCurrentDayDisputeAnalytics(requestType = null) {
  const url = `${CONFIG.DCIR_HOST}${
    requestType === "front-office"
      ? FRONTOFFICE_API.GET_CURRENT_DAY_DISPUTE_ANALYTICS
      : BACKOFFICE_API.GET_CURRENT_DAY_DISPUTE_ANALYTICS
  }`;
  const header = GET_BASIC_HEADER();
  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getHistoricDisputeAnalytics(params, requestType = null) {
  const url = `${CONFIG.DCIR_HOST}${
    requestType === "front-office"
      ? FRONTOFFICE_API.GET_HISTORIC_DISPUTE_ANALYTICS
      : BACKOFFICE_API.GET_HISTORIC_DISPUTE_ANALYTICS
  }?${params}`;
  const header = GET_BASIC_HEADER();
  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getDisputeRefundAttempts(params, requestType) {
  const url = `${CONFIG.DCIR_HOST}${
    requestType === "front-office"
      ? FRONTOFFICE_API.GET_DISPUTE_REFUND_ATTEMPTS
      : BACKOFFICE_API.GET_DISPUTE_REFUND_ATTEMPTS
  }?${params}`;
  const header = GET_BASIC_HEADER();
  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

export const SHARED_SERVICES = {
  GENERATE_TRANSACTION_REPORT: generateTransactionReport,
  GET_SINGLE_TRANSACTION_REPORT: getSingleTransactionReport,
  GET_GENERATED_TRANSACTION_REPORTS: getGeneratedTransactionReports,
  DOWNLOAD_TRANSACTION_REPORT: downloadTransactionReport,
  GET_CURRENT_DAY_TRANSACTION_ANALYTICS: getCurrentDayTransactionAnalytics,
  GET_HISTORIC_TRANSACTION_ANALYTICS: getHistoricTransactionAnalytics,
  GET_CURRENT_DAY_DISPUTE_ANALYTICS: getCurrentDayDisputeAnalytics,
  GET_HISTORIC_DISPUTE_ANALYTICS: getHistoricDisputeAnalytics,
  GET_DISPUTE_REFUND_ATTEMPTS: getDisputeRefundAttempts,
};
