import React, { useCallback, useEffect, useRef, useState } from "react";
import { TabPanel, TabView } from "primereact/tabview";
import { HELPER } from "../../../../shared/helper/helper";
import { Icon } from "../../../../shared/icons/icon";
import { CustomLoader } from "../../../../shared/components/custom-loader/custom-loader";
import { CustomTable } from "../../../../shared/components/custom-table/custom-table";
import { useWindowSize } from "../../../../core/custom-hooks/use-window-size";
import { CustomModal } from "../../../../shared/components/custom-modal/custom-modal";
import { Optional } from "../../../../shared/components/optional/optional";
import { FileUploadErrors } from "./file-upload-errors";
import { BACKOFFICE_SERVICE } from "../../../../core/services/backoffice-service";
// import { BulkTransactionsFilter } from "./bulk-transactions-filter";
// import { DownloadConfirmation } from "./download-bulk-transaction-confirmation";
import { ReconFileDownload } from "./download-recon-statement";
// import { DownloadProgress } from "../../../../shared/components/download-progress/download-progress";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

export function FileUploadBreakdown(props) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [emptyText, setEmptyText] = useState("No results found...");
  const [search, setSearch] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const windowSize = useWindowSize();
  const [fileTransactionMetrics, setFileTransactionMetrics] = useState([]);
  const [visible, setVisible] = useState(false);
  // const [searchParams, setSearchParams] = useState(null);
  const [currentModalIndex, setCurrentModalIndex] = useState(0);
  const [reportDownloadStatus, setReportDownloadStatus] = useState(null);
  const [fileDownloadKey, setFileDownloadKey] = useState(null);
  const reportProgressTimeout = useRef(null);
  const [reportGenerationProgress, setReportGenerationProgress] = useState(0);

  const tableHeaders = [
    { label: "Transaction Date", value: "transactionDate", type: "dateOnly" },
    { label: "Count", value: "totalCount" },
  ];

  useEffect(() => {
    function getReconFileTransactionMetrics() {
      // setLoading(false);
      const params = HELPER.TO_URL_STRING({
        page: pageNo,
        size: 10,
      });
      BACKOFFICE_SERVICE.GET_RECON_FILE_TRANSACTION_METRICS(
        props.fileKey,
        params
      )
        .then((data) => {
          const result = data?.result?.content;
          if (!result.length) {
            setEmptyText("No results found!");
          } else {
            setTotalItems(data?.result.totalElements);
            setTotalPages(data?.result.totalPages);
            setFileTransactionMetrics(data?.result?.content);
          }
          setError(null);
          setCurrentIndex(1);
          // setCurrentPageIndex(1);
          setLoading(false);
        })
        .catch((error) => {
          setError(HELPER.PROCESS_ERROR(error));
        });
    }

    getReconFileTransactionMetrics();
  }, [pageNo, props.fileKey]);

  const getReportDownloadStatus = useCallback(() => {
    const payload = {
      fileKey: props.fileKey,
    };
    BACKOFFICE_SERVICE.CHECK_OR_GENERATE_RECON_REPORT(payload)
      .then((data) => {
        const result = data?.result;
        setReportDownloadStatus(result?.downloadStatus);
        setFileDownloadKey(result?.downloadRecordKey);
        setReportGenerationProgress(result?.downloadPercentage);
        if (
          result.downloadStatus === "IN_PROGRESS" ||
          result.downloadStatus === "PENDING"
        ) {
          reportProgressTimeout.current = setTimeout(() => {
            getReportDownloadStatus();
          }, 5000);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [props.fileKey]);

  useEffect(() => {
    getReportDownloadStatus();
    return () => {
      clearTimeout(reportProgressTimeout.current);
    };
  }, [getReportDownloadStatus]);

  function closeModal(reload) {
    setVisible(false);
    if (reload) {
      getReportDownloadStatus();
    }
  }

  function reload() {
    // setSearchParams(null);
    setPageNo(0);
    setLoading(true);
    setSearch(false);
  }

  function openModal(index) {
    if (props.mobile) {
      setCurrentIndex(3);
    } else {
      setCurrentModalIndex(index);
      setVisible(true);
    }
  }

  function isTabMobile() {
    return windowSize.width <= 948;
  }

  const modalContent = () => {
    switch (currentModalIndex) {
      case 0:
        return (
          <ReconFileDownload
            closeModal={closeModal}
            fileKey={props.fileKey}
            reportDownloadStatus={reportDownloadStatus}
            downloadKey={fileDownloadKey}
          />
        );
      case 1:
        return (
          // <DownloadConfirmation
          //   closeModal={closeModal}
          //   batchId={props.batchId}
          // />
          <div />
        );
      default:
        return;
    }
  };

  const uploadBreakdownView = () => {
    return (
      <div
        style={{
          backgroundColor: "#ffffff",
          paddingTop: "1em",
          paddingBottom: currentIndex === 0 ? "8rem" : "2rem",
        }}
        className="p-shadow-1"
      >
        <div className="p-text-left p-ml-3 p-mt-2 p-pb-3">
          <span onClick={props?.goBack} className="add-cursor">
            <span>
              <Icon icon="go-back-icon" />
            </span>
          </span>
        </div>

        <div className="breakdown-wrapper">
          <div className="flex default">
            <div className="recon breakdown-card">
              <h3>{props.uploadTotalCount || "-"}</h3>
              <small>Total transaction records</small>
            </div>
          </div>
        </div>
        <TabView>
          <TabPanel
            header={`Records uploaded successfully (${props?.fileDetails?.successCount})`}
          >
            <div className="">
              <div className="p-grid">
                <div className="p-col-12">
                  <div>
                    <div className="p-text-left">
                      <p
                        className={
                          isTabMobile()
                            ? "dcir-show custom-title p-ml-4"
                            : "dcir-hide"
                        }
                      >
                        File Upload Breakdown
                      </p>
                    </div>
                    <Optional show={reportDownloadStatus}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          marginBottom: "2rem",
                        }}
                      >
                        <Optional
                          show={
                            reportDownloadStatus !== "IN_PROGRESS" &&
                            reportDownloadStatus !== "PENDING"
                          }
                        >
                          <button
                            className="download-button"
                            style={{ fontSize: "0.75rem", margin: "1rem 0" }}
                            onClick={() => openModal(0)}
                          >
                            <i className="pi pi-download"></i>{" "}
                            {reportDownloadStatus === "COMPLETED"
                              ? "Download"
                              : "Generate Report"}
                          </button>
                        </Optional>
                        <Optional
                          show={
                            reportDownloadStatus === "IN_PROGRESS" ||
                            reportDownloadStatus === "PENDING"
                          }
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <small>File generation progress:</small>
                            <div
                              style={{
                                width: 50,
                                height: 50,
                              }}
                              className="p-ml-2"
                            >
                              <CircularProgressbar
                                value={reportGenerationProgress}
                                text={`${reportGenerationProgress}%`}
                              />
                            </div>
                          </div>
                        </Optional>
                      </div>
                    </Optional>

                    <Optional show={loading}>
                      <div className="loading-container">
                        <CustomLoader loadingText="Loading..." />
                      </div>
                    </Optional>
                    <Optional show={!loading}>
                      <div style={{ marginTop: "2rem" }}>
                        <CustomTable
                          isReload={false}
                          totalPages={totalPages}
                          totalItems={totalItems}
                          currentPage={pageNo + 1}
                          emptyText={emptyText}
                          search={search}
                          reload={reload}
                          error={error}
                          items={fileTransactionMetrics}
                          headers={tableHeaders}
                          nextPage={() => setPageNo(pageNo + 1)}
                          prevPage={() => setPageNo(pageNo - 1)}
                          goToFirstPage={() => setPageNo(0)}
                          goToLastPage={() => setPageNo(totalPages - 1)}
                          goToPage={(no) => setPageNo(no)}
                        />
                      </div>
                    </Optional>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel
            header={`Duplicate Records Found (${props.fileDetails?.duplicateCount})`}
          >
            <div className="p-mt-5">
              <FileUploadErrors
                reconUploadErrorType="DUPLICATE"
                fileKey={props.fileKey}
              />
            </div>
          </TabPanel>
          <TabPanel
            header={`Failed Records Found (${props?.fileDetails?.failedCount})`}
          >
            <div className="p-mt-5">
              <FileUploadErrors
                reconUploadErrorType="FAILED"
                fileKey={props.fileKey}
              />
            </div>
          </TabPanel>
        </TabView>
      </div>
    );
  };
  return (
    <div>
      <div>
        <CustomModal
          closeModal={closeModal}
          onHide={closeModal}
          visible={visible}
          modalContent={modalContent}
        />
      </div>
      <div>{uploadBreakdownView()}</div>
    </div>
  );
}
