import React, { useState } from "react";
import { CustomToast } from "../../../shared/components/alert/custom-toast";
import { CustomLoader } from "../../../shared/components/custom-loader/custom-loader";
import { CustomMessage } from "../alert/custom-message";
import { Optional } from "../optional/optional";

export function CustomConfirmationDialog(props) {
  const [loading, setLoading] = useState(false);
  const [error] = useState(null);

  function performAction() {
    setLoading(true);
    props.action();
  }

  const confirmationView = () => {
    return (
      <div>
        <h4 className="p-text-left p-mt-0">Confirm Action!</h4>
        {/* <div className="question">
            
        </div> */}
        <p className="p-text-left">{props.text}</p>
        <Optional show={error}>
          <div className="p-pb-1">
            <CustomToast title="Error" description={error} type="error" />
          </div>
        </Optional>
        <Optional show={loading && !props.errorMessage}>
          <CustomLoader loadingText="Loading..." />
        </Optional>
        <Optional show={props.errorMessage}>
          <CustomToast
            title={props.errorTitle ? props.errorTitle : "Error!"}
            description={props.errorMessage}
            type="error"
          />
        </Optional>
        <Optional show={!loading || props.errorMessage}>
          <div className="p-mt-5 p-pb-3">
            <div className="p-grid">
              <div className="p-col-6">
                <button onClick={props.closeModal} className="secondary-button">
                  Cancel
                </button>
              </div>
              <div className="p-col-6">
                <button onClick={performAction} className="primary-button">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </Optional>
      </div>
    );
  };

  const responseView = () => {
    return (
      <div>
        <div>
          <CustomMessage messageType="success" />
        </div>
        <div>
          <p className="success-message-text">{props.responseMessage}</p>
        </div>
        <div className="success-message-btn-container p-text-center">
          <p
            onClick={() => {
              props.closeModal(true);
            }}
            className="close-modal add-cursor"
          >
            Close
          </p>
        </div>
      </div>
    );
  };

  const dialogView = () => {
    if (props.responseMessage) {
      return responseView();
    } else {
      return confirmationView();
    }
  };

  return dialogView();
}
