import { useCallback, useEffect, useState } from "react";
import { BACKOFFICE_API } from "../../../core/apis/backoffice-apis";
import { BACKOFFICE_SERVICE } from "../../../core/services/backoffice-service";
import { CustomBreadcrumb } from "../../../shared/components/custom-breadcrumb/custom-breadcrumb";
import { CustomLoader } from "../../../shared/components/custom-loader/custom-loader";
import { CustomModal } from "../../../shared/components/custom-modal/custom-modal";
import { CustomTable } from "../../../shared/components/custom-table/custom-table";
import ErrorBoundary from "../../../shared/components/error-boundary/error-boundary";
import { Optional } from "../../../shared/components/optional/optional";
import { HELPER } from "../../../shared/helper/helper";
import { CreateTransitAccount } from "./create-transit-account";

export function TransitAccounts() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentModalIndex, setCurrentModalIndex] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [transitAccounts, setTransitAccounts] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [error, setError] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  const tableHeaders = [
    { label: "Account Number", value: "accountNumber" },
    { label: "Account Name", value: "accountName" },
    // { label: "Account Reference", value: "accountReference" },
    { label: "Created At", value: "createdAt", itemCase: "createdAt" },
    { label: "Active", value: "active" },
  ];

  const getTransitAccounts = useCallback(() => {
    const payload = HELPER.TO_URL_STRING({
      page: pageNo,
      size: 10,
    });
    setCurrentIndex(0);
    BACKOFFICE_SERVICE.MAKE_GET_REQUEST(
      `${BACKOFFICE_API.SETTLEMENT_TRANSIT_ACCOUNTS}/paged`,
      payload
    )
      .then((response) => {
        const data = response?.result;
        // console.log("response", response);
        setTransitAccounts(data?.content);
        // setTransitAccounts(response?.result);
        setTotalItems(data?.totalElements);
        setTotalPages(data?.totalPages);
        setCurrentIndex(1);
        setError(null);
      })
      .catch((error) => {
        setError(HELPER.PROCESS_ERROR(error));
        setCurrentIndex(1);
      });
  }, [pageNo]);

  useEffect(() => {
    getTransitAccounts();
  }, [getTransitAccounts]);

  const openModal = useCallback((index) => {
    setCurrentModalIndex(index);
    setIsModalVisible(true);
  }, []);

  function closeModal(isReload) {
    if (isModalVisible) {
      setIsModalVisible(false);
    }
    if (isReload === true) {
      reload();
    }
  }

  function reload() {
    // setSearch(false);
    setCurrentIndex(0);

    getTransitAccounts();
  }

  const modalContent = () => {
    // eslint-disable-next-line default-case
    switch (currentModalIndex) {
      case 0:
        return <CreateTransitAccount closeModal={closeModal} />;
    }
  };

  const transitAccountsView = () => {
    // eslint-disable-next-line default-case
    switch (currentIndex) {
      case 0:
        return (
          <div className="loading-container">
            <CustomLoader loadingText="Loading transit accounts..." />
          </div>
        );
      case 1:
        return (
          <CustomTable
            totalPages={totalPages}
            totalItems={totalItems}
            currentPage={pageNo + 1}
            range={null}
            emptyText="No transit accounts found!"
            search={false}
            reload={reload}
            error={error}
            items={transitAccounts}
            headers={tableHeaders}
            nextPage={() => setPageNo(pageNo + 1)}
            prevPage={() => setPageNo(pageNo - 1)}
            goToFirstPage={() => setPageNo(0)}
            goToLastPage={() => setPageNo(totalPages - 1)}
            goToPage={(pageNo) => setPageNo(pageNo)}
          />
        );
    }
  };

  return (
    <ErrorBoundary>
      <div>
        {/* <Toast ref={toast} /> */}
        <div>
          <CustomModal
            closeModal={closeModal}
            onHide={closeModal}
            visible={isModalVisible}
            modalContent={modalContent}
          />
        </div>
        <div className="page-title p-text-left">Transit Accounts</div>
        <div className="p-mt-2">
          <CustomBreadcrumb page="Transit Accounts" />
        </div>
        <div className="floating-buttons desktop-scree p-mt-5">
          <div className="p-grid">
            <div className="p-col-7"></div>
            <div className="p-col-5">
              <div className="p-grid">
                <div className="p-col-6"></div>
                <div className="p-col-6">
                  <Optional
                    show={HELPER.HAS_AUTHORITY(
                      "bo_create_settlement_transit_account"
                    )}
                  >
                    <button
                      onClick={() => openModal(0, false)}
                      className="primary-button hide-btn-text"
                    >
                      <i className="pi pi-plus" />
                      <span className="hide-btn-text">
                        {" "}
                        New Transit Account
                      </span>
                    </button>
                  </Optional>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>{transitAccountsView()}</div>
      </div>
    </ErrorBoundary>
  );
}
