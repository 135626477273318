const get_bulk_dispute_upload_summary =
  "/backoffice/bulk-dispute/transactions/summary/";
const generate_transaction_report =
  "/backoffice/transaction/filter/generatereport";
const get_generated_transaction_reports =
  "/backoffice/transaction/filter/reportinfo";
const get_single_transaction_report =
  "/backoffice/transaction/filter/reportinfo";
const download_transaction_report = "/backoffice/transaction/filter/download";
const get_current_day_transaction_analytics =
  "/backoffice/analytics/currentDay/transactions";
const get_historic_transaction_analytics =
  "/backoffice/analytics/historical/transactions";
const get_current_day_dispute_analytics =
  "/backoffice/analytics/currentDay/dispute";
const get_historic_dispute_analytics =
  "/backoffice/analytics/historical/dispute";
const get_dispute_refund_attempts =
  "/backoffice/dispute/dispute-refund-attempts";
const get_charge_models = "/backoffice/charge-model/page";
const get_charge_model_by_code = "/backoffice/charge-model";
const get_backoffice_disputes = "/backoffice/dispute/backoffice";
const get_superagent_disputes = "/backoffice/dispute/superagent";
const get_filtered_backoffice_disputes =
  "/backoffice/dispute/backoffice/filter";
const get_filtered_superagent_disputes =
  "/backoffice/dispute/superagent/filter";
const download_superagent_disputes =
  "/backoffice/dispute/superagent/download/filter";
const retry_participant_report_generation =
  "/backoffice/settlement-participant-report/retry-report-generation";

const create_ad_user = "/backoffice/user/create/aduser";
const create_local_user = "/backoffice/user/create/localuser";

const upload_bulk_users = "/backoffice/user/upload-users";
// const upload_bulk_users = "/backoffice/manual-dispute-refund/upload";

const get_all_users = "/backoffice/user/fetch/backofficeusers";
const get_users_by_role = "/backoffice/user/fetch/backofficeuser-by-role";
const get_user_by_username =
  "/backoffice/user/fetch/backofficeuser-by-username";
const get_single_user = "/backoffice/user/fetch/backofficeuser";
const get_ad_user_by_username =
  "/backoffice/user/get/backofficeuser/ad-user-by-username";
const get_user_roles = "/backoffice/user/roles";
const unblock_user = "/common/authentication/unblock-user";
const upload_reconciliation_file = "/backoffice/reconciliation/new-upload";
const get_uploaded_reconciliation_files = "/backoffice/reconciliation/uploads";
// const get_uploaded_reconciliation_files_by_days =
//   "/backoffice/reconciliation/transaction/daily-metric";
const get_uploaded_reconciliation_files_by_days =
  "/backoffice/reconciliation/transactions/daily";
const get_recon_file_upload_errors = "/backoffice/reconciliation/upload-errors";
const get_recon_uploads_by_day =
  "/backoffice/reconciliation/transactions/daily";
const get_recon_file_account_entries =
  "/backoffice/reconciliation/transactions";
const recon_configurations = "/backoffice/reconciliation/configs";
const download_recon_statement =
  "/backoffice/reconciliation/transactions/download";
const check_or_generate_recon_report =
  "/backoffice/reconciliation/transactions/check-or-generate-report";
const get_daily_credit_debit_summaries =
  "/backoffice/reconciliation/daily-credit-debit-summaries";
const get_unmatched_db_exceptions =
  "/backoffice/reconciliation/exceptions/unmatched-on-db";
const initiate_disputes_on_failed_transactions =
  "/backoffice/reconciliation/initiate-dispute";
const resolve_recon_account_entry = "/backoffice/reconciliation/resolve";
const reinitiate_dispute_on_failed_transactions =
  "/backoffice/reconciliation/refresh-dispute-status";

const get_disputes_on_requery = "/backoffice/transfers-on-requery/disputes";
const get_settlements_on_requery =
  "/backoffice/transfers-on-requery/settlements";
const update_dispute_on_requery = "/backoffice/transfers-on-requery/dispute";
const update_settlement_on_requery =
  "/backoffice/transfers-on-requery/settlement";
const download_disputes_on_requery =
  "/backoffice/transfers-on-requery/disputes/download";
const download_settlements_on_requery =
  "/backoffice/transfers-on-requery/settlements/download";

const get_all_workflow_requests = "/common/workflows";
const get_user_workflow_requests = "/common/workflows/self-requests";
const process_workflow_request = "/common/workflows/process-workflow-request";

const update_user_role = "/common/authentication/roles/change-role";
const get_single_role = "/common/authentication/roles";

const settlement_transit_accounts = "/backoffice/settlement-transit-account";

const get_filtered_merchants = "/backoffice/merchant/filter";
const download_filtered_merchants = "/backoffice/merchant/download";

export const BACKOFFICE_API = {
  GET_BULK_DISPUTE_UPLOAD_SUMMARY: get_bulk_dispute_upload_summary,
  GENERATE_TRANSACTION_REPORT: generate_transaction_report,
  GET_GENERATED_TRANSACTION_REPORTS: get_generated_transaction_reports,
  GET_SINGLE_TRANSACTION_REPORT: get_single_transaction_report,
  DOWNLOAD_TRANSACTION_REPORT: download_transaction_report,
  GET_CURRENT_DAY_TRANSACTION_ANALYTICS: get_current_day_transaction_analytics,
  GET_HISTORIC_TRANSACTION_ANALYTICS: get_historic_transaction_analytics,
  GET_CURRENT_DAY_DISPUTE_ANALYTICS: get_current_day_dispute_analytics,
  GET_HISTORIC_DISPUTE_ANALYTICS: get_historic_dispute_analytics,
  GET_DISPUTE_REFUND_ATTEMPTS: get_dispute_refund_attempts,
  GET_CHARGE_MODELS: get_charge_models,
  GET_CHARGE_MODEL_BY_CODE: get_charge_model_by_code,
  GET_BACKOFFICE_DISPUTES: get_backoffice_disputes,
  GET_SUPERAGENT_DISPUTES: get_superagent_disputes,
  GET_FILTERED_BACKOFFICE_DISPUTES: get_filtered_backoffice_disputes,
  GET_FILTERED_SUPERAGENT_DISPUTES: get_filtered_superagent_disputes,
  DOWNLOAD_SUPERAGENT_DISPUTES: download_superagent_disputes,
  RETRY_PARTICIPANT_REPORT_REGENERATION: retry_participant_report_generation,
  CREATE_AD_USER: create_ad_user,
  CREATE_LOCAL_USER: create_local_user,
  GET_ALL_USERS: get_all_users,
  GET_USERS_BY_ROLE: get_users_by_role,
  GET_USER_BY_USERNAME: get_user_by_username,
  GET_SINGLE_USER: get_single_user,
  GET_AD_USER_BY_USERNAME: get_ad_user_by_username,
  GET_USER_ROLES: get_user_roles,
  UNBLOCK_USER: unblock_user,
  UPLOAD_RECONCILIATION_FILE: upload_reconciliation_file,
  GET_UPLOADED_RECONCILIATION_FILES: get_uploaded_reconciliation_files,
  GET_UPLOADED_RECONCILIATION_FILES_BY_DAYS:
    get_uploaded_reconciliation_files_by_days,
  GET_RECON_FILE_UPLOAD_ERRORS: get_recon_file_upload_errors,
  GET_RECON_UPLOADS_BY_DAY: get_recon_uploads_by_day,
  RECON_CONFIGURATIONS: recon_configurations,
  GET_RECON_FILE_ACCOUNT_ENTRIES: get_recon_file_account_entries,
  DOWNLOAD_RECON_STATEMENT: download_recon_statement,
  CHECK_OR_GENERATE_RECON_REPORT: check_or_generate_recon_report,
  UPLOAD_BULK_USERS: upload_bulk_users,
  GET_DISPUTES_ON_REQUERY: get_disputes_on_requery,
  GET_SETTLEMENTS_ON_REQUERY: get_settlements_on_requery,
  UPDATE_DSIPUTE_ON_REQUERY: update_dispute_on_requery,
  UPDATE_SETTLEMENT_ON_REQUERY: update_settlement_on_requery,
  GET_ALL_WORKFLOW_REQUESTS: get_all_workflow_requests,
  GET_USER_WORKFLOW_REQUESTS: get_user_workflow_requests,
  PROCESS_WORKFLOW_REQUEST: process_workflow_request,
  GET_DAILY_CREDIT_DEBIT_SUMMARIES: get_daily_credit_debit_summaries,
  GET_UNMATCHED_DB_EXCEPTIONS: get_unmatched_db_exceptions,
  INITIATE_DISPUTE_ON_FAILED_TRANSACTIONS:
    initiate_disputes_on_failed_transactions,
  RESOLVE_RECON_ACCOUNT_ENTRY: resolve_recon_account_entry,
  DOWNLOAD_DISPUTES_ON_REQUERY: download_disputes_on_requery,
  DOWNLOAD_SETTLEMENTS_ON_REQUERY: download_settlements_on_requery,
  UPDATE_USER_ROLE: update_user_role,
  GET_SINGLE_ROLE: get_single_role,
  REINITIATE_DISPUTE_ON_FAILED_TRANSACTIONS:
    reinitiate_dispute_on_failed_transactions,
  SETTLEMENT_TRANSIT_ACCOUNTS: settlement_transit_accounts,
  GET_FILTERED_MERCHANTS: get_filtered_merchants,
  DOWNLOAD_FILTERED_MERCHANTS: download_filtered_merchants,
  MANUAL_PAYMENTS: {
    GET_PENDING_DISPUTE_REFUNDS: "/backoffice/manual-dispute-refund/pending",
    GET_PENDING_SETTLEMENT_PAYMENTS:
      "/backoffice/manual-settlement-payment/pending",
    DOWNLOAD_DISPUTE_REFUNDS: "/backoffice/manual-dispute-refund/download",
    DOWNLOAD_SETTLEMENT_PAYMENTS:
      "/backoffice/manual-settlement-payment/download",
    PROCESS_DISPUTE_REFUND: "/backoffice/manual-dispute-refund",
    PROCESS_SETTLEMENT_PAYMENT: "/backoffice/manual-settlement-payment",
    CHECK_MANUAL_SETTLEMENTS_DOWNLOAD_STATUS:
      "/backoffice/manual-settlement-payment/download-info",
    CHECK_PENDING_DISPUTE_REFUNDS_DOWNLOAD_STATUS:
      "/backoffice/manual-dispute-refund/download-info",
    GET_DISPUTE_REFUND_BULK_UPLOADS:
      "/backoffice/manual-dispute-refund/batches",
    UPLOAD_DISPUTE_REFUND_BULK: "/backoffice/manual-dispute-refund/upload",
    GET_ITEMS_IN_DISPUTE_REFUND_BULK:
      "/backoffice/manual-dispute-refund/batch-items",
    GET_DISPUTE_REFUND_BULK_SUMMARY:
      "/backoffice/manual-dispute-refund/transactions/summary",
    GET_MANUAL_SETTLEMENT_BULK_UPLOADS:
      "/backoffice/manual-settlement-payment/batches",
    UPLOAD_MANUAL_SETTLEMENT_BULK:
      "/backoffice/manual-settlement-payment/upload",
    GET_ITEMS_IN_MANUAL_SETTLEMENT_BULK:
      "/backoffice/manual-settlement-payment/batch-items",
    GET_MANUAL_SETTLEMENT_BULK_SUMMARY:
      "/backoffice/manual-settlement-payment/transactions/summary",
  },
};
