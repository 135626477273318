import { useEffect, useRef, useState } from "react";
import { FormInput } from "../../../shared/components/form-component/form-input";
import { CUSTOM_VALIDATION } from "../../../shared/validation/validation";
import { SERVICES } from "../../../core/services/services";
import { HELPER } from "../../../shared/helper/helper";
import { BACKOFFICE_API } from "../../../core/apis/backoffice-apis";
import { Optional } from "../../../shared/components/optional/optional";
import { CustomLoader } from "../../../shared/components/custom-loader/custom-loader";
import { ManualPaymentTypes } from "../../../shared/constants";
import { Toast } from "primereact/toast";
import { CustomMessage } from "../../../shared/components/alert/custom-message";

export function UpdatePaymentRefundStatus(props) {
  const toast = useRef(null);
  const [paymentDetails, setPaymentDetails] = useState({
    transactionReference: null,
    paymentRequestId: null,
    narration: null,
  });
  const [formErrors, setFormErrors] = useState({
    transactionReference: null,
    paymentRequestId: null,
    narration: null,
  });
  const [loading, setLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isValidForm, setIsValidForm] = useState(false);

  useEffect(() => {
    function isFormValid() {
      let isFormValid;
      if (props.manualPaymentType === ManualPaymentTypes.Dispute) {
        isFormValid =
          paymentDetails["narration"]?.length &&
          paymentDetails["transactionReference"]?.length;
      } else {
        isFormValid =
          paymentDetails["paymentRequestId"]?.length &&
          paymentDetails["transactionReference"]?.length;
      }
      return isFormValid;
    }

    const noErrors = !CUSTOM_VALIDATION.VALID_OBJ_ANY(formErrors);
    setIsValidForm(isFormValid() && noErrors);
  }, [paymentDetails, formErrors, props.manualPaymentType]);

  function getUrl() {
    let url;
    if (props.manualPaymentType === ManualPaymentTypes.Dispute) {
      url = BACKOFFICE_API.MANUAL_PAYMENTS.PROCESS_DISPUTE_REFUND;
    } else {
      url = BACKOFFICE_API.MANUAL_PAYMENTS.PROCESS_SETTLEMENT_PAYMENT;
    }
    return url;
  }

  function getRequestPayload() {
    let payload;
    if (props.manualPaymentType === ManualPaymentTypes.Dispute) {
      let { paymentRequestId, ...disputeRefundPayload } = paymentDetails;
      payload = {
        ...disputeRefundPayload,
        transactionSearchKey: props?.paymentDetails.transactionSearchKey,
      };
    } else if (props.manualPaymentType === ManualPaymentTypes.Settlement) {
      let { narration, ...settlementPaymentPayload } = paymentDetails;
      payload = {
        ...settlementPaymentPayload,
        bulkSettlementKey:
          props.paymentDetails?.bulkSettlementRecord.bulkSettlementKey,
      };
    }
    return payload;
  }

  useEffect(() => {
    if (props.manualPaymentType === ManualPaymentTypes.Dispute) {
      // setPaymentDetails({
      //   ...paymentDetails,
      //   narration: props.paymentDetails.narration,
      // });
      setPaymentDetails((paymentDetails) => {
        return { ...paymentDetails, narration: props.paymentDetails.narration };
      });
    } else {
      // setPaymentDetails({
      //   ...paymentDetails,
      //   paymentRequestId: props.paymentDetails.requestId,
      // });
      setPaymentDetails((paymentDetails) => {
        return {
          ...paymentDetails,
          paymentRequestId: props.paymentDetails.requestId,
        };
      });
    }
  }, [props.manualPaymentType, props.paymentDetails]);

  function submit() {
    setLoading(true);
    const url = getUrl();
    // const params = HELPER.TO_URL_STRING(getFilters());
    const payload = getRequestPayload();
    SERVICES.MAKE_POST_REQUEST(url, payload)
      .then(() => {
        setCurrentIndex(3);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: HELPER.PROCESS_ERROR(error, "TOAST"),
          life: 10000,
        });
      });
  }

  function validateForm(e, name, type, refineName, required) {
    let value = e?.target?.value;
    const isEmpty = CUSTOM_VALIDATION.IS_EMPTY(value);
    const isValidInput = !isEmpty
      ? CUSTOM_VALIDATION.BASIC_VALIDATION(value, type)
      : false;
    if (isValidInput) {
      setPaymentDetails({ ...paymentDetails, [name]: value });
      setFormErrors({ ...formErrors, [name]: null });
    } else {
      let errorMessage =
        required && isEmpty ? `${refineName} is required` : null;
      if (!isValidInput) {
        errorMessage = `${refineName} is invalid`;
      }
      setFormErrors({ ...formErrors, [name]: errorMessage });
    }
  }

  function cancelModal() {
    props.closeModal();
  }

  function renderDetailsConfirmationView() {
    const details = [];
    details.push({
      label: "New Status",
      value: "Success",
      itemCase: "status",
    });
    for (const property in paymentDetails) {
      if (paymentDetails[property]) {
        details.push({
          label: HELPER.CAMEL_TO_TITLE_CASE(property),
          value: paymentDetails[property],
        });
      }
    }
    return details.map((details, index) => (
      <div
        key={index.toString()}
        className="p-grid dcir-row p-text-left p-pl-0"
      >
        <div className="p-col-5">
          <p className="details-label">{details.label}:</p>
        </div>
        <div className="p-col-7">
          <p className="details-value">
            {HELPER.TRANSFORM_VIEW(details.itemCase, details.value)}
          </p>
        </div>
      </div>
    ));
  }

  function renderForm() {
    return (
      <div className="p-grid p-mt-3">
        <Optional
          show={props.manualPaymentType === ManualPaymentTypes.Settlement}
        >
          <div className="p-col-12">
            <FormInput
              value={paymentDetails["paymentRequestId"]}
              required={false}
              field="paymentRequestId"
              type="INPUT"
              error={formErrors["paymentRequestId"]}
              fn={validateForm}
              placeholder="Payment request Id"
              disabled={true}
            />
          </div>
        </Optional>
        <Optional show={props.manualPaymentType === ManualPaymentTypes.Dispute}>
          <div className="p-col-12">
            <FormInput
              value={paymentDetails["narration"]}
              required={false}
              field="narration"
              type="INPUT"
              error={formErrors["narration"]}
              fn={validateForm}
              placeholder="Refund narration"
              disabled={true}
            />
          </div>
        </Optional>
        <div className="p-col-12">
          <FormInput
            value={paymentDetails["transactionReference"]}
            required={false}
            field="transactionReference"
            type="INPUT"
            error={formErrors["transactionReference"]}
            fn={validateForm}
            placeholder="Transaction reference"
          />
        </div>
      </div>
    );
  }

  function renderView() {
    switch (currentIndex) {
      case 0:
        return renderForm();
      case 1:
        return <div className="p-mt-5">{renderDetailsConfirmationView()}</div>;
      case 3:
        return (
          <div>
            <CustomMessage messageType="success" />
            <p className="success-message-text">Status successfully updated!</p>
            <div className="success-message-btn-container p-text-center">
              <p
                onClick={() => {
                  props.closeModal(true);
                }}
                className="close-modal add-cursor"
              >
                Close
              </p>
            </div>
          </div>
        );
      default:
        break;
    }
  }

  return (
    <div className="p-pb-2">
      <Toast ref={toast} />
      <div>
        <Optional show={currentIndex !== 3}>
          <div className="custom-modal-title p-text-left">Update Status</div>
        </Optional>
        <Optional show={currentIndex === 1}>
          <div>
            <p className="text-small p-text-left">Confirm details below</p>
          </div>
        </Optional>
        <Optional show={currentIndex === 0}>
          <div>
            <p className="text-small p-text-left">
              Enter transaction details below
            </p>
          </div>
        </Optional>

        {renderView()}

        <div className="p-grid">
          <div className="p-col-12 p-mt-4">
            <Optional show={!loading && currentIndex !== 3}>
              <div className="p-mt-2">
                <div className="p-grid">
                  <div className="p-col-6">
                    <button
                      onClick={() => {
                        currentIndex === 0 ? cancelModal() : setCurrentIndex(0);
                      }}
                      className="secondary-button"
                    >
                      {currentIndex === 0 ? "Cancel" : "Back"}
                    </button>
                  </div>
                  <div className="p-col-6">
                    <button
                      onClick={() => {
                        currentIndex === 0 ? setCurrentIndex(1) : submit();
                      }}
                      disabled={!isValidForm}
                      className="primary-button"
                    >
                      {currentIndex === 0 ? "Next" : "Submit"}
                    </button>
                  </div>
                </div>
              </div>
            </Optional>
            <Optional show={loading}>
              <div className="pull-up-element-2 p-mt-5">
                <CustomLoader loadingText="Loading..." />
              </div>
            </Optional>
          </div>
        </div>
      </div>
    </div>
  );
}
