const generate_transaction_report =
  "/frontoffice/transaction/filter/generatereport";
const get_generated_transaction_reports =
  "/frontoffice/transaction/filter/reportinfo";
const get_single_transaction_report =
  "/frontoffice/transaction/filter/reportinfo";
const download_transaction_report = "/frontoffice/transaction/filter/download";
const get_current_day_transaction_analytics =
  "/frontoffice/analytics/currentDay/transactions";
const get_historic_transaction_analytics =
  "/frontoffice/analytics/historical/transactions";
const get_current_day_dispute_analytics =
  "/frontoffice/analytics/currentDay/dispute";
const get_historic_dispute_analytics =
  "/frontoffice/analytics/historical/dispute";
const get_dispute_refund_attempts =
  "/frontoffice/dispute/dispute-refund-attempts";
const get_bulk_dispute_responses = "/frontoffice/dispute/bulk/resolve/batch";
const get_bulk_dispute_response_transactions =
  "/frontoffice/dispute/bulk/resolve/transactions";
const upload_bulk_dispute_response = "/frontoffice/dispute/bulk/resolve/upload";
const get_bulk_dispute_response_upload_summary =
  "/frontoffice/dispute/bulk/resolve/creation-summary";
const download_bulk_dispute_response_transactions =
  "/frontoffice/dispute/bulk/resolve/transactions/download";

export const FRONTOFFICE_API = {
  GENERATE_TRANSACTION_REPORT: generate_transaction_report,
  GET_GENERATED_TRANSACTION_REPORTS: get_generated_transaction_reports,
  GET_SINGLE_TRANSACTION_REPORT: get_single_transaction_report,
  DOWNLOAD_TRANSACTION_REPORT: download_transaction_report,
  GET_CURRENT_DAY_TRANSACTION_ANALYTICS: get_current_day_transaction_analytics,
  GET_HISTORIC_TRANSACTION_ANALYTICS: get_historic_transaction_analytics,
  GET_CURRENT_DAY_DISPUTE_ANALYTICS: get_current_day_dispute_analytics,
  GET_HISTORIC_DISPUTE_ANALYTICS: get_historic_dispute_analytics,
  GET_DISPUTE_REFUND_ATTEMPTS: get_dispute_refund_attempts,
  GET_BULK_DISPUTE_RESPONSES: get_bulk_dispute_responses,
  GET_BULK_DISPUTE_RESPONSE_TRANSACTIONS:
    get_bulk_dispute_response_transactions,
  UPLOAD_BULK_DISPUTE_RESPONSE: upload_bulk_dispute_response,
  GET_BULK_DISPUTE_RESPONSE_UPLOAD_SUMMARY:
    get_bulk_dispute_response_upload_summary,
  DOWNLOAD_BULK_DISPUTE_RESPONSE_TRANSACTIONS:
    download_bulk_dispute_response_transactions,
};
