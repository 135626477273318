import { Optional } from "../../../shared/components/optional/optional";
import "./user-management.css";
export function UserDetailsConfirmation({ userDetails, isLocalUser }) {
  return (
    <div className="p-text-left user-details-confirmation">
      <div className="p-grid dcir-row">
        <div className="p-col-5">
          <p className="details-label">Role:</p>
        </div>
        <div className="p-col-7">
          <p className="details-value">{userDetails?.role}</p>
        </div>
      </div>
      <div className="p-grid dcir-row">
        <div className="p-col-5">
          <p className="details-label">Firstname:</p>
        </div>
        <div className="p-col-7">
          <p className="details-value">{userDetails?.firstname}</p>
        </div>
      </div>
      <div className="p-grid dcir-row">
        <div className="p-col-5">
          <p className="details-label">Lastname:</p>
        </div>
        <div className="p-col-7">
          <p className="details-value">{userDetails?.lastname}</p>
        </div>
      </div>
      <div className="p-grid dcir-row">
        <div className="p-col-5">
          <p className="details-label">Email:</p>
        </div>
        <div className="p-col-7">
          <p className="details-value">{userDetails?.email}</p>
        </div>
      </div>
      <div className="p-grid dcir-row">
        <div className="p-col-5">
          <p className="details-label">Username:</p>
        </div>
        <div className="p-col-7">
          <p className="details-value">{userDetails?.username}</p>
        </div>
      </div>

      <div className="p-grid dcir-row">
        <div className="p-col-5">
          <p className="details-label">Company Name:</p>
        </div>
        <div className="p-col-7">
          <p className="details-value">{userDetails?.companyName}</p>
        </div>
      </div>
      <Optional show={isLocalUser}>
        <div
          className="p-mt-3"
          style={{ display: `${isLocalUser ? "block" : "none"}` }}
        >
          <small className="">
            Please note that a one time password will be sent to{" "}
            {userDetails?.email} (For local users only)
          </small>
        </div>
      </Optional>
    </div>
  );
}
