import "./transactions.css";
import React, {
  useCallback,
  // useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { CustomBreadcrumb } from "../../../shared/components/custom-breadcrumb/custom-breadcrumb";
import { CustomTable } from "../../../shared/components/custom-table/custom-table";
import { SERVICES } from "../../../core/services/services";
import { CustomLoader } from "../../../shared/components/custom-loader/custom-loader";
import { CustomModal } from "../../../shared/components/custom-modal/custom-modal";
import { Toast } from "primereact/toast";
// import { MainContext } from "../../../../App";
import { TransactionsFilter } from "./transactions-filter";
import { HELPER } from "../../../shared/helper/helper";
import { AccessDenied } from "../../access-denied/access-denied";
import { TransactionBreakdown } from "./transaction-breakdown";
import ErrorBoundary from "../../../shared/components/error-boundary/error-boundary";

export function Transactions() {
  const toast = useRef(null);
  // const mainContext = useContext(MainContext);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [error, setError] = useState("");
  const [search, setSearch] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [isMobileTransaction, setIsMobileTransaction] = useState(false);
  // const [currentPage] = useState(mainContext?.mainState?.currentPage);
  const [range] = useState(5);
  const [emptyText, setEmptyText] = useState("");
  const [responseCode, setResponseCode] = useState(null);
  const [currentModalIndex, setCurrentModalIndex] = useState(0);
  const [transactionSearchKey, setTransactionSearchKey] = useState("");
  const [pageNo, setPageNo] = useState(0);
  const [searchParams, setSearchParams] = useState(null);

  const tableHeaders = [
    { label: "Time", value: "transactionTime" },
    { label: "Masked pan", value: "maskedPan" },
    { label: "Rrn", value: "rrn" },
    { label: "Amount", value: "transactionAmount" },
    { label: "Terminal id", value: "terminalId" },
    { label: "Response code", value: "responseCode" },
    { label: "Actions", value: "actions" },
  ];
  const [details, setDetails] = useState([]);
  const [transactions, setTransactions] = useState([]);

  const getTransactionDetails = useCallback((e, isMobile) => {
    let arr = [];
    setDetails([]);
    setResponseCode(e?.responseCode);
    setTransactionSearchKey(e?.transactionSearchKey);
    arr.push({
      label: "Transaction amount",
      value: e?.transactionAmount,
      itemCase: "transactionAmount",
    });
    arr.push({
      label: "Charge amount",
      value: e?.chargeAmount,
      itemCase: "chargeAmount",
    });
    arr.push({
      label: "Transaction time",
      value: e?.transactionTime,
      itemCase: "transactionTime",
    });
    arr.push({
      label: "Response code",
      value: e?.responseCode,
      itemCase: "responseCode",
    });
    arr.push({ label: "Card acceptor Id", value: e?.cardAcceptorId });

    arr.push({ label: "RRN", value: e?.rrn });
    arr.push({ label: "STAN", value: e?.stan });

    arr.push({ label: "Masked pan", value: e?.maskedPan });
    arr.push({ label: "Terminal Id", value: e?.terminalId });
    arr.push({ label: "Bulk key", value: e?.bulkSettlementKey });

    setDetails(arr);
    setIsMobileTransaction(isMobile);
    setCurrentIndex(3);
  }, []);

  const openAction = useCallback(
    (e, action, isMobile) => {
      // eslint-disable-next-line default-case
      switch (action) {
        case "VIEW": {
          getTransactionDetails(e, isMobile);
          break;
        }
      }
    },
    [getTransactionDetails]
  );

  const getTransactions = useCallback(() => {
    setCurrentIndex(0);
    setLoading(true);
    setTransactions([]);
    let params = {
      page: pageNo,
      size: 10,
    };
    params = HELPER.TO_URL_STRING(params);
    SERVICES.GET_TRANSACTION(params)
      .then((data) => {
        const result = data?.result?.content;
        if (!result.length) {
          setEmptyText("No transactions found...");
        } else {
          let arr = [];
          setTotalItems(data?.result.totalElements); //need adjustment
          setTotalPages(data?.result.totalPages); //need adjustment
          result.forEach((e) => {
            arr.push({
              ...e,
              actions: "CR",
              detailsFunction: openAction,
            });
          });
          setTransactions(arr);
        }
        setError(null);
        setCurrentIndex(1);
        setLoading(false);
      })
      .catch((error) => {
        setError(HELPER.PROCESS_ERROR(error));
        setCurrentIndex(1);
        setLoading(false);
      });
  }, [pageNo, openAction]);

  const searchTransaction = useCallback(
    (searchParams) => {
      setCurrentIndex(0);
      // if (e) {
      //   setPageNo(0);
      //   setSearchParams({ ...e });
      // }
      // let params = e ? e : searchParams;
      setTransactions([]);
      setVisible(false);
      setLoading(true);
      const pageParam = HELPER.TO_URL_STRING({
        page: pageNo,
        size: 10,
      });
      SERVICES.SEARCH_TRANSACTIONS(searchParams, pageParam)
        .then((data) => {
          const result = data.result.content;
          if (!result.length) {
            setEmptyText("No transactions found...");
            setSearch(true);
            setLoading(false);
          } else {
            let arr = [];
            setTotalItems(data.result.totalItems);
            setTotalPages(data.result.totalPages);
            result.forEach((e) => {
              arr.push({
                ...e,
                actions: "CR",
                detailsFunction: openAction,
              });
            });
            setTransactions(arr);
          }
          setCurrentIndex(1);
          setError(null);
          setLoading(false);
        })
        .catch((error) => {
          setError(HELPER.PROCESS_ERROR(error));
          setLoading(false);
          setCurrentIndex(1);
        });
    },
    [pageNo, openAction]
  );

  useEffect(() => {
    if (searchParams) {
      searchTransaction(searchParams);
    } else {
      getTransactions();
    }
  }, [searchParams, searchTransaction, getTransactions]);

  function goToPage(pageNo) {
    setPageNo(pageNo);
  }

  function onHide() {}

  function openModal(index, isMobile) {
    setCurrentModalIndex(index);
    if (isMobile) {
      setCurrentIndex(2);
    } else {
      setVisible(true);
    }
  }

  function reload() {
    setPageNo(0);
    setSearchParams(null);
    setLoading(true);
    setSearch(false);
    setCurrentIndex(0);
    getTransactions();
  }

  function closeModal(isReload) {
    if (visible) {
      setVisible(false);
    }
    if (currentIndex === 2) {
      setCurrentIndex(1);
    }

    // if (isReload) {
    // 	reload();
    // }
  }

  function goBack() {
    setCurrentIndex(1);
  }

  const transactionsView = () => {
    // eslint-disable-next-line default-case
    switch (currentIndex) {
      case 0:
        return (
          <div className="loading-container">
            <CustomLoader loadingText="Loading transactions..." />
          </div>
        );
      case 1:
        return (
          <CustomTable
            tableActionFunction={mobileFilter}
            tableActions={tableActions}
            isReload={true}
            totalPages={totalPages}
            totalItems={totalItems}
            currentPage={pageNo + 1}
            range={range}
            emptyText={emptyText}
            search={search}
            reload={reload}
            error={error}
            items={transactions}
            headers={tableHeaders}
            nextPage={() => setPageNo(pageNo + 1)}
            prevPage={() => setPageNo(pageNo - 1)}
            goToFirstPage={() => setPageNo(0)}
            goToLastPage={() => setPageNo(totalPages - 1)}
            goToPage={goToPage}
          />
        );
      case 2:
        return (
          <div className="mobile-modal-container">
            <div className="custom-card">
              <div className="mobile-card-position">{mobileModal()}</div>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="p-mt-2">
            <TransactionBreakdown
              responseCode={responseCode}
              transactionSearchKey={transactionSearchKey}
              goBack={goBack}
              details={details}
              mobile={isMobileTransaction}
            />
          </div>
        );
    }
  };

  function mobileFilter() {
    openModal(1, true);
  }

  const tableActions = ["FILTER"];

  function saveSearchParams(params) {
    setPageNo(0);
    setSearchParams(params);
  }

  const mobileModal = () => {
    // eslint-disable-next-line default-case
    switch (currentModalIndex) {
      case 1:
        return (
          <TransactionsFilter
            searchFunction={saveSearchParams}
            closeModal={closeModal}
            canDownload={true}
          />
        );
    }
  };

  const modalContent = () => {
    // eslint-disable-next-line default-case
    switch (currentModalIndex) {
      case 1:
        return (
          <TransactionsFilter
            searchFunction={saveSearchParams}
            closeModal={closeModal}
            canDownload={true}
          />
        );
    }
  };

  return (
    <ErrorBoundary>
      <div>
        <Toast ref={toast} />
        <div>
          <CustomModal
            closeModal={closeModal}
            onHide={onHide}
            visible={visible}
            modalContent={modalContent}
          />
        </div>
        <div className="page-title p-text-left">Transactions</div>
        <div className="p-mt-2">
          <CustomBreadcrumb page="Manage Transactions" />
        </div>
        <div className="floating-buttons desktop-scree">
          <div className="p-grid">
            <div className="p-col-7"></div>
            <div className="p-col-5">
              <div className="p-grid">
                <div className="p-col-6"></div>
                <div className="p-col-6">
                  <div
                    className={
                      HELPER.HAS_AUTHORITY("bo_dcir_get_all_transactions") &&
                      currentIndex === 1
                        ? "dcir-show"
                        : "dcir-hide"
                    }
                  >
                    <button
                      disabled={loading || transactions?.length === 0}
                      onClick={() => openModal(1, false)}
                      className="primary-button"
                    >
                      <i className="pi pi-filter" />
                      <span className="hide-btn-text"> Filter</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            HELPER.HAS_AUTHORITY("bo_dcir_get_all_transactions")
              ? "dcir-show"
              : "dcir-hide"
          }
        >
          {transactionsView()}
        </div>
        <div
          className={
            HELPER.HAS_AUTHORITY("bo_dcir_get_all_transactions")
              ? "dcir-hide"
              : "dcir-show"
          }
        >
          <AccessDenied />
        </div>
      </div>
    </ErrorBoundary>
  );
}
